import React, { useEffect, useState } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  CardImage,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import moment from "moment";
import styled from "styled-components";
import axios from "axios";

const { REACT_APP_API_SHINSEGAE_URI } = process.env;

const Weather = ({ projectInfo, height }) => {
  const [weatherData, setWeatherData] = useState({});
  const [dailyWeatherData, setDailyWeatherData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { uuu_latitude, uuu_longitude } = projectInfo;
      const param = uuu_latitude + "," + uuu_longitude;

      const requestWeather = await axios
        .get(
          `${REACT_APP_API_SHINSEGAE_URI}/shinsegae/getweather?path=${param}`
        )
        .then((com) => com.data.data);

      if (requestWeather.current && requestWeather.forecast) {
        setWeatherData(requestWeather.current);
        setDailyWeatherData(requestWeather.forecast.forecastday);
      }
    };

    fetchData();
  }, [projectInfo]);

  return (
    <div className="weather">
      {weatherData.last_updated && dailyWeatherData.length > 0 && (
        <GridLayout
          gap={{
            rows: 5,
            cols: 0,
          }}
          rows={[
            {
              height: (height - 20) * 0.11,
            },
            {
              height: (height - 20) * 0.25,
            },
            {
              height: (height - 20) * 0.22,
            },
            {
              height: (height - 20) * 0.52,
            },
          ]}
          cols={[
            {
              width: "25%",
            },
            {
              width: "25%",
            },
            {
              width: "25%",
            },
            {
              width: "25%",
            },
          ]}
        >
          <GridLayoutItem col={1} row={1} colSpan={4}>
            <WeatherHeader>
              {moment(weatherData.last_updated).format("YYYY년 MM월 DD일 HH시")}
            </WeatherHeader>
          </GridLayoutItem>
          <GridLayoutItem col={1} row={2} colSpan={2}>
            <TodayWeatherImgBox>
              <TodayWeatherImg alt="weather" src={weatherData.condition.icon} />
            </TodayWeatherImgBox>
          </GridLayoutItem>
          <GridLayoutItem col={3} row={2} colSpan={2}>
            <TodayWeatherTextBox>
              <TodayWeatherText>{weatherData.temp_c}℃</TodayWeatherText>
              <TodayWeatherText>
                체감온도: {weatherData.feelslike_c}℃
              </TodayWeatherText>
            </TodayWeatherTextBox>
          </GridLayoutItem>
          <GridLayoutItem col={1} row={3}>
            <DetailWeatherImgBox>
              <DetailWeatherImg
                alt="weather"
                src={"/images/weather/humidity.png"}
              />
              <DetailWeatherText>{weatherData.humidity}%</DetailWeatherText>
            </DetailWeatherImgBox>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={3}>
            <DetailWeatherImgBox>
              <DetailWeatherImg
                alt="weather"
                src={"/images/weather/wind.png"}
              />
              <DetailWeatherText>
                {weatherData.wind_dir} {weatherData.wind_kph}km/h
              </DetailWeatherText>
            </DetailWeatherImgBox>
          </GridLayoutItem>
          <GridLayoutItem col={3} row={3}>
            <DetailWeatherImgBox>
              <DetailWeatherImg
                alt="weather"
                src={"/images/weather/sunset.png"}
              />
              <DetailWeatherText>
                {dailyWeatherData[0].astro["sunset"]}
              </DetailWeatherText>
            </DetailWeatherImgBox>
          </GridLayoutItem>
          <GridLayoutItem col={4} row={3}>
            <DetailWeatherImgBox>
              <DetailWeatherImg
                alt="weather"
                src={"/images/weather/air-quality.png"}
              />
              <DetailWeatherText>
                {weatherData.air_quality["pm2_5"].toFixed(2)}㎍/m³
              </DetailWeatherText>
            </DetailWeatherImgBox>
          </GridLayoutItem>
          <GridLayoutItem col={1} row={4} colSpan={4}>
            <div className="k-card-group">
              {dailyWeatherData.map((pi, index) => {
                return (
                  <Card
                    orientation="vertical"
                    key={index}
                    style={{
                      width: "20%",
                      height: (height - 20) * 0.52,
                      border: "none",
                    }}
                  >
                    <CardImage
                      style={{ width: "50%", margin: "0 auto" }}
                      src={pi.day.condition.icon}
                    />
                    <CardTitle
                      style={{
                        fontSize: "0.7rem",
                        marginBottom: "0",
                        textAlign: "center",
                      }}
                    >
                      {moment.unix(pi.date_epoch).format("MM/DD")}
                    </CardTitle>
                    <CardBody
                      style={{
                        padding: "5px",
                        marginBottom: "0",
                        textAlign: "center",
                      }}
                    >
                      <CardTitle
                        style={{ fontSize: "0.7rem", marginBottom: "1px" }}
                      >
                        기온: {pi.day.avgtemp_c}℃
                      </CardTitle>
                      <CardTitle
                        style={{ fontSize: "0.7rem", marginBottom: "1px" }}
                      >
                        습도: {pi.day.avghumidity}%
                      </CardTitle>
                      <CardTitle style={{ fontSize: "0.7rem" }}>
                        강수확률: {pi.day.daily_chance_of_rain}%
                      </CardTitle>
                    </CardBody>
                  </Card>
                );
              })}
            </div>
          </GridLayoutItem>
        </GridLayout>
      )}
    </div>
  );
};

export default Weather;

const WeatherHeader = styled.div`
  font-size: 0.9rem;
`;

const TodayWeatherImgBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TodayWeatherImg = styled.img`
  width: 50%;
`;

const TodayWeatherTextBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TodayWeatherText = styled.div`
  font-size: 0.8rem;
`;

const DetailWeatherImgBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DetailWeatherImg = styled.img`
  width: 30%;
`;

const DetailWeatherText = styled.div`
  margin-top: 5px;
  font-size: 0.7rem;
`;
