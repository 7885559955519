import React, { useMemo } from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import _ from "lodash";
import {
  headerClass,
  LGChemicalTable,
  CostDivBox,
  CostDivTitle,
  CostDivCost,
} from "../../../Public/StyledComponents";

import { normalCell, listNumberCell } from "../../../Public/TableCell";

const LongTermStock = ({ height, materialInventoryManagerData }) => {
  const longTermStockTableData = useMemo(() => {
    return materialInventoryManagerData
      .filter((com) => com.umatReceivedQuantityDA > 500)
      .map((com, idx) => ({
        ...com,
        No: idx + 1,
      }));
  }, [materialInventoryManagerData]);

  const longTermStockCostSum = useMemo(() => {
    return Number(
      (
        _.sumBy(longTermStockTableData, "umatTotalPriceSNL") / 100000000
      ).toFixed(1)
    );
  }, [longTermStockTableData]);

  return (
    <>
      <GridLayoutItem row={3} col={2}>
        <CostDivBox>
          <CostDivTitle>장기보유재고</CostDivTitle>
          <CostDivCost>{longTermStockCostSum} 억원</CostDivCost>
        </CostDivBox>
      </GridLayoutItem>
      <GridLayoutItem row={4} col={2}>
        <LGChemicalTable>
          <Grid
            className={headerClass}
            style={{
              width: "100%",
              maxHeight: height * 0.35,
            }}
            data={longTermStockTableData}
          >
            <GridColumn
              title="No."
              field="No"
              width={45}
              cell={listNumberCell}
            />
            <GridColumn
              title="Category"
              field="umatCategoryPD"
              cell={normalCell}
            />
            <GridColumn
              title="Material Name"
              field="uuu_cm5_name"
              cell={normalCell}
            />
            <GridColumn
              title="재고 금액"
              field="umatTotalPriceSNL"
              width={100}
              cell={normalCostCell}
            />
            <GridColumn title="회전율" field="" width={80} cell={normalCell} />
          </Grid>
        </LGChemicalTable>
      </GridLayoutItem>
    </>
  );
};

export default LongTermStock;

const normalCostCell = (props) => {
  const { field, dataItem } = props;

  return (
    <td colSpan={props.colSpan} style={{ ...props.style, fontSize: "0.7rem" }}>
      {(dataItem[field] / 100000000).toFixed(2)}억원
    </td>
  );
};
