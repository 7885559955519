import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import "./VendorEvaluationRouter.scss";

const VendorEvaluationRouter = () => {
  const history = useHistory();

  const [vendorName, setVendorName] = useState("");
  const [texID, setTexID] = useState("");
  const [overLapCheck, setOverLapCheck] = useState(false);

  const handleRouter = (e) => {
    const { name } = e.target;

    history.push({
      pathname:`/vevaluation/router/${name}`,
      state: {
        vendorName,
        texID,
      },
    })
  };

  const handleInput = (e) => {
    const { value } = e.target;

    setVendorName(value);
  };

  const handleVendorName = (e) => {
    const { value } = e.target;

    setVendorName(value);
  };

  const handleTexId = (e) => {
    const { value } = e.target;
    setTexID(value.replaceAll(" ", ""));
  };

  const checkVendorTexID = async () => {
    if (texID.length > 0 && texID.length === 12) {
      let body = {
        bpname: "Vendor Evaluation (New) W",
      };

      const res = await axios.post(`http://localHost:8080/getbprecord2`, body);

      const axiosDataArr = res.data.data.data.map((com) =>
        com.uveTaxIDTB16 === null
          ? com.uveTaxIDTB16
          : com.uveTaxIDTB16.replaceAll("-", "")
      );

      const overLapFind = axiosDataArr.find(
        (com) => com === texID.replaceAll("-", "")
      );

      if (overLapFind) {
        alert("이미 사전평가를 완료한 업체입니다.");
      } else {
        setOverLapCheck(true);
      }
    } else {
      alert("올바른 사업자등록번호를 기입하여 주시기 바랍니다.");
    }
  };

  return (
    <div className="vendorEvaluationRouter">
      
        <div className="inspectionVendorBox">
          <div>이미 존재하는 사업자등록번호: 880-81-01710 (샘플용)</div>
          <input
            onChange={handleTexId}
            value={texID}
            placeholder="사업자등록번호 12자리( - 포함)"
          />
          <button onClick={checkVendorTexID}>조회</button>
        </div>
      

      {overLapCheck && (
        <>
          <input
            onChange={handleVendorName}
            value={vendorName}
            placeholder="업체이름"
          />
          <button name="construction" onClick={handleRouter}>
            construction
          </button>
          <button name="procurement" onClick={handleRouter}>
            procurement
          </button>
          <button name="design" onClick={handleRouter}>
            design
          </button>
          <button name="service" onClick={handleRouter}>
            service
          </button>
        </>
      )}
    </div>
  );
};

export default VendorEvaluationRouter;
