import React, { useEffect, useState } from "react";
import { GridLayout } from "@progress/kendo-react-layout";
import axios from "axios";
import moment from "moment";
import styled from "styled-components";
import { bplist } from "../../../Public/BPList";
import PurchaseStatus from "./PurchaseStatus";
import PurchaseTable from "./PurchaseTable";

const { REACT_APP_API_LGCHEMICAL_URI } = process.env;

const { PurchaseOrdersFM, MaterialsReceived } = bplist;

const this_months = new Date(moment(new Date()).format("YYYY-MM-DD 00:00:00"));
this_months.setDate(1);

const this_months_last = new Date(
  moment(new Date()).add(1, "months").format("YYYY-MM-DD 00:00:00")
);

this_months_last.setDate(0);

const PurchaseManagement = ({ project_code, height }) => {
  const [purchaseOrdersFMData_Original, setPurchaseOrdersFMData_Original] =
    useState([]);
  const [materialsReceivedData_Original, setMaterialsReceivedData_Original] =
    useState([]);
  const [purchaseOrdersFMData_concat, setPurchaseOrdersFMData_concat] =
    useState([]);

  const [dateRange, setDateRange] = useState({
    start: this_months,
    end: this_months_last,
  });

  useEffect(() => {
    const fetchData = async () => {
      const request_purchaseOrdersFM = await axios
        .post(
          `${REACT_APP_API_LGCHEMICAL_URI}/lgchemical/getbprecord?path=${project_code}`,
          PurchaseOrdersFM
        )
        .then((com) => {
          console.log(com.data.data);
          return com.data.data

            .filter((com) => com._bp_lineitems !== undefined)
            .map((com2) => ({
              ...com2,
              effective_date: new Date(
                com2.uuu_effective_date === null
                  ? com2.uuu_creation_date.slice(0, 10)
                  : com2.uuu_effective_date.slice(0, 10)
              ),
            }));
        });

      const request_materialsReceived = await axios
        .post(
          `${REACT_APP_API_LGCHEMICAL_URI}/lgchemical/getbprecord?path=${project_code}`,
          MaterialsReceived
        )
        .then((com) => com.data.data);

      setPurchaseOrdersFMData_Original(request_purchaseOrdersFM);
      setMaterialsReceivedData_Original(request_materialsReceived);

      const request_purchaseOrdersFM_flat = request_purchaseOrdersFM.reduce(
        (acc, cur) => {
          const { effective_date, record_no } = cur;

          const cur_bp = cur._bp_lineitems.map((com) => {
            return Object.assign(com, { effective_date, record_no });
          });

          const acc_concat = acc.concat(cur_bp);

          return acc_concat;
        },
        []
      );

      setPurchaseOrdersFMData_concat(request_purchaseOrdersFM_flat);
    };

    fetchData();
  }, [project_code]);

  const handleDateRange = (e) => {
    const { value } = e;

    setDateRange((prev) => ({ ...prev, ...value }));
  };

  return (
    <PurchaseManagementDiv>
      <GridLayout
        gap={{
          rows: 0,
          cols: 0,
        }}
        rows={[
          {
            height: height * 0.1,
          },
          {
            height: height * 0.4,
          },
          {
            height: height * 0.1,
          },
          {
            height: height * 0.4,
          },
        ]}
        cols={[
          {
            width: "50%",
          },
          {
            width: "50%",
          },
        ]}
      >
        <PurchaseStatus
          height={height}
          dateRange={dateRange}
          handleDateRange={handleDateRange}
          purchaseOrdersFMData_Original={purchaseOrdersFMData_Original}
          materialsReceivedData_Original={materialsReceivedData_Original}
        />
        <PurchaseTable
          height={height}
          dateRange={dateRange}
          purchaseOrdersFMData_concat={purchaseOrdersFMData_concat}
          materialsReceivedData_Original={materialsReceivedData_Original}
        />
      </GridLayout>
    </PurchaseManagementDiv>
  );
};

export default PurchaseManagement;

const PurchaseManagementDiv = styled.div``;
