import React, { useEffect, useState } from "react";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";

const OverallPerformanceChart = ({ height, com }) => {
  const [chartData, setChartData] = useState(
    { name: "계획", value: 0 },
    { name: "실적", value: 0 }
  );

  useEffect(() => {
    setChartData([
      { name: "계획", value: com.plan },
      { name: "실적", value: com.actual },
    ]);
  }, [com]);

  const labelContent = (e) => e.value;

  const seriesLabels = {
    visible: true,
    padding: 0,
    font: "0.6rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
    content: labelContent,
  };

  const handleColor = (props) => {
    if (props.category === "실적") {
      return "#4F81BD";
    } else {
      return "rgb(155, 187, 89)";
    }
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points } = props;

    return (
      <div>
        <div>{com.name.replace("사업본부", "")}</div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              <div>
                {point.series.data.map((com, idx2) => {
                  return (
                    <div key={idx + "_" + idx2}>
                      {idx2 === 0 ? "목표" : "실적"}:{" "}
                      {Number(com.value.toFixed(0)).toLocaleString()}t
                    </div>
                  );
                })}
                {/* <div>
                  차이:{" "}
                  {(
                    point.series.data[0]["value"] -
                    point.series.data[1]["value"]
                  ).toLocaleString()}
                  t
                </div> */}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <GridLayout
        style={{ height, alignItems: "" }}
        cols={[
          {
            width: "100%",
          },
        ]}
        rows={[
          {
            height: height * 0.35,
          },
          {
            height: height * 0.65,
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem col={1} row={1} style={{ padding: "0 5px" }}>
          <Card
            style={{
              padding: "0",
              height: height * 0.35,
              border: "none",
            }}
          >
            <CardBody
              style={{
                padding: "0",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <CardTitle style={{ font: "0.8rem Pretendard-B" }}>
                {com.name.replace("사업본부", "")}
              </CardTitle>

              <CardTitle
                style={{
                  font: "0.6rem Pretendard-R",
                  color: com.dev > 0 ? "rgb(0, 107, 84)" : "rgb(158, 16, 48)",
                }}
              >
                {Number(com.percent)}%{" "}
              </CardTitle>
              <CardTitle
                style={{
                  font: "0.6rem Pretendard-R",
                  color: com.dev > 0 ? "rgb(0, 107, 84)" : "rgb(158, 16, 48)",
                }}
              >
                {Number(com.dev).toLocaleString()}t{" "}
                {com.dev > 0 ? "절감" : "초과"}
              </CardTitle>
            </CardBody>
          </Card>
        </GridLayoutItem>
        <Chart
          style={{
            height: height * 0.65,
          }}
        >
          <ChartArea background="none" />
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartTooltip shared={true} render={sharedTooltipRender} />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              maxDivisions={10}
              startAngle={45}
              labels={seriesLabels}
              majorGridLines={{ step: 100 }}
              minorGridLines={{ step: 100 }}
            />
          </ChartCategoryAxis>
          <ChartValueAxis>
            <ChartValueAxisItem
              labels={{ font: "0.7rem Arial, sans-serif" }}
              majorTicks={{ step: 100 }}
              minorTicks={{ step: 100 }}
              majorGridLines={{ step: 10 }}
              minorGridLines={{ step: 10 }}
              visible={false}
            />
          </ChartValueAxis>
          <ChartSeries>
            <ChartSeriesItem
              type={"column"}
              data={chartData}
              autoFit={true}
              categoryField="name"
              color={handleColor}
              // labels={{
              //   visible: true,
              //   content: (e) => {
              //     return e.value === 0 ||
              //       e.value === null ||
              //       typeof e.value === "undefined"
              //       ? ""
              //       : Number(e.value.toFixed(0)).toLocaleString() + "t";
              //   },
              //   background: "none",
              //   color: "black",
              // }}
            />
          </ChartSeries>
        </Chart>
      </GridLayout>
    </div>
  );
};

export default OverallPerformanceChart;
