import React, { useEffect, useState } from "react";
// import moment from "moment";
// import _ from "lodash";
import MonthPerformanceChart from "../../../Components/MonthPerformanceChart";

const TotalMonthPerformance = ({
  height,
  endDate,
  dateRangeData,
  base_code,
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const total = dateRangeData.find((com) => com.name === "전사");

    setChartData(
      total === undefined
        ? []
        : dateRangeData.find((com) => com.name === "전사")["dateRange"]
    );
  }, [dateRangeData]);

  return (
    <div>
      <MonthPerformanceChart
        height={height - 45 - 32}
        chartData={chartData}
        endDate={endDate}
        MonthPerformanceToggle={true}
      />
    </div>
  );
};

export default TotalMonthPerformance;
