import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import moment from "moment";
import _ from "lodash";

import TotalTable from "./Table/TotalTable/TotalTable";
import SectorTable from "./Table/SectorTable/SectorTable";
import OverallPerformanceChart from "./Chart/OverallPerformanceChart";
import OverallManbPerformanceDetail from "./Chart/OverallManbPerformanceDetail";
import MonthPerformanceChart from "./Chart/MonthPerformanceChart";
import OverallMonthIncreaseChart from "./Chart/OverallMonthIncreaseChart";
import "./ReportPageOverall.scss";

const ReportPageOverall = () => {
  const location = useLocation();
  const printRef = useRef();
  const {
    dateRange,
    // overallTable,
    // sectorTable,
    // targetPerformance,
    // targetManbPerformance,
    projectListData,
    projectActTotalData,
  } = location.state;

  const [userNameToggle, setUserNameToggle] = useState(false);
  const [userName, setUserName] = useState("");
  const [monthPerformaceChart, setMonthPerformaceChart] = useState([]);
  const [monthSectorPerformaceChart, setMonthSectorPerformaceChart] = useState(
    []
  );

  const [overallTable, setOverallTable] = useState([]);
  const [sectorTable, setSectorTable] = useState([]);
  const [targetPerformance, setTargetPerformance] = useState([]);
  const [targetManbPerformance, setTargetManbPerformance] = useState([]);

  useEffect(() => {
    const uniq_sector_arr_obj_table = _.uniqBy(
      projectListData,
      "uxesg_SectorName"
    ).map((com) => ({ name: com.uxesg_SectorName }));

    uniq_sector_arr_obj_table.unshift({ name: "전사" });

    const sector_arr_data_sum_table = uniq_sector_arr_obj_table.map((com) => {
      if (com.name === "전사") {
        return { ...com, data: projectListData };
      } else {
        return {
          ...com,
          data: projectListData.filter(
            (com2) => com2.uxesg_SectorName === com.name
          ),
        };
      }
    });

    const total_table = sector_arr_data_sum_table.find(
      (com) => com.name === "전사"
    );
    const sector_table = sector_arr_data_sum_table.filter(
      (com) => com.name !== "전사"
    );

    const chartDatas_base_set_target = sector_arr_data_sum_table
      .map((com) => ({
        ...com,
        plan: Number(
          _.sumBy(com.data, "project_total_target_weight").toFixed(0)
        ),
        actual: Number(
          _.sumBy(com.data, "project_total_act_weight").toFixed(0)
        ),
      }))
      .map((com) => ({
        ...com,
        dev: com.plan - com.actual,
        percent:
          Number(((com.actual / com.plan) * 100).toFixed(2)) === Infinity ||
          isNaN(Number(((com.actual / com.plan) * 100).toFixed(2)))
            ? 0
            : Number(((com.actual / com.plan) * 100).toFixed(2)),
      }));

    const chartDatas_base_set = sector_arr_data_sum_table.map((com) => {
      const base_data = [];
      com.data.forEach((com2) => base_data.push(com2.present_plan_data));

      const base_data_flat = base_data.flat();

      const base_code_set = base_code_table
        .map((com2) => ({
          ...com2,
          data: base_data_flat.filter((com3) => com3.code === com2.code),
        }))
        .map((com2) => ({
          ...com2,
          plan: _.sumBy(com2.data, "plan_target_weight_cum"),
          plan_cost: _.sumBy(com2.data, "plan_cost_target_cum"),
          actual: _.sumBy(com2.data, "act_weight"),
          actual_cost: _.sumBy(com2.data, "act_cost"),
        }))
        .map((com2) => ({
          ...com2,
          dev: com2.plan - com2.actual,
          dev_cost: com2.plan_cost - com2.actual_cost,
        }));

      return {
        ...com,
        base_code: base_code_set,
      };
    });

    setOverallTable(total_table.data);
    setSectorTable(sector_table);
    setTargetPerformance(chartDatas_base_set_target);
    setTargetManbPerformance(chartDatas_base_set);

    const uniq_sector = _.uniqBy(projectListData, "uxesg_SectorName").map(
      (com) => com.uxesg_SectorName
    );

    const uniq_sector_arr_obj = uniq_sector.map((com) => ({ name: com }));

    uniq_sector_arr_obj.unshift({ name: "전사" });

    const datefilterData = projectActTotalData.filter(
      (com) =>
        new Date(com.uxesg_CarryDate.slice(0, 10)) >= dateRange.startDate &&
        new Date(com.uxesg_CarryDate.slice(0, 10)) <= dateRange.endDate
    );

    const monthRange = createDateRange(dateRange.startDate, dateRange.endDate);

    const sector_arr_data_sum = uniq_sector_arr_obj
      .map((com) => {
        if (com.name === "전사") {
          return { ...com, data: datefilterData };
        } else {
          return {
            ...com,
            data: datefilterData.filter(
              (com2) => com2.uxesg_SectorName === com.name
            ),
          };
        }
      })
      .map((com) => ({
        ...com,
        data: _.sortBy(
          com.data.map((com2) => ({
            ...com2,
            carryDate: new Date(com2.uxesg_CarryDate.slice(0, 10)),
            carryMonth: moment(
              new Date(com2.uxesg_CarryDate.slice(0, 10))
            ).format("YYYY-MM"),
            carryMonth2: moment(
              new Date(com2.uxesg_CarryDate.slice(0, 10))
            ).format("YY-MM"),
          })),
          "carryDate"
        ),
      }))
      .map((com) => ({
        ...com,
        dateRange: monthRange.map((com2) => ({
          ...com2,
          data: com.data.filter((com3) => com3.carryMonth === com2.months),
          value: _.sumBy(
            com.data.filter((com3) => com3.carryMonth === com2.months),
            "uxesg_CarryQty2"
          ),
          cost: Number(
            (
              _.sumBy(
                com.data.filter((com3) => com3.carryMonth === com2.months),
                "uxesg_CostTotal"
              ) / 1000000
            ).toFixed(0)
          ),
        })),
      }));

    const monthPerformance_base_set =
      sector_arr_data_sum.find((com) => com.name === "전사") === undefined
        ? []
        : sector_arr_data_sum.find((com) => com.name === "전사")["dateRange"];

    setMonthPerformaceChart(monthPerformance_base_set);

    const deleteTotal = sector_arr_data_sum.filter(
      (com) => com.name !== "전사"
    );

    const base_code_insert = deleteTotal.map((com) => ({
      ...com,
      base_code_data: base_code
        .map((com2) => {
          if (com2.code === "others") {
            return {
              ...com2,
              data: com.data.filter(
                (com3) =>
                  com3.uxesg_WasteCode !== "40-01-01" &&
                  com3.uxesg_WasteCode !== "40-02-07" &&
                  com3.uxesg_WasteCode !== "40-02-06" &&
                  com3.uxesg_WasteCode !== "40-04-14"
              ),
            };
          } else {
            return {
              ...com2,
              data: com.data.filter(
                (com3) => com3.uxesg_WasteCode === com2.code
              ),
            };
          }
        })
        .map((com2) => ({
          ...com2,
          data:
            com2.data.length > 0
              ? _.sortBy(
                  com2.data.map((com3) => ({
                    ...com3,
                    carryDate: new Date(com3.uxesg_CarryDate.slice(0, 10)),
                    carryMonth: moment(
                      new Date(com3.uxesg_CarryDate.slice(0, 10))
                    ).format("YYYY-MM"),
                    carryMonth2: moment(
                      new Date(com3.uxesg_CarryDate.slice(0, 10))
                    ).format("YY-MM"),
                  })),
                  "carryDate"
                )
              : [],
        }))
        .map((com2) => ({
          ...com2,
          data2: _.uniqBy(com2.data, "carryMonth").map((com3) => ({
            date: com3.carryDate,
            month: com3.carryMonth,
            month2: com3.carryMonth2,
            value: _.sumBy(
              com2.data.filter((com4) => com4.carryMonth === com3.carryMonth),
              "uxesg_CarryQty2"
            ),
            cost: _.sumBy(
              com2.data.filter((com4) => com4.carryMonth === com3.carryMonth),
              "uxesg_CostTotal"
            ),
          })),
        }))
        .map((com2) => {
          const findIndex = com2.data2.findIndex(
            (com3) =>
              com3.month ===
              moment(new Date(dateRange.endDate)).format("YYYY-MM")
          );

          const findIndex2 = com2.data2.findIndex(
            (com3) =>
              com3.month ===
              moment(new Date(dateRange.endDate))
                .subtract(1, "months")
                .format("YYYY-MM")
          );

          return {
            ...com2,
            data3: [
              {
                month: moment(new Date(dateRange.endDate))
                  .subtract(1, "months")
                  .format("YYYY-MM"),
                value:
                  com2.data2[findIndex2]?.["value"] === undefined
                    ? 0
                    : com2.data2[findIndex2]?.["value"],
                cost:
                  com2.data2[findIndex2]?.["cost"] === undefined
                    ? 0
                    : com2.data2[findIndex2]?.["cost"],
              },
              {
                month: moment(new Date(dateRange.endDate)).format("YYYY-MM"),
                value:
                  com2.data2[findIndex]?.["value"] === undefined
                    ? 0
                    : com2.data2[findIndex]?.["value"],
                cost:
                  com2.data2[findIndex]?.["cost"] === undefined
                    ? 0
                    : com2.data2[findIndex]?.["cost"],
              },
            ],
          };
        }),
    }));

    setMonthSectorPerformaceChart(base_code_insert);
  }, [location]);

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleUserToggleKey = (e) => {
    if (e.code === "Enter" || e.code === "Tab" || e.code === "Escape") {
      setUserNameToggle(true);
    }
  };

  const handleUserToggleClick = () => {
    setUserNameToggle(false);
  };

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button style={{ margin: "10px 0px 0px 10px" }}>
            <span className="k-icon k-i-print" style={{ fontSize: "24px" }} />
          </button>
        )}
        content={() => printRef.current}
      />
      <div className="reportPageTotal" ref={printRef}>
        <div className="reportPage">
          <div className="reportHeader">
            <div className="reportTitle">
              {moment(new Date()).format("YYYY년 MM월")} ESG Report (폐기물 배출
              실적)
            </div>
            <div className="reportSignBox">
              <div className="reportSignBoxLeft">결재</div>
              <div className="reportSignBoxRight">
                <div className="reportSignBoxDetail">
                  <div className="reportSignBoxDetailTitle">작성</div>
                </div>
                <div className="reportSignBoxDetail">
                  <div className="reportSignBoxDetailTitle">검토</div>
                </div>
                <div className="reportSignBoxDetail">
                  <div className="reportSignBoxDetailTitle">승인</div>
                </div>
              </div>
            </div>
          </div>

          <div className="reportBlank" />

          <div className="reportSiteInfo">
            <div className="reportSiteInfoTitle">현장명</div>
            <div className="reportSiteInfoContent">전사</div>
            <div className="reportSiteInfoTitle">담당자</div>
            {userNameToggle ? (
              <div
                className="reportSiteInfoContent"
                onClick={() => handleUserToggleClick()}
              >
                {userName}
              </div>
            ) : (
              <input
                className="reportSiteInfoContent"
                onChange={(e) => handleUserName(e)}
                onKeyDown={(e) => handleUserToggleKey(e)}
                value={userName}
              />
            )}

            <div className="reportSiteInfoTitle">작성일자</div>
            <div className="reportSiteInfoContent">
              {moment(new Date()).format("YYYY. MM. DD")}
            </div>
          </div>

          <div className="reportBlank" />

          <div className="reportIndex">
            1. 현재 목표 / 실적 (공정률 대비 달성률)
          </div>
          <div className="reportBlank" />
          <div className="overallreportTableBox">
            <div className="overallreportTableBoxLeft">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: "0.7rem", fontFamily: "Pretendard-B" }}>
                  [전사 총괄]
                </div>
                <div style={{ fontSize: "0.7rem", fontFamily: "Pretendard-B" }}>
                  [반출량 : t / 비용 : 백만원]
                </div>
              </div>
              <TotalTable height={170} tableDatas={overallTable} />
            </div>
            <div className="overallreportTableBoxRight">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ fontSize: "0.7rem", fontFamily: "Pretendard-B" }}>
                  [사업본부별]
                </div>
                <div style={{ fontSize: "0.7rem", fontFamily: "Pretendard-B" }}>
                  [반출량 : t / 비용 : 백만원]
                </div>
              </div>
              <SectorTable height={170} tableDatas={sectorTable} />
            </div>
          </div>
          <div className="reportBlank" />

          <div className="reportIndex">2. 목표 달성 실적</div>

          <div className="reportBlank" />
          <div className="reportTargetPerformanceBox">
            {targetPerformance.map((com, idx) => {
              return (
                <div className={`reportTargetPerformance`} key={idx}>
                  <OverallPerformanceChart height={150} com={com} />
                </div>
              );
            })}
          </div>

          <div className="reportIndex">3. 성상별 목표 달성 실적</div>

          {targetManbPerformance.map((com0, idx) => {
            return (
              <div
                col={2}
                row={idx + 1}
                key={idx}
                style={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                  padding: "10px 0",
                }}
              >
                <OverallManbPerformanceDetail
                  height={130}
                  com={com0}
                  key={idx}
                />
              </div>
            );
          })}
        </div>
        <div className="reportPage">
          <div className="reportIndex">
            4. 월간 배출량 ({moment(dateRange.startDate).format("YY. MM. DD")} ~{" "}
            {moment(dateRange.endDate).format("YY. MM. DD")})
          </div>
          <div className="reportBlank" />
          <MonthPerformanceChart
            height={242}
            chartData={monthPerformaceChart}
            endDate={moment(dateRange.endDate).format("YYYY-MM")}
            MonthPerformanceToggle={true}
          />
          <div className="reportBlank" />
          <div className="reportIndex">5. 본부별 배출량 </div>
          <div className="reportBlank" />
          {monthSectorPerformaceChart.map((com, idx) => {
            return (
              <div className="overallIncreaseBox" key={idx}>
                <div
                  className="overallIncreaseBoxLeft"
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                    padding: "10px 0",
                    fontSize: "0.6rem",
                  }}
                >
                  <div className="overallMonthIncreaseBox">
                    <div
                      className="overallMonthIncrease"
                      style={{
                        fontSize: "0.7rem",
                      }}
                    >
                      {com.name.replace("사업본부", "")}
                    </div>
                  </div>
                </div>
                <div
                  className="overallIncreaseBoxRight"
                  style={{
                    borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                    padding: "10px 0",
                  }}
                >
                  <OverallMonthIncreaseChart
                    height={250}
                    chartData={com.base_code_data}
                    endDate={dateRange.endDate}
                  />
                </div>
              </div>
            );
          })}
          <div className="reportBlank" />
        </div>
      </div>
    </>
  );
};

export default ReportPageOverall;

// const color = {
//   green: "rgb(169, 208, 142)",
//   red: "rgb(244, 178, 132)",
// };

// const base_code = ["40-01-01", "40-02-07", "40-02-06", "40-04-14"];

const createDateRange = (startDate, endDate) => {
  const monthDiff = moment(new Date(endDate)).diff(
    new Date(startDate),
    "months"
  );

  const monthRange = [];

  for (let i = 0; i <= monthDiff; i++) {
    monthRange.push({
      months: moment(startDate).add(i, "months").format("YYYY-MM"),
    });
  }

  return monthRange;
};

const base_code = [
  { code: "40-01-01", name: "폐콘크리트" },
  { code: "40-02-07", name: "폐합성수지" },
  { code: "40-02-06", name: "폐목재" },
  { code: "40-04-14", name: "혼합건설폐기물" },
  { code: "others", name: "기타" },
];

const base_code_table = [
  { code: "40-01-01", name: "폐콘크리트" },
  { code: "40-02-07", name: "폐합성수지" },
  { code: "40-02-06", name: "폐목재" },
  { code: "40-04-14", name: "혼합건설폐기물" },
];
