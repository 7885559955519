import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import moment from "moment";

const SlotDialog = ({ slotList }) => {
  return (
    <Grid
      style={{ fontSize: "0.8rem", width: "568px", maxHeight: "150px" }}
      data={slotList.map((com) => {
        return {
          ...com,
          Start: moment(com.Start).format("YYYY년 MM월 DD일 HH시 mm분"),
          End: moment(com.End).format("YYYY년 MM월 DD일 HH시 mm분"),
          Duration: slotList.length > 0 && com.Range.length,
          approved:
            slotList.length > 0 && com.ApprovedIDS === 1
              ? "승인 대기"
              : "승인 완료",
        };
      })}
      className="reservationTable"
    >
      <GridColumn field="Title" title="업체명" />
      <GridColumn field="Start" title="시작일" />
      <GridColumn field="End" title="종료일" />
      <GridColumn field="Duration" title="기간" />
      <GridColumn
        field="approved"
        title="승인여부
      "
      />
    </Grid>
  );
};

export default SlotDialog;
