import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import MonthIncreaseChart from "../../../Components/MonthIncreaseChart";

const MonthIncrease = ({ height, dateRange, dateRangeData }) => {
  const gridHeight = height - 45 - 32;
  const [chartData, setChartData] = useState([]);
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM"));

  useEffect(() => {
    setEndDate(moment(dateRange.endDate).format("YYYY-MM"));

    const base_code = [
      { code: "40-01-01", name: "폐콘크리트" },
      { code: "40-02-07", name: "폐합성수지" },
      { code: "40-02-06", name: "폐목재" },
      { code: "40-04-14", name: "혼합건설폐기물" },
      { code: "others", name: "기타" },
    ];

    const dateRangeData_3 = base_code
      .map((com) => {
        if (com.code === "others") {
          return {
            ...com,
            data: dateRangeData.filter(
              (com2) =>
                com2.uxesg_WasteCode !== "40-01-01" &&
                com2.uxesg_WasteCode !== "40-02-07" &&
                com2.uxesg_WasteCode !== "40-02-06" &&
                com2.uxesg_WasteCode !== "40-04-14"
            ),
          };
        } else {
          return {
            ...com,
            data: dateRangeData.filter(
              (com2) => com2.uxesg_WasteCode === com.code
            ),
          };
        }
      })
      .map((com) => ({
        ...com,
        data:
          com.data.length > 0
            ? _.sortBy(
                com.data.map((com2) => ({
                  ...com2,
                  carryDate: new Date(com2.uxesg_CarryDate.slice(0, 10)),
                  carryMonth: moment(
                    new Date(com2.uxesg_CarryDate.slice(0, 10))
                  ).format("YYYY-MM"),
                  carryMonth2: moment(
                    new Date(com2.uxesg_CarryDate.slice(0, 10))
                  ).format("YY-MM"),
                })),
                "carryDate"
              )
            : [],
      }))
      .map((com) => ({
        ...com,
        data2: _.uniqBy(com.data, "carryMonth").map((com2) => ({
          date: com2.carryDate,
          month: com2.carryMonth,
          month2: com2.carryMonth2,
          value: _.sumBy(
            com.data.filter((com3) => com3.carryMonth === com2.carryMonth),
            "uxesg_CarryQty2"
          ),
          cost: _.sumBy(
            com.data.filter((com3) => com3.carryMonth === com2.carryMonth),
            "uxesg_CostTotal"
          ),
        })),
      }));

    const monthsData = dateRangeData_3.map((com) => {
      const findIndex = com.data2.findIndex((com2) => com2.month === endDate);
      const findIndex2 = com.data2.findIndex(
        (com2) =>
          com2.month ===
          moment(new Date(endDate)).subtract(1, "months").format("YYYY-MM")
      );

      return {
        ...com,
        data3: [
          {
            month: moment(new Date(endDate))
              .subtract(1, "months")
              .format("YYYY-MM"),
            value:
              com.data2[findIndex2]?.["value"] === undefined
                ? 0
                : com.data2[findIndex2]?.["value"],
            cost:
              com.data2[findIndex2]?.["cost"] === undefined
                ? 0
                : com.data2[findIndex2]?.["cost"],
          },
          {
            month: endDate,
            value:
              com.data2[findIndex]?.["value"] === undefined
                ? 0
                : com.data2[findIndex]?.["value"],
            cost:
              com.data2[findIndex]?.["cost"] === undefined
                ? 0
                : com.data2[findIndex]?.["cost"],
          },
        ],
      };
    });

    setChartData(monthsData);
  }, [dateRangeData, dateRange]);

  return (
    <div>
      <MonthIncreaseChart
        height={gridHeight}
        chartData={chartData}
        endDate={endDate}
      />
    </div>
  );
};

export default MonthIncrease;
