import React from "react";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import {
  Chart,
  ChartTitle,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";
import moment from "moment";

const MonthIncreaseChart = ({ height, chartData, endDate }) => {
  const labelContent = (e) =>
    e.value === null || typeof e.value === "undefined"
      ? ""
      : moment(new Date(e.value)).format("YY-MM");

  const seriesLabels = {
    visible: true,
    padding: 0,
    font: "0.5rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
    content: labelContent,
  };

  const handleColor = (props) => {
    if (props.category === moment(new Date(endDate)).format("YYYY-MM")) {
      return "#F0696E";
    } else {
      return "#4F81BD";
    }
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    return (
      <div>
        <div>{categoryText}</div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              <div>
                반출량: {Number(point.value.toFixed(0)).toLocaleString()}t
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <GridLayout
      style={{ height, alignItems: "" }}
      cols={[
        {
          width: "20%",
        },
        {
          width: "20%",
        },
        {
          width: "20%",
        },
        {
          width: "20%",
        },
        {
          width: "20%",
        },
      ]}
      rows={[
        {
          height: (height / 5) * 3,
        },
        {
          height: (height / 5) * 2,
        },
      ]}
      gap={{
        cols: 0,
        rows: 0,
      }}
    >
      {chartData.map((com, idx) => {
        return (
          <GridLayoutItem col={idx + 1} row={1} key={idx}>
            <Chart
              style={{
                height: (height / 5) * 3,
              }}
            >
              <ChartTitle
                text={com.name}
                position="top"
                align="center"
                font="0.7rem Pretendard-B"
              />
              <ChartArea background="none" />
              <ChartLegend position="bottom" orientation="horizontal" />
              <ChartTooltip shared={true} render={sharedTooltipRender} />
              <ChartCategoryAxis>
                <ChartCategoryAxisItem
                  // categories={originalChartData.categories}
                  maxDivisions={10}
                  startAngle={45}
                  labels={seriesLabels}
                  majorGridLines={{ step: 100 }}
                  minorGridLines={{ step: 100 }}
                />
              </ChartCategoryAxis>
              <ChartValueAxis>
                <ChartValueAxisItem
                  visible={false}
                  labels={{ font: "0.7rem Arial, sans-serif" }}
                  majorTicks={{ step: 100 }}
                  minorTicks={{ step: 100 }}
                  majorGridLines={{ step: 10 }}
                  minorGridLines={{ step: 10 }}
                />
              </ChartValueAxis>
              <ChartSeries>
                <ChartSeriesItem
                  type={"column"}
                  data={com.data3}
                  autoFit={true}
                  categoryField="month"
                  color={handleColor}
                  labels={{
                    visible: true,
                    content: (e) => {
                      return e.value === 0 ||
                        e.value === null ||
                        typeof e.value === "undefined"
                        ? ""
                        : Number(e.value.toFixed(0)).toLocaleString() + "t";
                    },
                    background: "none",
                    color: "black",
                    font: "0.6rem pretendard-R",
                    // position: "top",
                  }}
                />
              </ChartSeries>
            </Chart>
          </GridLayoutItem>
        );
      })}
      {chartData.map((com, idx) => {
        return (
          <GridLayoutItem
            col={idx + 1}
            row={2}
            key={idx}
            style={{ padding: "0 5px" }}
          >
            <Card
              style={{
                padding: "0",
                height: (height / 5) * 2,
              }}
            >
              <CardBody
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <CardTitle style={{ font: "0.5rem Pretendard-B" }}>
                  처리량 증감:{" "}
                </CardTitle>
                <p style={{ font: "0.5rem Pretendard-R" }}>
                  {Number(
                    (com.data3[1]["value"] - com.data3[0]["value"]).toFixed(0)
                  ).toLocaleString()}
                  t
                </p>
                <CardTitle style={{ font: "0.5rem Pretendard-B" }}>
                  처리비용 증감:{" "}
                </CardTitle>
                <p style={{ font: "0.5rem Pretendard-R" }}>
                  {" "}
                  {Number(
                    (
                      (com.data3[1]["cost"] - com.data3[0]["cost"]) /
                      1000000
                    ).toFixed(1)
                  ).toLocaleString()}
                  백만원
                </p>
              </CardBody>
            </Card>
          </GridLayoutItem>
        );
      })}
    </GridLayout>
  );
};

export default MonthIncreaseChart;
