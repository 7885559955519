//DTS 본진
const bpname = {
  wasteCoefficient: "발생 예정량",
  wasteTarget: "폐기물 목표 관리",
  wasteVendor: "폐기물 업체 등록 (라인)",
  wasteContract: "폐기물 계약 등록",
  wasteProject: "현장개요 W",
  wastePerformance: "폐기물 실적",
  wasteDocument: "법정서류 등록",
  wasteError: "오류인계서",
  wasteBaseCode: "표준 폐기물 분류",
  wasteWaylist: "폐기물 처리방법",
};

// 한화건설 개발 서버
// const bpname = {
//   wasteCoefficient: "폐기물 발생 계수",
//   wasteTarget: "폐기물 목표 관리",
//   wasteVendor: "폐기물 업체 등록",
//   wasteContract: "폐기물 계약 등록",
//   wasteProject: "폐기물 현장개요",
//   wastePerformance: "폐기물 실적",
//   wasteDocument: "폐기물 법정서류 등록",
//   wasteError: "폐기물 오류인계서",
//   wasteBaseCode: "표준 폐기문 분류",
//   wasteWaylist: "표준 폐기물 처리방법",
// };

export default bpname;
