import React from "react";
import { DateInput } from "@progress/kendo-react-dateinputs";
import styled from "styled-components";

const DateRangeInput = (props) => {
  return (
    <DateRangeInputLabel>
      <DateInput {...props} label={undefined} style={{ height: "15px" }} />
    </DateRangeInputLabel>
  );
};

export default DateRangeInput;

const DateRangeInputLabel = styled.label`
  .k-input-inner {
    height: 20px;
    width: 150px;
  }
`;
