import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import reportWebVitals from './reportWebVitals';
import KGrid from './Grid';
import Table from './budget'
import TotalLayOut from "./TotalLayOut/TotalLayOut"
import TotalLayOutInsert from './TotalLayOutInsert/TotalLayOutInsert';
import BlockInsert from "./BlockInsert/BlockInsert"
import { Route ,BrowserRouter,Switch } from 'react-router-dom';
import PermitTimeLine from './PermitTimeLine/PermitTimeLine';
import QualityTileLayout from "./QualityTileLayout/QualityTileLayout"
import Progress1 from './progress/progress';
import { LicenseInfo } from '@mui/x-license-pro';
import VendorEvaluation from "./VendorEvaluation/VendorEvaluation"
import VendorEvaluationRouter from './VendorEvaluation/VendorEvaluationRouter';

import EquipReservation from "./hallaEquip/EquipReservation/EquipReservation"

import SkonEMSOrgChart from './SkonEMSOrgChart/SkonEMSOrgChart';
import SkoneEMSProgress from "./SkoneEMSProgress/SkoneEMSProgress";
import MapE from './Map/map';


import OverallPage from "./hanwhaENV/OverallPage/OverallPage"
import ProjectDetailPage from "./hanwhaENV/ProjectDetailPage/ProjectDetailPage"
import ReportPage from "./hanwhaENV/ReportPage/ReportPage";
import ReportPageOverall from "./hanwhaENV/ReportPageOverall/ReportPageOverall";


import ProjectPage from "./Shinsegae/Project/ProjectPage";
import LGProjectPage from "./LGChemical/Project/ProjectPage";


import Progress1Test from './test';
import SSGTile from './ssg';
// import TestE from "./test/test"
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Switch>
    <Route exact path="/ssg" component={SSGTile} />

    <Route exact path="/shinsegae/:project_code" component={ProjectPage} />
    <Route exact path="/lgchemical/:project_code" component={LGProjectPage} />

    <Route exact path="/maps" component={MapE} />


      <Route exact path="/:project_code" component={KGrid} />
      <Route exact path="/:project_code/budget" component={Table} />
      
      <Route exact path="/:project_code/permit" component={PermitTimeLine} />
      <Route exact path="/:project_code/qualityp" component={QualityTileLayout} />
      <Route exact path="/:project_code/hallaprogress" component={Progress1} />
      <Route exact path="/:project_code/block" component={TotalLayOut} />
      <Route exact path="/:project_code/block/insert" component={TotalLayOutInsert} />
      
      <Route exact path="/vevaluation/router/:type" component={VendorEvaluation} />
      <Route exact path="/vevaluation/router" component={VendorEvaluationRouter} />
      <Route exact path="/equipreservation/:project_code" component={EquipReservation} />
      <Route exact path="/:project_code/emsorg" component={SkonEMSOrgChart} />
      <Route exact path="/:project_code/emsprogress" component={SkoneEMSProgress} />
      <Route exact path="/hanwhaenv/projects/:project_code" component={ProjectDetailPage} />
      <Route exact path="/hanwhaenv/overall" component={OverallPage} />
      <Route exact path="/hanwhaenv/projectreport" component={ReportPage} />
      <Route exact path="/hanwhaenv/overallreport" component={ReportPageOverall} />


      <Route exact path="/test/:project_code" component={Progress1Test} />

      
      {/* <Route exact path="/excel" component={TestE} /> */}


    
    </Switch>


</BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
