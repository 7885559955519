import React, { useState, useEffect, useMemo } from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { TileLayout } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import useBreakpoint from "use-breakpoint";
import _ from "lodash";
import EMSSCurveChart from "./Components/EMSSCurveChart";
import EMSProgressDetail from "./Components/EMSProgressDetail";
import "./kendocustom.scss";

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

const SkoneEMSProgress = () => {
  const { project_code } = useParams();
  const [grid, setgrid] = useState({
    mobile: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
    ],

    tablet: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 2,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
    ],

    desktop: [
      {
        col: 1,
        colSpan: 12,
        rowSpan: 4,
        status: true,
      },
      {
        col: 1,
        colSpan: 12,
        status: true,
        rowSpan: 2,
      },
    ],
  });

  const [status_grid, setstatusgrid] = useState([
    { status: true },
    { status: true },
    { status: true },
  ]);

  const [columns, setcolumns] = useState(12);
  const [blockH, setblockH] = useState(140);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");

  const [positiondata, setPositionData] = useState(grid["desktop"]);

  useEffect(() => {
    setPositionData(grid[breakpoint]);
  }, [breakpoint, grid]);

  const [fixblock, setfixblock] = useState(true);

  const togglefix = () => {
    setfixblock(!fixblock);
  };

  const tiles = useMemo(
    () => [
      {
        header: <div style={{ position: "relative" }}>EMS Progress</div>,
        body: (
          <EMSSCurveChart
            positiondata={positiondata}
            blockH={blockH}
            order={0}
            project_code={project_code}
          />
        ),
        // body: "",
        status: true,
        key: "EMS Progress",
        resizable: fixblock,
        reorderable: fixblock,
      },
      {
        header: (
          <div style={{ position: "relative" }}>Progress Detail (by Disc.)</div>
        ),
        body: (
          <EMSProgressDetail
            positiondata={positiondata}
            blockH={blockH}
            order={1}
            project_code={project_code}
            // order={isNaN(tile2) ? 1 : tile2}
          />
        ),
        status: true,
        key: "Progress Detail(by Disc.)",
        resizable: fixblock,
        reorderable: fixblock,
      },
    ],
    [fixblock, positiondata, blockH, project_code]
  );

  const [tile, settile] = useState(tiles);

  useEffect(() => {
    settile(
      tiles.map((v, index) => ({
        ...v,
        status: _.get(status_grid[index], "status"),
      }))
    );
  }, [status_grid, fixblock, tiles]);

  const tile_filtered = _.filter(tile, ["status", true]);

  const handleReposition = (e) => {
    setPositionData(e.value);
  };

  return (
    <div className="skoneEMSProgress">
      <div
        style={{
          position: "absolute",
          top: "0px",
          right: "20px",
          zIndex: "100",
        }}
      >
        <ButtonGroup>
          <Button
            title="고정하기"
            iconClass={fixblock ? "k-icon k-i-unpin" : "k-icon k-i-pin"}
            togglable={true}
            onClick={togglefix}
          ></Button>
        </ButtonGroup>
      </div>

      <TileLayout
        columns={columns}
        rowHeight={blockH}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tile_filtered}
        autoFlow="row dense"
        onReposition={handleReposition}
      />
    </div>
  );
};

export default SkoneEMSProgress;
