import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ToggleButton from "@mui/material/ToggleButton";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import moment from "moment";
import "@progress/kendo-theme-default/dist/all.css";
import krMessages from "../../kr.json";
// import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
// import currencyData from "cldr-core/supplemental/currencyData.json";
// import weekData from "cldr-core/supplemental/weekData.json";
// import numbers from "cldr-numbers-full/main/ko/numbers.json";
// import currencies from "cldr-numbers-full/main/ko/currencies.json";
// import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
// import dateFields from "cldr-dates-full/main/ko/dateFields.json";
// import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
// load(
//   likelySubtags,
//   currencyData,
//   weekData,
//   numbers,
//   currencies,
//   caGregorian,
//   dateFields,
//   timeZoneNames
// );
// loadMessages(krMessages, "ko-KR");

const ProjectInfoPage = ({
  height,
  selectedValue,
  setSelectedValue,
  dateRange,
  setDateRange,
  comboBoxValue,
  setComboBoxValue,
  comboBoxList,
  request_project_data,
  gotoReportPage,
}) => {
  const [calendarToggle, setCalendarToggle] = useState(false);

  const { project_code } = useParams();
  const [projectCombo, setProjectCombo] = useState([]);

  useEffect(() => {
    if (comboBoxValue.sector !== null) {
      const projectList = comboBoxList.project
        .filter((com) => com.uxesg_SectorName === comboBoxValue.sector)
        .map((com) => com.uxesg_PJTName);

      setProjectCombo(projectList);
    } else {
      setProjectCombo([]);
    }
  }, [comboBoxValue, comboBoxList]);

  const handleComboBox = (e) => {
    setComboBoxValue((prev) => ({ ...prev, [e.target.name]: e.value }));

    if (e.target.name === "sector") {
      setProjectCombo([]);
      setComboBoxValue((prev) => ({ ...prev, project: null }));
    }
  };

  const handleCalendar = (e, name) => {
    if (name === "endDate") {
      if (dateRange.startDate > e.value) {
        return alert("종료일을 재 설정하여 주시기 바랍니다.");
      }
    }

    if (name === "startDate") {
      if (dateRange.endDate < e.value) {
        return alert("시작일을 재 설정하여 주시기 바랍니다.");
      }
    }

    setDateRange((prev) => ({ ...prev, [name]: e.value }));
  };

  const toggleDialog = () => {
    setCalendarToggle((prev) => !prev);
  };

  return (
    <div className="projectInfoPage">
      <div className="projectInfoLeft">
        <div className="projectInfoLeftContents">
          {!selectedValue && (
            <div
              className="infoTextBox"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCalendarToggle((prev) => !prev);
              }}
            >
              <div className="infoTextTitle">기간</div>
              <div className="infoTextContent2">
                {moment(dateRange.startDate).format("YYYY. MM. DD")}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>~</div>
              <div className="infoTextContent2">
                {moment(dateRange.endDate).format("YYYY. MM. DD")}
              </div>
            </div>
          )}
        </div>

        <div className="projectInfoLeftContents">
          <div className="infoTextBox">
            <div className="infoTextTitle">사업본부</div>
            {project_code === "allproject" ? (
              <ComboBox
                value={comboBoxValue.sector}
                className="infoTextContent"
                data={comboBoxList.sector}
                allowCustom={true}
                name={"sector"}
                onChange={handleComboBox}
                style={{ width: "150px" }}
              />
            ) : (
              <div
                className="infoTextContent"
                style={{ width: "150px", paddingLeft: "10px" }}
              >
                {comboBoxValue.sector}
              </div>
            )}
          </div>
        </div>

        <div className="projectInfoLeftContents">
          <div className="infoTextBox">
            <div className="infoTextTitle">현장명</div>
            {project_code === "allproject" ? (
              <ComboBox
                value={comboBoxValue.project}
                className="infoTextContent"
                data={projectCombo}
                allowCustom={true}
                name={"project"}
                onChange={handleComboBox}
                onOpen={() => {
                  if (comboBoxValue.sector === null) {
                    alert("사업본부를 먼저 선택하여주세요");
                  }
                }}
                style={{ width: "300px" }}
              />
            ) : (
              <div
                className="infoTextContent"
                style={{ width: "300px", paddingLeft: "10px" }}
              >
                {comboBoxValue.project}
              </div>
            )}
          </div>
        </div>
        <button className="projectInfoButton" onClick={request_project_data}>
          조회
        </button>
        <button className="projectInfoButton" onClick={gotoReportPage}>
          월간 Report 출력
        </button>
      </div>
      <ToggleButton
        value="check"
        selected={selectedValue}
        onChange={() => {
          setSelectedValue(!selectedValue);
        }}
        disabled={
          comboBoxValue.project === null || comboBoxValue.sector === null
        }
        sx={{ height: height / 2 }}
      >
        <img
          src="/images/right-left-solid.svg"
          alt="transfer"
          style={{ width: "16px" }}
        />
      </ToggleButton>
      {calendarToggle && (
        <Dialog title={"기간 선택"} onClose={toggleDialog}>
          <div style={{ display: "flex" }}>
            <div>
              <div
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Pretendard-B",
                  marginBottom: "5px",
                }}
              >
                시작일
              </div>
              {/* <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko-KR"> */}
                  <Calendar
                    value={dateRange.startDate}
                    onChange={(e) => handleCalendar(e, "startDate")}
                  />
                {/* </IntlProvider>
              </LocalizationProvider> */}
            </div>
            <div>
              <div
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Pretendard-B",
                  marginBottom: "5px",
                }}
              >
                종료일
              </div>
              {/* <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko-KR"> */}
                  <Calendar
                    value={dateRange.endDate}
                    onChange={(e) => handleCalendar(e, "endDate")}
                  />
                {/* </IntlProvider>
              </LocalizationProvider> */}
            </div>
          </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}
            >
              닫기
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default ProjectInfoPage;
