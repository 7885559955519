import React from "react";
import {
  Chart,
  ChartSeries,
  ChartTooltip,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartLegend,
} from "@progress/kendo-react-charts";
import "hammerjs";

const CostWasteChart = ({ height, chartData }) => {
  const seriesLabels = {
    visible: true,
    padding: 0,
    font: "0.7rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
  };

  const chartValueAxisContents = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : Number(e.value.toFixed(0)).toLocaleString();
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    return (
      <div>
        <div style={{ font: "0.8rem pretendard-B", marginBottom: "5px" }}>
          공정률: {categoryText}%
        </div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              <div>
                {point.series.field === "plan"
                  ? "계획"
                  : point.series.field === "target"
                  ? "목표"
                  : "실적"}
                : {Number(point.value.toFixed(0)).toLocaleString()}백만원
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <Chart
      style={{
        height: height,
      }}
    >
      <ChartLegend position="right" visible={true} />
      <ChartTooltip shared={true} render={sharedTooltipRender} />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          maxDivisions={10}
          startAngle={45}
          labels={seriesLabels}
          majorGridLines={{ step: 100 }}
          minorGridLines={{ step: 100 }}
        />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem
          maxDivisions={10}
          startAngle={45}
          labels={{
            font: "0.7rem pretendard-R",
            content: chartValueAxisContents,
          }}
          majorTicks={{ step: 100 }}
          minorTicks={{ step: 100 }}
          majorGridLines={{ step: 10 }}
          minorGridLines={{ step: 10 }}
        />
      </ChartValueAxis>
      <ChartSeries>
        <ChartSeriesItem
          type="line"
          field="plan"
          data={chartData}
          categoryField={"percent"}
          color="#9BBB59"
          markers={{
            visible: false,
          }}
          name={"계획"}
        />
        <ChartSeriesItem
          type="line"
          field="target"
          data={chartData}
          categoryField={"percent"}
          color={"#FFE600"}
          markers={{
            visible: false,
          }}
          name={"목표"}
        />
        <ChartSeriesItem
          type="line"
          field="actual"
          data={chartData}
          categoryField={"percent"}
          color="#EF8329"
          markers={{
            visible: false,
          }}
          name={"실적"}
        />
      </ChartSeries>
    </Chart>
  );
};

export default CostWasteChart;
