import React, { useMemo } from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  headerClass,
  LGChemicalTable,
  CostDivBox,
  CostDivTitle,
  CostDivCost,
} from "../../../Public/StyledComponents";

import { normalCell, listNumberCell } from "../../../Public/TableCell";

const RunningOutStock = ({ height, materialInventoryManagerData }) => {
  const runningOutTableData = useMemo(() => {
    return materialInventoryManagerData
      .filter(
        (com) =>
          com.umatReceivedQuantityDA < 20 && com.umatReceivedQuantityDA !== 0
      )
      .map((com, idx) => ({
        ...com,
        No: idx + 1,
        expectedeExhaustion: "0." + (idx + 1) + " M",
      }));
  }, [materialInventoryManagerData]);

  return (
    <>
      <GridLayoutItem row={1} col={2}>
        <CostDivBox>
          <CostDivTitle>소진임박재고</CostDivTitle>
          <CostDivCost>{runningOutTableData.length} SKU</CostDivCost>
        </CostDivBox>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={2}>
        <LGChemicalTable>
          <Grid
            className={headerClass}
            style={{
              width: "100%",
              maxHeight: height * 0.35,
            }}
            data={runningOutTableData}
          >
            <GridColumn
              title="No."
              field="No"
              width={45}
              cell={listNumberCell}
            />
            <GridColumn
              title="Category"
              field="umatCategoryPD"
              cell={normalCell}
            />
            <GridColumn
              title="Material Name"
              field="uuu_cm5_name"
              cell={normalCell}
            />
            <GridColumn
              title="단위"
              field="ugenUnitofMeasurePD"
              width={60}
              cell={normalCell}
            />
            <GridColumn
              title="현 재고량"
              field="umatReceivedQuantityDA"
              width={100}
              cell={normalCell}
            />
            <GridColumn
              title="예상소진시점"
              field="expectedeExhaustion"
              width={100}
              cell={normalCell}
            />
          </Grid>
        </LGChemicalTable>
      </GridLayoutItem>
    </>
  );
};

export default RunningOutStock;
