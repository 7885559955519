import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import Url from "../../url/fetchURL";
import "./QualityDashboard.scss";

const QualityDashboard = () => {
  const { project_code } = useParams();
  const qualityDashboard = useRef();
  const [tabledata, setTableData] = useState([
    {
      eqa: "qualityBlue",
      eqc: "qualityGreen",
      ker: "qualityOrange",
      ll: "qualityRed",
    },
  ]);

  useEffect(() => {
    const addClassName = " qualityDashboardBody";
    const parentNode = qualityDashboard.current.parentNode;

    parentNode.className = parentNode.className + addClassName;
  }, []);

  useEffect(() => {
    const testDate = new Date("2022-08-01");
    const testDate2 = new Date("2022-08-17");
    console.log(eqa_eqcDelayDiscount(testDate, testDate2, "week"));
  }, [project_code]);

  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];
    const className = `k-icon k-i-circle ${cell}`;
    return (
      <td style={{ fontSize: "0.8rem", textAlign: "center" }}>
        <div className={className}></div>
      </td>
    );
  };

  return (
    <div className="qualityDashboard" ref={qualityDashboard}>
      <div className="qualityDashboardLegendBox">
        <div className="qualityDashboardLegendContents">
          <div>90점 이상: </div>
          <div className="k-icon k-i-circle qualityBlue"></div>
        </div>
        <div className="qualityDashboardLegendContents">
          <div>90점 미만 ~ 80점 이상: </div>
          <div className="k-icon k-i-circle qualityGreen"></div>
        </div>
        <div className="qualityDashboardLegendContents">
          <div>80점 미만 ~ 70점 이상: </div>
          <div className="k-icon k-i-circle qualityOrange"></div>
        </div>
        <div className="qualityDashboardLegendContents">
          <div>70점 미만: </div>
          <div className="k-icon k-i-circle qualityRed"></div>
        </div>
      </div>
      <div className="qualityDashboardTableBox">
        <Grid data={tabledata} className="qualityDashboardTable">
          <GridColumn field="eqa" title="EQA" cell={normalcell} />
          <GridColumn field="eqc" title="EQC" cell={normalcell} />
          <GridColumn field="ker" title="Key Event Review" cell={normalcell} />
          <GridColumn field="ll" title="LL" cell={normalcell} />
        </Grid>
      </div>
    </div>
  );
};

export default QualityDashboard;

const eqa_eqcDelayDiscount = (findDate, clearDate, base) => {
  const findDate_moment = moment(new Date(findDate)).format("YYYY-MM-DD");

  const clearDate_moment =
    clearDate !== null
      ? moment(new Date(clearDate)).format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD");

  const diffDate = moment(clearDate_moment).diff(
    moment(findDate_moment),
    "days"
  );

  if (base === "week") {
    if (diffDate > 14) {
      return diffDate - 14;
    } else {
      return 0;
    }
  } else if (base === "month") {
    if (diffDate > 30) {
      return diffDate - 30;
    } else {
      return 0;
    }
  }
};

// CA : -3점/건
// C : -1점/건
// B : +2점/건

// CA/C : 발견일로부터 2주 후 -1점/일
// O : 발견일로부터 30일 후 -1점/일
