import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import _ from "lodash";
import "./EMSOverallTable.scss";

const EMSOverallTable = ({ chartList, today, lastWeek }) => {
  const tableData = chartList
    .map((com) => ({
      ...com,
      lastWeek_act: (com.lastWeek_act * com.total_wf) / 100,
      lastWeek_plan: (com.lastWeek_plan * com.total_wf) / 100,
      today_act: (com.today_act * com.total_wf) / 100,
      today_plan: (com.today_plan * com.total_wf) / 100,
    }))
    .map((com) => ({
      ...com,
      lastWeek_dev: com.lastWeek_act - com.lastWeek_plan,
      today_dev: com.today_act - com.today_plan,
    }));

  const HeaderCell = (props) => {
    const { title } = props;

    return <div style={tableHeaderStyle}>{title}</div>;
  };

  const normalCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];

    return (
      <td
        style={{
          fontSize: "0.8rem",
          textAlign: "center",
        }}
      >
        {field === "disc" ? value : value.toFixed(2) + "%"}
      </td>
    );
  };

  const footerCell = (props) => {
    const field = props.field || "";

    return (
      <td
        colSpan={props.colSpan}
        style={{ fontSize: "0.8rem", textAlign: "center" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <div>{_.sumBy(tableData, field).toFixed(2)}%</div>
        </div>
      </td>
    );
  };

  return (
    <div className="eMSOverallTable">
      <Grid data={tableData}>
        <GridColumn
          field="disc"
          title="항목"
          headerCell={HeaderCell}
          cell={normalCell}
        ></GridColumn>
        <GridColumn
          field="total_wf"
          title="비중"
          headerCell={HeaderCell}
          cell={normalCell}
          footerCell={footerCell}
        ></GridColumn>

        <GridColumn title={`${lastWeek} 까지`} headerCell={HeaderCell}>
          <GridColumn
            field="lastWeek_plan"
            title="계획"
            headerCell={HeaderCell}
            cell={normalCell}
            footerCell={footerCell}
          ></GridColumn>
          <GridColumn
            field="lastWeek_act"
            title="실적"
            headerCell={HeaderCell}
            cell={normalCell}
            footerCell={footerCell}
          ></GridColumn>
          <GridColumn
            field="lastWeek_dev"
            title="차이"
            headerCell={HeaderCell}
            cell={normalCell}
            footerCell={footerCell}
          ></GridColumn>
        </GridColumn>

        <GridColumn title={`${today} 까지`} headerCell={HeaderCell}>
          <GridColumn
            field="today_plan"
            title="계획"
            headerCell={HeaderCell}
            cell={normalCell}
            footerCell={footerCell}
          ></GridColumn>
          <GridColumn
            field="today_act"
            title="실적"
            headerCell={HeaderCell}
            cell={normalCell}
            footerCell={footerCell}
          ></GridColumn>
          <GridColumn
            field="today_dev"
            title="차이"
            headerCell={HeaderCell}
            cell={normalCell}
            footerCell={footerCell}
          ></GridColumn>
        </GridColumn>
      </Grid>
    </div>
  );
};

export default EMSOverallTable;

const tableHeaderStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "100%",
  font: "normal normal bold 0.8rem Pretendard-R",
};
