import React, { useEffect, useState } from "react";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import _ from "lodash";

const ProjectTarget = ({
  height,
  projectActTotalData,
  targetProject,
  base_code,
}) => {
  const [actualData, setActualData] = useState({
    plan: 0,
    plan_cost: 0,
    weight: 0,
    cost: 0,
  });

  useEffect(() => {
    const base_code_arr = base_code.map((com) => com.code);

    const projectActTotalData_filter = projectActTotalData.filter((com) =>
      base_code_arr.includes(com.uxesg_WasteCode)
    );

    setActualData((prev) => ({
      ...prev,
      plan: _.sumBy(targetProject.data_arr, "plan_weight"),
      plan_cost: _.sumBy(targetProject.data_arr, "plan_cost"),
      weight: _.sumBy(projectActTotalData_filter, "uxesg_CarryQty2"),
      cost: _.sumBy(projectActTotalData_filter, "uxesg_CostTotal"),
    }));
  }, [projectActTotalData, targetProject, base_code]);

  const gridHeight = height - 32 - 45;
  return (
    <div>
      <GridLayout
        style={{ height, alignItems: "" }}
        cols={[
          {
            width: "20%",
          },
          {
            width: "40%",
          },
          {
            width: "40%",
          },
        ]}
        rows={[
          {
            height: gridHeight,
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem col={1} rows={1} style={{ padding: "0 5px" }}>
          <Card
            style={{
              padding: "0",
              height: "100%",
            }}
          >
            <CardBody
              className="manbDetailCard"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <CardTitle
                style={{ font: "1rem Pretendard-B", textAlign: "center" }}
              >
                공정률
              </CardTitle>
              <CardTitle
                style={{ font: "0.9rem Pretendard-B", textAlign: "center" }}
              >
                {targetProject.percent.toFixed(2)}%
              </CardTitle>
            </CardBody>
          </Card>
        </GridLayoutItem>
        <GridLayoutItem col={2} rows={1} style={{ padding: "0 5px" }}>
          <Card
            style={{
              padding: "0",
              height: "100%",
            }}
          >
            <CardBody
              className="manbDetailCard"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <CardTitle
                style={{ font: "1rem Pretendard-B", textAlign: "center" }}
              >
                누적 배출량(톤)
              </CardTitle>
              <div>
                <CardTitle
                  style={{
                    font: "1rem Pretendard-B",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      color:
                        (actualData.weight / actualData.plan) * 100 > 100
                          ? "rgb(158, 16, 48)"
                          : "rgb(0, 107, 84)",
                    }}
                  >
                    {Number(
                      ((actualData.weight / actualData.plan) * 100).toFixed(2)
                    ) === Infinity ||
                    isNaN(
                      Number(
                        ((actualData.weight / actualData.plan) * 100).toFixed(2)
                      )
                    )
                      ? 0
                      : Number(
                          ((actualData.weight / actualData.plan) * 100).toFixed(
                            2
                          )
                        )}
                    %
                  </div>
                </CardTitle>
                <CardTitle
                  style={{
                    font: "1rem Pretendard-B",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>차이: &nbsp;</div>
                  <div
                    style={{
                      color:
                        actualData.plan - actualData.weight < 0
                          ? "rgb(158, 16, 48)"
                          : "rgb(0, 107, 84)",
                    }}
                  >
                    {Number(
                      (actualData.plan - actualData.weight).toFixed(0)
                    ).toLocaleString()}
                    t
                  </div>
                </CardTitle>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <CardTitle
                  style={{
                    font: "0.9rem Pretendard-B",
                    textAlign: "left",
                  }}
                >
                  계획{" "}
                  <div>
                    {Number(actualData.plan.toFixed(0)).toLocaleString()}t
                  </div>
                </CardTitle>
                <CardTitle
                  style={{
                    font: "0.9rem Pretendard-B",
                    textAlign: "right",
                  }}
                >
                  실적
                  <div>
                    {Number(actualData.weight.toFixed(0)).toLocaleString()}t
                  </div>
                </CardTitle>
              </div>
            </CardBody>
          </Card>
        </GridLayoutItem>
        <GridLayoutItem col={3} rows={1} style={{ padding: "0 5px" }}>
          <Card
            style={{
              padding: "0",
              height: "100%",
            }}
          >
            <CardBody
              className="manbDetailCard"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <CardTitle
                style={{ font: "1rem Pretendard-B", textAlign: "center" }}
              >
                누적 배출량(백만원)
              </CardTitle>
              <div>
                <CardTitle
                  style={{
                    font: "1rem Pretendard-B",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      color:
                        (actualData.cost / actualData.plan_cost) * 100 > 100
                          ? "rgb(158, 16, 48)"
                          : "rgb(0, 107, 84)",
                    }}
                  >
                    {Number(
                      ((actualData.cost / actualData.plan_cost) * 100).toFixed(
                        2
                      )
                    ) === Infinity ||
                    isNaN(
                      Number(
                        (
                          (actualData.cost / actualData.plan_cost) *
                          100
                        ).toFixed(2)
                      )
                    )
                      ? 0
                      : Number(
                          (
                            (actualData.cost / actualData.plan_cost) *
                            100
                          ).toFixed(2)
                        )}
                    %
                  </div>
                </CardTitle>
                <CardTitle
                  style={{
                    font: "1rem Pretendard-B",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div>차이: &nbsp;</div>
                  <div
                    style={{
                      color:
                        actualData.plan_cost - actualData.cost < 0
                          ? "rgb(158, 16, 48)"
                          : "rgb(0, 107, 84)",
                    }}
                  >
                    {Number(
                      (
                        (actualData.plan_cost - actualData.cost) /
                        1000000
                      ).toFixed(0)
                    ).toLocaleString()}{" "}
                    백만원
                  </div>
                </CardTitle>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <CardTitle
                  style={{
                    font: "0.9rem Pretendard-B",
                    textAlign: "left",
                  }}
                >
                  계획{" "}
                  <div>
                    {Number(
                      (actualData.plan_cost / 1000000).toFixed(0)
                    ).toLocaleString()}
                    백만원
                  </div>
                </CardTitle>
                <CardTitle
                  style={{
                    font: "0.9rem Pretendard-B",
                    textAlign: "right",
                  }}
                >
                  실적{" "}
                  <div>
                    {Number(
                      (actualData.cost / 1000000).toFixed(0)
                    ).toLocaleString()}
                    백만원
                  </div>
                </CardTitle>
              </div>
            </CardBody>
          </Card>
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

export default ProjectTarget;
