import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const SectorTable = ({ height, tableDatas }) => {
  const [arcTableDataWeight, setArcTableDataWeight] = useState(base_obj);
  const [arcTableDataCost, setArcTableDataCost] = useState(base_obj);
  const [civilTableDataWeight, setCivilTableDataWeight] = useState(base_obj);
  const [civilTableDataCost, setCivilTableDataCost] = useState(base_obj);
  const [plantTableDataWeight, setPlantTableDataWeight] = useState(base_obj);
  const [plantTableDataCost, setPlantTableDataCost] = useState(base_obj);

  useEffect(() => {
    if (tableDatas.length > 0) {
      const arc_data = tableDatas.find((com) => com.name === "건축사업본부")?.[
        "data"
      ];
      const civil_data = tableDatas.find(
        (com) => com.name === "토목사업본부"
      )?.["data"];
      const plant_data = tableDatas.find(
        (com) => com.name === "플랜트사업본부"
      )?.["data"];

      setArcTableDataWeight((prev) => ({
        ...prev,
        target: sum_data(
          arc_data,
          "project_total_target_weight"
        ).toLocaleString(),
        act: sum_data(arc_data, "project_total_act_weight").toLocaleString(),
        dev: (
          sum_data(arc_data, "project_total_target_weight") -
          sum_data(arc_data, "project_total_act_weight")
        ).toLocaleString(),
        percent:
          Number(
            (
              (sum_data(arc_data, "project_total_act_weight") /
                sum_data(arc_data, "project_total_target_weight")) *
              100
            ).toFixed(2)
          ) === Infinity ||
          isNaN(
            Number(
              (
                (sum_data(arc_data, "project_total_act_weight") /
                  sum_data(arc_data, "project_total_target_weight")) *
                100
              ).toFixed(2)
            )
          )
            ? 0 + "%"
            : Number(
                (
                  (sum_data(arc_data, "project_total_act_weight") /
                    sum_data(arc_data, "project_total_target_weight")) *
                  100
                ).toFixed(2)
              ) + "%",
      }));

      setArcTableDataCost((prev) => ({
        ...prev,
        target: sum_data(
          arc_data,
          "project_total_target_cost"
        ).toLocaleString(),
        act: sum_data(arc_data, "project_total_act_cost").toLocaleString(),
        dev: (
          sum_data(arc_data, "project_total_target_cost") -
          sum_data(arc_data, "project_total_act_cost")
        ).toLocaleString(),
        percent:
          Number(
            (
              (sum_data(arc_data, "project_total_act_cost") /
                sum_data(arc_data, "project_total_target_cost")) *
              100
            ).toFixed(2)
          ) === Infinity ||
          isNaN(
            Number(
              (
                (sum_data(arc_data, "project_total_act_cost") /
                  sum_data(arc_data, "project_total_target_cost")) *
                100
              ).toFixed(2)
            )
          )
            ? 0 + "%"
            : Number(
                (
                  (sum_data(arc_data, "project_total_act_cost") /
                    sum_data(arc_data, "project_total_target_cost")) *
                  100
                ).toFixed(2)
              ) + "%",
      }));

      setCivilTableDataWeight((prev) => ({
        ...prev,
        target: sum_data(
          civil_data,
          "project_total_target_weight"
        ).toLocaleString(),
        act: sum_data(civil_data, "project_total_act_weight").toLocaleString(),
        dev: (
          sum_data(civil_data, "project_total_target_weight") -
          sum_data(civil_data, "project_total_act_weight")
        ).toLocaleString(),
        percent:
          Number(
            (
              (sum_data(civil_data, "project_total_act_weight") /
                sum_data(civil_data, "project_total_target_weight")) *
              100
            ).toFixed(2)
          ) === Infinity ||
          isNaN(
            Number(
              (
                (sum_data(civil_data, "project_total_act_weight") /
                  sum_data(civil_data, "project_total_target_weight")) *
                100
              ).toFixed(2)
            )
          )
            ? 0 + "%"
            : Number(
                (
                  (sum_data(civil_data, "project_total_act_weight") /
                    sum_data(civil_data, "project_total_target_weight")) *
                  100
                ).toFixed(2)
              ) + "%",
      }));

      setCivilTableDataCost((prev) => ({
        ...prev,
        target: sum_data(
          civil_data,
          "project_total_target_cost"
        ).toLocaleString(),
        act: sum_data(civil_data, "project_total_act_cost").toLocaleString(),
        dev: (
          sum_data(civil_data, "project_total_target_cost") -
          sum_data(civil_data, "project_total_act_cost")
        ).toLocaleString(),
        percent:
          Number(
            (
              (sum_data(civil_data, "project_total_act_cost") /
                sum_data(civil_data, "project_total_target_cost")) *
              100
            ).toFixed(2)
          ) === Infinity ||
          isNaN(
            Number(
              (
                (sum_data(civil_data, "project_total_act_cost") /
                  sum_data(civil_data, "project_total_target_cost")) *
                100
              ).toFixed(2)
            )
          )
            ? 0 + "%"
            : Number(
                (
                  (sum_data(civil_data, "project_total_act_cost") /
                    sum_data(civil_data, "project_total_target_cost")) *
                  100
                ).toFixed(2)
              ) + "%",
      }));

      setPlantTableDataWeight((prev) => ({
        ...prev,
        target: sum_data(
          plant_data,
          "project_total_target_weight"
        ).toLocaleString(),
        act: sum_data(plant_data, "project_total_act_weight").toLocaleString(),
        dev: (
          sum_data(plant_data, "project_total_target_weight") -
          sum_data(plant_data, "project_total_act_weight")
        ).toLocaleString(),
        percent:
          Number(
            (
              (sum_data(plant_data, "project_total_act_weight") /
                sum_data(plant_data, "project_total_target_weight")) *
              100
            ).toFixed(2)
          ) === Infinity ||
          isNaN(
            Number(
              (
                (sum_data(plant_data, "project_total_act_weight") /
                  sum_data(plant_data, "project_total_target_weight")) *
                100
              ).toFixed(2)
            )
          )
            ? 0 + "%"
            : Number(
                (
                  (sum_data(plant_data, "project_total_act_weight") /
                    sum_data(plant_data, "project_total_target_weight")) *
                  100
                ).toFixed(2)
              ) + "%",
      }));

      setPlantTableDataCost((prev) => ({
        ...prev,
        target: sum_data(
          plant_data,
          "project_total_target_cost"
        ).toLocaleString(),
        act: sum_data(plant_data, "project_total_act_cost").toLocaleString(),
        dev: (
          sum_data(plant_data, "project_total_target_cost") -
          sum_data(plant_data, "project_total_act_cost")
        ).toLocaleString(),
        percent:
          Number(
            (
              (sum_data(plant_data, "project_total_act_cost") /
                sum_data(plant_data, "project_total_target_cost")) *
              100
            ).toFixed(2)
          ) === Infinity ||
          isNaN(
            Number(
              (
                (sum_data(plant_data, "project_total_act_cost") /
                  sum_data(plant_data, "project_total_target_cost")) *
                100
              ).toFixed(2)
            )
          )
            ? 0 + "%"
            : Number(
                (
                  (sum_data(plant_data, "project_total_act_cost") /
                    sum_data(plant_data, "project_total_target_cost")) *
                  100
                ).toFixed(2)
              ) + "%",
      }));
    }
  }, [tableDatas]);

  return (
    <div>
      <GridLayout
        style={{ height: height - 20, border: "1px solid #E8E7E6" }}
        cols={[
          {
            width: `${100 / 5}%`,
          },
          {
            width: `${100 / 5}%`,
          },
          {
            width: `${100 / 5}%`,
          },
          {
            width: `${100 / 5}%`,
          },
          {
            width: `${100 / 5}%`,
          },
        ]}
        rows={[
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem
          col={1}
          row={1}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          본부
        </GridLayoutItem>
        <GridLayoutItem
          col={2}
          row={1}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          배출
          <br />
          목표
        </GridLayoutItem>
        <GridLayoutItem
          col={3}
          colSpan={5}
          row={1}
          className="OverallTable tablegray"
        >
          배출실적
        </GridLayoutItem>
        <GridLayoutItem col={3} row={2} className="OverallTable tablegray">
          누적 배출량
        </GridLayoutItem>
        <GridLayoutItem col={4} row={2} className="OverallTable tablegray">
          차이
        </GridLayoutItem>
        <GridLayoutItem col={5} row={2} className="OverallTable tablegray">
          달성률
        </GridLayoutItem>
        <GridLayoutItem
          col={1}
          row={3}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          건축
        </GridLayoutItem>
        <GridLayoutItem
          col={1}
          row={5}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          토목
        </GridLayoutItem>
        <GridLayoutItem
          col={1}
          row={7}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          플랜트
        </GridLayoutItem>
        {Object.keys(arcTableDataWeight).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={3}
              className="OverallTable"
              key={idx}
            >
              {arcTableDataWeight[com]}
            </GridLayoutItem>
          );
        })}
        {Object.keys(arcTableDataCost).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={4}
              className="OverallTable"
              key={idx}
            >
              {arcTableDataCost[com]}
            </GridLayoutItem>
          );
        })}
        {Object.keys(civilTableDataWeight).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={5}
              className="OverallTable"
              key={idx}
            >
              {civilTableDataWeight[com]}
            </GridLayoutItem>
          );
        })}
        {Object.keys(civilTableDataCost).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={6}
              className="OverallTable"
              key={idx}
            >
              {civilTableDataCost[com]}
            </GridLayoutItem>
          );
        })}
        {Object.keys(plantTableDataWeight).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={7}
              className="OverallTable"
              key={idx}
            >
              {plantTableDataWeight[com]}
            </GridLayoutItem>
          );
        })}
        {Object.keys(plantTableDataCost).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={8}
              className="OverallTable"
              key={idx}
            >
              {plantTableDataCost[com]}
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default SectorTable;

const base_obj = {
  target: 0,
  act: 0,
  dev: 0,
  percent: 0,
};

const sum_data = (arr, field) => {
  const sum = _.sumBy(arr, field);

  return sum;
};
