import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Scheduler, DayView, MonthView } from "@progress/kendo-react-scheduler";

import { ComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import moment from "moment";

import CustomFormDialogTotal from "./CustomFormDialog/CustomFormDialogTotal";

import url from "../URL/URL";

import "@progress/kendo-theme-default/dist/all.css";
import "./EquipReservation.scss";

let equipBody = {
  bpname: "호이스트 목록",
  lineitem: "yes",
  filter_condition: "status=Active",
};

let reservationBody = {
  bpname: "호이스트 예약",
  lineitem: "yes",
  // filter_condition: "status=Active",
};

const EquipReservation = () => {
  const { project_code } = useParams();

  const [targetEquipment, setTargetEquipment] = useState("");
  const [equipList, setEquipList] = useState([]);
  const [equipNreservationList, setEquipNreservationList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchDataEquipList = await axios.post(
        `${url}/gethallabprecord?path=${project_code}`,
        equipBody
      );

      const fetchDataReservationList = await axios.post(
        `${url}/gethallabprecord?path=${project_code}`,
        reservationBody
      );

      const equipmentList = fetchDataEquipList.data.data.data.map((com) => {
        return com.Equiment_Name + " (" + com.ugenLocationTB120 + ")";
      });

      const reservationList = fetchDataReservationList.data.data.data
        .filter((com) => com.status !== "Rejected")
        .map((com, idx) => {
          return {
            Equiment_BPK: com.Equiment_Name,
            Equiment_Name:
              com.Equiment_Name + " (" + com.ugenLocationTB120 + ")",
            TaskID: idx + 1,
            Title: com.uveVendorNameTB50,
            Start: setStartFinsh(com.Equipment_start, "start"),
            End: setStartFinsh(com.Equipment_finish, "finish"),
            isAllDay: false,
            ApprovedIDS: com.status === "Pending" ? 1 : 2,
            EquipmentID: 1,
            Range: dateRange(com.Equipment_start, com.Equipment_finish),
            record_no: com.record_no,
          };
        });

      const equipReservationList = equipmentList.map((com) => {
        return {
          equip: com,
          data: [],
        };
      });

      reservationList.forEach((com) => {
        const findIndex = equipReservationList.findIndex(
          (com2) => com2.equip === com.Equiment_Name
        );
        equipReservationList[findIndex]["data"].push(com);
      });

      const finalData = equipReservationList.find(
        (com) => com.equip === equipmentList[0]
      );

      setEquipNreservationList(equipReservationList);
      setEquipList(equipmentList);
      setTargetEquipment(equipmentList[0]);
      setData(finalData["data"]);
    };
    fetchData();
  }, [project_code]);

  const handleComboBox = (e) => {
    const { value } = e;
    if (value === null) {
      setTargetEquipment((prev) => prev);
    } else {
      setTargetEquipment(value);

      const finalData = equipNreservationList.find(
        (com) => com.equip === value
      );

      setData(finalData["data"]);
    }
  };

  const handleDateChange = useCallback(
    (event) => {
      setDate(event.value);
    },
    [setDate]
  );

  const handleDataChange = ({ created, updated, deleted }) => {
    if (created.length > 0) {
      const { Start, End, Title } = created[0];

      requestCreateBP(Title, Start, End);
    }
  };

  const requestCreateBP = async (vendor_name, startDate, finishDate) => {
    try {
      const requestobj = [
        {
          Equiment_BPK: targetEquipment.split("(")[0].slice(0, -1),
          uveVendorNameTB50: vendor_name,
          Equipment_start: moment(startDate).format("MM-DD-YYYY HH:mm:00"),
          Equipment_finish: moment(finishDate).format("MM-DD-YYYY HH:mm:00"),
        },
      ];

      const res2 = await axios({
        method: "post",
        url: `${url}/createhallabprecord?path=${project_code}`,
        data: requestobj,
      });

      if (res2.status === 201) {
        const fetchDataEquipList = await axios.post(
          `${url}/gethallabprecord?path=${project_code}`,
          equipBody
        );

        const fetchDataReservationList = await axios.post(
          `${url}/gethallabprecord?path=${project_code}`,
          reservationBody
        );

        const equipmentList = fetchDataEquipList.data.data.data.map((com) => {
          return com.Equiment_Name + " (" + com.ugenLocationTB120 + ")";
        });
        console.log(fetchDataReservationList.data.data.data);
        const reservationList = fetchDataReservationList.data.data.data
          .filter((com) => com.status !== "Rejected")
          .map((com, idx) => {
            return {
              Equiment_BPK: com.Equiment_Name,
              Equiment_Name:
                com.Equiment_Name + " (" + com.ugenLocationTB120 + ")",
              TaskID: idx + 1,
              Title: com.uveVendorNameTB50,
              Start: setStartFinsh(com.Equipment_start, "start"),
              End: setStartFinsh(com.Equipment_finish, "finish"),
              isAllDay: false,
              ApprovedIDS: com.status === "Pending" ? 1 : 2,
              EquipmentID: 1,
              Range: dateRange(com.Equipment_start, com.Equipment_finish),
              record_no: com.record_no,
            };
          });

        const equipReservationList = equipmentList.map((com) => {
          return {
            equip: com,
            data: [],
          };
        });

        reservationList.forEach((com) => {
          const findIndex = equipReservationList.findIndex(
            (com2) => com2.equip === com.Equiment_Name
          );
          equipReservationList[findIndex]["data"].push(com);
        });

        const finalData = equipReservationList.find(
          (com) => com.equip === targetEquipment
        );

        setEquipNreservationList(equipReservationList);
        setEquipList(equipmentList);
        setData(finalData["data"]);
        // setData(startDate);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const customModelFields = {
    id: "TaskID",
    title: "Title",
    start: "Start",
    end: "End",
  };

  return (
    <div className="equipReservation">
      <ComboBox
        // placeholder="A등급"
        defaultValue={targetEquipment}
        value={targetEquipment}
        suggest={true}
        style={{ width: "100%", marginBottom: "20px" }}
        data={equipList}
        onChange={handleComboBox}
      />
      <br />
      <Scheduler
        height={"745px"}
        form={(props) => CustomFormDialogTotal(props, data)}
        onDataChange={handleDataChange}
        onDateChange={handleDateChange}
        data={data}
        date={date}
        editable={{
          add: true,
          remove: false,
          drag: false,
          resize: false,
          edit: true,
        }}
        modelFields={customModelFields}
        group={{
          resources: ["equipment", "approved"],
          // orientation: "vertical",
          // orientation: "horizontal",
        }}
        resources={[
          {
            name: "equipment",
            data: [{ text: targetEquipment, value: 1 }],
            field: "EquipmentID",
            valueField: "value",
            textField: "text",
            colorField: "color",
          },
          {
            name: "approved",
            data: [
              { text: "승인 대기", value: 1, color: "lightgray" },
              { text: "승인 완료", value: 2, color: "#5392E4" },
            ],
            field: "ApprovedIDS",
            valueField: "value",
            textField: "text",
            colorField: "color",
          },
        ]}
        footer={() => <React.Fragment />}
      >
        <MonthView className="equipmentMonth" />
        <DayView className="equipmentMonth" showWorkHours={false} />
      </Scheduler>
    </div>
  );
};

export default EquipReservation;

const setStartFinsh = (date, type) => {
  const targetDate = new Date(date.slice(0, 10));

  const targetHour = Number(date.slice(11, 13));

  const targetMin = Number(date.slice(14, 16));

  switch (type) {
    case "start":
      targetDate.setHours(targetHour, targetMin, 0);
      return targetDate;
    case "finish":
      targetDate.setHours(targetHour, targetMin, 0);
      return targetDate;
    default:
      return;
  }
};

const dateRange = (start, finish) => {
  const startDate = moment(new Date(start.slice(0, 10)));
  const finishDate = moment(new Date(finish.slice(0, 10)));

  const diffDate = moment.duration(finishDate.diff(startDate)).asDays() + 1;

  const dateRange = [];

  for (let i = 0; i < diffDate; i++) {
    dateRange.push(moment(startDate).add(i, "days").format("YYYY-MM-DD"));
  }

  return dateRange;
};
