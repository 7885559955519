import React from "react";
import {
  Chart,
  ChartLegend,
  ChartArea,
  ChartTooltip,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";
import "hammerjs";

const TotalBarChart = ({ height, chartData }) => {
  const seriesLabels = {
    visible: true,

    padding: 0,
    font: "0.8rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    return (
      <div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              <div>
                {categoryText}:{" "}
                {Number(point.value.toFixed(0)).toLocaleString()}t
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Chart
      style={{
        height: height,
      }}
    >
      <ChartTooltip shared={true} render={sharedTooltipRender} />
      <ChartArea background="none" />
      <ChartLegend position="bottom" orientation="horizontal" />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          startAngle={45}
          labels={seriesLabels}
          name="categoryAxis"
          majorGridLines={{ step: 100 }}
          minorGridLines={{ step: 100 }}
        />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem
          visible={true}
          labels={seriesLabels}
          majorTicks={{ step: 100 }}
          minorTicks={{ step: 100 }}
          majorGridLines={{ step: 10 }}
          minorGridLines={{ step: 10 }}
        />
      </ChartValueAxis>
      <ChartSeries>
        {chartData.map((com, idx) => {
          return (
            <ChartSeriesItem
              key={idx}
              type="bar"
              field="value"
              data={com}
              stack={{ group: "a" }}
              categoryField="name"
              color="#4F81BD"
              border={{ width: 0 }}
              spacing={0}
              labels={{
                visible: true,
                content: (e) => {
                  return e.value === 0 ||
                    e.value === null ||
                    typeof e.value === "undefined"
                    ? ""
                    : Number(e.value.toFixed(0)).toLocaleString() + "t";
                },
                background: "none",
                color: "black",
              }}
            />
          );
        })}
      </ChartSeries>
    </Chart>
  );
};

export default TotalBarChart;
