import React from "react";
import { SchedulerForm } from "@progress/kendo-react-scheduler";
import CustomDialog from "../CustomDialog/CustomDialog";
import CustomContents from "../CustomDialog/CustomContents";

const CustomFormDialogTotal = (props, slotList) => {
  const { dataItem } = props;

  if (dataItem.ApprovedIDS === 2 && dataItem.Title === undefined) {
    return;
  } else {
    return (
      <SchedulerForm
        {...props}
        dialog={CustomDialog}
        editor={(e) => CustomContents(e, dataItem, slotList)}
      />
    );
  }
};

export default CustomFormDialogTotal;
