import React from "react";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import {
  Chart,
  ChartLegend,
  ChartArea,
  ChartTooltip,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-react-charts";

import "hammerjs";

const ManbDetailChart = ({ height, chartData }) => {
  const seriesLabelsContents = (e) => {
    return Number(e.value).toLocaleString();
  };

  const seriesLabels = {
    visible: true,

    padding: 0,
    font: "0.7rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
  };

  const seriesLabels2 = {
    visible: true,

    padding: 0,
    font: "0.7rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
    content: seriesLabelsContents,
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    return (
      <div>
        <div>{categoryText}</div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              <div>
                {point.series.field === "planWeight" ? "계획" : "실적"}:{" "}
                {Number(point.value.toFixed(0)).toLocaleString()}t
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <GridLayout
        style={{ height, alignItems: "" }}
        cols={[
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
        ]}
        rows={[
          {
            height: height * 0.55,
          },
          {
            height: height * 0.55,
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem col={1} colSpan={4} row={1}>
          <Chart
            style={{
              width: "100%",
              height: height * 0.55,
            }}
          >
            <ChartTooltip shared={true} render={sharedTooltipRender} />
            <ChartArea background="none" />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                startAngle={45}
                labels={seriesLabels}
                name="categoryAxis"
                majorGridLines={{ step: 100 }}
                minorGridLines={{ step: 100 }}
              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                visible={true}
                labels={seriesLabels2}
                majorTicks={{ step: 100 }}
                minorTicks={{ step: 100 }}
                majorGridLines={{ step: 10 }}
                minorGridLines={{ step: 10 }}
              />
            </ChartValueAxis>

            <ChartSeries>
              <ChartSeriesItem
                type="bar"
                field="planWeight"
                data={chartData}
                stack={{ group: "a" }}
                categoryField="name"
                color="#9BBB59"
                name="계획"
                border={{ width: 0 }}
                spacing={0}
              />
              <ChartSeriesItem
                type="bar"
                field="actualWeight"
                data={chartData}
                stack={{ group: "b" }}
                categoryField="name"
                color="#4F81BD"
                name="실적"
                border={{ width: 0 }}
                spacing={0}
              />
            </ChartSeries>
          </Chart>
        </GridLayoutItem>
        {chartData.map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 1}
              row={2}
              style={{ padding: "0 5px" }}
              key={idx}
            >
              <Card
                style={{
                  padding: "0",
                  height: height * 0.45,
                }}
              >
                <CardBody
                  className="manbDetailCard"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <CardTitle
                    style={{ font: "0.9rem Pretendard-B", textAlign: "center" }}
                  >
                    {com.name}
                  </CardTitle>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardTitle
                        style={{
                          font: "0.9rem Pretendard-B",
                          textAlign: "center",
                        }}
                      >
                        계획:
                      </CardTitle>
                      <div style={{ font: "0.9rem Pretendard-R" }}>
                        {Number(com.planWeight.toFixed(0)).toLocaleString()}t
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardTitle
                        style={{
                          font: "0.9rem Pretendard-B",
                          textAlign: "center",
                        }}
                      >
                        실적:
                      </CardTitle>
                      <div style={{ font: "0.9rem Pretendard-R" }}>
                        {Number(com.actualWeight.toFixed(0)).toLocaleString()}t
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardTitle
                        style={{
                          font: "0.9rem Pretendard-B",
                          textAlign: "center",
                        }}
                      >
                        차이:
                      </CardTitle>
                      <div style={{ font: "0.9rem Pretendard-R" }}>
                        {Number(com.devWeight.toFixed(0)).toLocaleString()}t
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <CardTitle
                        style={{
                          font: "0.9rem Pretendard-B",
                          textAlign: "center",
                        }}
                      >
                        비용:
                      </CardTitle>
                      <div style={{ font: "0.9rem Pretendard-R" }}>
                        {Number(
                          (com.devCost / 1000000).toFixed(0)
                        ).toLocaleString()}
                        백만원
                      </div>
                    </div>
                  </div>
                  {/* <p style={{ font: "0.9rem Pretendard-R" }}>
                  {Number(
                    (com.data3[1]["value"] - com.data3[0]["value"]).toFixed(0)
                  ).toLocaleString()}
                  t
                </p> */}
                </CardBody>
              </Card>
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default ManbDetailChart;
