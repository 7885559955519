import React, { useEffect, useState } from "react";
import { TileLayout } from "@progress/kendo-react-layout";
import ManbWaste from "./ManbWaste/ManbWaste";
import PercentWaste from "./PercentWaste/PercentWaste";
import CostWaste from "./CostWaste/CostWaste";
import ProjectTarget from "./ProjectTarget/ProjectTarget";

const TotalPage = ({
  width,
  height,
  projectActTotalData,
  targetProject,
  projectTargetPercent,
  setReportChartData,
}) => {
  const [tiles, setTiles] = useState([]);
  useEffect(() => {
    const tiles = [
      {
        header: <div>현재 목표/ 실적(공정률 대비)</div>,
        body: (
          <ProjectTarget
            height={(height / 6) * 2}
            projectActTotalData={projectActTotalData}
            targetProject={targetProject}
            projectTargetPercent={projectTargetPercent}
            base_code={base_code}
          />
        ),
        reorderable: false,
      },
      {
        header: <div>성상별 배출현황(누적%)</div>,
        body: (
          <ManbWaste
            height={(height / 6) * 4}
            base_code={base_code}
            projectActTotalData={projectActTotalData}
            targetProject={targetProject}
            projectTargetPercent={projectTargetPercent}
            setReportChartData={setReportChartData}
          />
        ),
        reorderable: false,
      },
      {
        header: <div>공정률 대비 배출현황</div>,
        body: (
          <PercentWaste
            height={(height / 6) * 3}
            base_code={base_code}
            projectActTotalData={projectActTotalData}
            targetProject={targetProject}
            projectTargetPercent={projectTargetPercent}
            setReportChartData={setReportChartData}
          />
        ),
        reorderable: false,
      },
      {
        header: <div>실행대비 비용 집행 실적</div>,
        body: (
          <CostWaste
            height={(height / 6) * 3}
            base_code={base_code}
            projectActTotalData={projectActTotalData}
            targetProject={targetProject}
            projectTargetPercent={projectTargetPercent}
          />
        ),
        reorderable: false,
      },
    ];

    setTiles(tiles);
  }, [height, projectActTotalData, targetProject, projectTargetPercent]);
  return (
    <div>
      <TileLayout
        columns={2}
        rowHeight={(height - 50) / 6}
        positions={data}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tiles}
        style={{ padding: "0px" }}
      />
    </div>
  );
};

export default TotalPage;

const data = [
  {
    col: 1,
    colSpan: 1,
    rowSpan: 2,
  },
  {
    col: 1,
    colSpan: 1,
    rowSpan: 4,
  },
  {
    col: 2,
    colSpan: 1,
    rowSpan: 3,
  },
  {
    col: 2,
    colSpan: 1,
    rowSpan: 3,
  },
];

const base_code = [
  { code: "40-01-01", name: "폐콘크리트" },
  { code: "40-02-07", name: "폐합성수지" },
  { code: "40-02-06", name: "폐목재" },
  { code: "40-04-14", name: "혼합건설폐기물" },
];
