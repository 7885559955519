import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import _ from "lodash";

import OverallManbPerformanceDetail from "./OverallManbPerformanceDetail";

const OverallManbPerformance = ({
  height,
  sectorData,
  base_code,
  setTargetManbPerformance,
}) => {
  const gridHeight = height - 32 - 45;
  const [chartDatas, setChartDatas] = useState([]);

  useEffect(() => {
    if (sectorData.length > 1) {
      const chartDatas_base_set = sectorData.map((com) => {
        const base_data = [];
        com.data.forEach((com2) => base_data.push(com2.present_plan_data));

        const base_data_flat = base_data.flat();

        const base_code_set = base_code
          .map((com2) => ({
            ...com2,
            data: base_data_flat.filter((com3) => com3.code === com2.code),
          }))
          .map((com2) => ({
            ...com2,
            plan: _.sumBy(com2.data, "plan_target_weight_cum"),
            plan_cost: _.sumBy(com2.data, "plan_cost_target_cum"),
            actual: _.sumBy(com2.data, "act_weight"),
            actual_cost: _.sumBy(com2.data, "act_cost"),
          }))
          .map((com2) => ({
            ...com2,
            dev: com2.plan - com2.actual,
            dev_cost: com2.plan_cost - com2.actual_cost,
          }));

        return {
          ...com,
          base_code: base_code_set,
        };
      });

      setChartDatas(chartDatas_base_set);
      setTargetManbPerformance(chartDatas_base_set);
    }
  }, [sectorData, base_code]);

  return (
    <div>
      <GridLayout
        style={{ height: gridHeight }}
        cols={[
          {
            width: "10%",
          },
          {
            width: "90%",
          },
        ]}
        rows={[
          {
            height: gridHeight * 0.25,
          },
          {
            height: gridHeight * 0.25,
          },
          {
            height: gridHeight * 0.25,
          },
          {
            height: gridHeight * 0.25,
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        {chartDatas.map((com, idx) => {
          return (
            <GridLayoutItem
              col={1}
              row={idx + 1}
              key={idx}
              style={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                padding: "10px 0",
              }}
            >
              <div className="overallMonthIncreaseBox">
                <div className="overallMonthIncrease">
                  {com.name.replace("사업본부", "")}
                </div>
              </div>
            </GridLayoutItem>
          );
        })}
        {chartDatas.map((com0, idx) => {
          return (
            <GridLayoutItem
              col={2}
              row={idx + 1}
              key={idx}
              style={{
                borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                padding: "10px 0",
              }}
            >
              <OverallManbPerformanceDetail
                height={gridHeight * 0.25 - 15}
                com={com0}
                key={idx}
              />
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default OverallManbPerformance;

// {sectorData.map((com, idx) => {
//   if (idx < 2) {
//     return (
//       <GridLayoutItem
//         col={Number((idx + 1) % 2) === 1 ? 1 : 2}
//         row={1}
//         key={idx}
//       >
//         <OverrallMabPerformanceChart height={gridHeight * 0.5} />
//       </GridLayoutItem>
//     );
//   } else {
//     return (
//       <GridLayoutItem
//         col={Number((idx + 1) % 2) === 1 ? 1 : 2}
//         row={2}
//         key={idx}
//       >
//         <OverrallMabPerformanceChart height={gridHeight * 0.5} />
//       </GridLayoutItem>
//     );
//   }
// })}
