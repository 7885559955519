import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import axios from "axios";
import BlockInsert from "../BlockInsert/BlockInsert";
import Url from "../url/fetchURL";
// import "./TotalLayOut.scss";

const TotalLayOutInsert = () => {
  const { project_code } = useParams();
  const [selected, setSelected] = useState(0);
  const [projectName, setProjectName] = useState("");

  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await axios.get(
        `${Url}/blockProjectInfo/${project_code}`
      );

      setProjectName(
        fetchData.data.data?.["shell_name"] === undefined
          ? ""
          : fetchData.data.data?.["shell_name"]
      );
    };

    fetchData();
  }, [project_code]);

  return (
    <div className="blockDiagramBody">
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title={`${projectName} Flow-Diagram Editor`}>
          <BlockInsert />
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default TotalLayOutInsert;
