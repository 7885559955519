import krMessages from "./kr.json";
import { useParams } from 'react-router-dom';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '@progress/kendo-theme-default/dist/all.css';
import { orderBy, filterBy  } from "@progress/kendo-data-query";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json"
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const initialFilterD = {
  logic: "and",
  filters: [
    {
      field: "uuu_tab_id",
      operator: "contains",
      value: "",
    },
  ],
};

const DetailComponent = props => {
  const [filter, setFilter] = useState(initialFilterD);

    const data = props.dataItem._bp_lineitems;

    if (data) {
        return <Grid data={filterBy(data, filter)} filterable={true}  onFilterChange={(e) => setFilter(e.filter)}
        filter={filter} style={{maxHeight:'500px', fontSize:'smaller', width:'80%', float:'right'}}>
            <Column field="uuu_tab_id" title="탭이름"/>
            <Column field="short_desc" title="이름" />
            <Column field="uuu_quantity" title="Unit Qty" />
            <Column field="uuu_unit_price" title="Unit Price"  />
        </Grid>;
    }

    return <div style={{
        height: "50px",
        width: '100%'
    }}>
        <div style={{
            position: 'absolute',
            width: '100%'
        }}>
      데이터 없음
        </div>
    </div>;
};


const initialDataState = {
    skip: 0,
    take: 10,
  };

  const initialSort = [
    {
      field: "record_no",
      dir: "",
    },
  ];

  const initialFilter = {
    logic: "and",
    filters: [
      {
        field: "record_no",
        operator: "contains",
        value: "",
      },
    ],
  };


const KGrid = () => {

    const { project_code } = useParams();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(initialDataState); 
    const [sort, setSort] = useState(initialSort);
    const [filter, setFilter] = useState(initialFilter);

    const amountCell = (props) => {
        const field = props.field || "";
        const total = list.reduce((acc, current) => acc + current[field], 0);
        return (
          <td colSpan={props.colSpan} style={props.style}>
            total: {total}
          </td>
        );
      };

    const pageChange = (event) => {
        setPage(event.page);
      };
    

    const expandChange = (event) => {
        let newData = list.map(item => {
            if (item.record_no === event.dataItem.record_no) {
              item.expanded = !event.dataItem.expanded;
            }
      
            return item;
          });
        setList(newData);
      };

    useEffect(() => {
        const fetchData = async () => {

            const res = await axios.get(`https://api.dtsolution.kr/api/getcontract?path=${project_code}`)
            setList(res.data.data)

        };
        fetchData();
    }, [project_code]);

    let pagerSettings = {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: true,
        previousNext: true,
      };


    return (
        <div>
              <LocalizationProvider language="ko-KR">
      <IntlProvider locale="ko-KR">
            <Grid  data={filterBy( orderBy(list.slice(page.skip, page.take + page.skip),sort), filter)} detail={DetailComponent} expandField="expanded" onExpandChange={expandChange}
                    skip={page.skip}
                    take={page.take}
                    total={list.length}
                    pageable={pagerSettings}
                    pageSize={page.take}
                    onPageChange={pageChange}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                      setSort(e.sort);
                    }}
                    reorderable={true}
                    resizable={true}
                    filterable={true}
                    filter={filter}
                    onFilterChange={(e) => setFilter(e.filter)}
                    >
                <Column field="record_no" title="ID" />
                <Column field="title" title="Title" />
                <Column field="amount" filter="numeric" footerCell={amountCell} />
            </Grid>
            </IntlProvider>
            </LocalizationProvider>
        </div>

    )

};

export default KGrid
