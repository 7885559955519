import React, { useEffect, useState } from "react";
import _ from "lodash";
import CostWasteChart from "../../../Components/CostWasteChart";

const CostWaste = ({
  height,
  projectActTotalData,
  targetProject,
  projectTargetPercent,
}) => {
  const gridHeight = height - 32 - 45;

  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    const total_percent_base_set = targetProject.data_arr;
    if (total_percent_base_set.length > 0) {
      const act_data_arr = total_percent_base_set.map((com) => {
        const filter_cost = projectActTotalData
          .filter((com2) => com2.uxesg_WasteCode === com.code)
          .map((com2) => ({
            ...com2,
            cost_sum: com2.uxesg_DispCost + com2.uxesg_TranCost,
          }));

        const code_cost =
          filter_cost.length > 0
            ? _.maxBy(filter_cost, "cost_sum")["cost_sum"]
            : 0;
        console.log(targetProject.uxesg_target);

        return com.percent_data
          .map((com2) => ({
            ...com2,
            plan_weight:
              (((targetProject.uxesg_SiteArea *
                com2.uxesg_factor *
                com2.factor) /
                100) *
                code_cost) /
              1000000,
            plan_weight_cum:
              (((targetProject.uxesg_SiteArea *
                com2.uxesg_factor *
                com2.factor_cum) /
                100) *
                code_cost) /
              1000000,
            plan_target_weight:
              (((targetProject.uxesg_SiteArea *
                com2.uxesg_factor *
                com2.factor) /
                100) *
                ((100 - targetProject.uxesg_target) / 100) *
                code_cost) /
              1000000,
            plan_target_weight_cum:
              (((targetProject.uxesg_SiteArea *
                com2.uxesg_factor *
                com2.factor_cum) /
                100) *
                ((100 - targetProject.uxesg_target) / 100) *
                code_cost) /
              1000000,
            act_data_date: _.maxBy(
              targetProject.act_data.filter(
                (com3) =>
                  com2.before_percent < com3.WCR_ACT_CUM &&
                  com2.percent >= com3.WCR_ACT_CUM
              ),
              "WCR_ACT_CUM"
            )?.["h_CM_CWR_enddate_PK"]?.slice(0, 10),
          }))
          .map((com2) => ({
            ...com2,
            act_data:
              com2.act_data_date === undefined
                ? 0
                : _.sumBy(
                    projectActTotalData.filter(
                      (com3) => com.code === com3.uxesg_WasteCode
                    ),
                    "uxesg_CostTotal"
                  ) / 1000000,
          }));
      });

      const chart_data_2 = _.uniqBy(act_data_arr.flat(), "percent").map(
        (com) => ({
          ...com,
          target: _.sumBy(
            act_data_arr.flat().filter((com2) => com2.percent === com.percent),
            "plan_target_weight_cum"
          ),
          plan: _.sumBy(
            act_data_arr.flat().filter((com2) => com2.percent === com.percent),
            "plan_weight_cum"
          ),
          actual: _.sumBy(
            act_data_arr.flat().filter((com2) => com2.percent === com.percent),
            "act_data"
          ),
        })
      );

      const chart_data_3 = chart_data_2
        .map((com, idx) => ({
          ...com,
          actual:
            com.actual === 0
              ? chart_data_2[idx > 0 ? idx - 1 : 0]?.["actual"]
              : com.actual,
        }))
        .map((com) => ({
          ...com,
          actual:
            com.percent - 5 > targetProject.percent ? undefined : com.actual,
        }));

      const fixed_chart_data_3 = chart_data_3.map((com, idx) => {
        if (idx !== 0) {
          if (com.actual === 0) {
            const slice_data = chart_data_3.slice(0, idx);

            const max_actual = _.maxBy(slice_data, "actual");

            return { ...com, actual: max_actual["actual"] };
          } else {
            return com;
          }
        } else {
          return com;
        }
      });

      setChartData(fixed_chart_data_3);
    } else {
      setChartData([]);
    }
  }, [targetProject, projectActTotalData]);

  return (
    <div>
      <CostWasteChart height={gridHeight} chartData={chartData} />
    </div>
  );
};

export default CostWaste;
