import React, { useEffect, useState } from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import _ from "lodash";
import {
  SubTitleDiv,
  headerClass,
  LGChemicalTable,
} from "../../../Public/StyledComponents";
import { normalCell, listNumberCell } from "../../../Public/TableCell";
import moment from "moment";

const PurchaseTable = ({
  height,
  purchaseOrdersFMData_concat,
  materialsReceivedData_Original,
  dateRange,
}) => {
  const [receivedData, setReceivedData] = useState([]);
  const [delayData, setDelayData] = useState([]);

  useEffect(() => {
    if (
      Object.keys(dateRange).filter((com) => dateRange[com] === null).length ===
      0
    ) {
      const FMData_filter = purchaseOrdersFMData_concat.filter(
        (com) =>
          com.effective_date >= dateRange.start &&
          com.effective_date <= dateRange.end
      );

      const recevied_FMData = _.sortBy(
        FMData_filter.filter((com) => {
          const find_obj = materialsReceivedData_Original.find(
            (com2) => com2.uponumber === com.record_no
          );

          return find_obj;
        }),
        "effective_date"
      ).map((com, idx) => {
        return {
          ...com,
          No: idx + 1,
          receivedDate: moment(com.effective_date).format("YY.MM.DD"),
        };
      });

      const not_recevied_FMData = _.sortBy(
        FMData_filter.filter((com) => {
          const find_obj = materialsReceivedData_Original.find(
            (com2) => com2.uponumber === com.record_no
          );

          return !find_obj;
        }),
        "effective_date"
      ).map((com, idx) => ({
        ...com,
        No: idx + 1,
        receivedDate: moment(com.effective_date).format("YY.MM.DD"),
      }));

      setReceivedData(recevied_FMData);
      setDelayData(not_recevied_FMData);
      //
    }
  }, [dateRange, purchaseOrdersFMData_concat, materialsReceivedData_Original]);
  return (
    <>
      <GridLayoutItem row={3} col={1}>
        <SubTitleDiv text_position={"flex-start"}>
          기간 내 입고 목록
        </SubTitleDiv>
      </GridLayoutItem>
      <GridLayoutItem row={4} col={1} style={{ marginRight: "5px" }}>
        <LGChemicalTable>
          <Grid
            className={headerClass}
            style={{
              width: "100%",
              maxHeight: height * 0.4,
            }}
            data={receivedData}
          >
            <GridColumn
              title="No."
              field="No"
              width={45}
              cell={listNumberCell}
            />
            <GridColumn
              title="Material Name"
              field="umatSKUMasterNameTB250"
              cell={normalCell}
            />
            <GridColumn
              title="입고량"
              field="uuu_quantity"
              width={60}
              cell={normalCell}
            />
            <GridColumn
              title="입고일"
              field="receivedDate"
              width={70}
              cell={normalCell}
            />
          </Grid>
        </LGChemicalTable>
      </GridLayoutItem>
      <GridLayoutItem row={3} col={2}>
        <SubTitleDiv text_position={"flex-start"}>
          기간 내 입고 지연 품목
        </SubTitleDiv>
      </GridLayoutItem>
      <GridLayoutItem row={4} col={2} style={{ marginLeft: "5px" }}>
        <LGChemicalTable>
          <Grid
            className={headerClass}
            style={{
              width: "100%",
              maxHeight: height * 0.3,
            }}
            data={delayData}
          >
            <GridColumn
              title="No."
              field="No"
              width={45}
              cell={listNumberCell}
            />
            <GridColumn
              title="Material Name"
              field="umatSKUMasterNameTB250"
              cell={normalCell}
            />
            <GridColumn
              title="입고량"
              field="uuu_quantity"
              width={60}
              cell={normalCell}
            />
            <GridColumn
              title="입고일"
              field="receivedDate"
              width={70}
              cell={normalCell}
            />
          </Grid>
        </LGChemicalTable>
      </GridLayoutItem>
    </>
  );
};

export default PurchaseTable;
