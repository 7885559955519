export const normalCell = (props) => {
  const { field, dataItem } = props;

  return (
    <td colSpan={props.colSpan} style={{ ...props.style, fontSize: "0.7rem" }}>
      {dataItem[field]}
    </td>
  );
};

export const listNumberCell = (props) => {
  const { field, dataItem } = props;

  return (
    <td
      colSpan={props.colSpan}
      style={{ ...props.style, fontSize: "0.7rem", padding: "8px 6px" }}
    >
      {dataItem[field]}
    </td>
  );
};
