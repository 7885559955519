import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const SectorTable = ({ height, tableDatas }) => {
  const [arcTableDataWeight, setArcTableDataWeight] = useState(base_obj);
  const [arcTableDataCost, setArcTableDataCost] = useState(base_obj);
  const [civilTableDataWeight, setCivilTableDataWeight] = useState(base_obj);
  const [civilTableDataCost, setCivilTableDataCost] = useState(base_obj);
  const [plantTableDataWeight, setPlantTableDataWeight] = useState(base_obj);
  const [plantTableDataCost, setPlantTableDataCost] = useState(base_obj);

  useEffect(() => {
    const arc_data_set = tableDatas.find(
      (com) => com.name === "건축사업본부"
    )?.["base_code_data"];

    setArcTableDataWeight((prev) => ({
      ...prev,
      total: _.sumBy(arc_data_set, "totalWeight"),
      conc:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "40-01-01")?.[
              "totalWeight"
            ],
      plastic:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "40-02-07")?.[
              "totalWeight"
            ],

      wood:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "40-02-06")?.[
              "totalWeight"
            ],
      mix:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "40-04-14")?.[
              "totalWeight"
            ],
      others:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "others")?.["totalWeight"],
    }));

    setArcTableDataCost((prev) => ({
      ...prev,
      total: _.sumBy(arc_data_set, "totalCost") / 1000000,
      conc:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "40-01-01")?.["totalCost"] /
            1000000,
      plastic:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "40-02-07")?.["totalCost"] /
            1000000,

      wood:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "40-02-06")?.["totalCost"] /
            1000000,
      mix:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "40-04-14")?.["totalCost"] /
            1000000,
      others:
        arc_data_set === undefined
          ? 0
          : arc_data_set.find((com) => com.code === "others")?.["totalCost"] /
            1000000,
    }));

    const civil_data_set = tableDatas.find(
      (com) => com.name === "토목사업본부"
    )?.["base_code_data"];

    setCivilTableDataWeight((prev) => ({
      ...prev,
      total: _.sumBy(civil_data_set, "totalWeight"),
      conc:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "40-01-01")?.[
              "totalWeight"
            ],
      plastic:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "40-02-07")?.[
              "totalWeight"
            ],

      wood:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "40-02-06")?.[
              "totalWeight"
            ],
      mix:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "40-04-14")?.[
              "totalWeight"
            ],
      others:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "others")?.[
              "totalWeight"
            ],
    }));

    setCivilTableDataCost((prev) => ({
      ...prev,
      total: _.sumBy(civil_data_set, "totalCost") / 1000000,
      conc:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "40-01-01")?.[
              "totalCost"
            ] / 1000000,
      plastic:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "40-02-07")?.[
              "totalCost"
            ] / 1000000,

      wood:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "40-02-06")?.[
              "totalCost"
            ] / 1000000,
      mix:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "40-04-14")?.[
              "totalCost"
            ] / 1000000,
      others:
        civil_data_set === undefined
          ? 0
          : civil_data_set.find((com) => com.code === "others")?.["totalCost"] /
            1000000,
    }));

    const plant_data_set = tableDatas.find(
      (com) => com.name === "플랜트사업본부"
    )?.["base_code_data"];

    setPlantTableDataWeight((prev) => ({
      ...prev,
      total: _.sumBy(plant_data_set, "totalWeight"),
      conc:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "40-01-01")?.[
              "totalWeight"
            ],
      plastic:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "40-02-07")?.[
              "totalWeight"
            ],

      wood:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "40-02-06")?.[
              "totalWeight"
            ],
      mix:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "40-04-14")?.[
              "totalWeight"
            ],
      others:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "others")?.[
              "totalWeight"
            ],
    }));

    setPlantTableDataCost((prev) => ({
      ...prev,
      total: _.sumBy(plant_data_set, "totalCost") / 1000000,
      conc:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "40-01-01")?.[
              "totalCost"
            ] / 1000000,
      plastic:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "40-02-07")?.[
              "totalCost"
            ] / 1000000,

      wood:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "40-02-06")?.[
              "totalCost"
            ] / 1000000,
      mix:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "40-04-14")?.[
              "totalCost"
            ] / 1000000,
      others:
        plant_data_set === undefined
          ? 0
          : plant_data_set.find((com) => com.code === "others")?.["totalCost"] /
            1000000,
    }));
  }, [tableDatas]);

  return (
    <div>
      <GridLayout
        style={{ height: height - 20, border: "1px solid #E8E7E6" }}
        cols={[
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
        ]}
        rows={[
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
          {
            height: `${100 / 8}%`,
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem
          col={1}
          row={1}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          본부
        </GridLayoutItem>
        <GridLayoutItem
          col={2}
          row={1}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          배출
          <br />
          총량
        </GridLayoutItem>
        <GridLayoutItem
          col={3}
          colSpan={5}
          row={1}
          className="OverallTable tablegray"
        >
          성상구분
        </GridLayoutItem>
        <GridLayoutItem col={3} row={2} className="OverallTable tablegray">
          폐콘
        </GridLayoutItem>
        <GridLayoutItem col={4} row={2} className="OverallTable tablegray">
          폐합
        </GridLayoutItem>
        <GridLayoutItem col={5} row={2} className="OverallTable tablegray">
          폐목
        </GridLayoutItem>
        <GridLayoutItem col={6} row={2} className="OverallTable tablegray">
          혼합
        </GridLayoutItem>
        <GridLayoutItem col={7} row={2} className="OverallTable tablegray">
          기타
        </GridLayoutItem>
        <GridLayoutItem
          col={1}
          row={3}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          건축
        </GridLayoutItem>
        <GridLayoutItem
          col={1}
          row={5}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          토목
        </GridLayoutItem>
        <GridLayoutItem
          col={1}
          row={7}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          플랜트
        </GridLayoutItem>
        {Object.keys(arcTableDataWeight).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={3}
              className="OverallTable"
              key={idx}
            >
              {Number(arcTableDataWeight[com].toFixed(0)).toLocaleString()}
            </GridLayoutItem>
          );
        })}
        {Object.keys(arcTableDataCost).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={4}
              className="OverallTable"
              key={idx}
            >
              {Number(arcTableDataCost[com].toFixed(0)).toLocaleString()}
            </GridLayoutItem>
          );
        })}
        {Object.keys(civilTableDataWeight).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={5}
              className="OverallTable"
              key={idx}
            >
              {Number(civilTableDataWeight[com].toFixed(0)).toLocaleString()}
            </GridLayoutItem>
          );
        })}
        {Object.keys(civilTableDataCost).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={6}
              className="OverallTable"
              key={idx}
            >
              {Number(civilTableDataCost[com].toFixed(0)).toLocaleString()}
            </GridLayoutItem>
          );
        })}
        {Object.keys(plantTableDataWeight).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={7}
              className="OverallTable"
              key={idx}
            >
              {Number(plantTableDataWeight[com].toFixed(0)).toLocaleString()}
            </GridLayoutItem>
          );
        })}
        {Object.keys(plantTableDataCost).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={8}
              className="OverallTable"
              key={idx}
            >
              {Number(plantTableDataCost[com].toFixed(0)).toLocaleString()}
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default SectorTable;

const base_obj = {
  total: 0,
  conc: 0,
  plastic: 0,
  wood: 0,
  mix: 0,
  others: 0,
};
