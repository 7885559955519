import React, { useEffect, useState } from "react";
import { GridLayout } from "@progress/kendo-react-layout";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import { bplist } from "../../../Public/BPList";
import InProgressMaintenance from "./InProgressMaintenance";
import PreventiveMaintenance from "./PreventiveMaintenance";
import ExpectedMaterials from "./ExpectedMaterials";

const { REACT_APP_API_LGCHEMICAL_URI } = process.env;
const {
  CorrectiveWorkOrders,
  PreventiveWorkOrders,
  PM_Books,
  JobPlans,
  MaterialInventoryManager,
} = bplist;

const next_months = new Date(
  moment(new Date()).add(3, "months").format("YYYY-MM-DD")
);

const FacilityManagement = ({ project_code, height }) => {
  const [correctiveWorkOrderData, setCorrectiveWorkOrderData] = useState([]);
  const [preventiveWorkOrderData, setPreventiveWorkOrderData] = useState([]);
  const [pmBooksData, setPmBooksData] = useState([]);
  const [jobPlansData, setJobPlanesData] = useState([]);
  const [materialInventoryManagerData, setMaterialInventoryManagerData] =
    useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const request_correctiveWorkOrders = await axios
        .post(
          `${REACT_APP_API_LGCHEMICAL_URI}/lgchemical/getbprecord?path=${project_code}`,
          CorrectiveWorkOrders
        )
        .then((com) => com.data.data);

      const request_preventiveWorkOrders = await axios
        .post(
          `${REACT_APP_API_LGCHEMICAL_URI}/lgchemical/getbprecord?path=${project_code}`,
          PreventiveWorkOrders
        )
        .then((com) => com.data.data);

      const request_pm_books = await axios
        .post(
          `${REACT_APP_API_LGCHEMICAL_URI}/lgchemical/getbprecord?path=${project_code}`,
          PM_Books
        )
        .then((com) =>
          com.data.data
            .filter((com2) => com2._bp_lineitems !== undefined)
            .map((com2) => ({
              ...com2,
              _bp_lineitems: com2._bp_lineitems.filter(
                (com3) => com3.uuu_tab_id === "Scheduled PM"
              ),
            }))
            .filter((com2) => com2._bp_lineitems.length > 0)
            .reduce(
              (acc, cur) =>
                acc.concat(
                  cur._bp_lineitems.map((com2) => {
                    return Object.assign(com2, {
                      uuu_rec_asset_bp: cur.uuu_rec_asset_bp,
                      ueqpPMBookTemPK: cur.ueqpPMBookTemPK,
                      upmModelTXT120: cur.upmModelTXT120,
                      upmEqpTypePD: cur.upmEqpTypePD,
                      upmManufacturerTXT120: cur.upmManufacturerTXT120,
                      upmEqpName: cur.upmEqpName,
                    });
                  })
                ),

              []
            )
            .map((com2) => ({
              ...com2,
              due_date: new Date(com2.uuu_rec_next_due_date.slice(0, 10)),
            }))
            .filter(
              (com2) =>
                com2.due_date >= new Date() && com2.due_date <= next_months
            )
        );

      const request_JobPlans = await axios
        .post(
          `${REACT_APP_API_LGCHEMICAL_URI}/lgchemical/getbprecord?path=${project_code}`,
          JobPlans
        )
        .then((com) =>
          com.data.data
            .filter((com2) => com2._bp_lineitems !== undefined)
            .map((com2) => ({
              ...com2,
              _bp_lineitems: com2._bp_lineitems.filter(
                (com3) => com3.uuu_tab_id === "Material"
              ),
            }))
            .filter((com2) => com2._bp_lineitems.length > 0)
            .reduce(
              (acc, cur) =>
                acc.concat(
                  cur._bp_lineitems.map((com2) => {
                    return Object.assign(com2, {
                      uAssetTemplateDP: cur.uAssetTemplateDP,
                      ujpJobPlanName: cur.ujpJobPlanName,
                      upmModelTXT120: cur.upmModelTXT120,
                      upmEqpTypePD: cur.upmEqpTypePD,
                      description: cur.description,
                      upmManufacturerTXT120: cur.upmManufacturerTXT120,
                      record_no: cur.record_no,
                    });
                  })
                ),
              []
            )
        );

      const request_MaterialInventoryManager = await axios
        .post(
          `${REACT_APP_API_LGCHEMICAL_URI}/lgchemical/getbprecord?path=${project_code}`,
          MaterialInventoryManager
        )
        .then((com) => com.data.data);

      setCorrectiveWorkOrderData(request_correctiveWorkOrders);
      setPreventiveWorkOrderData(request_preventiveWorkOrders);
      setPmBooksData(request_pm_books);
      setJobPlanesData(request_JobPlans);
      setMaterialInventoryManagerData(request_MaterialInventoryManager);
    };

    fetchData();
  }, [project_code]);
  return (
    <FacilityManagementDiv>
      <GridLayout
        gap={{
          rows: 0,
          cols: 0,
        }}
        rows={[
          {
            height: height * 0.1,
          },
          {
            height: height * 0.2,
          },
          {
            height: height * 0.35,
          },
          {
            height: height * 0.35,
          },
        ]}
        cols={[
          {
            width: "30%",
          },
          {
            width: "30%",
          },
          {
            width: "40%",
          },
        ]}
      >
        <InProgressMaintenance
          height={height}
          correctiveWorkOrderData={correctiveWorkOrderData}
          preventiveWorkOrderData={preventiveWorkOrderData}
        />
        <PreventiveMaintenance
          height={height}
          pmBooksData={pmBooksData}
          next_months={next_months}
        />
        <ExpectedMaterials
          height={height}
          jobPlansData={jobPlansData}
          pmBooksData={pmBooksData}
          materialInventoryManagerData={materialInventoryManagerData}
        />
      </GridLayout>
    </FacilityManagementDiv>
  );
};

export default FacilityManagement;

const FacilityManagementDiv = styled.div``;
