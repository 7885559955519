import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TileLayout } from "@progress/kendo-react-layout";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import record_project from "../url/Record_Project";
import ProjectInfoPage from "./ProjectInfoPage/ProjectInfoPage";
import DateRangePage from "./DateRangePage/DateRangePage";
import TotalPage from "./TotalPage/TotalPage";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import bpname from "../BpName/BpName";
import url from "../url/URL";
import "../index.scss"

const iframe_boolean = window.self === window.top;

const ProjectDetailPage = () => {
  const navigate = useHistory();
  const { project_code } = useParams();
  const { height, width } = useWindowDimensions();

  const [wasteCoefficient, setWasteCoefficient] = useState([]);

  const [projectTargetPercent, setProjectTargetPercent] = useState(0);

  const [projectAct, setProjectAct] = useState({
    target: 0,
    percent_act: 0,
    percent_data: [],
  });

  const [targetProject, setTargetProject] = useState({
    percent: 0,
    data_arr: [],
    act_data: [],
    uxesg_SiteArea: 0,
    uxesg_target: 0,
  });

  const [projectList, setProjectList] = useState([]);

  const [selectedValue, setSelectedValue] = useState(true);

  const [tiles, setTiles] = useState([]);

  const [reportData, setReportData] = useState({
    date: new Date(),
    startDate: new Date(),
    endDate: new Date(),
  });

  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment(new Date()).subtract(11, "months").format("YYYY-MM") + "-01"
    ),
    endDate: new Date(),
  });

  const [comboBoxValue, setComboBoxValue] = useState({
    sector: "",
    project: "",
  });
  const [comboBoxList, setComboBoxList] = useState({ sector: [], project: [] });

  const [projectActTotalData, setProjectActTotalData] = useState([]);
  const [projectActTotalData_report, setProjectActTotalData_report] = useState(
    []
  );
  const [reportChartData, setReportChartData] = useState({
    percent: [],
    manb: [],
  });

  const [project_info, setProject_info] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const projectTarget_body = {
        bpname: bpname.wasteTarget,
        lineitem: "no",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "uxesg_YearOnlytxt",
              value: moment(new Date()).format("YYYY"),
              condition_type: "eq",
            },
          ],
        },
      };

      const requestProjectTarget = await axios.post(
        `${url}/getbprecord3?path=${record_project}`,
        projectTarget_body
      );

      const project_tartget_filter = requestProjectTarget.data.data.data
        .filter((com) => com.status !== "Completed")
        .filter((com) => com.status !== "Terminated");

      const project_target =
        project_tartget_filter.length > 0
          ? project_tartget_filter[0]["uxesg_ReducePlan"]
          : 0;

      setProjectTargetPercent(project_target);

      const projectInfo_body = {
        bpname: bpname.wasteProject,
        lineitem: "yes",
      };

      const requestProjectInformation = await axios.post(
        `${url}/getbprecord3?path=${record_project}`,
        projectInfo_body
      );

      const requestProjectInformation_filter =
        requestProjectInformation.data.data.data
          .filter((com) => com.status !== "Completed")
          .filter((com) => com.status !== "Terminated");

      const requestProjectInformation_result = requestProjectInformation_filter
        .filter(
          (com) => com.status !== "Completed" || com.status !== "Terminated"
        )
        .filter((com) => com._bp_lineitems !== undefined)
        .map((com) => ({
          ...com,
          _bp_lineitems: com._bp_lineitems.filter((com2) => com2.tab_id === 1),
        }))
        .filter((com) => com._bp_lineitems.length > 0);

      setProjectList(requestProjectInformation_result);

      setComboBoxList({
        sector: _.uniq(
          requestProjectInformation_result.map((com) => com.uxesg_SectorName)
        ),
        project: requestProjectInformation_result,
      });

      if (requestProjectInformation_result.length > 0) {
        const projectValue =
          project_code === "allproject"
            ? requestProjectInformation_result[0]["uxesg_PJTName"]
            : requestProjectInformation_result.find(
                (com) => com.uxesg_PJTCode === project_code
              )?.["uxesg_PJTName"];

        const sectorValue =
          project_code === "allproject"
            ? requestProjectInformation_result[0]["uxesg_SectorName"]
            : requestProjectInformation_result.find(
                (com) => com.uxesg_PJTCode === project_code
              )?.["uxesg_SectorName"];

        const uxesg_PJTCate =
          project_code === "allproject"
            ? requestProjectInformation_result[0]["uxesg_PJTCate"]
            : requestProjectInformation_result.find(
                (com) => com.uxesg_PJTCode === project_code
              )?.["uxesg_PJTCate"];

        const projectCode =
          project_code === "allproject"
            ? requestProjectInformation_result[0]["uxesg_PJTCode"]
            : requestProjectInformation_result.find(
                (com) => com.uxesg_PJTCode === project_code
              )?.["uxesg_PJTCode"];

        setProject_info(
          project_code === "allproject"
            ? requestProjectInformation_result[0]
            : requestProjectInformation_result.find(
                (com) => com.uxesg_PJTCode === project_code
              )
        );

        setComboBoxValue((prev) => ({
          ...prev,
          project: projectValue,
          sector: sectorValue,
        }));

        let body = {
          bpname: bpname.wastePerformance,
          lineitem: "no",
          filter_criteria: {
            join: "AND",
            filter: [
              {
                field: "uxesg_PJTPK",
                value: projectValue,
                condition_type: "eq",
              },
              {
                field: "uxesg_manb_type",
                value: "확정/입력",
                condition_type: "eq",
              },
            ],
          },
        };

        const request_performance_res = await axios.post(
          `${url}/getbprecord3?path=${record_project}`,
          body
        );

        setProjectActTotalData_report(request_performance_res.data.data.data);

        setProjectActTotalData(
          request_performance_res.data.data.data.filter(
            (com) =>
              com.uxesg_RegiCate === "건설폐기물" &&
              com.uxesg_PresentWaste === "본공사"
          )
        );

        setReportData((prev) => ({
          ...prev,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          project: projectValue,
          sector: sectorValue,
        }));

        const wasteCoefficient_body = {
          bpname: bpname.wasteCoefficient,
          lineitem: "no",
          filter_criteria: {
            join: "AND",
            filter: [
              {
                field: "uxesg_PJTCate",
                value: uxesg_PJTCate,
                condition_type: "eq",
              },
            ],
          },
        };

        const requestWasteCoefficient = await axios.post(
          `${url}/getbprecord3?path=${record_project}`,
          wasteCoefficient_body
        );

        setWasteCoefficient(requestWasteCoefficient.data.data.data);

        const project_percent_body = {
          bpname: "Construction Weekly Report",
          lineitem: "no",
        };

        const request_project_percent = await axios.post(
          `${url}/getbprecord3?path=${projectCode}`,
          project_percent_body
        );

        const project_actual_2 = request_project_percent.data.data.data;

        if (project_actual_2.length > 0) {
          setProjectAct((prev) => ({
            ...prev,
            target: project_target,
            percent_act:
              _.maxBy(project_actual_2, "WCR_ACT_CUM")?.["WCR_ACT_CUM"] ===
              undefined
                ? 0
                : _.maxBy(project_actual_2, "WCR_ACT_CUM")?.["WCR_ACT_CUM"],
            percent_data: project_actual_2,
          }));
        }
      }
    };
    fetchData();
  }, [project_code]);

  useEffect(() => {
    if (comboBoxValue.project !== null) {
      const targetProject_1 = projectList.find(
        (com) => com.uxesg_PJTName === comboBoxValue.project
      );

      const percentArr = [];

      wasteCoefficient.forEach((com) => {
        for (let i = 1; i <= 21; i++) {
          percentArr.push({
            cate: com.uxesg_PJTCate,
            code: com.uxesg_WasteCode,
            name: com.uxesg_WasteCate,
            uxesg_factor: com.uxesg_factor,
            before_percent: i === 1 ? 0 : com[`uxesg_ProcessRatio${i - 1}`],
            percent: com[`uxesg_ProcessRatio${i}`],
            factor: com[`uxesg_factor2_${i}`],
            factor_cum: com[`uxesg_factor3_${i}`],
          });
        }
      });

      const project_base_code = base_code.map((com) => ({
        ...com,
        percent_data: _.sortBy(
          percentArr.filter((com2) => com2.code === com.code),
          "percent"
        ),
      }));

      const projectTargetArr = project_base_code
        .map((com) => ({
          ...com,
          projectPercent: projectAct.percent_act,
          percent_data2: com.percent_data.find(
            (com2) =>
              com2.before_percent < projectAct.percent_act &&
              com2.percent >= projectAct.percent_act
          ),
        }))
        .map((com) => ({
          ...com,
          target_weight:
            targetProject_1 !== undefined && com.percent_data2 !== undefined
              ? ((targetProject.uxesg_SiteArea *
                  com.percent_data2.uxesg_factor *
                  com.percent_data2.factor_cum) /
                  100) *
                ((100 - projectAct.target) / 100)
              : 0,
          plan_weight:
            targetProject_1 !== undefined && com.percent_data2 !== undefined
              ? (targetProject.uxesg_SiteArea *
                  com.percent_data2.uxesg_factor *
                  com.percent_data2.factor_cum) /
                100
              : 0,
          plan_cost: 0,
        }))
        .map((com) => {
          const filter_cost = projectActTotalData
            .filter((com2) => com2.uxesg_WasteCode === com.code)
            .map((com2) => ({
              ...com2,
              cost_sum: com2.uxesg_DispCost + com2.uxesg_TranCost,
            }));

          const code_cost =
            filter_cost.length > 0
              ? _.maxBy(filter_cost, "cost_sum")["cost_sum"]
              : 0;

          return { ...com, plan_cost: com.plan_weight * code_cost };
        });

      setTargetProject((prev) => ({
        ...prev,
        percent: projectAct.percent_act,
        data_arr: projectTargetArr,
        act_data: projectAct.percent_data,
        uxesg_SiteArea:
          targetProject_1 === undefined ? 0 : targetProject_1.uxesg_SiteArea,
        uxesg_target: projectTargetPercent,
      }));
    }
  }, [
    projectList,
    wasteCoefficient,
    projectActTotalData,
    projectAct,
    projectTargetPercent,
  ]);

  const request_project_data = async () => {
    let body = {
      bpname: bpname.wastePerformance,
      lineitem: "no",
      filter_criteria: {
        join: "AND",
        filter: [
          {
            field: "uxesg_manb_type",
            value: "확정/입력",
            condition_type: "eq",
          },
          {
            field: "uxesg_RegiCate",
            value: "건설폐기물",
            condition_type: "eq",
          },
          {
            field: "uxesg_PresentWaste",
            value: "본공사",
            condition_type: "eq",
          },
        ],
      },
    };

    if (comboBoxValue.project === null) {
      body.filter_criteria.filter.push({
        field: "uxesg_SectorName",
        value: comboBoxValue.sector,
        condition_type: "eq",
      });
    } else {
      body.filter_criteria.filter.push({
        field: "uxesg_PJTPK",
        value: comboBoxValue.project,
        condition_type: "eq",
      });
    }

    if (comboBoxValue.sector === null) {
      body.filter_criteria.filter.pop();
    }

    const request_performance_res = await axios.post(
      `${url}/getbprecord3?path=${record_project}`,
      body
    );

    setProjectActTotalData_report(request_performance_res.data.data.data);

    setProjectActTotalData(
      request_performance_res.data.data.data.filter(
        (com) =>
          com.uxesg_RegiCate === "건설폐기물" &&
          com.uxesg_PresentWaste === "본공사"
      )
    );

    if (comboBoxValue.project === null || comboBoxValue.sector === null) {
      setSelectedValue(false);
    } else {
      setSelectedValue((prev) => prev);

      const projectInfo_body = {
        bpname: bpname.wasteProject,
        lineitem: "yes",
      };

      const requestProjectInformation = await axios.post(
        `${url}/getbprecord3?path=${record_project}`,
        projectInfo_body
      );

      const uxesg_PJTCate = requestProjectInformation.data.data.data.find(
        (com) =>
          com.uxesg_SectorName === comboBoxValue.sector &&
          com.uxesg_PJTName === comboBoxValue.project
      );

      setProject_info(uxesg_PJTCate);

      const wasteCoefficient_body = {
        bpname: bpname.wasteCoefficient,
        lineitem: "no",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "uxesg_PJTCate",
              value: uxesg_PJTCate["uxesg_PJTCate"],
              condition_type: "eq",
            },
          ],
        },
      };

      const requestWasteCoefficient = await axios.post(
        `${url}/getbprecord3?path=${record_project}`,
        wasteCoefficient_body
      );

      setWasteCoefficient(requestWasteCoefficient.data.data.data);

      const project_percent_body = {
        bpname: "Construction Weekly Report",
        lineitem: "no",
      };

      const request_project_percent = await axios.post(
        `${url}/getbprecord3?path=${uxesg_PJTCate["uxesg_PJTCode"]}`,
        project_percent_body
      );

      // const project_actual_2 = sampleData.data;
      const project_actual_2 = request_project_percent.data.data.data;

      if (project_actual_2.length > 0) {
        setProjectAct((prev) => ({
          ...prev,
          target: projectTargetPercent,
          percent_act:
            _.maxBy(project_actual_2, "WCR_ACT_CUM")?.["WCR_ACT_CUM"] ===
            undefined
              ? 0
              : _.maxBy(project_actual_2, "WCR_ACT_CUM")?.["WCR_ACT_CUM"],
          percent_data: project_actual_2,
        }));
      } else {
        setProjectAct((prev) => ({
          ...prev,
          target: 0,
          percent_act: 0,
          percent_data: [],
        }));
      }
    }
  };

  const gotoReportPage = () => {
    navigate.push({
      pathname:`/hanwhaenv/projectreport`, 
      state: {
        project_info: project_info,
        targetProject: targetProject,
        projectAct: projectAct,
        projectActTotalData: projectActTotalData_report,
        projectTargetPercent: projectTargetPercent,
        dateRange: dateRange,
        reportChartData_percent: reportChartData.percent,
        reportChartData_manb: reportChartData.manb,
      },
    });
  };

  useEffect(() => {
    const tiles = [
      {
        item: (
          <ProjectInfoPage
            width={width - 20}
            height={((iframe_boolean ? height : height - 210) - 30) * 0.1}
            selectedValue={selectedValue}
            dateRange={dateRange}
            comboBoxList={comboBoxList}
            comboBoxValue={comboBoxValue}
            setSelectedValue={setSelectedValue}
            setDateRange={setDateRange}
            setComboBoxValue={setComboBoxValue}
            request_project_data={request_project_data}
            gotoReportPage={gotoReportPage}
          />
        ),
        reorderable: false,
        resizable: false,
      },
      {
        item: selectedValue ? (
          <TotalPage
            width={width - 20}
            height={((iframe_boolean ? height : height - 210) - 30) * 0.9}
            projectActTotalData={projectActTotalData}
            targetProject={targetProject}
            projectTargetPercent={projectTargetPercent}
            setReportChartData={setReportChartData}
          />
        ) : (
          <DateRangePage
            width={width - 20}
            height={((iframe_boolean ? height : height - 210) - 30) * 0.9}
            projectActTotalData={projectActTotalData}
            dateRange={dateRange}
          />
        ),
        reorderable: false,
        resizable: false,
      },
    ];

    setTiles(tiles);
  }, [
    selectedValue,
    projectActTotalData,
    comboBoxValue,
    comboBoxList,
    dateRange,
    height,
    targetProject,
    width,
    projectTargetPercent,
  ]);

  return (
    <div className="projectDetailPage">
      <TileLayout
        columns={1}
        rowHeight={(height - 210) / 12}
        positions={data}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tiles}
      />
    </div>
  );
};

export default ProjectDetailPage;

const data = [
  {
    col: 1,
    colSpan: 1,
    rowSpan: 1,
  },
  {
    col: 1,
    colSpan: 1,
    rowSpan: 12,
  },
];

const base_code = [
  { code: "40-01-01", name: "폐콘크리트" },
  { code: "40-02-07", name: "폐합성수지" },
  { code: "40-02-06", name: "폐목재" },
  { code: "40-04-14", name: "혼합건설폐기물" },
];
