import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const TotalTable = ({ height, tableDatas }) => {
  const [tableDataWeight, setTableDataWeight] = useState({
    target: 0,
    act: 0,
    dev: 0,
    percent: 0,
  });

  const [tableDataCost, setTableDataCost] = useState({
    target: 0,
    act: 0,
    dev: 0,
    percent: 0,
  });

  useEffect(() => {
    setTableDataWeight((prev) => ({
      ...prev,
      target: sum_data(
        tableDatas,
        "project_total_target_weight"
      ).toLocaleString(),
      act: sum_data(tableDatas, "project_total_act_weight").toLocaleString(),
      dev: (
        sum_data(tableDatas, "project_total_target_weight") -
        sum_data(tableDatas, "project_total_act_weight")
      ).toLocaleString(),
      percent:
        Number(
          (
            (sum_data(tableDatas, "project_total_act_weight") /
              sum_data(tableDatas, "project_total_target_weight")) *
            100
          ).toFixed(2)
        ) === Infinity || 
        isNaN(Number(
          (
            (sum_data(tableDatas, "project_total_act_weight") /
              sum_data(tableDatas, "project_total_target_weight")) *
            100
          ).toFixed(2)
        )) ? 0 :  Number(
          (
            (sum_data(tableDatas, "project_total_act_weight") /
              sum_data(tableDatas, "project_total_target_weight")) *
            100
          ).toFixed(2)
        ) + "%",
    }));

    setTableDataCost((prev) => ({
      ...prev,
      target: sum_data(
        tableDatas,
        "project_total_target_cost"
      ).toLocaleString(),
      act: sum_data(tableDatas, "project_total_act_cost").toLocaleString(),
      dev: (
        sum_data(tableDatas, "project_total_target_cost") -
        sum_data(tableDatas, "project_total_act_cost")
      ).toLocaleString(),
      percent:
        Number(
          (
            (sum_data(tableDatas, "project_total_act_cost") /
              sum_data(tableDatas, "project_total_target_cost")) *
            100
          ).toFixed(2)
        ) === Infinity ||
        isNaN(
          Number(
            (
              (sum_data(tableDatas, "project_total_act_cost") /
                sum_data(tableDatas, "project_total_target_cost")) *
              100
            ).toFixed(2)
          )
        )
          ? 0
          : Number(
              (
                (sum_data(tableDatas, "project_total_act_cost") /
                  sum_data(tableDatas, "project_total_target_cost")) *
                100
              ).toFixed(2)
            ) + "%",
    }));
  }, [tableDatas]);

  return (
    <div>
      <GridLayout
        style={{ height: height - 20, border: "1px solid #E8E7E6" }}
        cols={[
          {
            width: `${100 / 5}%`,
          },
          {
            width: `${100 / 5}%`,
          },
          {
            width: `${100 / 5}%`,
          },
          {
            width: `${100 / 5}%`,
          },
          {
            width: `${100 / 5}%`,
          },
        ]}
        rows={[
          {
            height: "25%",
          },
          {
            height: "25%",
          },
          {
            height: "25%",
          },
          {
            height: "25%",
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem
          col={1}
          row={1}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          구분
        </GridLayoutItem>
        <GridLayoutItem
          col={2}
          row={1}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          배출
          <br />
          목표
        </GridLayoutItem>
        <GridLayoutItem
          col={3}
          colSpan={5}
          row={1}
          className="OverallTable tablegray"
        >
          배출실적
        </GridLayoutItem>
        <GridLayoutItem col={3} row={2} className="OverallTable tablegray">
          누적
          <br /> 배출량
        </GridLayoutItem>
        <GridLayoutItem col={4} row={2} className="OverallTable tablegray">
          차이
        </GridLayoutItem>
        <GridLayoutItem col={5} row={2} className="OverallTable tablegray">
          달성률
        </GridLayoutItem>

        <GridLayoutItem col={1} row={3} className="OverallTable tablegray">
          반출량
        </GridLayoutItem>
        <GridLayoutItem col={1} row={4} className="OverallTable tablegray">
          비용
        </GridLayoutItem>

        {Object.keys(tableDataWeight).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={3}
              className="OverallTable"
              key={idx}
            >
              {tableDataWeight[com]}
            </GridLayoutItem>
          );
        })}

        {Object.keys(tableDataCost).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={4}
              className="OverallTable"
              key={idx}
            >
              {tableDataCost[com]}
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default TotalTable;

const sum_data = (arr, field) => {
  const sum = _.sumBy(arr, field);

  return sum;
};
