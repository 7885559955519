import React, { useEffect, useMemo } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import styled from "styled-components";
import "./CostPlanPage.scss";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH363108325");

const CostPlanPage = ({
  height,
  piedata,
  sliceChartName,
  setSliceChartName,
  sliceChartIndex,
  setSliceChartIndex,
}) => {
  useEffect(() => {
    if (piedata.length > 0) {
      var container = am4core.create("qchart1", am4core.Container);
      container.width = am4core.percent(100);
      container.height = am4core.percent(100);
      container.layout = "horizontal";

      var chart = container.createChild(am4charts.PieChart);

      chart.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: "천" },
        { number: 1e6, suffix: "백만" },
        { number: 1e8, suffix: "억" },
      ];
      // Add data
      chart.data = piedata;

      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "cost";
      pieSeries.dataFields.category = "key";
      pieSeries.slices.template.states.getKey(
        "active"
      ).properties.shiftRadius = 0;
      // pieSeries.labels.template.text = "{category} {value}원\n{value.percent.formatNumber('#.#')}%";

      pieSeries.colors.list = [
        "#0072B5",
        "#00A170",
        "#D2386C",
        "#CD212A",
        "#FFA500",
        "#00758F",
        "#9C4722",
      ].map(function (color) {
        return new am4core.color(color);
      });

      pieSeries.slices.template.events.on("hit", function (event) {
        selectSlice(event.target.dataItem);

        const find_index = piedata.findIndex(
          (com) => com.key === event.target.dataItem.properties.category
        );
        setSliceChartName(event.target.dataItem.properties.category);
        setSliceChartIndex(find_index);
      });

      pieSeries.slices.template.tooltipText =
        "{category}: {value.formatNumber('#,###.0a')}원";

      var chart2 = container.createChild(am4charts.PieChart);
      chart2.width = am4core.percent(30);
      chart2.radius = am4core.percent(80);

      // Add and configure Series
      var pieSeries2 = chart2.series.push(new am4charts.PieSeries());

      pieSeries2.dataFields.value = "cost";
      pieSeries2.dataFields.category = "key";
      pieSeries2.slices.template.states.getKey(
        "active"
      ).properties.shiftRadius = 0;
      //pieSeries2.labels.template.radius = am4core.percent(50);
      //pieSeries2.labels.template.inside = true;
      //pieSeries2.labels.template.fill = am4core.color("#ffffff");
      // pieSeries2.labels.template.disabled = false;
      // pieSeries2.ticks.template.disabled = true;
      // pieSeries2.alignLabels = false;
      pieSeries2.events.on("positionchanged", updateLines);
      // pieSeries2.labels.template.text = "{value.percent.formatNumber('#.#')}%";

      pieSeries2.slices.template.tooltipText =
        "{category}: {value.formatNumber('#,###.0a')}원";
      pieSeries2.ticks.template.disabled = true;
      pieSeries2.alignLabels = false;
      pieSeries2.labels.template.text = "{value.percent.formatNumber('#.0')}%";
      pieSeries2.labels.template.radius = am4core.percent(-40);
      pieSeries2.labels.template.fill = am4core.color("white");

      pieSeries2.labels.template.adapter.add(
        "radius",
        function (radius, target) {
          if (target.dataItem.values.value.percent < 10) {
            return am4core.percent(0);
          }
          return radius;
        }
      );

      var interfaceColors = new am4core.InterfaceColorSet();

      var line1 = container.createChild(am4core.Line);
      line1.strokeDasharray = "2,2";
      line1.strokeOpacity = 0.5;
      line1.stroke = interfaceColors.getFor("alternativeBackground");
      line1.isMeasured = false;

      var line2 = container.createChild(am4core.Line);
      line2.strokeDasharray = "2,2";
      line2.strokeOpacity = 0.5;
      line2.stroke = interfaceColors.getFor("alternativeBackground");
      line2.isMeasured = false;

      var selectedSlice;

      function selectSlice(dataItem) {
        if (dataItem !== undefined) {
          selectedSlice = dataItem.slice;

          var fill = selectedSlice.fill;

          var count = dataItem.dataContext.sub_sub.length;

          pieSeries2.colors.list = [];

          for (var i = 0; i < count; i++) {
            pieSeries2.colors.list.push(fill.brighten((i * 2) / count));
          }

          chart2.data = dataItem.dataContext.sub_sub;
          pieSeries2.appear();

          var middleAngle = selectedSlice.middleAngle;
          var firstAngle = pieSeries.slices.getIndex(0).startAngle;
          var animation = pieSeries.animate(
            [
              { property: "startAngle", to: firstAngle - middleAngle },
              { property: "endAngle", to: firstAngle - middleAngle + 360 },
            ],
            600,
            am4core.ease.sinOut
          );

          animation.events.on("animationprogress", updateLines);

          selectedSlice.events.on("transformed", updateLines);

          //  var animation = chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
          //  animation.events.on("animationprogress", updateLines)
        }
      }

      function updateLines() {
        if (selectedSlice) {
          var p11 = {
            x:
              selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle),
            y:
              selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle),
          };
          var p12 = {
            x:
              selectedSlice.radius *
              am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc),
            y:
              selectedSlice.radius *
              am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc),
          };

          p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
          p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

          var p21 = { x: 0, y: -pieSeries2.pixelRadius };
          var p22 = { x: 0, y: pieSeries2.pixelRadius };

          p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
          p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

          line1.x1 = p11.x;
          line1.x2 = p21.x;
          line1.y1 = p11.y;
          line1.y2 = p21.y;

          line2.x1 = p12.x;
          line2.x2 = p22.x;
          line2.y1 = p12.y;
          line2.y2 = p22.y;
        }
      }

      chart.events.on("datavalidated", function () {
        setTimeout(function () {
          if (pieSeries.dataItems.getIndex(sliceChartIndex) !== "") {
            selectSlice(pieSeries.dataItems.getIndex(sliceChartIndex));
          }
        }, 100);
      });

      return () => {
        container && container.dispose();
      };
    }
  }, [piedata, sliceChartIndex]);

  useEffect(() => {
    if (piedata.length > 0) {
      const find_index = piedata[sliceChartIndex];

      setSliceChartName(find_index["key"]);
    }
  }, [sliceChartIndex, piedata]);

  const totalCostPlan = useMemo(() => {
    if (piedata.length > 0) {
      return handleNumberSlice(_.sumBy(piedata, "cost"));
    } else {
      return 0;
    }
  }, [piedata]);

  const targetCostPlan = useMemo(() => {
    if (piedata.length > 0) {
      const find_index = piedata.find((com) => com.key === sliceChartName);

      return handleNumberSlice(
        find_index?.cost === undefined ? 0 : find_index?.cost
      );
    } else {
      return 0;
    }
  }, [piedata, sliceChartName]);

  return (
    <CostPlanPageDiv className="costPlanPage">
      <TotalCostPlanBox>
        <TotalCostPlan>합계 금액: {totalCostPlan}</TotalCostPlan>
        <TotalCostPlan>
          {sliceChartName}: {targetCostPlan}
        </TotalCostPlan>
      </TotalCostPlanBox>

      <AmChartArea id="qchart1" height={height * 0.75} />
    </CostPlanPageDiv>
  );
};

export default CostPlanPage;

const handleNumberSlice = (num) => {
  if (num >= 100000000)
    return Number((num / 100000000).toFixed(1)).toLocaleString() + "억";

  if (num >= 1000000)
    return Number((num / 1000000).toFixed(1)).toLocaleString() + "백만";

  if (num >= 1000)
    return Number((num / 1000).toFixed(1)).toLocaleString() + "천";

  return Number(num.toFixed(1)).toLocaleString();
};

const CostPlanPageDiv = styled.div`
  position: relative;
`;

const TotalCostPlanBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

const TotalCostPlan = styled.div`
  font-size: 0.8rem;
`;

const AmChartArea = styled.div`
  width: 100%;
  ${(props) => {
    return { height: props.height };
  }};
  z-index: 999;
  margin: auto;
`;
