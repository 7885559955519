import React from "react";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";
import moment from "moment";

const OverallMonthIncreaseChart = ({ height, chartData, endDate }) => {
  const labelContent = (e) =>
    e.value === null || typeof e.value === "undefined"
      ? ""
      : moment(new Date(e.value)).format("YY-MM");

  const seriesLabels = {
    visible: true,
    padding: 0,
    font: "0.8rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
    content: labelContent,
  };

  const handleColor = (props) => {
    if (props.category === moment(new Date(endDate)).format("YYYY-MM")) {
      return "#F0696E";
    } else {
      return "#4F81BD";
    }
  };

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    return (
      <div>
        <div>{categoryText}</div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              <div>
                {point.series.field === "planWeight" ? "계획" : "실적"}:{" "}
                {Number(point.value.toFixed(0)).toLocaleString()}t
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <GridLayout
      style={{ height, alignItems: "" }}
      cols={[
        {
          width: "20%",
        },
        {
          width: "20%",
        },
        {
          width: "20%",
        },
        {
          width: "20%",
        },
        {
          width: "20%",
        },
      ]}
      rows={[
        {
          height: (height / 10) * 2,
        },
        {
          height: (height / 10) * 7,
        },
        {
          height: (height / 10) * 1,
        },
      ]}
      gap={{
        cols: 0,
        rows: 0,
      }}
    >
      {chartData.map((com, idx) => {
        return (
          <React.Fragment key={idx}>
            <GridLayoutItem col={idx + 1} row={1} style={{ padding: "0 5px" }}>
              <Card
                style={{
                  padding: "0",
                  height: (height / 10) * 2,
                  border: "none",
                }}
              >
                <CardBody
                  style={{
                    padding: "0",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <CardTitle style={{ font: "0.8rem Pretendard-B" }}>
                    {com.name}
                  </CardTitle>

                  <CardTitle
                    style={{
                      font: "0.8rem Pretendard-R",
                      color:
                        com.data3[1]["value"] - com.data3[0]["value"] <= 0
                          ? "rgb(0, 107, 84)"
                          : "rgb(158, 16, 48)",
                    }}
                  >
                    {Number(
                      (com.data3[1]["value"] - com.data3[0]["value"] <= 0
                        ? (com.data3[1]["value"] - com.data3[0]["value"]) * -1
                        : com.data3[1]["value"] - com.data3[0]["value"]
                      ).toFixed(0)
                    ).toLocaleString()}
                    t{" "}
                    {com.data3[1]["value"] - com.data3[0]["value"] <= 0
                      ? "감소"
                      : "증가"}
                  </CardTitle>
                </CardBody>
              </Card>
            </GridLayoutItem>
            <GridLayoutItem col={idx + 1} row={2}>
              <Chart
                style={{
                  height: (height / 10) * 7,
                }}
              >
                {/* <ChartTitle
                  text={com.name}
                  position="top"
                  align="center"
                  font="0.9rem Pretendard-B"
                /> */}
                <ChartArea background="none" />
                <ChartLegend position="bottom" orientation="horizontal" />
                <ChartTooltip shared={true} render={SharedTooltip} />
                <ChartCategoryAxis>
                  <ChartCategoryAxisItem
                    // categories={originalChartData.categories}
                    maxDivisions={10}
                    startAngle={45}
                    labels={seriesLabels}
                    majorGridLines={{ step: 100 }}
                    minorGridLines={{ step: 100 }}
                  />
                </ChartCategoryAxis>
                <ChartValueAxis>
                  <ChartValueAxisItem
                    visible={false}
                    labels={{ font: "0.7rem Arial, sans-serif" }}
                    majorTicks={{ step: 100 }}
                    minorTicks={{ step: 100 }}
                    majorGridLines={{ step: 10 }}
                    minorGridLines={{ step: 10 }}
                  />
                </ChartValueAxis>
                <ChartSeries>
                  <ChartSeriesItem
                    type={"column"}
                    data={com.data3}
                    autoFit={true}
                    categoryField="month"
                    color={handleColor}
                    labels={{
                      visible: true,
                      content: (e) => {
                        return e.value === 0 ||
                          e.value === null ||
                          typeof e.value === "undefined"
                          ? ""
                          : Number(e.value.toFixed(0)).toLocaleString();
                      },
                      background: "none",
                      color: "white",
                      position: "centor",
                    }}
                  />
                </ChartSeries>
              </Chart>
            </GridLayoutItem>
          </React.Fragment>
        );
      })}
      {chartData.map((com, idx) => {
        return (
          <GridLayoutItem
            col={idx + 1}
            row={3}
            key={idx}
            style={{ padding: "0 5px" }}
          >
            <Card
              style={{
                padding: "0",
                height: (height / 10) * 1,
                border: "none",
              }}
            >
              <CardBody
                style={{
                  padding: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <CardTitle style={{ font: "0.8rem Pretendard-R" }}>
                  {Number(
                    (com.data3[1]["value"] - com.data3[0]["value"]).toFixed(0)
                  ).toLocaleString()}
                  t
                </CardTitle> */}
                <CardTitle
                  style={{
                    font: "0.8rem Pretendard-B",
                    color:
                      com.data3[1]["cost"] - com.data3[0]["cost"] <= 0
                        ? "rgb(0, 107, 84)"
                        : "rgb(158, 16, 48)",
                  }}
                >
                  {Number(
                    (
                      (com.data3[1]["cost"] - com.data3[0]["cost"] <= 0
                        ? (com.data3[1]["cost"] - com.data3[0]["cost"]) * -1
                        : com.data3[1]["cost"] - com.data3[0]["cost"]) / 1000000
                    ).toFixed(1)
                  ).toLocaleString()}
                  백만원{" "}
                  {com.data3[1]["cost"] - com.data3[0]["cost"] <= 0
                    ? "감소"
                    : "증가"}
                </CardTitle>
              </CardBody>
            </Card>
          </GridLayoutItem>
        );
      })}
    </GridLayout>
  );
};

export default OverallMonthIncreaseChart;
