import React, { useEffect, useState, useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Spirited from "@amcharts/amcharts5/themes/Spirited";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const CHART_ID = 'pchart';

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#004098',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.6rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        // borderRadius: '8px 0px 0px 8px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important',
        width: 60

    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0)
        }
    }
}));


const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

const PeroidMultiple = ({ height }) => {
    const [selectedValue, setSelectedValue] = useState("1");
    const [value, setValue] = React.useState(dayjs(new Date()).add(-1,"year"));
    const [value2, setValue2] = React.useState(dayjs(new Date()));


    const handleChange = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
        }
    };
    useLayoutEffect(() => {
        let root = am5.Root.new(CHART_ID);

        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panY: false,
                panX: true,
                wheelY: "zoomX",
                layout: root.verticalLayout
            })
        );

        let tooltip = am5.Tooltip.new(root, {});

        chart.plotContainer.set("tooltipPosition", "pointer");
        chart.plotContainer.set("tooltipText", "a");
        chart.plotContainer.set("tooltip", tooltip);


        tooltip.label.adapters.add("text", function (text, target) {
            text = "";
            let i = 0;
            chart.series.each(function (series) {
                let tooltipDataItem = series.get("tooltipDataItem");


                if (tooltipDataItem) {
                    if (i === 0) {
                        text += "[bold]" + tooltipDataItem.dataContext.category + "\n";
                    }

                    if (i !== 0) {
                        text += "\n";
                    }

                    text +=
                        "[" +
                        series.get("stroke") +
                        "]●[/] [width:100px]" +
                        series.get("name") +
                        ":[/] " +
                        Number(
                            (tooltipDataItem.get("valueY") ?? 0).toFixed(2)
                        ).toLocaleString() +
                        " ";
                    // (unit ? unit : series.get("name").includes("시간") ? "h" : "%");
                }
                i++;
            });
            return text;
        });

        tooltip.get("background").setAll({
            stroke: am5.color(0x000000),
            strokeOpacity: 0.8,
            fill: am5.color(0xffffff),
            fillOpacity: 0.8,
        });
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        let cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                behavior: "zoomX",
            })
        );

        cursor.lineX.setAll({
            stroke: "#525252",
            strokeWidth: 1,
            strokeDasharray: [5, 5],
        });

        cursor.lineY.setAll({
            stroke: "#525252",
            strokeWidth: 1,
            strokeDasharray: [5, 5],
        });

        // cursor.lineY.set("visible", false);

        // let data = [...data];

        let legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
            })
        );

        legend.markers.template.setAll({
            width: 10,
            height: 10,
        });

        legend.labels.template.setAll({
            fontSize: "0.7rem",
            fontWeight: "400",
            width: 90,
            //   fill: "#fff",
        });

        const startDate =new Date(new Date(value).getFullYear(), 0, 1);
        const endDate = Number(dayjs(value).format("YYYY")) === new Date().getFullYear()? new Date(): new Date(new Date(value).getFullYear(), 11, 31);
        const startDate2 = new Date(new Date(value2).getFullYear(), 0, 1);
        const endDate2 = Number(dayjs(value2).format("YYYY")) === new Date().getFullYear()? new Date(): new Date(new Date(value2).getFullYear(), 11, 31);


        const chartdata = [...generateRandomChartData(startDate, endDate), ...generateRandomChartData2(startDate2, endDate2)]

        // Define data
        var data = chartdata.map((v) => ({ ...v, date: new Date(v.category).getTime() }))
        // Craete Y-axes
        var yAxis1 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                height: am5.percent(50),
                x: am5.percent(100),
                min: 0,
                centerX: am5.percent(100),
                renderer: am5xy.AxisRendererY.new(root, {
                    grid: {
                        disabled: true,
                    },
                })
            })
        );


        yAxis1.axisHeader.children.push(am5.Label.new(root, {
            text: "",
            fontWeight: "500"
        }));

        var yAxis2 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                height: am5.percent(50),
                min: 0,
                x: am5.percent(100),
                centerX: am5.percent(100),
                renderer: am5xy.AxisRendererY.new(root, {
                    grid: {
                        disabled: true,
                    },
                })
            })
        );

        yAxis2.axisHeader.set("paddingTop", 10);
        yAxis2.axisHeader.children.push(am5.Label.new(root, {
            text: "",
            fontWeight: "500"
        }));


        var yAxis3 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                height: am5.percent(50),
                x: am5.percent(100),
                centerX: am5.percent(100),
                renderer: am5xy.AxisRendererY.new(root, {
                    opposite: true,
                    // strokeOpacity: 1,
                    // strokeWidth: 1,
                    // stroke: "rgba(224, 224, 224, 0.5)",
                    // opposite:true
                })
            })
        );


        yAxis3.axisHeader.children.push(am5.Label.new(root, {
            text: "",
            fontWeight: "500"
        }));

        var yAxis4 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                height: am5.percent(50),
                x: am5.percent(100),
                centerX: am5.percent(100),
                renderer: am5xy.AxisRendererY.new(root, {
                    opposite: true,
                    // strokeOpacity: 1,
                    // strokeWidth: 1,
                    // stroke: "rgba(224, 224, 224, 0.5)",
                    // opposite:true
                })
            })
        );

        yAxis4.axisHeader.set("paddingTop", 10);
        yAxis4.axisHeader.children.push(am5.Label.new(root, {
            text: "",
            fontWeight: "500"
        }));




        // Stack Y-axes
        chart.leftAxesContainer.set("layout", root.verticalLayout);

        let xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 30,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            strokeOpacity: 0,
            // strokeWidth: 1,
            // stroke: "rgba(224, 224, 224, 0.5)",
        });

        // Create X-Axis
        var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                minGridDistance: 30,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9,
                strokeOpacity: 0,
                baseInterval: { timeUnit: selectedValue ==="1"? "month":"day", count: 1 },
                renderer: xRenderer
            })
        );


        xRenderer.grid.template.setAll({
            location: 1,
            strokeOpacity: 0,
        });


        xAxis.get("renderer").labels.template.setAll({
            oversizedBehavior: "fit",
            textAlign: "center",
            //   fill: "#fff",
            maxWidth: 45,
        });

        // Create series
        function createSeries(name, field, color, yAxis) {
            var series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    stroke: color,
                    fill: color,
                    valueYField: field,
                    valueXField: "date",
                    clustered: true
                })
            );
            series.data.setAll(data);
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        // text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true,
                    }),
                });
            });

            legend.data.push(series);
        };


        createSeries("취급액", "취급액", "#1F3864", yAxis1);
        createSeries("취급액2", "취급액2", "#1F3864", yAxis2);

        const makeParetoSeries = (name, field, color, yAxis) => {
            var series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    stroke: color,
                    fill: color,
                    valueYField: field,
                    valueXField: "date",
                })
            );


            series.data.setAll(data);
            series.appear();

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0,
                    sprite: am5.Label.new(root, {
                        // text: "{valueY}",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: 0,
                        centerX: am5.p50,
                        populateText: true,
                    }),
                });
            });

            legend.data.push(series);
        };


        makeParetoSeries("취급액 누적", "취급액 누적", "#E37912", yAxis3)
        makeParetoSeries("취급액 누적2", "취급액 누적2", "#E37912", yAxis4)


        return () => {
            root && root.dispose();
        };
    }, [selectedValue, value, value2]);


    return (
        <>

<div style={{ display: "flex", fontSize: '0.8rem', zIndex: 9999, justifyContent: "flex-end", position:"absolute", top:8 , right:10}}>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']} sx={{marginRight:1}}>
                        <DatePicker
                            label={selectedValue === "1" ? 'Year 1' : "Year Month 1"}
                            slotProps={{ textField: { size: 'small' } }}
                            view={['year']}
                            format={selectedValue === "1" ? 'YYYY' : "YYYY-MM"}
                            views={selectedValue === "1" ? ['year'] : ['year', 'month']}
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                        />

                    </DemoContainer>
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}sx={{marginRight:1}}>
                        <DatePicker
                            label={selectedValue === "1" ? 'Year 2' : "Year Month 2"}
                            slotProps={{ textField: { size: 'small' } }}
                            view={['year']}
                            format={selectedValue === "1" ? 'YYYY' : "YYYY-MM"}
                            views={selectedValue === "1" ? ['year'] : ['year', 'month']}
                            value={value2}
                            onChange={(newValue) => setValue2(newValue)}
                        />

                    </DemoContainer>
                </LocalizationProvider>
            </div>
            <ThemeProvider theme={theme}>
                    <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                        <ToggleButton value="1" aria-label='year' >
                            YEAR
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="month">
                            MONTH
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ThemeProvider>
            <div id={CHART_ID} style={{ width: "100%", height: (height / 5) * 2, zIndex: '999', margin: '0 auto', marginTop: 20 }}></div>
        </>
    );
};

export default PeroidMultiple

function generateRandomChartData(startDate, endDate) {
    const date = new Date(startDate);
    const chartData = [];
    let cumulativeIn = 0;
    let cumulativeOut = 0;

    while (date <= endDate) {
        const inComplete = Math.floor(Math.random() * 20); // Randomly generates between 0 and 20
        const outComplete = Math.floor(Math.random() * 15); // Randomly generates between 0 and 15
        cumulativeIn += inComplete;
        cumulativeOut += outComplete;

        chartData.push({
            category: date.toISOString().split('T')[0], // Formats the date to YYYY-MM-DD
            "취급액": inComplete,
            "공헌이익금": outComplete,
            "취급액 누적": cumulativeIn,
            "공헌이익금 누적": cumulativeOut
        });

        date.setDate(date.getDate() + 1); // Increment the day by one
    }

    return chartData;
}


function generateRandomChartData2(startDate, endDate) {
    const date = new Date(dayjs(startDate).add(-1,"year"));
    const edate = new Date(dayjs(endDate).add(-1,"year"));
    const chartData = [];
    let cumulativeIn = 0;
    let cumulativeOut = 0;

    while (date <= edate) {
        const inComplete = Math.floor(Math.random() * 20); // Randomly generates between 0 and 20
        const outComplete = Math.floor(Math.random() * 15); // Randomly generates between 0 and 15
        cumulativeIn += inComplete;
        cumulativeOut += outComplete;

        chartData.push({
            category: date.toISOString().split('T')[0], // Formats the date to YYYY-MM-DD
            "취급액2": inComplete,
            "공헌이익금2": outComplete,
            "취급액 누적2": cumulativeIn,
            "공헌이익금 누적2": cumulativeOut
        });

        date.setDate(date.getDate() + 1); // Increment the day by one
    }

    return chartData;
}
