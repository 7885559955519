import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import moment from "moment";
import _ from "lodash";
import MonthPerformanceChart from "../../../Components/MonthPerformanceChart";

const MonthPerformance = ({
  height,
  dateRange,
  dateRangeData,
  monthsRangeData,
}) => {
  const [chartData, setChartData] = useState([]);
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM"));
  const [MonthPerformanceToggle, setMonthPerformanceToggle] = useState(true);
  const [tableToggle, setTableToggle] = useState(false);
  const [totalTableData, setTotalTableData] = useState([]);
  const [sort, setSort] = useState([{}]);

  useEffect(() => {
    setEndDate(moment(dateRange.endDate).format("YYYY-MM"));

    const projectActData_2 = dateRangeData.map((com) => ({
      ...com,
      carryMonth: moment(new Date(com.uxesg_CarryDate.slice(0, 10))).format(
        "YYYY-MM"
      ),
    }));

    const monthsRangeData_2 = monthsRangeData
      .map((com) => ({
        ...com,
        data: projectActData_2.filter((com2) => com2.carryMonth === com.months),
      }))
      .map((com) => ({
        ...com,
        value: _.sumBy(com.data, "uxesg_CarryQty2"),
        cost: Number(
          (_.sumBy(com.data, "uxesg_CostTotal") / 1000000).toFixed(0)
        ),
      }));

    setChartData(monthsRangeData_2);

    setTotalTableData(dateRangeData);
  }, [dateRange, dateRangeData, monthsRangeData]);

  const handlePerformanceToggle = () => {
    setMonthPerformanceToggle((prev) => !prev);
  };

  const handleDetailDialog = (data) => {
    setTableToggle((prev) => !prev);

    if (tableToggle) {
      setSort([{}]);
    }
  };

  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];

    if (field === "uxesg_CarryQty2" || field === "uxesg_CostTotal") {
      return (
        <td style={{ fontSize: "0.8rem", textAlign: "left" }}>
          {field === "uxesg_CarryQty2"
            ? Number(cell.toFixed(0)).toLocaleString() + "t"
            : Number(cell.toFixed(0)).toLocaleString() + "원"}
        </td>
      );
    } else if (field === "uxesg_CarryDate") {
      return (
        <td style={{ fontSize: "0.8rem", textAlign: "left" }}>
          {moment(new Date(cell.slice(0, 10))).format("YY-MM-DD")}
        </td>
      );
    } else {
      return <td style={{ fontSize: "0.8rem", textAlign: "left" }}>{cell}</td>;
    }
  };

  const footerCell = (props) => {
    const field = props.field || "";

    if (totalTableData !== undefined) {
      return (
        <td style={{ fontSize: "0.8rem", textAlign: "left" }}>
          {field === "uxesg_CarryQty2"
            ? Number(
                _.sumBy(totalTableData, field).toFixed(0)
              ).toLocaleString() + "t"
            : Number(
                _.sumBy(totalTableData, field).toFixed(0)
              ).toLocaleString() + "원"}
        </td>
      );
    } else {
      return <td style={{ fontSize: "0.8rem", textAlign: "left" }}>0</td>;
    }
  };

  return (
    <div className="weightCostChange">
      <button
        className="weightCostChangeButton2 k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
        onClick={handleDetailDialog}
      >
        <span
          role="presentation"
          className="k-button-icon k-icon k-i-grid"
          style={{ color: "white" }}
        ></span>
      </button>

      <button
        className="weightCostChangeButton k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
        onClick={handlePerformanceToggle}
      >
        {MonthPerformanceToggle ? (
          <span
            role="presentation"
            className="k-button-icon k-icon k-i-redo"
            style={{ color: "white" }}
          />
        ) : (
          <span
            role="presentation"
            className="k-button-icon k-icon k-i-undo"
            style={{ color: "white" }}
          />
        )}
      </button>

      <MonthPerformanceChart
        height={height - 45 - 32}
        chartData={chartData}
        endDate={endDate}
        MonthPerformanceToggle={MonthPerformanceToggle}
      />

      {tableToggle && (
        <Dialog
          title={"폐기물 상세 배출 내역"}
          onClose={handleDetailDialog}
          height={"600px"}
          width={"80%"}
        >
          <Grid
            data={orderBy(totalTableData, sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
          >
            <GridColumn
              field="uxesg_SectorName"
              title="본부명"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_PJTName"
              title="현장명"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_CarryDate"
              title="배출일자"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_WasteCode"
              title="성상"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_WastePropertie"
              title="폐기물"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_TranVendor"
              title="운반업체"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_DispVendor"
              title="처리업체"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_CarryQty2"
              title="처리량"
              cell={normalcell}
              // headerCell={HeaderCell}
              footerCell={footerCell}
            />
            <GridColumn
              field="uxesg_CostTotal"
              title="처리비용"
              cell={normalcell}
              // headerCell={HeaderCell}
              footerCell={footerCell}
            />
          </Grid>
        </Dialog>
      )}
    </div>
  );
};

export default MonthPerformance;
