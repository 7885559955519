import React, { useState, useEffect, useMemo } from 'react';
import { useBreakpoint } from 'use-breakpoint';
import useWindowDimensions from '../common/useWindowDimentions'
import { TileLayout } from "@progress/kendo-react-layout";
import * as am5 from "@amcharts/amcharts5";
import ProfitTop from './profit';
import Target from './target';
import Period from './period';
import Time from './time';
import TimeHeatmap from './timeheatmap';
import PeroidMultiple from './periodmultiple';
import PRDChart from './product';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

am5.addLicense("AM5C363108325");
am5.addLicense("AM5M363108325");

const SSGTile = () => {

    const [fixblock, setfixblock] = useState(false);
    const { height, width } = useWindowDimensions();
    const [grid, setgrid] = useState({
        mobile:
            [

                {
                    col: 1,
                    colSpan: 16,
                    rowSpan: 6,
                    status: true,
                },
                {
                    col: 1,
                    colSpan: 16,
                    status: true,
                    rowSpan: 2,
                },
                {
                    col: 1,
                    colSpan: 16,
                    status: true,
                    rowSpan: 2,
                },
                {
                    col: 1,
                    colSpan: 16,
                    status: true,
                    rowSpan: 2,
                },

                {
                    col: 1,
                    colSpan: 16,
                    rowSpan: 2,
                    status: true,
                },

           ]
        ,
        tablet:
            [

                {
                    col: 1,
                    colSpan: 6,
                    rowSpan: 2,
                    status: true,
                },
                {
                    col: 7,
                    colSpan: 6,
                    rowSpan: 2,
                    status: true,
                },
                {
                    col: 13,
                    colSpan: 6,
                    rowSpan: 2,
                    status: true,
                },
                {
                    col: 1,
                    colSpan: 9,
                    rowSpan: 2,
                    status: true,
                },
                {
                    col: 10,
                    colSpan: 9,
                    rowSpan: 2,
                    status: true,
                },
             
            ]
        ,
        desktop:
            [

                {
                    col: 1,
                    colSpan: 6,
                    rowSpan: 2,
                    status: true,
                },
                {
                    col: 7,
                    colSpan: 6,
                    rowSpan: 2,
                    status: true,
                },
                {
                    col: 13,
                    colSpan: 6,
                    rowSpan: 2,
                    status: true,
                },
                {
                    col: 1,
                    colSpan: 9,
                    rowSpan: 3,
                    status: true,
                },
                {
                    col: 10,
                    colSpan: 9,
                    rowSpan: 3,
                    status: true,
                },
           
            ]
    });
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')

    const togglefix = () => {
        setfixblock(!fixblock);
    };

    const [positiondata, setPositionData] = useState(grid['desktop']);

    useEffect(() => {
        setPositionData(grid[breakpoint])
    }, [breakpoint]);

    const tiles = [
        {
            defaultPosition: {
                col: 1,
                colSpan: 6,
                rowSpan: 2,
            },
            header: "목표달성 현황",
            body:<Target height={height}/>,
            resizable: fixblock,
            reorderable: fixblock,

        },
        {
            defaultPosition: {
                col: 7,
                colSpan: 6,
                rowSpan: 2,
            },
            header: "공헌이익율 TOP List",
            body: <ProfitTop height={height}/>,
            resizable: fixblock,
            reorderable: fixblock,

        },
        {
            defaultPosition: {
                col: 13,
                colSpan: 6,
                rowSpan: 2,
            },
            header: "시간별 현황",
            body: <TimeHeatmap height={height}/>,
            // body: <Period height={height}/>,
            resizable: fixblock,
            reorderable: fixblock,
            className: 'milestone'
        },
        {
            defaultPosition: {
                col: 1,
                colSpan: 9,
                rowSpan: 3,
            },
            header: "제품 대비 현황",
            body: <PRDChart height={height}/>,
            resizable: fixblock,
            reorderable: fixblock,
            className: 'milestone'
        },
        {
            defaultPosition: {
                col: 10,
                colSpan: 9,
                rowSpan: 3
            },
            header: "기간 대비 현황",
            // body: <Time height={height}/>,
            body: <PeroidMultiple height={height}/>,
            resizable: fixblock,
            reorderable: fixblock,
            className: 'milestone'
        },


    ];
    const handleReposition = (e) => {
        setPositionData(e.value);
    };
    return (

        <TileLayout
            className='ssg'
            columns={18}
            rowHeight={height / 5.5}
            positions={positiondata}
            gap={{
                rows: 10,
                columns: 10
            }}
            items={tiles}
            autoFlow="row dense"
            onReposition={handleReposition}
        // style={{marginTop:20}}
        />

    );

};

export default SSGTile;

