export const bplist = {
  //자산별 예방정비 설정
  PM_Books: {
    bpname: "PM Books",
    lineitem: "yes",
  },
  //돌발정비 작업지시
  CorrectiveWorkOrders: {
    bpname: "Corrective Work Orders",
    lineitem: "yes",
  },
  //예방정비 작업지시
  PreventiveWorkOrders: {
    bpname: "Preventive Work Orders",
    lineitem: "yes",
  },
  //Job Plans
  JobPlans: {
    bpname: "Job Plans",
    lineitem: "yes",
  },
  //Purchase Orders-FM
  PurchaseOrdersFM: {
    bpname: "Purchase Orders-FM",
    lineitem: "yes",
    filter_condition: "status=Approved",
  },
  //Materials Received
  MaterialsReceived: {
    bpname: "Materials Received",
    lineitem: "yes",
  },
  //Material Inventory Manager
  MaterialInventoryManager: {
    bpname: "Material Inventory Manager",
    lineitem: "yes",
  },
};
