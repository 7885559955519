import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TileLayout } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import _ from "lodash";
import styled from "styled-components";
import usewindowDimensions from "../Hooks/useWindowDimensions";
import ProjectLocation from "./ProjectLocation/ProjectLocation";
import CostPlanPage from "./CostPlanPage/CostPlanPage";
import CostActPage from "./CostActPage/CostActPage";
import DocumentPage from "./DocumentPage/DocumentPage";
import "@progress/kendo-theme-default";

const { REACT_APP_API_SHINSEGAE_URI } = process.env;

const ProjectPage = () => {
  const { project_code } = useParams();
  const { height } = usewindowDimensions();
  const [projectInfo, setProjectInfo] = useState({});
  const [piedata, setpiedata] = useState([]);
  const [projectCostToggle, setProjectCostToggle] = useState(false);
  const [sliceChartIndex, setSliceChartIndex] = useState(0);
  const [sliceChartName, setSliceChartName] = useState(" ");
  const [documentList, setDocumentList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const requestProjectData = await axios
        .get(`${REACT_APP_API_SHINSEGAE_URI}/shinsegae/getproject`)
        .then((com) => com.data.data);

      const targetProject = requestProjectData.find(
        (com) => com.ugenProjectNumber === project_code
      );

      setProjectInfo(targetProject);

      const documentChangeCost = await Promise.all(
        [drawingChangeCostBody, designChangeCostBody].map(async (com) => {
          const requsetData = await axios
            .post(
              `${REACT_APP_API_SHINSEGAE_URI}/shinsegae/getbprecord?path=${project_code}`,
              com
            )
            .then((com2) => com2.data.data);

          return requsetData.map((com2) => {
            return {
              ...com2,
              type: com.bpname.includes("Drawing") ? "Drawing" : "Design",
            };
          });
        })
      ).then((com) => com.flat());

      const requesPaymentApplication2 = await axios
        .post(
          `${REACT_APP_API_SHINSEGAE_URI}/shinsegae/getbprecord?path=${project_code}`,
          paymentApplication2Body
        )
        .then((com) => com.data.data);

      const requestCostPlan = await axios
        .post(
          `${REACT_APP_API_SHINSEGAE_URI}/shinsegae/getbprecord?path=${project_code}`,
          requestCostPlanBody
        )
        .then((com) => com.data.data);

      const groupData = _(requestCostPlan)
        .groupBy("ssg_dept_pd")
        .map((objs, key) => ({
          key: key,
          cost: _.sumBy(objs, "amount"),
          sub_sub: objs.map((com2) => {
            return {
              key: com2.title,
              cost: com2.amount,
            };
          }),
          payment_application: requesPaymentApplication2.filter(
            (com) => com.ssgdeptname === key
          ),
          document_change_cost_total: documentChangeCost.filter(
            (com) => com.ssg_dept_pd === key
          ),
          document_change_cost_approved: documentChangeCost.filter(
            (com) => com.ssg_dept_pd === key && com.status === "Approved"
          ),
        }))

        .value();

      const groupDataSorting = _.sortBy(
        groupData
          .map((com) => ({
            ...com,
            payment_application_cost: _.sumBy(
              com.payment_application,
              "upaCurrPaymentDueCA"
            ),
            document_change_cost_approved_cost: _.sumBy(
              com.document_change_cost_approved,
              "EstimatedBudget"
            ),
          }))
          .map((com) => ({
            ...com,
            act_cost:
              com.payment_application_cost +
              com.document_change_cost_approved_cost,
          })),
        "act_cost"
      ).reverse();

      setpiedata(groupDataSorting);

      const find_name = groupDataSorting[0];

      setSliceChartName(find_name?.["key"]);

      const requestDrawingData = await Promise.all(
        [
          drawingsBody,
          constructionDrawingsBody,
          drawingsReviewBody,
          constructionDrawingsReviewBody,
        ].map(async (com, idx) => {
          const requestData = await axios.post(
            `${REACT_APP_API_SHINSEGAE_URI}/shinsegae/getbprecord?path=${project_code}`,
            com
          );

          return requestData.data.data.map((com2) => {
            if (idx <= 1) {
              return {
                ...com2,
                type: idx === 0 ? "Drawing" : "Design",
              };
            } else {
              return { ...com2 };
            }
          });
        })
      );

      const [
        resultDrawings,
        resultConDrawings,
        resultDrawingsReview,
        resultDrawingsConReview,
      ] = requestDrawingData;

      const resultDrawingsConcat = resultDrawings
        .map((com) => ({
          ...com,
          review_list: resultDrawingsReview.filter(
            (com2) => com2.udrawDrawingBP === com.udrDrawingNoTB120
          ),
        }))
        .map((com) => ({
          ...com,
          status_type:
            com.review_list.filter((com2) => com2.status !== "Approved")
              .length > 0
              ? "진행중"
              : "승인됨",
          on_reivew_count: com.review_list.filter(
            (com2) => com2.status !== "Approved"
          ).length,
        }));

      const resultConDrawingsConcat = resultConDrawings
        .map((com) => ({
          ...com,
          sss_cnt_Count_of_Revisions: com.sss_cnt_revision_count,
          review_list: resultDrawingsConReview.filter(
            (com2) => com2.udrawDrawingBP === com.udrDrawingNoTB120
          ),
        }))
        .map((com) => ({
          ...com,
          status_type:
            com.review_list.filter((com2) => com2.status !== "Approved")
              .length > 0
              ? "진행중"
              : "승인됨",
          on_reivew_count: com.review_list.filter(
            (com2) => com2.status !== "Approved"
          ).length,
        }));

      setDocumentList([...resultDrawingsConcat, ...resultConDrawingsConcat]);
    };
    fetchData();
  }, [project_code]);

  const tileData = useMemo(() => {
    return [
      {
        header: <div>프로젝트 위치/날씨</div>,
        body: (
          <ProjectLocation height={height - 210} projectInfo={projectInfo} />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
      {
        header: <div>부서별 예산 현황</div>,
        body: (
          <CostPlanPage
            height={(height - 210) * 0.5}
            piedata={piedata}
            sliceChartName={sliceChartName}
            setSliceChartName={setSliceChartName}
            sliceChartIndex={sliceChartIndex}
            setSliceChartIndex={setSliceChartIndex}
          />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
      {
        header: (
          <SpaceBetweenDiv>
            부서별 집행 현황 ({sliceChartName}){" "}
            <Button
              style={{
                width: "20px",
                height: "20px",
                background: "none",
                border: "none",
              }}
              onClick={() => setProjectCostToggle((prev) => !prev)}
            >
              <span
                role="presentation"
                className="k-button-icon k-icon k-i-grid"
              ></span>
            </Button>
          </SpaceBetweenDiv>
        ),
        body: (
          <CostActPage
            height={(height - 210) * 0.5}
            projectCostPlan={piedata}
            sliceChartName={sliceChartName}
            setSliceChartName={setSliceChartName}
            sliceChartIndex={sliceChartIndex}
            setSliceChartIndex={setSliceChartIndex}
            projectCostToggle={projectCostToggle}
            setProjectCostToggle={setProjectCostToggle}
          />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
      {
        header: <div>설계 현황</div>,
        body: (
          <DocumentPage
            height={(height - 210) * 0.5}
            documentList={documentList}
          />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
    ];
  }, [
    height,
    projectInfo,
    sliceChartName,
    piedata,
    sliceChartIndex,
    projectCostToggle,
    documentList,
  ]);

  return (
    <ProjectPageDiv>
      <TileLayout
        columns={13}
        rowHeight={(height - 210) / 10}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tileData}
        autoFlow="row dense"
        className="allpage"
        style={{
          height: height - 10,
          width: "calc(100% - 210)",
        }}
      />
    </ProjectPageDiv>
  );
};

export default ProjectPage;

const ProjectPageDiv = styled.div`
  .k-tilelayout-item-body.k-card-body {
    overflow: auto;
  }
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const positiondata = [
  {
    col: 1,
    colSpan: 3,
    rowSpan: 10,
    status: true,
  },
  {
    col: 4,
    colSpan: 5,
    rowSpan: 5,
    status: true,
  },
  {
    col: 9,
    colSpan: 5,
    rowSpan: 5,
    status: true,
  },
  {
    col: 4,
    colSpan: 10,
    rowSpan: 5,
    status: true,
  },
];

const requestCostPlanBody = {
  bpname: "Initial Budget NW",
};

const drawingChangeCostBody = {
  bpname: "Drawing Change Cost",
  lineitem: "no",
  // filter_condition: "status=Approved",
  record_fields:
    "project_projectname;title;uuu_creation_date;ssg_dept_pd;status;EstimatedBudget;uuu_bp_record_url",
};

const designChangeCostBody = {
  bpname: "Design Change Cost",
  lineitem: "no",
  // filter_condition: "status=Approved",
  record_fields:
    "project_projectname;title;uuu_creation_date;ssg_dept_pd;status;EstimatedBudget;uuu_bp_record_url",
};

const paymentApplication2Body = {
  bpname: "Payment Applications2",
  lineitem: "no",
  filter_condition: "status=Approved",
  record_fields:
    "title;ssgdeptname;ssg_dept_pd;upaInvoiceDateDOP;uconOriginalContrctAmtCA;uconRevisedContctAmtCA;upaCurrPaymentDueCA;status;uuu_bp_record_url;",
};

const drawingsBody = {
  bpname: "Drawings",
  lineitem: "no",
  // filter_condition: "status=Approved",
};

const constructionDrawingsBody = {
  bpname: "Construction Drawings",
  lineitem: "no",
  // filter_condition: "status=Approved",
};

const drawingsReviewBody = {
  bpname: "Drawing Revisions",
  lineitem: "no",
  // filter_condition: "status=Approved",
};

const constructionDrawingsReviewBody = {
  bpname: "Construction Drawing Revisions",
  lineitem: "no",
  // filter_condition: "status=Approved",
};
