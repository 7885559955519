import React, { useEffect, useState } from "react";
import {
  Chart,
  ChartTitle,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import "hammerjs";
import moment from "moment";
import _ from "lodash";

const MonthPerformanceChart = ({
  height,
  chartData,
  endDate,
  MonthPerformanceToggle,
}) => {
  const [costData, setCostData] = useState({
    last: 0,
    lastCost: 0,
    twoLast: 0,
    twoLastCost: 0,
  });
  const [detailTableData, setDetailTableData] = useState({});
  const [detailDataToggle, setDetailDataToogle] = useState(false);
  const [sort, setSort] = useState([{}]);

  const handleDetailDialog = (data) => {
    setDetailDataToogle((prev) => !prev);

    if (detailDataToggle) {
      setDetailTableData(null);
      setSort([{}]);
    } else {
      setDetailTableData(data);
    }
  };

  useEffect(() => {
    const findIndex = chartData.findIndex((com) => com.months === endDate);
    const findIndex2 = chartData.findIndex(
      (com) =>
        com.months ===
        moment(new Date(endDate)).subtract(1, "months").format("YYYY-MM")
    );

    setCostData((prev) => ({
      ...prev,
      last:
        chartData[findIndex]?.["value"] === undefined
          ? 0
          : chartData[findIndex]["value"],
      lastCost:
        chartData[findIndex]?.["cost"] === undefined
          ? 0
          : chartData[findIndex]["cost"],
      twoLast:
        chartData[findIndex2]?.["value"] === undefined
          ? 0
          : chartData[findIndex2]["value"],
      twoLastCost:
        chartData[findIndex2]?.["cost"] === undefined
          ? 0
          : chartData[findIndex2]["cost"],
    }));
  }, [chartData, endDate]);

  const handleColor = (props) => {
    if (props.category === moment(new Date(endDate)).format("YYYY-MM")) {
      return "#F0696E";
    } else {
      return "#4F81BD";
    }
  };

  const chartLabelContents = (e) => {
    if (MonthPerformanceToggle) {
      return e.value === 0 || e.value === null || typeof e.value === "undefined"
        ? ""
        : Number(e.value.toFixed(0)).toLocaleString() + "t";
    } else {
      return e.value === 0 || e.value === null || typeof e.value === "undefined"
        ? ""
        : Number(e.value.toFixed(0)).toLocaleString() + "백만원";
    }
  };

  const chartValueAxisContents = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : Number(e.value.toFixed(0)).toLocaleString();
  };

  const labelContent = (e) =>
    e.value === null || typeof e.value === "undefined"
      ? ""
      : moment(new Date(e.value)).format("YY-MM");

  const seriesLabels = {
    visible: true,
    padding: 0,
    font: "0.8rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
    content: labelContent,
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    return (
      <div>
        <div>{categoryText}</div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              <div>
                처리량: {Number(point.value.toFixed(0)).toLocaleString()}t
              </div>
              <div>
                처리비용:{" "}
                {
                  chartData.find((com) => com.months === point.category)?.[
                    "cost"
                  ]
                }
                백만원
              </div>
              <div
                className="chartTooltipText"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  handleDetailDialog(
                    chartData.find((com) => com.months === point.category)
                  );
                }}
              >
                자세히 보기
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];

    if (field === "uxesg_CarryQty2" || field === "uxesg_CostTotal") {
      return (
        <td style={{ fontSize: "0.8rem", textAlign: "left" }}>
          {field === "uxesg_CarryQty2"
            ? Number(cell.toFixed(0)).toLocaleString() + "t"
            : Number(cell.toFixed(0)).toLocaleString() + "원"}
        </td>
      );
    } else if (field === "uxesg_CarryDate") {
      return (
        <td style={{ fontSize: "0.8rem", textAlign: "left" }}>
          {moment(new Date(cell.slice(0, 10))).format("YY-MM-DD")}
        </td>
      );
    } else {
      return <td style={{ fontSize: "0.8rem", textAlign: "left" }}>{cell}</td>;
    }
  };

  const footerCell = (props) => {
    const field = props.field || "";

    if (detailTableData["data"] !== undefined) {
      return (
        <td style={{ fontSize: "0.8rem", textAlign: "left" }}>
          {field === "uxesg_CarryQty2"
            ? Number(
                _.sumBy(detailTableData["data"], field).toFixed(0)
              ).toLocaleString() + "t"
            : Number(
                _.sumBy(detailTableData["data"], field).toFixed(0)
              ).toLocaleString() + "원"}
        </td>
      );
    } else {
      return <td style={{ fontSize: "0.8rem", textAlign: "left" }}>0</td>;
    }
  };

  return (
    <>
      <Chart
        style={{
          height: height,
        }}
      >
        <ChartTitle
          text={`전월대비 처리량 ${Number(
            (costData.last - costData.twoLast).toFixed(0)
          ).toLocaleString()}t / 처리비용 ${Number(
            (costData.lastCost - costData.twoLastCost).toFixed(0)
          ).toLocaleString()}백만원`}
          position="top"
          align="center"
          font="0.9rem Pretendard-B"
        />
        <ChartArea background="none" />
        <ChartLegend position="bottom" orientation="horizontal" />
        <ChartTooltip shared={true} render={sharedTooltipRender} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            maxDivisions={10}
            startAngle={45}
            labels={seriesLabels}
            majorGridLines={{ step: 100 }}
            minorGridLines={{ step: 100 }}
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem
            labels={{
              font: "0.8rem pretendard-R",
              content: chartValueAxisContents,
            }}
            majorTicks={{ step: 100 }}
            minorTicks={{ step: 100 }}
            majorGridLines={{ step: 10 }}
            minorGridLines={{ step: 10 }}
          />
        </ChartValueAxis>
        <ChartSeries>
          <ChartSeriesItem
            type={"column"}
            data={chartData}
            field={MonthPerformanceToggle ? "value" : "cost"}
            autoFit={true}
            categoryField="months"
            color={handleColor}
            labels={{
              visible: true,
              content: chartLabelContents,
              background: "none",
              color: "black",
            }}
          />
        </ChartSeries>
      </Chart>
      {detailDataToggle && (
        <Dialog
          title={detailTableData["months"] + " 폐기물 상세 배출 내역"}
          onClose={handleDetailDialog}
          height={"600px"}
          width={"90%"}
        >
          <Grid
            data={orderBy(detailTableData["data"], sort)}
            sortable={true}
            sort={sort}
            onSortChange={(e) => {
              setSort(e.sort);
            }}
          >
            <GridColumn
              field="uxesg_SectorName"
              title="본부명"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_PJTPK"
              title="현장명"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_CarryDate"
              title="배출일자"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_WasteCode"
              title="성상"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_WastePropertie"
              title="폐기물"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_TranVendor"
              title="운반업체"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_DispVendor"
              title="처리업체"
              cell={normalcell}
              // headerCell={HeaderCell}
            />
            <GridColumn
              field="uxesg_CarryQty2"
              title="처리량"
              cell={normalcell}
              // headerCell={HeaderCell}
              footerCell={footerCell}
            />
            <GridColumn
              field="uxesg_CostTotal"
              title="처리비용"
              cell={normalcell}
              // headerCell={HeaderCell}
              footerCell={footerCell}
            />
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default MonthPerformanceChart;
