import React from "react";

import { useSchedulerEditItemPropsContext } from "@progress/kendo-react-scheduler";

import { Dialog } from "@progress/kendo-react-dialogs";

const CustomDialog = (props) => {
  const editItemProps = useSchedulerEditItemPropsContext();

  const Title = (
    <React.Fragment>
      {editItemProps[0] === null
        ? "승인요청"
        : editItemProps.dataItem.ApprovedIDS === 1
        ? "승인대기"
        : "승인완료"}
    </React.Fragment>
  );

  return <Dialog {...props} title={Title}></Dialog>;
};

export default CustomDialog;
