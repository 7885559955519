import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import Maps from "./Maps";
import Weather from "./Weather";

const ProjectLocation = ({ height, projectInfo }) => {
  return (
    <div className="projectLocation">
      <GridLayout
        gap={{
          rows: 10,
          cols: 10,
        }}
        rows={[
          {
            height: (height - 20) * 0.5,
          },
          {
            height: (height - 20) * 0.5,
          },
        ]}
        cols={[
          {
            width: "100%",
          },
        ]}
      >
        <GridLayoutItem row={1} col={1}>
          {projectInfo.uuu_latitude && projectInfo.uuu_longitude && (
            <Maps projectInfo={projectInfo} height={(height - 20) * 0.5} />
          )}
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1}>
          {projectInfo.uuu_latitude && projectInfo.uuu_longitude && (
            <Weather projectInfo={projectInfo} height={(height - 20) * 0.5} />
          )}
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

export default ProjectLocation;
