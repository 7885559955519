import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-react-data-tools";
import _ from "lodash";
import styled from "styled-components";
import { HyperLinkCell, TotalText } from "../../PublicCell/PubilcCell";

const DocumentPage = ({ height, documentList }) => {
  const [sort, setSort] = useState([{}]);

  return (
    <div>
      <DocumentPageBox height={height - 32}>
        {/* <DetailComponentTitle>도면 등록 현황</DetailComponentTitle> */}
        <Grid
          data={orderBy(documentList, sort)}
          sortable={(true, { mode: "multiple" })}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
        >
          <GridColumn
            field="type"
            title="구분"
            width={100}
            footerCell={TotalText}
          />
          <GridColumn field="ssgdeptname" title="관련부서" width={100} />
          <GridColumn
            field="udrDrawingTitleTB120"
            title="도면 제목"
            cell={HyperLinkCell}
          />
          <GridColumn
            field="udrDrawingNoTB120"
            title="도면 번호"
            cell={HyperLinkCell}
          />
          <GridColumn field="udrDrawingPhaseDP" title="도면 단계" width={100} />
          <GridColumn field="status_type" title="진행사항" width={80} />
          <GridColumn
            field="sss_cnt_Count_of_Revisions"
            title="Revision"
            width={80}
          />
          <GridColumn
            field="on_reivew_count"
            title="On Review"
            width={100}
            cell={OnReviewCell}
            footerCell={(e) => OnReviewTotalCell(e, documentList)}
          />
        </Grid>
      </DocumentPageBox>
    </div>
  );
};

export default DocumentPage;

const OnReviewCell = (props) => {
  const { field, dataItem } = props;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {Number(dataItem[field]).toLocaleString()}건
    </td>
  );
};

const OnReviewTotalCell = (props, dataItem) => {
  const total_sum = _.sumBy(dataItem, props.field);

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {Number(total_sum).toLocaleString()}건
    </td>
  );
};

const DocumentPageBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;