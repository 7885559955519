import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Grid,
  GridColumn,
  GridColumnMenuFilter,
  GridColumnMenuCheckboxFilter,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { orderBy, process } from "@progress/kendo-data-query";
import "hammerjs";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";

const Table = () => {
    
  const _export = React.useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const { project_code } = useParams();
  const [data, setdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        `https://api.dtsolution.kr/api/getlatest?path=/home/centos/download/halla/budget/${project_code}/`
      ); 

      

      setdata(
        res.data.reduce(
          (c, v) =>
            c.concat(
              v._bp_lineitems.map((o) =>
                Object.assign(o, {
                  PhaseDP: v.PhaseDP,
                  DiscNameDP: v.DiscNameDP,
                  FacilityDP: v.FacilityDP,
                  UnitTypeBPK: v.UnitTypeBPK,
                })
              )
            ),
          []
        )
      );
    };
    fetchData();
  }, [project_code]);

  console.log(data)

  const tabledata = data.map((v) => ({
    공종: v.DiscNameDP,
    목적물: v.FacilityDP,
    세대: v.UnitTypeBPK,
    품명: v.short_desc,
    규격: v.ugenSpecifcatnsMTL4000,
    구분: v.Bid_Type_txt,
    수량: v.uuu_quantity,
    단위: v.UoMDPKtxt,
    단가: v.MaterialCost + v.LaborCost + v.Expense,
    금액: v.amount,
  }));

  const initialDataState = {
    group: [],
  };

  const aggregates = [
    {
      field: "금액",
      aggregate: "sum",
    },
  ];

  const processWithGroups = (data, dataState) => {
    const groups = dataState.group;

    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }

    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group,
    });
    return newDataState;
  };

  const [dataState, setDataState] = React.useState(initialDataState);
  const [collapsedState, setCollapsedState] = React.useState([]);

  const dataStateChange = (event) => {
    const newDataState = processWithGroups(tabledata, event.dataState);
    setDataState(event.dataState);
  };

  const expandChange = (event) => {
    const item = event.dataItem;

    if (item.groupId) {
      const newCollapsedIds = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter((groupId) => groupId !== item.groupId);
      setCollapsedState(newCollapsedIds);
    }
  };

  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "groupFooter") {
      if (cellProps.field === "금액") {
        return (
          <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {cellProps.dataItem.aggregates.금액.sum}
          </td>
        );
      }
    }

    return tdElement;
  };

  const newData =
    tabledata &&
    setExpandedState({
      data: processWithGroups(tabledata, dataState).data,
      collapsedIds: collapsedState,
    });

    console.log(newData)

  const ColumnMenu = (props) => {
    return (
      <div>
        <GridColumnMenuFilter {...props} expanded={true} />
      </div>
    );
  };

  const ColumnMenuCheckboxFilter = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter
          {...props}
          data={newData}
          expanded={true}
          searchBoxFilterOperator={"contains"}
        />
      </div>
    );
  };

  const UniqCountCell = (props) => {
    const field = props.field || "";
    const count = _.uniqBy(newData, field).length;
    return (
      <td colSpan={props.colSpan} style={props.style}>
        개수: {count}
      </td>
    );
  };

  const SimpleSumCell = (props) => {
    const field = props.field || "";
    const total = _.sumBy(newData, field);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        단순합계:{" "}
        {typeof total !== "undefined"
          ? Number(total.toFixed(0)).toLocaleString()
          : ""}
      </td>
    );
  };

  const clearF = () => {
    setDataState(initialDataState);
  };
  
  return (
    <>
      <ExcelExport data={newData} ref={_export}>
        <Grid
          data={newData}
          groupable={{
            footer: "always",
          }}
          sortable={true}
          {...dataState}
          onDataStateChange={dataStateChange}
          onExpandChange={expandChange}
          expandField="expanded"
          cellRender={cellRender}
          resizable={true}
          reorderable={true}
          style={{ height: "700px" }}
          className="piping"
        >
          <GridToolbar>
            <div>
              {typeof dataState.filter === "undefined"
                ? ""
                : dataState.filter &&
                  dataState.filter.filters.map((v, index) => (
                    <div key={index}>
                      {v.filters.map((f, index) => (
                        <React.Fragment key={index}>
                          <span>&#40;{f.field}&nbsp; </span>
                          <span>{f.operator}&nbsp; </span>
                          <span>{f.value}&#41;&nbsp; </span>
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
            </div>
            <Button
              fillMode="outline"
              themeColor={"primary"}
              title="필터해제"
              onClick={clearF}
              style={{
                position: "absolute",
                right: "145px",
                fontSize: "11px",
                width: "120ox",
              }}
            >
              전체 필터 해제
            </Button>
            <Button
              fillMode="outline"
              themeColor={"primary"}
              title="Export Excel"
              onClick={excelExport}
              style={{
                position: "absolute",
                right: "8px",
                fontSize: "11px",
                width: "120ox",
              }}
            >
              Export to Excel
            </Button>
          </GridToolbar>

          <GridColumn
            field="구분"
            title="구분"
            columnMenu={ColumnMenuCheckboxFilter}
            footerCell={UniqCountCell}
          />
          <GridColumn
            field="공종"
            title="공종"
            columnMenu={ColumnMenuCheckboxFilter}
            footerCell={UniqCountCell}
          />
          <GridColumn
            field="목적물"
            title="목적물"
            columnMenu={ColumnMenuCheckboxFilter}
            footerCell={UniqCountCell}
          />
          <GridColumn
            field="세대"
            title="세대"
            columnMenu={ColumnMenuCheckboxFilter}
            footerCell={UniqCountCell}
          />
          <GridColumn
            field="품명"
            title="품명"
            columnMenu={ColumnMenuCheckboxFilter}
            footerCell={UniqCountCell}
          />
          <GridColumn
            field="규격"
            title="규격"
            columnMenu={ColumnMenuCheckboxFilter}
            footerCell={UniqCountCell}
          />
          <GridColumn
            field="수량"
            title="수량"
            columnMenu={ColumnMenuCheckboxFilter}
            footerCell={UniqCountCell}
          />
          <GridColumn
            field="단위"
            title="단위"
            columnMenu={ColumnMenuCheckboxFilter}
            footerCell={UniqCountCell}
          />
          <GridColumn
            field="단가"
            title="단가"
            filter={"numeric"}
            columnMenu={ColumnMenu}
            footerCell={UniqCountCell}
          />
          <GridColumn
            field="금액"
            title="금액"
            filter={"numeric"}
            columnMenu={ColumnMenu}
            footerCell={SimpleSumCell}
          />
        </Grid>
      </ExcelExport>
    </>
  );
};

export default Table;
