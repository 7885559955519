import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { TileLayout } from "@progress/kendo-react-layout";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import styled from "styled-components";
import FacilityManagement from "./Components/FacilityManagement/FacilityManagement";
import PurchaseManagement from "./Components/PurchaseManagement/PurchaseManagement";
import StockManagement from "./Components/StockManagement/StockManagement";
import "@progress/kendo-theme-default";

const iframe_boolean = window.self === window.top;

const LGProjectPage = () => {
  const { project_code } = useParams();
  const viewPort = useWindowDimensions();

  const height = iframe_boolean ? viewPort.height - 20 : viewPort.height - 210;

  const tileData = useMemo(() => {
    return [
      {
        header: <div>설비관리 현황</div>,
        body: (
          <FacilityManagement
            project_code={project_code}
            height={(height / 12) * 5 - 80}
          />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
      {
        header: <div>구매자재 입고 현황</div>,
        body: (
          <PurchaseManagement
            project_code={project_code}
            height={(height / 12) * 7 - 80}
          />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
      {
        header: <div>재고현황</div>,
        body: (
          <StockManagement
            project_code={project_code}
            height={(height / 12) * 7 - 80}
          />
        ),
        status: true,
        resizable: false,
        reorderable: false,
      },
      // {
      //   header: <div>주요 자재 매입가격 추이</div>,
      //   body: (
      //     <MainMaterialManagement
      //       project_code={project_code}
      //       height={(height / 12) * 3 - 100}
      //     />
      //   ),
      //   status: true,
      //   resizable: false,
      //   reorderable: false,
      // },
    ];
  }, [height, project_code]);

  return (
    <ProjectPageDiv>
      <TileLayout
        columns={13}
        rowHeight={height / 12}
        positions={positiondata}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tileData}
        autoFlow="row dense"
        className="allpage"
        style={{
          height: height,
          padding: 0,
        }}
      />
    </ProjectPageDiv>
  );
};

export default LGProjectPage;

const ProjectPageDiv = styled.div`
  .k-tilelayout-item-body.k-card-body {
    overflow: auto;
  }

  .k-tilelayout-item-header.k-card-header {
    padding: 7px 16px;
    background-color: rgb(23, 42, 75);
    color: white;
    font-size: 1rem;
    font-weight: 500;
  }
`;

const positiondata = [
  {
    col: 1,
    colSpan: 13,
    rowSpan: 5,
    status: true,
  },

  {
    col: 1,
    colSpan: 6,
    rowSpan: 7,
    status: true,
  },
  {
    col: 7,
    colSpan: 7,
    rowSpan: 7,
    status: true,
  },
  // {
  //   col: 1,
  //   colSpan: 5,
  //   rowSpan: 3,
  //   status: true,
  // },
];
