import React, { useEffect, useState, useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Spirited from "@amcharts/amcharts5/themes/Spirited";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

const CHART_ID = 'timetypehart';

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#004098',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.6rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        // borderRadius: '8px 0px 0px 8px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important',
        width: 100

    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0)
        }
    }
}));


const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

const TimeHeatmap = ({ height }) => {
    const [selectedValue, setSelectedValue] = useState("1");
    const defaultvalue = new Date()
    const [datevalue, setDateValue] = React.useState([
      dayjs(dayjs(defaultvalue).format("YYYY-MM-DD")),
      dayjs(dayjs(defaultvalue).format("YYYY-MM-DD")),
    ]);

    const handleChange = (event, selectedValue) => {
        if (selectedValue !== null) {
          setSelectedValue(selectedValue);
        }
      };

    useLayoutEffect(() => {
        let root = am5.Root.new(CHART_ID);



        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);


        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            paddingLeft: 0,
            layout: root.verticalLayout
        }));


        // Create axes and their renderers
        var yRenderer = am5xy.AxisRendererY.new(root, {
            visible: false,
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true
        });

        yRenderer.grid.template.set("visible", false);

        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            renderer: yRenderer,
            categoryField: "weekday"
        }));

        var xRenderer = am5xy.AxisRendererX.new(root, {
            visible: false,
            minGridDistance: 30,
            opposite: true,
            minorGridEnabled: true
        });

        xRenderer.grid.template.set("visible", false);

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: "hour"
        }));


        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/#Adding_series
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: "hour",
            categoryYField: "weekday",
            valueField: "value"
        }));

        series.columns.template.setAll({
            tooltipText: "{value}",
            strokeOpacity: 1,
            strokeWidth: 2,
            width: am5.percent(100),
            height: am5.percent(100)
        });

        series.columns.template.events.on("pointerover", function (event) {
            var di = event.target.dataItem;
            if (di) {
                heatLegend.showValue(di.get("value", 0));
            }
        });

        series.events.on("datavalidated", function () {
            heatLegend.set("startValue", series.getPrivate("valueHigh"));
            heatLegend.set("endValue", series.getPrivate("valueLow"));
        });


        // Set up heat rules
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        series.set("heatRules", [{
            target: series.columns.template,
            min: am5.color(0xfffb77),
            max: am5.color(0xfe131a),
            dataField: "value",
            key: "fill"
        }]);

        series.data.setAll(data);
        yAxis.data.setAll([
            { weekday: "일요일" },
            { weekday: "월요일" },
            { weekday: "화요일" },
            { weekday: "수요일" },
            { weekday: "목요일" },
            { weekday: "금요일" },
            { weekday: "토요일" }
        ]);

        xAxis.data.setAll([
            { hour: "12pm" },
            { hour: "1am" },
            { hour: "2am" },
            { hour: "3am" },
            { hour: "4am" },
            { hour: "5am" },
            { hour: "6am" },
            { hour: "7am" },
            { hour: "8am" },
            { hour: "9am" },
            { hour: "10am" },
            { hour: "11am" },
            { hour: "12am" },
            { hour: "1pm" },
            { hour: "2pm" },
            { hour: "3pm" },
            { hour: "4pm" },
            { hour: "5pm" },
            { hour: "6pm" },
            { hour: "7pm" },
            { hour: "8pm" },
            { hour: "9pm" },
            { hour: "10pm" },
            { hour: "11pm" }
        ]);


        chart.appear(1000, 100);
        // Add heat legend
        // https://www.amcharts.com/docs/v5/concepts/legend/heat-legend/
        var heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
            orientation: "horizontal",
            endColor: am5.color(0xfffb77),
            startColor: am5.color(0xfe131a)
        }));

        return () => {
            root && root.dispose();
        };

    }, [data]);

    return (
        <>
         <div style={{ display: "flex", fontSize: '0.8rem', zIndex: 9999, justifyContent: "flex-end", position:"absolute", top:0 , right:10}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['SingleInputDateRangeField']} sx={{marginRight:1}}>
                <DateRangePicker
                  slots={{ field: SingleInputDateRangeField }}
                  slotProps={{ textField: { size: 'small' } }}
                  name="allowedRange"
                  format='YYYY-MM-DD'
                  value={datevalue}
                  onChange={(newValue) => setDateValue(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
                <ThemeProvider theme={theme}>
                    <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                        <ToggleButton value="1" aria-label='공헌이익률1' >
                            취급액 기준
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="공헌이익률2">
                            공헌이익금 기준
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ThemeProvider>
            </div>
            <div id={CHART_ID} style={{ width: "100%", height: (height / 6.5) * 2, zIndex: '999', margin: '0 auto', marginTop: -12 }}></div>
        </>
    );
};

export default TimeHeatmap

const data = [{
    hour: "12pm",
    weekday: "일요일",
    value: 2990
}, {
    hour: "1am",
    weekday: "일요일",
    value: 2520
}, {
    hour: "2am",
    weekday: "일요일",
    value: 2334
}, {
    hour: "3am",
    weekday: "일요일",
    value: 2230
}, {
    hour: "4am",
    weekday: "일요일",
    value: 2325
}, {
    hour: "5am",
    weekday: "일요일",
    value: 2019
}, {
    hour: "6am",
    weekday: "일요일",
    value: 2128
}, {
    hour: "7am",
    weekday: "일요일",
    value: 2246
}, {
    hour: "8am",
    weekday: "일요일",
    value: 2421
}, {
    hour: "9am",
    weekday: "일요일",
    value: 2788
}, {
    hour: "10am",
    weekday: "일요일",
    value: 2959
}, {
    hour: "11am",
    weekday: "일요일",
    value: 3018
}, {
    hour: "12am",
    weekday: "일요일",
    value: 3154
}, {
    hour: "1pm",
    weekday: "일요일",
    value: 3172
}, {
    hour: "2pm",
    weekday: "일요일",
    value: 3368
}, {
    hour: "3pm",
    weekday: "일요일",
    value: 3464
}, {
    hour: "4pm",
    weekday: "일요일",
    value: 3746
}, {
    hour: "5pm",
    weekday: "일요일",
    value: 3656
}, {
    hour: "6pm",
    weekday: "일요일",
    value: 3336
}, {
    hour: "7pm",
    weekday: "일요일",
    value: 3292
}, {
    hour: "8pm",
    weekday: "일요일",
    value: 3269
}, {
    hour: "9pm",
    weekday: "일요일",
    value: 3300
}, {
    hour: "10pm",
    weekday: "일요일",
    value: 3403
}, {
    hour: "11pm",
    weekday: "일요일",
    value: 3323
}, {
    hour: "12pm",
    weekday: "월요일",
    value: 3346
}, {
    hour: "1am",
    weekday: "월요일",
    value: 2725
}, {
    hour: "2am",
    weekday: "월요일",
    value: 3052
}, {
    hour: "3am",
    weekday: "월요일",
    value: 3876
}, {
    hour: "4am",
    weekday: "월요일",
    value: 4453
}, {
    hour: "5am",
    weekday: "월요일",
    value: 3972
}, {
    hour: "6am",
    weekday: "월요일",
    value: 4644
}, {
    hour: "7am",
    weekday: "월요일",
    value: 5715
}, {
    hour: "8am",
    weekday: "월요일",
    value: 7080
}, {
    hour: "9am",
    weekday: "월요일",
    value: 8022
}, {
    hour: "10am",
    weekday: "월요일",
    value: 8446
}, {
    hour: "11am",
    weekday: "월요일",
    value: 9313
}, {
    hour: "12am",
    weekday: "월요일",
    value: 9011
}, {
    hour: "1pm",
    weekday: "월요일",
    value: 8508
}, {
    hour: "2pm",
    weekday: "월요일",
    value: 8515
}, {
    hour: "3pm",
    weekday: "월요일",
    value: 8399
}, {
    hour: "4pm",
    weekday: "월요일",
    value: 8649
}, {
    hour: "5pm",
    weekday: "월요일",
    value: 7869
}, {
    hour: "6pm",
    weekday: "월요일",
    value: 6933
}, {
    hour: "7pm",
    weekday: "월요일",
    value: 5969
}, {
    hour: "8pm",
    weekday: "월요일",
    value: 5552
}, {
    hour: "9pm",
    weekday: "월요일",
    value: 5434
}, {
    hour: "10pm",
    weekday: "월요일",
    value: 5070
}, {
    hour: "11pm",
    weekday: "월요일",
    value: 4851
}, {
    hour: "12pm",
    weekday: "화요일",
    value: 4468
}, {
    hour: "1am",
    weekday: "화요일",
    value: 3306
}, {
    hour: "2am",
    weekday: "화요일",
    value: 3906
}, {
    hour: "3am",
    weekday: "화요일",
    value: 4413
}, {
    hour: "4am",
    weekday: "화요일",
    value: 4726
}, {
    hour: "5am",
    weekday: "화요일",
    value: 4584
}, {
    hour: "6am",
    weekday: "화요일",
    value: 5717
}, {
    hour: "7am",
    weekday: "화요일",
    value: 6504
}, {
    hour: "8am",
    weekday: "화요일",
    value: 8104
}, {
    hour: "9am",
    weekday: "화요일",
    value: 8813
}, {
    hour: "10am",
    weekday: "화요일",
    value: 9278
}, {
    hour: "11am",
    weekday: "화요일",
    value: 10425
}, {
    hour: "12am",
    weekday: "화요일",
    value: 10137
}, {
    hour: "1pm",
    weekday: "화요일",
    value: 9290
}, {
    hour: "2pm",
    weekday: "화요일",
    value: 9255
}, {
    hour: "3pm",
    weekday: "화요일",
    value: 9614
}, {
    hour: "4pm",
    weekday: "화요일",
    value: 9713
}, {
    hour: "5pm",
    weekday: "화요일",
    value: 9667
}, {
    hour: "6pm",
    weekday: "화요일",
    value: 8774
}, {
    hour: "7pm",
    weekday: "화요일",
    value: 8649
}, {
    hour: "8pm",
    weekday: "화요일",
    value: 9937
}, {
    hour: "9pm",
    weekday: "화요일",
    value: 10286
}, {
    hour: "10pm",
    weekday: "화요일",
    value: 9175
}, {
    hour: "11pm",
    weekday: "화요일",
    value: 8581
}, {
    hour: "12pm",
    weekday: "수요일",
    value: 8145
}, {
    hour: "1am",
    weekday: "수요일",
    value: 7177
}, {
    hour: "2am",
    weekday: "수요일",
    value: 5657
}, {
    hour: "3am",
    weekday: "수요일",
    value: 6802
}, {
    hour: "4am",
    weekday: "수요일",
    value: 8159
}, {
    hour: "5am",
    weekday: "수요일",
    value: 8449
}, {
    hour: "6am",
    weekday: "수요일",
    value: 9453
}, {
    hour: "7am",
    weekday: "수요일",
    value: 9947
}, {
    hour: "8am",
    weekday: "수요일",
    value: 11471
}, {
    hour: "9am",
    weekday: "수요일",
    value: 12492
}, {
    hour: "10am",
    weekday: "수요일",
    value: 9388
}, {
    hour: "11am",
    weekday: "수요일",
    value: 9928
}, {
    hour: "12am",
    weekday: "수요일",
    value: 9644
}, {
    hour: "1pm",
    weekday: "수요일",
    value: 9034
}, {
    hour: "2pm",
    weekday: "수요일",
    value: 8964
}, {
    hour: "3pm",
    weekday: "수요일",
    value: 9069
}, {
    hour: "4pm",
    weekday: "수요일",
    value: 8898
}, {
    hour: "5pm",
    weekday: "수요일",
    value: 8322
}, {
    hour: "6pm",
    weekday: "수요일",
    value: 6909
}, {
    hour: "7pm",
    weekday: "수요일",
    value: 5810
}, {
    hour: "8pm",
    weekday: "수요일",
    value: 5151
}, {
    hour: "9pm",
    weekday: "수요일",
    value: 4911
}, {
    hour: "10pm",
    weekday: "수요일",
    value: 4487
}, {
    hour: "11pm",
    weekday: "수요일",
    value: 4118
}, {
    hour: "12pm",
    weekday: "목요일",
    value: 3689
}, {
    hour: "1am",
    weekday: "목요일",
    value: 3081
}, {
    hour: "2am",
    weekday: "목요일",
    value: 6525
}, {
    hour: "3am",
    weekday: "목요일",
    value: 6228
}, {
    hour: "4am",
    weekday: "목요일",
    value: 6917
}, {
    hour: "5am",
    weekday: "목요일",
    value: 6568
}, {
    hour: "6am",
    weekday: "목요일",
    value: 6405
}, {
    hour: "7am",
    weekday: "목요일",
    value: 8106
}, {
    hour: "8am",
    weekday: "목요일",
    value: 8542
}, {
    hour: "9am",
    weekday: "목요일",
    value: 8501
}, {
    hour: "10am",
    weekday: "목요일",
    value: 8802
}, {
    hour: "11am",
    weekday: "목요일",
    value: 9420
}, {
    hour: "12am",
    weekday: "목요일",
    value: 8966
}, {
    hour: "1pm",
    weekday: "목요일",
    value: 8135
}, {
    hour: "2pm",
    weekday: "목요일",
    value: 8224
}, {
    hour: "3pm",
    weekday: "목요일",
    value: 8387
}, {
    hour: "4pm",
    weekday: "목요일",
    value: 8218
}, {
    hour: "5pm",
    weekday: "목요일",
    value: 7641
}, {
    hour: "6pm",
    weekday: "목요일",
    value: 6469
}, {
    hour: "7pm",
    weekday: "목요일",
    value: 5441
}, {
    hour: "8pm",
    weekday: "목요일",
    value: 4952
}, {
    hour: "9pm",
    weekday: "목요일",
    value: 4643
}, {
    hour: "10pm",
    weekday: "목요일",
    value: 4393
}, {
    hour: "11pm",
    weekday: "목요일",
    value: 4017
}, {
    hour: "12pm",
    weekday: "금요일",
    value: 4022
}, {
    hour: "1am",
    weekday: "금요일",
    value: 3063
}, {
    hour: "2am",
    weekday: "금요일",
    value: 3638
}, {
    hour: "3am",
    weekday: "금요일",
    value: 3968
}, {
    hour: "4am",
    weekday: "금요일",
    value: 4070
}, {
    hour: "5am",
    weekday: "금요일",
    value: 4019
}, {
    hour: "6am",
    weekday: "금요일",
    value: 4548
}, {
    hour: "7am",
    weekday: "금요일",
    value: 5465
}, {
    hour: "8am",
    weekday: "금요일",
    value: 6909
}, {
    hour: "9am",
    weekday: "금요일",
    value: 7706
}, {
    hour: "10am",
    weekday: "금요일",
    value: 7867
}, {
    hour: "11am",
    weekday: "금요일",
    value: 8615
}, {
    hour: "12am",
    weekday: "금요일",
    value: 8218
}, {
    hour: "1pm",
    weekday: "금요일",
    value: 7604
}, {
    hour: "2pm",
    weekday: "금요일",
    value: 7429
}, {
    hour: "3pm",
    weekday: "금요일",
    value: 7488
}, {
    hour: "4pm",
    weekday: "금요일",
    value: 7493
}, {
    hour: "5pm",
    weekday: "금요일",
    value: 6998
}, {
    hour: "6pm",
    weekday: "금요일",
    value: 5941
}, {
    hour: "7pm",
    weekday: "금요일",
    value: 5068
}, {
    hour: "8pm",
    weekday: "금요일",
    value: 4636
}, {
    hour: "9pm",
    weekday: "금요일",
    value: 4241
}, {
    hour: "10pm",
    weekday: "금요일",
    value: 3858
}, {
    hour: "11pm",
    weekday: "금요일",
    value: 3833
}, {
    hour: "12pm",
    weekday: "토요일",
    value: 3503
}, {
    hour: "1am",
    weekday: "토요일",
    value: 2842
}, {
    hour: "2am",
    weekday: "토요일",
    value: 2808
}, {
    hour: "3am",
    weekday: "토요일",
    value: 2399
}, {
    hour: "4am",
    weekday: "토요일",
    value: 2280
}, {
    hour: "5am",
    weekday: "토요일",
    value: 2139
}, {
    hour: "6am",
    weekday: "토요일",
    value: 2527
}, {
    hour: "7am",
    weekday: "토요일",
    value: 2940
}, {
    hour: "8am",
    weekday: "토요일",
    value: 3066
}, {
    hour: "9am",
    weekday: "토요일",
    value: 3494
}, {
    hour: "10am",
    weekday: "토요일",
    value: 3287
}, {
    hour: "11am",
    weekday: "토요일",
    value: 3416
}, {
    hour: "12am",
    weekday: "토요일",
    value: 3432
}, {
    hour: "1pm",
    weekday: "토요일",
    value: 3523
}, {
    hour: "2pm",
    weekday: "토요일",
    value: 3542
}, {
    hour: "3pm",
    weekday: "토요일",
    value: 3347
}, {
    hour: "4pm",
    weekday: "토요일",
    value: 3292
}, {
    hour: "5pm",
    weekday: "토요일",
    value: 3416
}, {
    hour: "6pm",
    weekday: "토요일",
    value: 3131
}, {
    hour: "7pm",
    weekday: "토요일",
    value: 3057
}, {
    hour: "8pm",
    weekday: "토요일",
    value: 3227
}, {
    hour: "9pm",
    weekday: "토요일",
    value: 3060
}, {
    hour: "10pm",
    weekday: "토요일",
    value: 2855
}, {
    hour: "11pm",
    weekday: "토요일",
    value: 2625
}
]