import React, { useEffect, useState, useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Spirited from "@amcharts/amcharts5/themes/Spirited";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';
import { MenuItem, Select, FormControl } from '@mui/material';

const CHART_ID = 'topchart4';

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#004098',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.6rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        // borderRadius: '8px 0px 0px 8px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important',
        width: 100

    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0)
        }
    }
}));

const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

const Time = ({ height }) => {
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState("제품명");
    // const [selectedComparison, setSelectedComparison] = useState("기간");
    const getProductList = (category) => {
        switch (category) {
            case 1:
                return ["제품명", "고기", "라면", "김치"];
            case 2:
                return ["제품명", "나이키", "아디다스", "푸마"];
            default:
                return ["제품명"];
        }
    };


    const handleChangeCategory = (event) => {
        const categoryValue = event.target.value;
        setSelectedCategory(categoryValue);
        setSelectedProduct("제품명"); // 선택된 제품 초기화
        // setSelectedComparison("기간"); // 선택된 비교 기간 초기화
    };
    
    const handleChangeProduct = (event) => {
        setSelectedProduct(event.target.value);
        // setSelectedComparison("기간");
    };

    // const handleChangeComparison = (event) => {
    //     setSelectedComparison(event.target.value);
    // };

    
    useLayoutEffect(() => {
        const sortedChartData = generateChartData(selectedCategory, selectedProduct);
        
        let root = am5.Root.new(CHART_ID);
        root.setThemes([am5themes_Animated.new(root), am5themes_Spirited.new(root)]);

        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0,
            paddingRight: 1
        }));

        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        var xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 30,
            minorGridEnabled: true
        });

        xRenderer.labels.template.setAll({
            centerY: am5.p100,
            centerX: am5.p50,
        });

        xRenderer.grid.template.setAll({
            location: 1
        })

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "time",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        var yRenderer = am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1
        })

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: yRenderer
        }));

        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "기간 대비 현황",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "profit",
            sequencedInterpolation: true,
            categoryXField: "time",
            tooltip: am5.Tooltip.new(root, {
                labelText: `{valueY.formatNumber("#,###")}`
            })
        }));

        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
        series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                text: `{valueY.formatNumber("#,###")}`,
                fill: root.interfaceColors.get("alternativeText"),
                centerY: am5.percent(50),
                centerX: am5.percent(50),
                populateText: true
              })
            });
          });

          var label = chart.children.push(am5.Label.new(root, {
            text: "(단위 : 천원)",
            x: am5.percent(90),
            y: am5.percent(-6), // 차트의 상단으로부터의 거리를 조정합니다.
            fontSize: 12, // 문구의 폰트 크기를 설정합니다.
            fill: am5.color("#333"), // 문구의 색상을 설정합니다.
            align: "middle", // 가운데 정렬합니다.
        }));

        xAxis.data.setAll(sortedChartData);
        series.data.setAll(sortedChartData);

        chart.appear(1000, 100);

        return () => {
            root && root.dispose();
        };

    }, [selectedCategory, selectedProduct]);

    const categoryItems  = [
        { value: 0, label: "카테고리" },
        { value: 1, label: "식품" },
        { value: 2, label: "의류" },
    ];

    // const productItems = selectedCategory === 1
    //     ? ["고기", "라면", "김치"]
    //     : selectedCategory === 2
    //         ? ["나이키", "아디다스", "푸마"]
    //         : ["제품명"];

    // const productItems = ["제품명","고기", "라면", "김치" ,"나이키", "아디다스", "푸마"];
    const productItems = getProductList(selectedCategory);

    // const comparisonItems = ["기간","연", "월", "주" ,"일"];

    return (
        <>
             <div style={{ position: "absolute", top: "40px", right: '12px', transform: 'translateY(-50%)', zIndex: 999999, height: "30px" }}>
                <ThemeProvider theme={theme}>
                    <FormControl>
                        <Select
                            value={selectedCategory}
                            onChange={handleChangeCategory}
                            style={{ color: 'black', width: "120px", height: "30px" }}
                        >
                            {categoryItems.map(item => (
                                <MenuItem key={item.value} value={item.value} style={{ color: 'black' }}>{item.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Select
                            value={selectedProduct}
                            onChange={handleChangeProduct}
                            style={{ color: 'black', width: "120px", height: "30px" }}
                        >
                            {productItems.map((item, index) => (
                                <MenuItem key={index} value={item} style={{ color: 'black'}}>{item}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {/* <FormControl>
                        <Select
                            value={selectedComparison}
                            onChange={handleChangeComparison}
                            style={{ color: 'black', width: "120px", height: "30px"  }}
                        >
                            {comparisonItems.map((item, index) => (
                                <MenuItem key={index} value={item} style={{ color: 'black', height: "30px" }}>{item}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                </ThemeProvider>
            </div>
            <div id={CHART_ID} style={{ width: "100%", height: (height / 5.5) * 3 - 60, zIndex: '999', margin: '0 auto', marginTop: 0 }}>
            </div>
        </>
    );
};

export default Time

const generateChartData = (category, product) => {
    const categoryData = getCategoryData(category);
    const productData = getProductData(product);
    
    if (categoryData && productData) {
        const today = new Date();
        const hoursData = [];

        for (let i = 0; i < 24; i++) {
            const hour = i < 10 ? `0${i}` : `${i}`;
            hoursData.push({
                time: `${hour}`,
                profit: Math.floor(Math.random() * 1000)
            });
        }

        return hoursData;
    } else {
        return [];
    }
};

const getCategoryData = (category) => {
    switch (category) {
        case 1:
            const categoryData1 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                categoryData1[`${hour}`] = `${hour}`;
            }
            return categoryData1;
        case 2:
            const categoryData2 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                categoryData2[`${hour}`] = `${hour}`;
            }
            return categoryData2;
        case 0:
            const categoryData3 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                categoryData3[`${hour}`] = `${hour}`;
            }
            return categoryData3;    
        // 추가적인 카테고리에 대한 데이터를 필요에 따라 추가할 수 있습니다.
        default:
            return null;
    }
};

const getProductData = (product) => {
    switch (product) {
        case '고기':
            const productData1 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                productData1[`${hour}시 매출`] = `${hour}시 매출`;
            }
            return productData1;
        case '라면':
            const productData2 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                productData2[`${hour}시 매출`] = `${hour}시 매출`;
            }
            return productData2;
        case '김치':
            const productData3 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                productData3[`${hour}시 매출`] = `${hour}시 매출`;
            }
            return productData3;
        case '나이키':
            const productData4 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                productData4[`${hour}시 매출`] = `${hour}시 매출`;
            }
            return productData4;
        case '아디다스':
            const productData5 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                productData5[`${hour}시 매출`] = `${hour}시 매출`;
            }
            return productData5;
        case '푸마':
            const productData6 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                productData6[`${hour}시 매출`] = `${hour}시 매출`;
            }
            return productData6;
        case '제품명':
            const productData7 = {};
            for (let i = 0; i < 24; i++) {
                const hour = i < 10 ? `0${i}` : `${i}`;
                productData7[`${hour}시 매출`] = `${hour}시 매출`;
            }
            return productData7;    
        // 추가적인 제품에 대한 데이터를 필요에 따라 추가할 수 있습니다.
        default:
            return null;
    }
};

