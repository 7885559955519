import React, { forwardRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import "./PrintTable.scss";

const PrintTable = forwardRef(({ detailTableData, tableWidth }, ref) => {
  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];

    if (field.includes("weight")) {
      return (
        <td style={{ fontSize: "0.3rem", textAlign: "left" }}>
          {Number(cell.toFixed(0)).toLocaleString()}t
        </td>
      );
    } else if (field.includes("cost")) {
      return (
        <td style={{ fontSize: "0.3rem", textAlign: "left" }}>
          {Number(cell.toFixed(0)).toLocaleString()}백만원
        </td>
      );
    } else if (field === "percent_act") {
      return <td style={{ fontSize: "0.3rem", textAlign: "left" }}>{cell}%</td>;
    } else if (field === "warning") {
      return (
        <td
          style={{
            font: "0.3rem pretendard-B",
            textAlign: "left",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              color: cell === true ? "black" : "red",
            }}
          >
            {cell === true ? "달성" : "미달성"}
          </div>
        </td>
      );
    } else {
      return <td style={{ fontSize: "0.3rem", textAlign: "left" }}>{cell}</td>;
    }
  };

  const HeaderCell = (props) => {
    const field = props.field || "";

    if (field === "warning") {
      return (
        <div
          style={{ fontSize: "0.7rem", textAlign: "left", fontWeight: "bold" }}
        >
          목표 <br />
          달성
        </div>
      );
    } else {
      return (
        <div
          style={{ fontSize: "0.7rem", textAlign: "left", fontWeight: "bold" }}
        >
          {props.title}
        </div>
      );
    }
  };

  return (
    <div className="printTable" style={{ display: "none" }}>
      <div>
        <Grid
          data={detailTableData}
          className="overallprojectListTable"
          ref={ref}
          style={{
            width: tableWidth,
            margin: "20px",
          }}
          scrollable={"none"}
        >
          <GridColumn
            field="uxesg_SectorName"
            title="본부명"
            cell={normalcell}
            // width="100px"
            width={tableWidth * 0.11}
            headerCell={HeaderCell}
          />
          <GridColumn
            field="uxesg_PJTName"
            title="현장명"
            cell={normalcell}
            // width="150px"
            width={tableWidth * 0.15}
            headerCell={HeaderCell}
          />
          <GridColumn
            field="percent_act"
            title="공정율"
            cell={normalcell}
            // width="70px"
            width={tableWidth * 0.07}
            headerCell={HeaderCell}
          />

          <GridColumn title="처리량" headerCell={HeaderCell}>
            <GridColumn
              field="project_total_target_weight"
              title="목표"
              cell={normalcell}
              // width="80px"
              headerCell={HeaderCell}
              width={tableWidth * 0.1}
            />
            <GridColumn
              field="project_total_plan_weight"
              title="계획"
              cell={normalcell}
              // width="80px"
              headerCell={HeaderCell}
              width={tableWidth * 0.1}
            />
            <GridColumn
              field="project_total_act_weight"
              title="실적"
              cell={normalcell}
              // width="80px"
              headerCell={HeaderCell}
              width={tableWidth * 0.1}
            />
          </GridColumn>
          <GridColumn title="처리비용" headerCell={HeaderCell}>
            <GridColumn
              field="project_total_target_cost"
              title="목표"
              cell={normalcell}
              // width="80px"
              headerCell={HeaderCell}
              width={tableWidth * 0.1}
            />
            <GridColumn
              field="project_total_plan_cost"
              title="계획"
              cell={normalcell}
              // width="80px"
              headerCell={HeaderCell}
              width={tableWidth * 0.1}
            />
            <GridColumn
              field="project_total_act_cost"
              title="실적"
              cell={normalcell}
              // width="80px"
              width={tableWidth * 0.1}
              headerCell={HeaderCell}
            />
          </GridColumn>
          <GridColumn
            field="warning"
            title="목표 달성"
            cell={normalcell}
            // width="80px"
            width={tableWidth * 0.1}
            headerCell={HeaderCell}
          />
        </Grid>
      </div>
    </div>
  );
});

export default PrintTable;
