import styled from "styled-components";

export const SubTitleDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.text_position};
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(92, 92, 92);
`;

export const SubTitleCountDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.text_position};
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(68, 130, 132);
`;

export const SpaceBetweenDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-space-between;
  align-items: center;
`;

export const headerClass = "lgchemical-custom-header";

export const LGChemicalTable = styled.div`
  padding: 5;
  width: 100%;

  .lgchemical-custom-header {
    .k-cell-inner > .k-link {
      font-size: 0.7rem;
      padding: 4px 12px !important;
    }
  }
`;

export const CostDivBox = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const CostDivTitle = styled.div`
  width: 50%;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(92, 92, 92);
`;

export const CostDivCost = styled.div`
  width: 50%;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(68, 130, 132);
`;
