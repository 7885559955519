import React, { useEffect, useState, useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Spirited from "@amcharts/amcharts5/themes/Spirited";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';


const CHART_ID = 'topchart';

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#004098',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.6rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        // borderRadius: '8px 0px 0px 8px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important',
        width: 100

    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0)
        }
    }
}));


const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

const ProfitTop = ({ height }) => {

    const [selectedValue, setSelectedValue] = useState("1");

    const handleChange = (event, selectedValue) => {
        if (selectedValue !== null) {
          setSelectedValue(selectedValue);
        }
      };

    useLayoutEffect(() => {

        const sortedChartData = _.sortBy( selectedValue === "1"? chartData:chartData2, "profit").reverse()

        let root = am5.Root.new(CHART_ID);
        root.setThemes([am5themes_Animated.new(root), am5themes_Spirited.new(root)]);

        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0,
            paddingRight: 1
        }));

        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        var xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 30,
            minorGridEnabled: true
        });

        xRenderer.labels.template.setAll({
            centerY: am5.p100,
            centerX: am5.p50,
        });

        xRenderer.grid.template.setAll({
            location: 1
        })

        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "product",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        var yRenderer = am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1
        })

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: yRenderer
        }));

        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "공헌이익률",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "profit",
            sequencedInterpolation: true,
            categoryXField: "product",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}%"
            })
        }));

        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
        series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.columns.template.adapters.add("stroke", function (stroke, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
              locationY: 0.5,
              sprite: am5.Label.new(root, {
                text: "{valueY}%",
                fill: root.interfaceColors.get("alternativeText"),
                centerY: am5.percent(50),
                centerX: am5.percent(50),
                populateText: true
              })
            });
          });

        xAxis.data.setAll(sortedChartData);
        series.data.setAll(sortedChartData);

        chart.appear(1000, 100);

        return () => {
            root && root.dispose();
        };

    }, [chartData, chartData2, selectedValue]);

    return (
        <>
            <div style={{ position: "absolute", top: "20px", right: '12px', transform: 'translateY(-50%)', zIndex: 999999 }}>
                <ThemeProvider theme={theme}>
                    <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                        <ToggleButton value="1" aria-label='공헌이익률1' >
                            공헌이익률 로직1
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="공헌이익률2">
                            공헌이익률 로직2
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ThemeProvider>
            </div>
            <div id={CHART_ID} style={{ width: "100%", height: (height / 5.5) * 2 -60, zIndex: '999', margin: '0 auto', marginTop: 0 }}></div>
        </>
    );
};


export default ProfitTop

const chartData = [
    {product:"A 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"B 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"C 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"D 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"E 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"F 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"G 제품", profit: Math.floor(Math.random() * 51)},  
    {product:"H 제품", profit: Math.floor(Math.random() * 51)},  
    {product:"I 제품", profit: Math.floor(Math.random() * 51)},  
    {product:"J 제품", profit: Math.floor(Math.random() * 51)},  
]

const chartData2 = [
    {product:"A 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"B 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"C 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"D 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"E 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"F 제품", profit: Math.floor(Math.random() * 51)}, 
    {product:"G 제품", profit: Math.floor(Math.random() * 51)},  
    {product:"H 제품", profit: Math.floor(Math.random() * 51)},  
    {product:"I 제품", profit: Math.floor(Math.random() * 51)},  
    {product:"J 제품", profit: Math.floor(Math.random() * 51)},  
]
