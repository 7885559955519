import React, { useEffect, useState, useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Spirited from "@amcharts/amcharts5/themes/Spirited";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';

const CHART_ID = 'topchart2';

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#004098',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.6rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        border: '1px solid rgba(0, 0, 0, 0.12)!important',
        width: 100
    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0)
        }
    }
}));

const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

const Target = ({ height }) => {

    const [selectedValue, setSelectedValue] = useState("1");

    const handleChange = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
        }
    };

    useLayoutEffect(() => {
        const sortedChartData = (selectedValue === "1" ? chartData : (selectedValue === "2" ? chartData2 : chartData3));
        const sortedChartData2 = (selectedValue === "1" ? chartData_target2 : (selectedValue === "2" ? chartData2_target2 : chartData3_target2));
        const sortedChartData3 = (selectedValue === "1" ? chartData_target3 : (selectedValue === "2" ? chartData2_target3 : chartData3_target3)); // 새로운 데이터 배열 추가
        const sortedChartData4 = (selectedValue === "1" ? chartData_target4 : (selectedValue === "2" ? chartData2_target4 : chartData3_target4)); // 새로운 데이터 배열 추가
    
        let root = am5.Root.new(CHART_ID);
        root.setThemes([am5themes_Animated.new(root), am5themes_Spirited.new(root)]);
        
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0,
            paddingRight: 1
        }));
    
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);
    
        var xRenderer = am5xy.AxisRendererX.new(root, {
            minGridDistance: 30,
            minorGridEnabled: true
        });
    
        xRenderer.labels.template.setAll({
            centerY: am5.p100,
            centerX: am5.p50,
        });
    
        xRenderer.grid.template.setAll({
            location: 1
        })
    
        var xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "date",
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));
    
        var yRenderer = am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1
        })
    
        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: yRenderer
        }));
    
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "목표",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "target",
            sequencedInterpolation: true,
            categoryXField: "date",
        }));
        
        // 시리즈에 툴팁 설정
        series.tooltip = am5.Tooltip.new(root, {
            labelText: "", // 라벨 텍스트를 빈 문자열로 설정하여 숨깁니다.
            backgroundFillOpacity: 0, // 배경의 투명도를 0으로 설정하여 숨깁니다.
            label: { // 라벨 설정
                fill: "rgba(0, 0, 0, 0)", // 라벨의 텍스트 색상을 투명하게 설정하여 숨깁니다.
            }
        });
        
        // 시리즈의 컬럼 템플릿 설정
        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0, fill: "#87CEEB" });
        
        // series.columns.template.adapters.add("fill", function (fill, target) {
        //     return chart.get("colors").getIndex(series.columns.indexOf(target));
        // });
    
        // series.columns.template.adapters.add("stroke", function (stroke, target) {
        //     return chart.get("colors").getIndex(series.columns.indexOf(target));
        // });
    
        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    text: "{valueY}",
                    fill: root.interfaceColors.get("alternativeText"),
                    centerY: am5.percent(50),
                    centerX: am5.percent(50),
                    populateText: true
                })
            });
        });

        
    
        // 새로운 데이터에 대한 선 그래프 추가
        var series2 = chart.series.push(am5xy.LineSeries.new(root, {
            name: "목표 누적",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "target2",
            sequencedInterpolation: true,
            categoryXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            }) // 파란색으로 선색깔을 설정합니다. 
        }));
        
        series2.strokes.template.setAll({
            strokeWidth: 3, // 선 두께를 3으로 설정합니다.
            stroke: am5.color(128, 0, 128), // 보라색으로 선색깔을 설정합니다.
            strokeDasharray: [] // 점선이 아닌 실선으로 설정합니다.
        });
        // series2에 대한 데이터 설정
        
        series2.data.setAll(sortedChartData2); // sortedChartData2로 변경
    
        series2.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    text: "{valueY}",
                    fill: "rgba(0, 0, 0, 0)",
                    centerY: am5.percent(50),
                    centerX: am5.percent(50),
                    populateText: true
                })
            });
        });

        // 막대 그래프 시리즈 추가
        var series3 = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "실적",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "target3",
            sequencedInterpolation: true,
            categoryXField: "date",
        }));
        
        // 시리즈에 툴팁 설정
        series3.tooltip = am5.Tooltip.new(root, {
            labelText: "", // 라벨 텍스트를 빈 문자열로 설정하여 숨깁니다.
            backgroundFillOpacity: 0, // 배경의 투명도를 0으로 설정하여 숨깁니다.
            label: { // 라벨 설정
                fill: "rgba(0, 0, 0, 0)", // 라벨의 텍스트 색상을 투명하게 설정하여 숨깁니다.
            }
        });
        
        series3.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0, fill: "#FFA500" });

                // 시리즈에 툴팁 설정
        series.tooltip = am5.Tooltip.new(root, {
            labelText: "", // 라벨 텍스트를 빈 문자열로 설정하여 숨깁니다.
            backgroundFillOpacity: 0, // 배경의 투명도를 0으로 설정하여 숨깁니다.
            label: { // 라벨 설정
                fill: "rgba(0, 0, 0, 0)", // 라벨의 텍스트 색상을 투명하게 설정하여 숨깁니다.
            }
        });
        
        // 시리즈의 컬럼 템플릿 설정
        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0, fill: "#87CEEB" });
        
        // series3에 대한 데이터 설정
        series3.data.setAll(sortedChartData3);
        
series3.bullets.push(function () {
    return am5.Bullet.new(root, {
        locationY: 0.5,
        sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.percent(50),
            centerX: am5.percent(50),
            populateText: true
        })
    });
});

// 선 그래프 시리즈 추가
var series4 = chart.series.push(am5xy.LineSeries.new(root, {
    name: "실적 누적",
    xAxis: xAxis,
    yAxis: yAxis,
    valueYField: "target4",
    sequencedInterpolation: true,
    categoryXField: "date",
    tooltip: am5.Tooltip.new(root, {
        labelText: "{valueY}"
    })
}));

series4.strokes.template.setAll({
    strokeWidth: 3,
    stroke: am5.color(0, 0, 255), // 파란색으로 선색깔을 설정합니다. 
    strokeDasharray: [10, 5]
  });
  

// series4에 대한 데이터 설정
series4.data.setAll(sortedChartData4);

series4.bullets.push(function () {
    return am5.Bullet.new(root, {
        locationY: 0.5,
        sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: "rgba(0, 0, 0, 0)",
            centerY: am5.percent(50),
            centerX: am5.percent(50),
            populateText: true
        })
    });
});

// 시리즈에 이름 지정
series.name = "목표";
series2.name = "목표 누적";
series3.name = "실적";
series4.name = "실적 누적";

// 범례 추가
var legend = chart.children.push(am5.Legend.new(root, {
    y: am5.percent(-9.1), // 차트의 상단으로부터의 거리를 조정합니다.
    x: am5.percent(10), // 차트의 오른쪽으로부터의 거리를 조정합니다.
    contentAlign: "right", // 내용을 오른쪽으로 정렬합니다.
}));

// 시리즈 데이터를 범례에 설정
legend.data.setAll([series, series2, series3, series4]);
  

    
        // 기존 데이터와 새로운 데이터 병합
        const mergedChartData = sortedChartData.map((data, index) => ({
            ...data,
            target2: sortedChartData2[index] ? sortedChartData2[index].target2 : null,
            target3: sortedChartData3[index] ? sortedChartData3[index].target3 : null, // 새로운 데이터 추가
            target4: sortedChartData4[index] ? sortedChartData4[index].target4 : null // 새로운 데이터 추가
        }));
    
        xAxis.data.setAll(mergedChartData);
        series.data.setAll(mergedChartData);
    
        chart.appear(1000, 100);
    
        return () => {
            root && root.dispose();
        };
    
    }, [selectedValue]);

    return (
        <>
            <div style={{ position: "absolute", top: "20px", right: '12px', transform: 'translateY(-50%)', zIndex: 999999 }}>
                <ThemeProvider theme={theme}>
                    <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                        <ToggleButton value="1" aria-label='연' >
                            연
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="월">
                            월
                        </ToggleButton>
                        <ToggleButton value="3" aria-label="주">
                            주
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ThemeProvider>
            </div>
            <div id={CHART_ID} style={{ width: "100%", height: (height / 5.5) * 2 -60, zIndex: '999', margin: '0 auto', marginTop: 0 }}></div>
         </>
    );
};

export default Target;

// 현재 날짜 가져오기
const today = new Date();

// 전연도 데이터 생성
const chartData01 = [];
const chartData01_target2 = [];
const chartData01_target3 = [];
const chartData01_target4 = [];

let cumulative2 = 0;
let cumulative4 = 0;

for (let i = 9; i >= 0; i--) { // i를 9부터 시작해서 0까지 감소시킴으로써 반대로 데이터를 생성합니다.
    const year = today.getFullYear() - i;
        
    const target = Math.floor(Math.random() * 10000); // 랜덤한 값 생성
    const target3 = Math.floor(Math.random() * 10000); // 랜덤한 값 생성
    
    cumulative2 += target; // 누적값 계산
    cumulative4 += target3; // 2024년의 데이터부터 역순으로 참조합니다.
    
    chartData01.push({ date: year.toString(), target});
    chartData01_target2.push({ date: year.toString(), target2: cumulative2 });
    chartData01_target3.push({ date: year.toString(), target3 });
    chartData01_target4.push({ date: year.toString(), target4: cumulative4 });
}

const chartData = chartData01;
const chartData_target2 = chartData01_target2;
const chartData_target3 = chartData01_target3;
const chartData_target4 = chartData01_target4;

// 전월 데이터 생성
const chartData02 = [];
const chartData02_target2 = [];
const chartData02_target3 = [];
const chartData02_target4 = [];

let cumulative2_02 = 0;
let cumulative4_02 = 0;

for (let i = 9; i >= 0; i--) {
    const year = today.getFullYear();
    let month = today.getMonth() - i + 1;
    if (month <= 0) {
        month += 12;
    }
    const monthDate = new Date(year, month - 1);
    const monthNumber = month.toString().padStart(2, '0');
    const target_02 = Math.floor(Math.random() * 1000);
    const target3_02 = Math.floor(Math.random() *1000);

    cumulative2_02 += target_02;
    cumulative4_02 += target3_02;

    chartData02.push({ date: monthNumber + "월", target: target_02 });
    chartData02_target2.push({ date: monthNumber + "월", target2: cumulative2_02 });
    chartData02_target3.push({ date: monthNumber + "월", target3: target3_02 });
    chartData02_target4.push({ date: monthNumber + "월", target4: cumulative4_02 });
}

const chartData2 = chartData02;
const chartData2_target2 = chartData02_target2;
const chartData2_target3 = chartData02_target3;
const chartData2_target4 = chartData02_target4;

// 전주 데이터 생성
const chartData03 = [];
const chartData03_target2 = [];
const chartData03_target3 = [];
const chartData03_target4 = [];

let cumulative2_03 = 0;
let cumulative4_03 = 0;

for (let i = 9; i >= 0; i--) { // i를 9부터 시작해서 0까지 감소시킴으로써 역순으로 데이터를 생성합니다.
    const weekStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7 * i);
    const weekEndDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7 * (i - 1));

    const month = (weekStartDate.getMonth() + 1).toString().padStart(2, '0');
    const weekNumber = Math.ceil(weekStartDate.getDate() / 7);
    const formattedDate = month + "월 " + weekNumber + "주";

    const target_03 = Math.floor(Math.random() * 100);
    const target2_03 = Math.floor(Math.random() * 100);

    cumulative2_03 += target_03; // 누적값 계산
    cumulative4_03 += target2_03; // 누적값 계산

    chartData03.push({ date: formattedDate, target: target_03 });
    chartData03_target2.push({ date: formattedDate, target2: cumulative2_03 });
    chartData03_target3.push({ date: formattedDate, target3: target2_03 });
    chartData03_target4.push({ date: formattedDate, target4: cumulative4_03 });
}

const chartData3 = chartData03;
const chartData3_target2 = chartData03_target2;
const chartData3_target3 = chartData03_target3;
const chartData3_target4 = chartData03_target4;








