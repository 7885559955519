import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import VendorChart from "./Components/VendorChart";
import VendorTable from "./Components/VendorTable";

const VendorDialog = ({ tranVendorData, dispVendorData }) => {
  return (
    <div>
      <GridLayout
        style={{ height: 623 }}
        cols={[
          {
            width: "50%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
        ]}
        rows={[
          {
            height: "50%",
          },
          {
            height: "50%",
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem
          col={1}
          row={1}
          style={{
            padding: "5px",
          }}
        >
          <VendorChart
            title={"운반비용 현황"}
            chartData={tranVendorData}
            height={301}
          />
        </GridLayoutItem>
        <GridLayoutItem
          col={1}
          row={2}
          style={{
            padding: "5px",
          }}
        >
          <VendorChart
            title={"처리비용 현황"}
            chartData={dispVendorData}
            height={301}
          />
        </GridLayoutItem>
        <GridLayoutItem
          col={2}
          row={1}
          rowSpan={2}
          style={{
            padding: "5px",
          }}
        >
          <VendorTable
            title={"운반자"}
            tableData={tranVendorData}
            height={613}
          />
        </GridLayoutItem>
        <GridLayoutItem
          col={3}
          row={1}
          rowSpan={2}
          style={{
            padding: "5px",
          }}
        >
          <VendorTable
            title={"처리자"}
            tableData={dispVendorData}
            height={613}
          />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

export default VendorDialog;
