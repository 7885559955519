import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TileLayout } from "@progress/kendo-react-layout";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import record_project from "../url/Record_Project";
import OverallInfoPage from "./OverallInfoPage/OverallInfoPage";
import OverallTotalPage from "./OverallTotalPage/OverallTotalPage";
import OverallDateRagePage from "./OverallDateRagePage/OverallDateRagePage";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import bpname from "../BpName/BpName";
import url from "../url/URL";
import sector_arr from "../SectorFilter/SectorFilter";
import "../index.scss"

const iframe_boolean = window.self === window.top;

const OverallPage = () => {
  const navigate = useHistory();
  const { height, width } = useWindowDimensions();

  const [selectedValue, setSelectedValue] = useState(true);

  const [dateRange, setDateRange] = useState({
    startDate: new Date(
      moment(new Date()).subtract(11, "months").format("YYYY-MM") + "-01"
    ),
    endDate: new Date(),
  });

  const [tiles, setTiles] = useState([]);
  const [projectListData, setProjectListData] = useState([]);
  const [projectActTotalData, setProjectActTotalData] = useState([]);

  const [overallTable, setOverallTable] = useState([]);
  const [sectorTable, setSectorTable] = useState([]);
  const [targetPerformance, setTargetPerformance] = useState([]);
  const [targetManbPerformance, setTargetManbPerformance] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const projectTarget_body = {
        bpname: bpname.wasteTarget,
        lineitem: "no",
        filter_criteria: {
          join: "AND",
          filter: [
            {
              field: "uxesg_YearOnlytxt",
              value: moment(new Date()).format("YYYY"),
              condition_type: "eq",
            },
          ],
        },
      };

      const requestProjectTarget = await axios.post(
        `${url}/getbprecord3?path=${record_project}`,
        projectTarget_body
      );

      const project_target =
        requestProjectTarget.data.data.data.length > 0
          ? requestProjectTarget.data.data.data[0]["uxesg_ReducePlan"]
          : 0;

      const projectInfo_body = {
        bpname: bpname.wasteProject,
        lineitem: "yes",
      };

      const requestProjectInformation = await axios.post(
        `${url}/getbprecord3?path=${record_project}`,
        projectInfo_body
      );

      const requestProjectInformation_result =
        requestProjectInformation.data.data.data
          .filter(
            (com) => com.status !== "Completed" || com.status !== "Terminated"
          )
          .filter((com) => com._bp_lineitems !== undefined)
          .map((com) => ({
            ...com,
            uxesg_SectorName: change_sector_name(com.uxesg_SectorName),
          }))
          .map((com) => ({
            ...com,
            _bp_lineitems: com._bp_lineitems.filter(
              (com2) => com2.tab_id === 1
            ),
          }))
          .filter((com) => com._bp_lineitems.length > 0);

      const wasteCoefficient_body = {
        bpname: bpname.wasteCoefficient,
        lineitem: "no",
      };

      const requestWasteCoefficient = await axios.post(
        `${url}/getbprecord3?path=${record_project}`,
        wasteCoefficient_body
      );

      const requestWasteCoefficient_result =
        requestWasteCoefficient.data.data.data;

      const uniq_waste_coefficient = _.uniqBy(
        requestWasteCoefficient_result,
        "uxesg_PJTCate"
      )
        .map((com) => ({
          uxesg_PJTCate: com.uxesg_PJTCate,
          data: requestWasteCoefficient_result.filter(
            (com2) => com2.uxesg_PJTCate === com.uxesg_PJTCate
          ),
        }))
        .map((com) => ({ ...com, percent_data: create_percent_arr(com.data) }));

      const projectInformation_coefficient_percent =
        requestProjectInformation_result.map(async (com) => {
          const project_percent_body = {
            bpname: "Construction Weekly Report",
            lineitem: "no",
          };

          const request_project_percent = await axios.post(
            `${url}/getbprecord3?path=${com.uxesg_PJTCode}`,
            project_percent_body
          );

          // const project_actual_2 = sampleData.data;
          const project_actual_2 = request_project_percent.data.data.data;

          const lastest_percent =
            _.maxBy(project_actual_2, "WCR_ACT_CUM")?.["WCR_ACT_CUM"] ===
            undefined
              ? 0
              : _.maxBy(project_actual_2, "WCR_ACT_CUM")?.["WCR_ACT_CUM"];

          return {
            ...com,
            uxesg_target: project_target,
            percent_act: lastest_percent,
            percent_data:
              uniq_waste_coefficient.find(
                (com2) => com2.uxesg_PJTCate === com.uxesg_PJTCate
              ) === undefined
                ? []
                : uniq_waste_coefficient.find(
                    (com2) => com2.uxesg_PJTCate === com.uxesg_PJTCate
                  )["percent_data"],
          };
        });

      const projectInformation_coefficient_percent_2 = await Promise.all(
        projectInformation_coefficient_percent.map((com) =>
          com.catch((err) => null)
        )
      );

      const projectInformation_promis = projectInformation_coefficient_percent_2
        .filter((com) => com !== null)
        .flat(1);

      // setProjectListData(requestProjectInformation_result);

      const request_performance_arr = requestProjectInformation_result.map(
        async (com) => {
          let performance_body = {
            bpname: bpname.wastePerformance,
            lineitem: "no",
            filter_criteria: {
              join: "AND",
              filter: [
                {
                  field: "uxesg_PJTPK",
                  value: com.uxesg_PJTName,
                  condition_type: "eq",
                },
                {
                  field: "uxesg_manb_type",
                  value: "확정/입력",
                  condition_type: "eq",
                },
                {
                  field: "uxesg_RegiCate",
                  value: "건설폐기물",
                  condition_type: "eq",
                },
                {
                  field: "uxesg_PresentWaste",
                  value: "본공사",
                  condition_type: "eq",
                },
              ],
            },
          };

          const request_performance_res = await axios.post(
            `${url}/getbprecord3?path=${record_project}`,
            performance_body
          );

          return request_performance_res.data.data.data;
        }
      );

      const request_performance_arr2 = await Promise.all(
        request_performance_arr.map((com) => com.catch((err) => null))
      );

      const result_performance_data = request_performance_arr2
        .filter((com) => com !== null)
        .flat()
        .map((com) => ({
          ...com,
          uxesg_SectorName: change_sector_name(com.uxesg_SectorName),
        }));

      const projectInformation_final = projectInformation_promis
        .map((com) => ({
          ...com,
          performance_data: result_performance_data.filter(
            (com2) =>
              com2.uxesg_PJTName === com.uxesg_PJTName &&
              base_code_arr.includes(com2.uxesg_WasteCode)
          ),
        }))
        .map((com) => ({
          ...com,
          present_plan_data: com.percent_data
            .filter(
              (com2) =>
                com2.before_percent < com.percent_act &&
                com2.percent >= com.percent_act
            )
            .map((com2) => ({
              ...com2,
              performance_data: com.performance_data
                .filter((com3) => com3.uxesg_WasteCode === com2.code)
                .map((com3) => ({
                  ...com3,
                  code_cost_sum: com3.uxesg_DispCost + com3.uxesg_TranCost,
                })),
            }))
            .map((com2) => ({
              ...com2,
              code_cost:
                _.maxBy(com2.performance_data, "code_cost_sum")?.[
                  "code_cost_sum"
                ] === undefined
                  ? 0
                  : _.maxBy(com2.performance_data, "code_cost_sum")?.[
                      "code_cost_sum"
                    ],
            }))
            .map((com2) => ({
              ...com2,
              plan_weight:
                (com.uxesg_SiteArea * com2.uxesg_factor * com2.factor) / 100,
              plan_weight_cum:
                (com.uxesg_SiteArea * com2.uxesg_factor * com2.factor_cum) /
                100,
              plan_target_weight:
                ((com.uxesg_SiteArea * com2.uxesg_factor * com2.factor) / 100) *
                ((100 - com.uxesg_target) / 100),
              plan_target_weight_cum:
                ((com.uxesg_SiteArea * com2.uxesg_factor * com2.factor_cum) /
                  100) *
                ((100 - com.uxesg_target) / 100),
              plan_cost:
                (((com.uxesg_SiteArea * com2.uxesg_factor * com2.factor) /
                  100) *
                  com2.code_cost) /
                1000000,
              plan_cost_cum:
                (((com.uxesg_SiteArea * com2.uxesg_factor * com2.factor_cum) /
                  100) *
                  com2.code_cost) /
                1000000,
              plan_cost_target:
                (((com.uxesg_SiteArea * com2.uxesg_factor * com2.factor) /
                  100) *
                  ((100 - com.uxesg_target) / 100) *
                  com2.code_cost) /
                1000000,
              plan_cost_target_cum:
                (((com.uxesg_SiteArea * com2.uxesg_factor * com2.factor_cum) /
                  100) *
                  ((100 - com.uxesg_target) / 100) *
                  com2.code_cost) /
                1000000,
              act_weight: Number(
                _.sumBy(com2.performance_data, "uxesg_CarryQty2").toFixed(0)
              ),
              act_cost: Number(
                (
                  _.sumBy(com2.performance_data, "uxesg_CostTotal") / 1000000
                ).toFixed(0)
              ),
            })),
        }))
        .map((com) => ({
          ...com,
          present_plan_data:
            com.present_plan_data.length > 0
              ? com.present_plan_data
              : base_code
                  .map((com2) => ({
                    ...com2,
                    performance_data: com.performance_data
                      .filter((com3) => com3.uxesg_WasteCode === com2.code)
                      .map((com3) => ({
                        ...com3,
                        code_cost_sum:
                          com3.uxesg_DispCost + com3.uxesg_TranCost,
                      })),
                  }))
                  .map((com2) => ({
                    ...com2,
                    code_cost:
                      _.maxBy(com2.performance_data, "code_cost_sum")?.[
                        "code_cost_sum"
                      ] === undefined
                        ? 0
                        : _.maxBy(com2.performance_data, "code_cost_sum")?.[
                            "code_cost_sum"
                          ],
                  }))
                  .map((com2) => ({
                    ...com2,
                    plan_weight: 0,
                    plan_weight_cum: 0,
                    plan_target_weight: 0,
                    plan_target_weight_cum: 0,
                    plan_cost: 0,
                    plan_cost_cum: 0,
                    plan_cost_target: 0,
                    plan_cost_target_cum: 0,
                    act_weight: Number(
                      _.sumBy(com2.performance_data, "uxesg_CarryQty2").toFixed(
                        0
                      )
                    ),
                    act_cost: Number(
                      (
                        _.sumBy(com2.performance_data, "uxesg_CostTotal") /
                        1000000
                      ).toFixed(0)
                    ),
                  })),
        }))
        .map((com) => ({
          ...com,
          project_total_plan_weight:
            com.present_plan_data.length > 0
              ? Number(
                  _.sumBy(com.present_plan_data, "plan_weight_cum").toFixed(0)
                )
              : 0,
          project_total_plan_cost:
            com.present_plan_data.length > 0
              ? Number(
                  _.sumBy(com.present_plan_data, "plan_cost_cum").toFixed(0)
                )
              : 0,
          project_total_target_weight:
            com.present_plan_data.length > 0
              ? Number(
                  _.sumBy(
                    com.present_plan_data,
                    "plan_target_weight_cum"
                  ).toFixed(0)
                )
              : 0,
          project_total_target_cost:
            com.present_plan_data.length > 0
              ? Number(
                  _.sumBy(
                    com.present_plan_data,
                    "plan_cost_target_cum"
                  ).toFixed(0)
                )
              : 0,
          project_total_act_weight: Number(
            _.sumBy(com.performance_data, "uxesg_CarryQty2").toFixed(0)
          ),
          project_total_act_cost: Number(
            (
              _.sumBy(com.performance_data, "uxesg_CostTotal") / 1000000
            ).toFixed(0)
          ),
        }))
        .map((com) => ({
          ...com,
          warning:
            com.project_total_target_weight >= com.project_total_act_weight,
        }));

      setProjectListData(projectInformation_final);

      setProjectActTotalData(result_performance_data);
    };
    fetchData();
  }, []);

  const gotoReportPage = () => {
    navigate.push({
      pathname:`/hanwhaenv/overallreport`,
      state: {
        dateRange,
        overallTable,
        sectorTable,
        targetPerformance,
        targetManbPerformance,
        projectListData,
        projectActTotalData,
      },
    });
  };

  useEffect(() => {
    const tiles = [
      {
        item: (
          <OverallInfoPage
            width={width - 20}
            height={((iframe_boolean ? height : height - 210) - 30) * 0.1}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            dateRange={dateRange}
            setDateRange={setDateRange}
            gotoReportPage={gotoReportPage}
            projectActTotalData={projectActTotalData}
          />
        ),
        reorderable: false,
      },
      {
        item: selectedValue ? (
          <OverallTotalPage
            width={(width - 20) * 0.5}
            height={((iframe_boolean ? height : height - 210) - 20) * 0.9}
            projectListData={projectListData}
            projectActTotalData={projectActTotalData}
            setOverallTable={setOverallTable}
            setSectorTable={setSectorTable}
            setTargetPerformance={setTargetPerformance}
            setTargetManbPerformance={setTargetManbPerformance}
          />
        ) : (
          <OverallDateRagePage
            width={(width - 20) * 0.5}
            height={((iframe_boolean ? height : height - 210) - 20) * 0.9}
            dateRange={dateRange}
            projectListData={projectListData}
            projectActTotalData={projectActTotalData}
          />
        ),
        reorderable: false,
      },
    ];

    setTiles(tiles);
  }, [
    selectedValue,
    dateRange,
    width,
    height,
    projectListData,
    projectActTotalData,
  ]);

  return (
    <div>
      <div className="projectDetailPage">
      <TileLayout
          columns={1}
          rowHeight={(height - 210) / 12}
          positions={data}
          gap={{
            rows: 10,
            columns: 10,
          }}
          items={tiles}
          style={{ height: height - 10, width: "calc(100% - 210)" }}
        />
      </div>
    </div>
  );
};

export default OverallPage;

const data = [
  {
    col: 1,
    colSpan: 1,
    rowSpan: 1,
  },
  {
    col: 1,
    colSpan: 1,
    rowSpan: 12,
  },
];

const change_sector_name = (name) => {
  const find_sector = sector_arr.find((com) => com.sectors.includes(name));

  return find_sector === undefined ? name : find_sector["sector"];
};

const create_percent_arr = (wasteCoefficient) => {
  const percentArr = [];

  wasteCoefficient.forEach((com) => {
    for (let i = 1; i <= 21; i++) {
      percentArr.push({
        cate: com.uxesg_PJTCate,
        code: com.uxesg_WasteCode,
        name: com.uxesg_WasteCate,
        uxesg_factor: com.uxesg_factor,
        before_percent: i === 1 ? 0 : com[`uxesg_ProcessRatio${i - 1}`],
        percent: com[`uxesg_ProcessRatio${i}`],
        factor: com[`uxesg_factor2_${i}`],
        factor_cum: com[`uxesg_factor3_${i}`],
      });
    }
  });

  return _.sortBy(percentArr, "percent");
};

const base_code = [
  { code: "40-01-01", name: "폐콘크리트" },
  { code: "40-02-07", name: "폐합성수지" },
  { code: "40-02-06", name: "폐목재" },
  { code: "40-04-14", name: "혼합건설폐기물" },
];

const base_code_arr = ["40-01-01", "40-02-07", "40-02-06", "40-04-14"];
