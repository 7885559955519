import React, { useMemo } from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { Milestones } from "react-milestones-vis";
import {
  SubTitleDiv,
  SubTitleCountDiv,
} from "../../../Public/StyledComponents";
import moment from "moment";

const PreventiveMaintenance = ({ height, pmBooksData, next_months }) => {
  const milestoneData = useMemo(() => {
    return pmBooksData.map((com) => ({
      date: moment(com.due_date).format("YYYY-MM-DD"),
      title: `${com.ujpJobPlanName} (${com.upmEqpName})`,
    }));
  }, [pmBooksData]);

  return (
    <>
      <GridLayoutItem row={1} col={2}>
        <SubTitleDiv text_position={"flex-start"}>예방정비 예정 건</SubTitleDiv>
      </GridLayoutItem>

      <GridLayoutItem row={2} col={2}>
        <SubTitleCountDiv text_position={"flex-start"}>
          {pmBooksData.length} 건
        </SubTitleCountDiv>
      </GridLayoutItem>

      <GridLayoutItem
        row={3}
        col={2}
        rowSpan={2}
        style={{ padding: "0px 10px" }}
      >
        <Milestones
          aggregateBy="day"
          data={[
            ...milestoneData,
            { date: moment(new Date()).format("YYYY-MM-DD"), title: "" },
            { date: moment(next_months).format("YYYY-MM-DD"), title: "" },
          ]}
          mapping={{
            text: "title",
            timestamp: "date",
          }}
          parseTime="%Y-%m-%d"
          distribution="top-bottom"
          optimize={true}
          autoResize={true}
        />
      </GridLayoutItem>
    </>
  );
};

export default PreventiveMaintenance;
