import React, { useMemo } from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  SubTitleDiv,
  SubTitleCountDiv,
  SpaceBetweenDiv,
  headerClass,
  LGChemicalTable,
} from "../../../Public/StyledComponents";
import { normalCell, listNumberCell } from "../../../Public/TableCell";

const InProgressMaintenance = ({
  height,
  correctiveWorkOrderData,
  preventiveWorkOrderData,
}) => {
  const correctiveWorkOrderData_in_grogress = useMemo(() => {
    return correctiveWorkOrderData
      .filter((com) => com.usrInprogressStatusTB === "In Progress")
      .map((com, idx) => ({ ...com, No: idx + 1 }));
  }, [correctiveWorkOrderData]);

  const preventiveWorkOrderData_in_grogress = useMemo(() => {
    return preventiveWorkOrderData
      .filter((com) => com.status === "In_Progress")
      .map((com, idx) => ({ ...com, No: idx + 1 }));
  }, [preventiveWorkOrderData]);

  return (
    <>
      <GridLayoutItem row={1} col={1}>
        <SubTitleDiv text_position={"flex-start"}>진행 중 정비</SubTitleDiv>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={1}>
        <SpaceBetweenDiv>
          <SubTitleCountDiv text_position={"center"}>
            돌발 {correctiveWorkOrderData_in_grogress.length} 건
          </SubTitleCountDiv>
          <SubTitleCountDiv text_position={"center"}>
            예방 {preventiveWorkOrderData_in_grogress.length} 건
          </SubTitleCountDiv>
        </SpaceBetweenDiv>
      </GridLayoutItem>
      <GridLayoutItem row={3} col={1}>
        <LGChemicalTable>
          <Grid
            className={headerClass}
            style={{ width: "100%", maxHeight: height * 0.3 }}
            data={correctiveWorkOrderData_in_grogress}
          >
            <GridColumn
              title="No."
              field="No"
              width={45}
              cell={listNumberCell}
            />
            <GridColumn
              title="Work Order Name"
              field="title"
              cell={normalCell}
            />
            <GridColumn
              title="대상 자산"
              field="upmEqpName"
              cell={normalCell}
            />
            <GridColumn
              title="긴급 여부"
              field="uworEmergencyPD"
              width={80}
              cell={uworEmergencyPDCell}
            />
            <GridColumn
              title="Status"
              field="usrMaterialRqrStatusTB"
              cell={normalCell}
              width={100}
            />
          </Grid>
        </LGChemicalTable>
      </GridLayoutItem>

      <GridLayoutItem row={4} col={1}>
        <LGChemicalTable>
          <Grid
            className={headerClass}
            style={{ width: "100%", maxHeight: height * 0.3 }}
            data={preventiveWorkOrderData_in_grogress}
          >
            <GridColumn
              title="No."
              field="No"
              width={45}
              cell={listNumberCell}
            />
            <GridColumn
              title="Work Order Name"
              field="ujpJobPlanActivityTxt2000"
              cell={normalCell}
            />
            <GridColumn
              title="대상 자산"
              field="upmEqpName"
              cell={normalCell}
            />
            <GridColumn
              title="Status"
              field="status"
              width={100}
              cell={normalCell}
              
            />
          </Grid>
        </LGChemicalTable>
      </GridLayoutItem>
    </>
  );
};

export default InProgressMaintenance;

const uworEmergencyPDCell = (props) => {
  const { field, dataItem } = props;

  return (
    <td colSpan={props.colSpan} style={{ ...props.style, fontSize: "0.7rem" }}>
      {dataItem[field] === "Yes" ? "Urgent" : ""}
    </td>
  );
};
