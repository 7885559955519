import React, { useEffect, useState } from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartTooltip,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import "hammerjs";
import _ from "lodash";
import styled from "styled-components";
import DateRangeInput from "./DateRangeInput";
import {
  SubTitleDiv,
  // SubTitleCountDiv,
  // SpaceBetweenDiv,
  // headerClass,
  // LGChemicalTable,
} from "../../../Public/StyledComponents";

const PurchaseStatus = ({
  height,
  dateRange,
  handleDateRange,
  purchaseOrdersFMData_Original,
  materialsReceivedData_Original,
}) => {
  const [purchaseOrderData, setPurchaseOrderData] = useState({
    purchase: {
      price: 0,
      count: 0,
    },
    recevied: 0,
    not_recevied: 0,
  });

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (
      Object.keys(dateRange).filter((com) => dateRange[com] === null).length ===
      0
    ) {
      const FMData_filter = purchaseOrdersFMData_Original.filter(
        (com) =>
          com.effective_date >= dateRange.start &&
          com.effective_date <= dateRange.end
      );

      const recevied_FMData = FMData_filter.filter((com) => {
        const find_obj = materialsReceivedData_Original.find(
          (com2) => com2.uponumber === com.record_no
        );

        return find_obj;
      });

      setPurchaseOrderData((prev) => ({
        ...prev,
        purchase: {
          price: Number(
            (_.sumBy(FMData_filter, "amount") / 100000000).toFixed(1)
          ).toLocaleString(),
          count: FMData_filter.length,
          list: _.sum(FMData_filter.map((com) => com._bp_lineitems.length)),
        },
        recevied: {
          count: recevied_FMData.length,
          list: _.sum(recevied_FMData.map((com) => com._bp_lineitems.length)),
        },
        not_recevied: FMData_filter.length - recevied_FMData.length,
      }));

      setChartData([
        {
          name: "입고 완료",
          color: "rgb(31, 56, 100)",
          count: recevied_FMData.length,
        },
        {
          name: "입고 지연",
          color: "rgb(160, 160, 160)",
          count: FMData_filter.length - recevied_FMData.length,
        },
      ]);
    }
  }, [
    dateRange,
    purchaseOrdersFMData_Original,
    materialsReceivedData_Original,
  ]);

  const renderTooltip = (context) => {
    const { category, value } = context.point || context;
    return (
      <div>
        {category}: {value}건
      </div>
    );
  };

  const labelContent = (e) => {
    return `${e.category}: \n ${e.value}건`;
  };

  return (
    <>
      <GridLayoutItem row={1} col={1}>
        <SubTitleDiv text_position={"flex-start"}>
          구매 및 입고 현황
        </SubTitleDiv>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={1}>
        <PurchaseStatusBox>
          <PurchaseStatusDetail location={"left"}>
            구매 오더
          </PurchaseStatusDetail>
          <PurchaseStatusDetail location={"right"}>
            {purchaseOrderData.purchase.price}억원 /{" "}
            {purchaseOrderData.purchase.count}건 /{" "}
            {purchaseOrderData.purchase.list}품목
          </PurchaseStatusDetail>
        </PurchaseStatusBox>

        <PurchaseStatusBox>
          <PurchaseStatusDetail location={"left"}>입고</PurchaseStatusDetail>
          <PurchaseStatusDetail location={"right"}>
            {purchaseOrderData.recevied.count}건 /{" "}
            {purchaseOrderData.recevied.list}품목
          </PurchaseStatusDetail>
        </PurchaseStatusBox>

        <PurchaseStatusBox>
          <PurchaseStatusDetail location={"left"}>
            입고 지연
          </PurchaseStatusDetail>
          <PurchaseStatusDetail location={"right"}>
            {purchaseOrderData.not_recevied}건
          </PurchaseStatusDetail>
        </PurchaseStatusBox>
      </GridLayoutItem>
      <GridLayoutItem row={1} col={2}>
        <SubTitleDiv2 text_position={"flex-end"}>
          <DateRangePicker
            style={{ height: "15px", padding: "0" }}
            startDateInput={DateRangeInput}
            endDateInput={DateRangeInput}
            onChange={(e) => {
              handleDateRange(e);
            }}
            format={"yyyy-MM-dd"}
            value={dateRange}
          />
        </SubTitleDiv2>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={2}>
        <Chart style={{ height: height * 0.4 }}>
          <ChartTooltip render={renderTooltip} />
          <ChartArea background="none" margin={30} />
          <ChartSeries>
            <ChartSeriesItem
              type="donut"
              holeSize={height * 0.4 * 0.15}
              data={chartData}
              categoryField="name"
              field="count"
            >
              <ChartSeriesLabels
                position="outsideEnd"
                background="none"
                content={labelContent}
              />
            </ChartSeriesItem>
          </ChartSeries>
          <ChartLegend visible={true} position={"right"} />
        </Chart>
      </GridLayoutItem>
    </>
  );
};

export default PurchaseStatus;

const PurchaseStatusBox = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => props.height}px;
`;

const PurchaseStatusDetail = styled.div`
  margin-bottom: 10px;
  width: ${(props) => (props.location === "left" ? "40%" : "60%")};
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) =>
    props.location === "left" ? "rgb(92, 92, 92)" : "rgb(68, 130, 132)"};
`;

const SubTitleDiv2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.text_position};
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(92, 92, 92);

  .k-daterange-picker,
  .k-daterangepicker {
    width: 300px;
  }
`;
