import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import ReactToPrint from "react-to-print";
import moment from "moment";
import _ from "lodash";
import "./ReportPage.scss";
import PercentDetailChart from "./Chart/PercentDetailChart";
import ManbDetailChart from "./Chart/ManbDetailChart";
import MonthPerformanceChart from "./Chart/MonthPerformanceChart";
import MonthIncreaseChart from "./Chart/MonthIncreaseChart";

const ReportPage = () => {
  const location = useLocation();
  // const { date, project, sector, actMonth } = location.state;
  const printRef = useRef();
  const [projectInfo, setProjectInfo] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [percentChart, setPercentChart] = useState([]);
  const [manbChart, setManbChart] = useState([]);
  const [monthPerformanceChart, setMonthPerformanceChart] = useState([]);
  const [monthIncreaseChart, setMonthIncreaseChart] = useState([]);
  const [appendixRange, setAppendixRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [projectTarget, setProjectTarget] = useState({
    latest_percent_act: 0,
    projectCost: 0,
    target: 0,
    totalWeight: 0,
    totalCost: 0,
    target_total_weight: 0,
    target_total_cost: 0,
    planWeight: 0,
    planCost: 0,
    targetWeight: 0,
    targetCost: 0,
    latest_plan_weight: 0,
    latest_plan_cost: 0,
    latest_target_weight: 0,
    latest_target_cost: 0,
  });

  const [userNameToggle, setUserNameToggle] = useState(false);
  const [userName, setUserName] = useState("");
  const [userCommentToggle, setUserCommentToggle] = useState(false);
  const [userComment, setUserComment] = useState("");

  useEffect(() => {
    const {
      project_info,
      projectActTotalData,
      reportChartData_manb,
      reportChartData_percent,
      targetProject,
      projectTargetPercent,
      projectAct,
      dateRange,
    } = location.state;

    setProjectInfo(project_info);
    setManbChart(reportChartData_manb);
    setPercentChart(reportChartData_percent);
    setAppendixRange(dateRange);

    const table_data = _.sortBy(
      projectActTotalData
        .map((com) => ({
          ...com,
          date: new Date(com.uxesg_CarryDate.slice(0, 10)),
        }))
        .filter(
          (com) =>
            new Date(com.uxesg_CarryDate.slice(0, 10)) >= dateRange.startDate &&
            new Date(com.uxesg_CarryDate.slice(0, 10)) <= dateRange.endDate
        )
        .filter((com) => com.uxesg_manb_type === "확정/입력"),
      "date"
    ).map((com, idx) => ({
      ...com,
      id: idx + 1,
      moment_date: moment(com.date).format("YYYY. MM. DD"),
      price_sum: com.uxesg_DispCost + com.uxesg_TranCost,
    }));

    const sum_data = {
      moment_date: "합  계",
      uxesg_CarryQty2: _.sumBy(table_data, "uxesg_CarryQty2").toFixed(2),
      uxesg_TranCostTotal: _.sumBy(table_data, "uxesg_TranCostTotal"),
      uxesg_DispCostTotal: _.sumBy(table_data, "uxesg_DispCostTotal"),
      uxesg_CostTotal: _.sumBy(table_data, "uxesg_CostTotal"),
    };

    setTableData([...table_data, sum_data]);

    const target_data_arr = targetProject.data_arr
      .map((com) => ({
        ...com,
        base_cost:
          _.maxBy(
            projectActTotalData
              .filter((com2) => com2.uxesg_WasteCode === com.code)
              .map((com2) => ({
                ...com2,
                sum_cost: com2.uxesg_DispCost + com2.uxesg_TranCost,
              })),
            "sum_cost"
          )?.["sum_cost"] === undefined
            ? 0
            : _.maxBy(
                projectActTotalData
                  .filter((com2) => com2.uxesg_WasteCode === com.code)
                  .map((com2) => ({
                    ...com2,
                    sum_cost: com2.uxesg_DispCost + com2.uxesg_TranCost,
                  })),
                "sum_cost"
              )?.["sum_cost"],
      }))
      .map((com) => ({
        ...com,
        plan_cost: com.base_cost * com.plan_weight,
        target_cost: com.base_cost * com.target_weight,
      }));

    const last_percentData = [];

    target_data_arr.forEach((com) =>
      last_percentData.push({
        ...com.percent_data.find((com2) => com2.percent === 100),
        base_cost: com.base_cost,
      })
    );

    const { uxesg_SiteArea, uxesg_PJTEsgCost } = project_info;

    const project_target = last_percentData
      .map((com) => ({
        ...com,
        planWeight: (uxesg_SiteArea * com.uxesg_factor * com.factor_cum) / 100,
      }))
      .map((com) => ({
        ...com,
        targetWeight: (com.planWeight * (100 - projectTargetPercent)) / 100,
      }))
      .map((com) => ({
        ...com,
        planCost: com.planWeight * com.base_cost,
        targetCost: com.targetWeight * com.base_cost,
      }));

    console.log(project_target);

    setProjectTarget((prev) => ({
      ...prev,
      latest_percent_act: projectAct.percent_act,
      projectCost: uxesg_PJTEsgCost,
      target: projectTargetPercent,
      totalWeight: _.sumBy(projectActTotalData, "uxesg_CarryQty2"),
      totalCost: _.sumBy(projectActTotalData, "uxesg_CostTotal"),
      target_total_weight: Number(
        _.sumBy(
          projectActTotalData.filter((com) =>
            base_code.includes(com.uxesg_WasteCode)
          ),
          "uxesg_CarryQty2"
        ).toFixed(0)
      ),
      target_total_cost: Number(
        (
          _.sumBy(
            projectActTotalData.filter((com) =>
              base_code.includes(com.uxesg_WasteCode)
            ),
            "uxesg_CostTotal"
          ) / 1000000
        ).toFixed(0)
      ),
      planWeight: Number(_.sumBy(project_target, "planWeight").toFixed(1)),
      planCost: Number(
        (_.sumBy(project_target, "planCost") / 1000000).toFixed(1)
      ),
      targetWeight: Number(_.sumBy(project_target, "targetWeight").toFixed(1)),
      targetCost: Number(
        (_.sumBy(project_target, "targetCost") / 1000000).toFixed(1)
      ),
      latest_plan_weight: Number(
        _.sumBy(target_data_arr, "plan_weight").toFixed(0)
      ),
      latest_plan_cost: Number(
        (_.sumBy(target_data_arr, "plan_cost") / 1000000).toFixed(0)
      ),
      latest_target_weight: Number(
        _.sumBy(target_data_arr, "target_weight").toFixed(0)
      ),
      latest_target_cost: Number(
        (_.sumBy(target_data_arr, "plan_cost") / 1000000).toFixed(0)
      ),
    }));
    const endDate = moment(new Date()).format("YYYY-MM-DD");
    const startDate = moment(new Date())
      .subtract(11, "months")
      .format("YYYY-MM-01");

    const monthsDateRange = createDateRange(startDate, endDate);

    const projectActTotalData_2 = projectActTotalData
      .filter(
        (com) =>
          com.uxesg_RegiCate === "건설폐기물" &&
          com.uxesg_PresentWaste === "본공사"
      )
      .map((com) => ({
        ...com,
        carryMonth: moment(new Date(com.uxesg_CarryDate.slice(0, 10))).format(
          "YYYY-MM"
        ),
      }));

    const monthsRangeData_2 = monthsDateRange
      .map((com) => ({
        ...com,
        data: projectActTotalData_2.filter(
          (com2) => com2.carryMonth === com.months
        ),
      }))
      .map((com) => ({
        ...com,
        value: _.sumBy(com.data, "uxesg_CarryQty2"),
        cost: Number(
          (_.sumBy(com.data, "uxesg_CostTotal") / 1000000).toFixed(0)
        ),
      }));

    setMonthPerformanceChart(monthsRangeData_2);

    const dateRangeData_3 = base_code2
      .map((com) => {
        if (com.code === "others") {
          return {
            ...com,
            data: projectActTotalData_2.filter(
              (com2) =>
                com2.uxesg_WasteCode !== "40-01-01" &&
                com2.uxesg_WasteCode !== "40-02-07" &&
                com2.uxesg_WasteCode !== "40-02-06" &&
                com2.uxesg_WasteCode !== "40-04-14"
            ),
          };
        } else {
          return {
            ...com,
            data: projectActTotalData_2.filter(
              (com2) => com2.uxesg_WasteCode === com.code
            ),
          };
        }
      })
      .map((com) => ({
        ...com,
        data:
          com.data.length > 0
            ? _.sortBy(
                com.data.map((com2) => ({
                  ...com2,
                  carryDate: new Date(com2.uxesg_CarryDate.slice(0, 10)),
                  carryMonth: moment(
                    new Date(com2.uxesg_CarryDate.slice(0, 10))
                  ).format("YYYY-MM"),
                  carryMonth2: moment(
                    new Date(com2.uxesg_CarryDate.slice(0, 10))
                  ).format("YY-MM"),
                })),
                "carryDate"
              )
            : [],
      }))
      .map((com) => ({
        ...com,
        data2: _.uniqBy(com.data, "carryMonth").map((com2) => ({
          date: com2.carryDate,
          month: com2.carryMonth,
          month2: com2.carryMonth2,
          value: _.sumBy(
            com.data.filter((com3) => com3.carryMonth === com2.carryMonth),
            "uxesg_CarryQty2"
          ),
          cost: _.sumBy(
            com.data.filter((com3) => com3.carryMonth === com2.carryMonth),
            "uxesg_CostTotal"
          ),
        })),
      }));

    const monthsData = dateRangeData_3.map((com) => {
      const findIndex = com.data2.findIndex(
        (com2) => com2.month === moment(new Date(endDate)).format("YYYY-MM")
      );

      const findIndex2 = com.data2.findIndex(
        (com2) =>
          com2.month ===
          moment(new Date(endDate)).subtract(1, "months").format("YYYY-MM")
      );

      return {
        ...com,
        data3: [
          {
            month: moment(new Date(endDate))
              .subtract(1, "months")
              .format("YYYY-MM"),
            value:
              com.data2[findIndex2]?.["value"] === undefined
                ? 0
                : com.data2[findIndex2]?.["value"],
            cost:
              com.data2[findIndex2]?.["cost"] === undefined
                ? 0
                : com.data2[findIndex2]?.["cost"],
          },
          {
            month: moment(new Date(endDate)).format("YYYY-MM"),
            value:
              com.data2[findIndex]?.["value"] === undefined
                ? 0
                : com.data2[findIndex]?.["value"],
            cost:
              com.data2[findIndex]?.["cost"] === undefined
                ? 0
                : com.data2[findIndex]?.["cost"],
          },
        ],
      };
    });

    setMonthIncreaseChart(monthsData);
  }, [location]);

  const handleUserName = (e, type) => {
    if (type === "name") {
      setUserName(e.target.value);
    } else {
      setUserComment(e.target.value);
    }
  };

  const handleUserToggleKey = (e, type) => {
    if (type === "name") {
      if (e.code === "Enter" || e.code === "Tab" || e.code === "Escape") {
        setUserNameToggle(true);
      }
    } else {
      if (e.code === "Tab" || e.code === "Escape") {
        setUserCommentToggle(true);
      }
    }
  };

  const handleUserToggleClick = (type) => {
    if (type === "name") {
      setUserNameToggle(false);
    } else {
      setUserCommentToggle(false);
    }
  };

  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];

    if (
      field === "uxesg_CarryQty2" ||
      field === "uxesg_TranCostTotal" ||
      field === "uxesg_DispCostTotal" ||
      field === "uxesg_CostTotal"
    ) {
      return (
        <td style={{ fontSize: "0.7rem", textAlign: "left" }}>
          {field === "uxesg_CarryQty2"
            ? Number(Number(cell).toFixed(0)).toLocaleString() + "t"
            : Number(Number(cell).toFixed(0)).toLocaleString() + "원"}
        </td>
      );
    } else {
      return <td style={{ fontSize: "0.7rem", textAlign: "left" }}>{cell}</td>;
    }
  };

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button style={{ margin: "10px 0px 0px 10px" }}>
            <span className="k-icon k-i-print" style={{ fontSize: "24px" }} />
          </button>
        )}
        content={() => printRef.current}
      />
      <div className="reportPageTotal" ref={printRef}>
        <div className="reportPage">
          <div className="reportHeader">
            <div className="reportTitle">
              {moment(new Date()).format("YYYY년 MM월")} ESG Report (폐기물 배출
              실적)
            </div>
            <div className="reportSignBox">
              <div className="reportSignBoxLeft">결재</div>
              <div className="reportSignBoxRight">
                <div className="reportSignBoxDetail">
                  <div className="reportSignBoxDetailTitle">작성</div>
                </div>
                <div className="reportSignBoxDetail">
                  <div className="reportSignBoxDetailTitle">검토</div>
                </div>
                <div className="reportSignBoxDetail">
                  <div className="reportSignBoxDetailTitle">승인</div>
                </div>
              </div>
            </div>
          </div>

          <div className="reportBlank" />

          <div className="reportSiteInfo">
            <div className="reportSiteInfoTitle">현장명</div>
            <div className="reportSiteInfoContent">
              {projectInfo && projectInfo.uxesg_PJTName}
            </div>
            <div className="reportSiteInfoTitle">담당자</div>
            {userNameToggle ? (
              <div
                className="reportSiteInfoContent"
                onClick={() => handleUserToggleClick("name")}
              >
                {userName}
              </div>
            ) : (
              <input
                className="reportSiteInfoContent"
                onChange={(e) => handleUserName(e, "name")}
                onKeyDown={(e) => handleUserToggleKey(e, "name")}
                value={userName}
              />
            )}

            <div className="reportSiteInfoTitle">작성일자</div>
            <div className="reportSiteInfoContent">
              {moment(new Date()).format("YYYY. MM. DD")}
            </div>
          </div>

          <div className="reportBlank" />

          <div className="reportIndex">1. 폐기물 발생량 예측 및 목표</div>

          <div className="reportBlank" />

          <div className="reportTargetPlan">
            <div className="reportTargetPlanBox2">
              <div className="reportTargetPlanBox title">폐기물 발생량예측</div>
              <div className="reportTargetPlanBox contents">
                {projectTarget.planWeight.toLocaleString()}톤/{" "}
                {projectTarget.planCost.toLocaleString()}백만원
              </div>
            </div>
            <div className="reportTargetPlanBox2">
              <div className="reportTargetPlanBox title">저감목표</div>
              <div className="reportTargetPlanBox contents2">
                {projectTarget.target}%
              </div>
            </div>

            <div className=" reportTargetPlanBox arrow">
              <span className="k-icon k-i-arrow-60-right"></span>
            </div>
            <div className="reportTargetPlanBox2">
              <div className="reportTargetPlanBox title">폐기물 배출목표</div>
              <div className="reportTargetPlanBox contents">
                {projectTarget.targetWeight.toLocaleString()}톤/{" "}
                {projectTarget.targetCost.toLocaleString()}백만원
              </div>
            </div>
          </div>

          <div className="reportBlank" />

          <div className="reportIndex">2. 현재 목표/실적(공정률 대비)</div>

          <div className="reportBlank" />

          <div className="reportTable">
            <table>
              <thead>
                <tr>
                  <th>공정률</th>
                  <th>배출목표</th>
                  <th>누적 배출량</th>
                  <th>차이</th>
                  <th>달성률</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{projectTarget.latest_percent_act}%</td>
                  <td>
                    {projectTarget.latest_target_weight.toLocaleString()}t{" "}
                    <br />
                    {projectTarget.latest_target_cost.toLocaleString()}백만원
                  </td>
                  <td>
                    {projectTarget.target_total_weight.toLocaleString()}t <br />
                    {projectTarget.target_total_cost.toLocaleString()}백만원
                  </td>
                  <td>
                    {(
                      projectTarget.latest_target_weight -
                      projectTarget.target_total_weight
                    ).toLocaleString()}
                    t <br />
                    {(
                      projectTarget.latest_target_cost -
                      projectTarget.target_total_cost
                    ).toLocaleString()}
                    백만원
                  </td>
                  <td>
                    {(
                      (projectTarget.target_total_weight /
                        projectTarget.latest_target_weight) *
                      100
                    ).toFixed(0)}
                    %
                    <br />
                    {(
                      (projectTarget.target_total_cost /
                        projectTarget.latest_target_cost) *
                      100
                    ).toFixed(0)}
                    %
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="reportBlank" />

          <div className="reportIndex">3. 폐기물 실행 / 잔여예산</div>

          <div className="reportBlank" />

          <div className="reportTable2">
            <table>
              <thead>
                <tr>
                  <th>폐기물 처리비(총 실행)</th>
                  <th>집행실적(누계)</th>
                  <th>잔여예산</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{projectTarget.projectCost.toLocaleString()}원</td>
                  <td>{projectTarget.totalCost.toLocaleString()}원</td>
                  <td>
                    {(
                      projectTarget.projectCost - projectTarget.totalCost
                    ).toLocaleString()}
                    원
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="reportBlank" />

          <div className="reportChartBox">
            <div className="reportChartBoxDetail detail1">
              <div className="reportChartTitle">공정률 대비 배출현황</div>
              <div className="reportChartContent">
                <PercentDetailChart height={242} chartData={percentChart} />
              </div>
            </div>
            <div className="reportChartBoxDetail detail2">
              <div className="reportChartTitle">성상별 배출현황</div>
              <div className="reportChartContent">
                <ManbDetailChart height={242} chartData={manbChart} />
              </div>
            </div>
            <div className="reportChartBoxDetail detail1">
              <div className="reportChartTitle">월간 배출량</div>
              <div className="reportChartContent">
                <MonthPerformanceChart
                  height={242}
                  chartData={monthPerformanceChart}
                  endDate={moment(new Date()).format("YYYY-MM")}
                  MonthPerformanceToggle={true}
                />
              </div>
            </div>
            <div className="reportChartBoxDetail detail2">
              <div className="reportChartTitle">월간 성상별 증감현황</div>
              <div className="reportChartContent">
                <MonthIncreaseChart
                  height={242}
                  chartData={monthIncreaseChart}
                  endDate={moment(new Date()).format("YYYY-MM")}
                />
              </div>
            </div>
          </div>

          <div className="reportBlank" />

          <div className="reportComment">
            <div className="reportCommentBox">
              <div
                className="reportCommentTitle"
                style={{ backgroundColor: "rgb(48, 84, 150)" }}
              >
                실적 분석
              </div>
              {userCommentToggle ? (
                <div
                  className="reportUserComment"
                  onClick={() => handleUserToggleClick("comment")}
                >
                  {userComment}
                </div>
              ) : (
                <textarea
                  className="reportUserComment"
                  onChange={(e) => handleUserName(e, "comment")}
                  onKeyDown={(e) => handleUserToggleKey(e, "comment")}
                  value={userComment}
                />
              )}
            </div>
            <div className="reportCommentBox">
              <div
                className="reportCommentTitle"
                style={{ backgroundColor: "black" }}
              >
                현장소장 <br />
                의견 및 지시사항
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="reportPage">
          <div className="reportIndex">
            Appendix. 일자별 폐기물 배출 현황 (
            {moment(appendixRange.startDate).format("YY. MM. DD")} ~{" "}
            {moment(appendixRange.endDate).format("YY. MM. DD")})
          </div>

          <div className="reportBlank" />

          <Grid data={tableData}>
            <GridColumn
              field="moment_date"
              title="처리일자"
              width="80px"
              cell={normalcell}
            />
            <GridColumn
              field="uxesg_WastePropertie"
              title="성상"
              width="100px"
              cell={normalcell}
            />
            <GridColumn
              field="uxesg_CarryQty2"
              title="처리량"
              width="65px"
              cell={normalcell}
            />
            <GridColumn
              field="uxesg_UoM"
              title="단위"
              width="45px"
              cell={normalcell}
            />
            <GridColumn title="운반">
              <GridColumn
                field="uxesg_TranVendor"
                title="운반자"
                width="80px"
                cell={normalcell}
              />
              <GridColumn
                field="uxesg_TranCostTotal"
                title="비용"
                width="89px"
                cell={normalcell}
              />
            </GridColumn>
            <GridColumn title="처리">
              <GridColumn
                field="uxesg_DispVendor"
                title="처리자"
                width="80px"
                cell={normalcell}
              />
              <GridColumn
                field="uxesg_WasteDisposal"
                title="처리방법"
                width="80px"
                cell={normalcell}
              />
              <GridColumn
                field="uxesg_DispCostTotal"
                title="비용"
                width="89px"
                cell={normalcell}
              />
            </GridColumn>
            <GridColumn
              field="uxesg_CostTotal"
              title="합계금액"
              width="89px"
              cell={normalcell}
            />
          </Grid>
        </div>
      </div>
    </>
  );
};

export default ReportPage;

// const color = {
//   green: "rgb(169, 208, 142)",
//   red: "rgb(244, 178, 132)",
// };

const base_code = ["40-01-01", "40-02-07", "40-02-06", "40-04-14"];

const createDateRange = (startDate, endDate) => {
  const monthDiff = moment(new Date(endDate)).diff(
    new Date(startDate),
    "months"
  );

  const monthRange = [];

  for (let i = 0; i <= monthDiff; i++) {
    monthRange.push({
      months: moment(startDate).add(i, "months").format("YYYY-MM"),
    });
  }

  return monthRange;
};

const base_code2 = [
  { code: "40-01-01", name: "폐콘크리트" },
  { code: "40-02-07", name: "폐합성수지" },
  { code: "40-02-06", name: "폐목재" },
  { code: "40-04-14", name: "혼합건설폐기물" },
  { code: "others", name: "기타" },
];
