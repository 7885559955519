import React, { useEffect, useState } from "react";
import { TileLayout } from "@progress/kendo-react-layout";
import TotalPerformance from "./TotalPerformance/TotalPerformance";
import MonthPerformance from "./MonthPerformance/MonthPerformance";
import MonthIncrease from "./MonthIncrease/MonthIncrease";
import moment from "moment";

const DateRangePage = ({ width, height, projectActTotalData, dateRange }) => {
  const [dateRangeData, setDateRangeData] = useState([]);
  const [monthsRangeData, setMonthsRangeData] = useState([]);

  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    const datefilterData = projectActTotalData.filter(
      (com) =>
        new Date(com.uxesg_CarryDate.slice(0, 10)) >= dateRange.startDate &&
        new Date(com.uxesg_CarryDate.slice(0, 10)) <= dateRange.endDate
    );

    setDateRangeData(datefilterData);

    const monthRange = createDateRange(dateRange.startDate, dateRange.endDate);

    setMonthsRangeData(monthRange);
  }, [projectActTotalData, dateRange]);

  useEffect(() => {
    const tiles = [
      {
        header: <div>누적 배출현황</div>,
        body: (
          <TotalPerformance
            height={height - 45}
            dateRangeData={dateRangeData}
          />
        ),
        reorderable: false,
        resizable: false,
      },
      {
        header: <div>월간 배출량</div>,
        body: (
          <MonthPerformance
            dateRange={dateRange}
            height={(height / 5) * 2}
            dateRangeData={dateRangeData}
            monthsRangeData={monthsRangeData}
          />
        ),
        reorderable: false,
        resizable: false,
      },
      {
        header: <div>월간 성상별 증감 현황</div>,
        body: (
          <MonthIncrease
            dateRange={dateRange}
            height={(height / 5) * 3}
            dateRangeData={dateRangeData}
          />
        ),
        reorderable: false,
        resizable: false,
      },
    ];

    setTiles(tiles);
  }, [dateRangeData, dateRange, height, monthsRangeData]);

  return (
    <div>
      <TileLayout
        columns={2}
        rowHeight={(height - 40) / 5}
        positions={data}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tiles}
        style={{ padding: "0px" }}
      />
    </div>
  );
};

export default DateRangePage;

const data = [
  {
    col: 1,
    colSpan: 1,
    rowSpan: 5,
  },
  {
    col: 2,
    colSpan: 1,
    rowSpan: 2,
  },
  {
    col: 2,
    colSpan: 1,
    rowSpan: 3,
  },
];

const createDateRange = (startDate, endDate) => {
  const monthDiff = moment(endDate).diff(startDate, "months");

  const monthRange = [];

  for (let i = 0; i <= monthDiff; i++) {
    monthRange.push({
      months: moment(startDate).add(i, "months").format("YYYY-MM"),
    });
  }

  return monthRange;
};
