import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_bullets from '@amcharts/amcharts4/plugins/bullets';
import am4geodata_southKoreaLow from '@amcharts/amcharts4-geodata/southKoreaLow';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}
am4core.addLicense("CH363108325");
am4core.addLicense("MP363108325");

am4core.useTheme(am4themes_animated);

var mapdata = [{
    latitude: 126.9779185,
    longitude: 37.5792919,
    count: 20,
    id: 'KR-42'
}
,
{
    latitude: 126.8779185,
    longitude: 37.4792919,
    count: 10,
    id: 'KR-41'
}

]


const CHART_ID = 'map_chart';

const MapE = () => {
    const { height, width } = useWindowDimensions();



    useEffect(() => {

        // Create map instance
        var chart = am4core.create(CHART_ID, am4maps.MapChart);
        // chart.geodata = am4geodata_continentsLow;
        chart.geodata = am4geodata_worldLow;
        chart.projection = new am4maps.projections.Miller();
        // chart.y = am4core.percent(-10);

        // Colors
        var color1 = am4core.color("#236D73");

        // chart.homeGeoPoint = {
        //     latitude: 126.8419,
        //     longitude: 37.4931
        // }
        chart.homeZoomLevel = 0.8;
        chart.minZoomLevel = 0.8;

        // Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        // polygonSeries.exclude = ["AQ"];
        // polygonSeries.exclude = ["antarctica"];
        polygonSeries.useGeodata = true;

        // Configure series
        var polygonTemplate = polygonSeries.mapPolygons.template;
        polygonTemplate.tooltipText = "{name}";
        polygonTemplate.fill = am4core.color("#236D73").lighten(0.5);

        // Create hover state and set alternative fill color
        var hs = polygonTemplate.states.create("hover");
        hs.properties.fill = am4core.color("#236D73");



        // Bubble series
        var bubbleSeries = chart.series.push(new am4maps.MapImageSeries());
        bubbleSeries.data = mapdata

        bubbleSeries.dataFields.value = "count";
        bubbleSeries.dataFields.id = "id";

        // adjust tooltip
        bubbleSeries.tooltip.animationDuration = 0;
        bubbleSeries.tooltip.showInViewport = false;
        bubbleSeries.tooltip.background.fillOpacity = 0.2;
        bubbleSeries.tooltip.getStrokeFromObject = true;
        bubbleSeries.tooltip.getFillFromObject = false;
        bubbleSeries.tooltip.background.fillOpacity = 0.2;
        bubbleSeries.tooltip.background.fill = am4core.color("#000000");



        var imageTemplate = bubbleSeries.mapImages.template;
        // if you want bubbles to become bigger when zoomed, set this to false
        imageTemplate.nonScaling = true;
        imageTemplate.strokeOpacity = 0;
        imageTemplate.fillOpacity = 0.55;
        imageTemplate.tooltipText = "{id}: [bold]{value}[/]";
        imageTemplate.applyOnClones = true;
        imageTemplate.propertyFields.longitude = "longitude";
        imageTemplate.propertyFields.latitude = "latitude";

        console.log(imageTemplate.propertyFields)

          var circle = imageTemplate.createChild(am4core.Circle);
  // this makes the circle to pulsate a bit when showing it
  circle.hiddenState.properties.scale = 0.0001;
  circle.hiddenState.transitionDuration = 2000;
  circle.defaultState.transitionDuration = 2000;
  circle.defaultState.transitionEasing = am4core.ease.elasticOut;
  // later we set fill color on template (when changing what type of data the map should show) and all the clones get the color because of this
  circle.applyOnClones = true;


  bubbleSeries.heatRules.push({
    "target": circle,
    "property": "radius",
    "min": 3,
    "max": 30,
    "dataField": "value"
  })

    // when data items validated, hide 0 value bubbles (because min size is set)
    // bubbleSeries.events.on("dataitemsvalidated", function() {
    //     bubbleSeries.dataItems.each((dataItem) => {
    //       var mapImage = dataItem.mapImage;
    //       var circle = mapImage.children.getIndex(0);
    //       if (mapImage.dataItem.value == 0) {
    //         circle.hide(0);
    //       }
    //       else if (circle.isHidden || circle.isHiding) {
    //         circle.show();
    //       }
    //     })
    //   })
    
      // this places bubbles at the visual center of a country
    //   imageTemplate.adapter.add("latitude", function(latitude, target) {
    //     if (!target.dataItem) {
    //         return latitude;
    //       }
    //       var values = target.dataItem._dataContext;
    //       console.log(values.latitude)
    //       return values.latitude
    //   })
    
    //   imageTemplate.adapter.add("longitude", function(longitude, target) {
    //     if (!target.dataItem) {
    //         return longitude;
    //       }
    //       var values = target.dataItem._dataContext;
    //       console.log(target.dataItem.id, values.longitude)

    //       return values.longitude
    //   })
      
  
        return () => {
            chart && chart.dispose();
        };


    }, [mapdata]);

    return (<>

        <div
            id={CHART_ID}
            style={{ width: width, height: '800px', margin: '0 auto', zIndex: '99' }}
        ></div>

    </>)
}

export default MapE
