import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import axios from "axios";
import KendoTable from "../KendoTable/KendoTable";

import BlockView from "../BlockView/BlockView";
import SCurveChart from "../SCurveChart/SCurveChart";
import Url from "../url/fetchURL";
import "./TotalLayOut.scss";

const TotalLayOut = () => {
  const { project_code } = useParams();
  const [selected, setSelected] = useState(0);
  const [projectName, setProjectName] = useState("");
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await axios.get(
        `${Url}/blockProjectInfo/${project_code}`
      );
        console.log(fetchData.data.data)
      setProjectName(
        fetchData.data.data?.["shell_name"] === undefined
          ? ""
          : fetchData.data.data?.["shell_name"]
      );
    };

    fetchData();
  }, [project_code]);


  return (
    <div className="blockDiagramBody">
    <TabStrip selected={selected} onSelect={handleSelect}>
    <TabStripTab title={`${projectName} Flow-Diagram`}>
          <BlockView />
        </TabStripTab>
      <TabStripTab title="S-Curve">
        <SCurveChart />
      </TabStripTab>
      <TabStripTab title="Activity List">
        <KendoTable />
      </TabStripTab>
    </TabStrip>
    </div>
  );
};

export default TotalLayOut;
