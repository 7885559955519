import React, { useEffect, useState, useRef } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import ReactToPrint from "react-to-print";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import PrintTable from "./PrintTable";

const OverallTarget = ({ height, sectorData }) => {
  const { width } = useWindowDimensions();

  const tableWidth = width * 0.7 - 32;

  const gridHeight = height - 32 - 45;
  const [tableToggle, setTableToggle] = useState(false);
  const [detailTableData, setDetailTableData] = useState([]);
  const [sort, setSort] = useState([{}]);
  const [tableData, setTableData] = useState({
    total: 0,
    total_act: 0,
    total_project: [],
    total_arc: 0,
    total_arc_act: 0,
    arc_project: [],
    total_civil: 0,
    total_civil_act: 0,
    civil_project: [],
    total_plant: 0,
    total_plant_act: 0,
    plant_project: [],
  });

  const printRef = useRef(null);

  useEffect(() => {
    if (sectorData.length > 1) {
      const total_project =
        sectorData.find((com) => com.name === "전사") === undefined
          ? { name: "전사", data: [] }
          : sectorData.find((com) => com.name === "전사");

      const arc_project =
        sectorData.find((com) => com.name === "건축사업본부") === undefined
          ? { name: "건축사업본부", data: [] }
          : sectorData.find((com) => com.name === "건축사업본부");

      const civil_project =
        sectorData.find((com) => com.name === "토목사업본부") === undefined
          ? { name: "토목사업본부", data: [] }
          : sectorData.find((com) => com.name === "토목사업본부");

      const plant_project =
        sectorData.find((com) => com.name === "플랜트사업본부") === undefined
          ? { name: "플랜트사업본부", data: [] }
          : sectorData.find((com) => com.name === "플랜트사업본부");

      setDetailTableData([...total_project.data]);

      setTableData((prev) => ({
        ...prev,
        total: total_project.data.length,
        total_act: total_project.data.filter((com) => com.warning === true)
          .length,
        total_project: total_project.data,

        total_arc: arc_project.data.length,
        total_arc_act: arc_project.data.filter((com) => com.warning === true)
          .length,
        arc_project: arc_project.data,

        total_civil: civil_project.data.length,
        total_civil_act: civil_project.data.filter(
          (com) => com.warning === true
        ).length,
        civil_project: civil_project.data,

        total_plant: plant_project.data.length,
        total_plant_act: plant_project.data.filter(
          (com) => com.warning === true
        ).length,
        plant_project: plant_project.data,
      }));
    }
  }, [sectorData]);

  const handleToggle = () => {
    setTableToggle((prev) => !prev);
  };

  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];

    const project_code = props.dataItem["uxesg_PJTCode"];

    const navigate_project_detail = () => {
      window.open(`/projects/${project_code}`, "_blank", "noopener,noreferrer");
    };

    if (field.includes("weight")) {
      return (
        <td style={{ fontSize: "0.6rem", textAlign: "left" }}>
          {Number(cell.toFixed(0)).toLocaleString()}t
        </td>
      );
    } else if (field.includes("cost")) {
      return (
        <td style={{ fontSize: "0.6rem", textAlign: "left" }}>
          {Number(cell.toFixed(0)).toLocaleString()}백만원
        </td>
      );
    } else if (field === "percent_act") {
      return <td style={{ fontSize: "0.6rem", textAlign: "left" }}>{cell}%</td>;
    } else if (field === "warning") {
      return (
        <td
          style={{
            font: "0.6rem pretendard-B",
            textAlign: "left",
            cursor: "pointer",
          }}
          onClick={navigate_project_detail}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              color: cell === true ? "black" : "red",
            }}
          >
            {cell === true ? "달성" : "미달성"}
          </div>
        </td>
      );
    } else {
      return <td style={{ fontSize: "0.6rem", textAlign: "left" }}>{cell}</td>;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <button
        className="k-button k-button-md k-button-rectangle k-button-flat k-button-flat-base k-rounded-md k-icon-button"
        style={{ position: "absolute", right: "0px", top: "-53px" }}
        onClick={handleToggle}
      >
        <span
          role="presentation"
          className="k-button-icon k-icon k-i-grid"
          style={{ color: "white" }}
        ></span>
      </button>
      <GridLayout
        style={{ height: gridHeight, border: "1px solid #E8E7E6" }}
        cols={[
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
        ]}
        rows={[
          {
            height: "50%",
          },
          {
            height: "50%",
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem col={1} row={1} className="OverallTable tablegray">
          총괄
        </GridLayoutItem>
        <GridLayoutItem col={2} row={1} className="OverallTable tablegray">
          건축
        </GridLayoutItem>
        <GridLayoutItem col={3} row={1} className="OverallTable tablegray">
          토목
        </GridLayoutItem>
        <GridLayoutItem col={4} row={1} className="OverallTable tablegray">
          플랜트
        </GridLayoutItem>
        <GridLayoutItem col={1} row={2} className="OverallTable">
          {tableData.total_act} / {tableData.total} (
          {Number(
            ((tableData.total_act / tableData.total) * 100).toFixed(2)
          ) === Infinity ||
          isNaN(
            Number(((tableData.total_act / tableData.total) * 100).toFixed(2))
          )
            ? 0
            : Number(
                ((tableData.total_act / tableData.total) * 100).toFixed(2)
              )}
          %)
        </GridLayoutItem>
        <GridLayoutItem col={2} row={2} className="OverallTable">
          {tableData.total_arc_act} / {tableData.total_arc} (
          {Number(
            ((tableData.total_arc_act / tableData.total_arc) * 100).toFixed(2)
          ) === Infinity ||
          isNaN(
            Number(
              ((tableData.total_arc_act / tableData.total_arc) * 100).toFixed(2)
            )
          )
            ? 0
            : Number(
                ((tableData.total_arc_act / tableData.total_arc) * 100).toFixed(
                  2
                )
              )}
          %)
        </GridLayoutItem>
        <GridLayoutItem col={3} row={2} className="OverallTable">
          {tableData.total_civil_act} / {tableData.total_civil} (
          {Number(
            ((tableData.total_civil_act / tableData.total_civil) * 100).toFixed(
              2
            )
          ) === Infinity ||
          isNaN(
            Number(
              (
                (tableData.total_civil_act / tableData.total_civil) *
                100
              ).toFixed(2)
            )
          )
            ? 0
            : Number(
                (
                  (tableData.total_civil_act / tableData.total_civil) *
                  100
                ).toFixed(2)
              )}
          %)
        </GridLayoutItem>
        <GridLayoutItem col={4} row={2} className="OverallTable">
          {tableData.total_plant_act} / {tableData.total_plant} (
          {Number(
            ((tableData.total_plant_act / tableData.total_plant) * 100).toFixed(
              2
            )
          ) === Infinity ||
          isNaN(
            Number(
              (
                (tableData.total_plant_act / tableData.total_plant) *
                100
              ).toFixed(2)
            )
          )
            ? 0
            : Number(
                (
                  (tableData.total_plant_act / tableData.total_plant) *
                  100
                ).toFixed(2)
              )}
          %)
        </GridLayoutItem>
      </GridLayout>
      {tableToggle && (
        <Dialog
          title={"전사 폐기물 목표 달성 현황"}
          onClose={handleToggle}
          height={"60%"}
          width={"70%"}
        >
          <div style={{ position: "relative" }}>
            <Grid
              data={orderBy(detailTableData, sort)}
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              style={{ overflow: "hidden" }}
              fixedScroll={true}
              scrollable={"none"}
            >
              <GridColumn
                field="uxesg_SectorName"
                title="본부명"
                cell={normalcell}
                width={tableWidth * 0.1}
                // headerCell={HeaderCell}
              />
              <GridColumn
                field="uxesg_PJTName"
                title="현장명"
                cell={normalcell}
                width={tableWidth * 0.15}
                // headerCell={HeaderCell}
              />
              <GridColumn
                field="percent_act"
                title="공정율"
                cell={normalcell}
                width={tableWidth * 0.07}
                // headerCell={HeaderCell}
              />

              <GridColumn
                title="처리량"
                // headerCell={HeaderCell}
              >
                <GridColumn
                  field="project_total_target_weight"
                  title="목표"
                  cell={normalcell}
                  // headerCell={HeaderCell}
                  width={tableWidth * 0.1}
                />
                <GridColumn
                  field="project_total_plan_weight"
                  title="계획"
                  cell={normalcell}
                  // headerCell={HeaderCell}
                  width={tableWidth * 0.1}
                />
                <GridColumn
                  field="project_total_act_weight"
                  title="실적"
                  cell={normalcell}
                  // headerCell={HeaderCell}
                  width={tableWidth * 0.1}
                />
              </GridColumn>
              <GridColumn
                title="처리비용"
                // headerCell={HeaderCell}
              >
                <GridColumn
                  field="project_total_target_cost"
                  title="목표"
                  cell={normalcell}
                  // headerCell={HeaderCell}
                  width={tableWidth * 0.1}
                />
                <GridColumn
                  field="project_total_plan_cost"
                  title="계획"
                  cell={normalcell}
                  // headerCell={HeaderCell}
                  width={tableWidth * 0.1}
                />
                <GridColumn
                  field="project_total_act_cost"
                  title="실적"
                  cell={normalcell}
                  width={tableWidth * 0.1}
                  // headerCell={HeaderCell}
                />
              </GridColumn>
              <GridColumn
                field="warning"
                title="목표 달성"
                cell={normalcell}
                width={tableWidth * 0.08}
                // headerCell={HeaderCell}
              />
            </Grid>

            <ReactToPrint
              trigger={() => (
                <button
                  style={{ margin: "10px 0px 0px 10px" }}
                  className="projectTablePrintButton"
                >
                  <span
                    className="k-icon k-i-print"
                    style={{ fontSize: "24px" }}
                  />
                </button>
              )}
              content={() => printRef.current}
            />

            <PrintTable
              ref={printRef}
              detailTableData={orderBy(detailTableData, sort)}
              tableWidth={760}
            />
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default OverallTarget;
