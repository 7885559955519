import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartTitle,
} from "@progress/kendo-react-charts";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import _ from "lodash";
import axios from "axios";
import EMSOverallTable from "./EMSOverallTable";
import "hammerjs";

import Url from "../../url/fetchURL";

import "./EMSProgressDetail.scss";

const EMSProgressDetail = ({ positiondata, blockH, order, project_code }) => {
  const [BoxHeight, setBoxHeight] = useState(250);

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  const today = moment(new Date()).format("YYYY-MM-DD");
  const lastWeek = moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");

  const [columnWidthList, setColumnWidthList] = useState([]);
  const [chartList, setChartList] = useState([]);

  const [visibleDialog, setVisibleDialog] = useState(true);

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "Document Register",
        lineitem: "no",
        filter_condition: "status=Active",
      };

      const res = await axios.post(
        `${Url}/getbprecord?path=${project_code}`,
        body
      );

      const discColum = _.sortBy(
        _.uniqBy(res.data.data.data, "SKMHDisc_spd").map(
          (com) => com.SKMHDisc_spd
        )
      );

      //Disc Total WF
      const discTotalWF = discColum.map((com) => ({
        disc: com,
        total_wf: Number(
          _.sumBy(
            res.data.data.data.filter((com2) => com2.SKMHDisc_spd === com),
            "emsDeliverableWF"
          ).toFixed(1)
        ),
      }));

      const totalDataArr = [];

      res.data.data.data.forEach((com) => {
        const planStart = new Date(com.emsPlanStartDO.slice(0, 10));
        const planFinish = new Date(com.emsPlanFinishDO.slice(0, 10));
        const planDiffDate = diffDateCounter(planStart, planFinish);

        for (let i = 0; i < planDiffDate; i++) {
          totalDataArr.push({
            type: "plan",
            disc: com.SKMHDisc_spd,
            date: moment(planStart).add(i, "days").format("YYYY-MM-DD"),
            value: Number(com.emsDeliverableWF) / planDiffDate,
          });
        }

        if (com.emsActualFinishDO !== null) {
          const actStart = new Date(com.emsActualStartDO.slice(0, 10));
          const actFinish = new Date(com.emsActualFinishDO.slice(0, 10));
          const actDiffDate = diffDateCounter(actStart, actFinish);

          for (let i = 0; i < actDiffDate; i++) {
            totalDataArr.push({
              type: "act",
              disc: com.SKMHDisc_spd,
              date: moment(actStart).add(i, "days").format("YYYY-MM-DD"),
              value: Number(com.emsDeliverableWF) / actDiffDate,
            });
          }
        }
      });

      const colmunNo = Math.round(discColum.length / 2);

      const columnListArr = [];

      for (var i = 1; i <= colmunNo * 2; i++) {
        columnListArr.push({ width: `${100 / colmunNo / 2}%` });
      }

      const createDiscColumn = discColum.map((com) => {
        const detail_WF = discTotalWF.find((com2) => com2.disc === com)?.[
          "total_wf"
        ];

        return {
          disc: com,
          total_wf: discTotalWF.find((com2) => com2.disc === com)?.["total_wf"],
          today_plan:
            (_.sumBy(
              totalDataArr
                .filter((com2) => com2.type === "plan")
                .filter((com2) => com2.disc === com)
                .filter((com2) => com2.date <= today),
              "value"
            ) /
              detail_WF) *
            100,

          today_act:
            (_.sumBy(
              totalDataArr
                .filter((com2) => com2.type === "act")
                .filter((com2) => com2.disc === com)
                .filter((com2) => com2.date <= today),
              "value"
            ) /
              detail_WF) *
            100,

          lastWeek_plan:
            (_.sumBy(
              totalDataArr
                .filter((com2) => com2.type === "plan")
                .filter((com2) => com2.disc === com)
                .filter((com2) => com2.date <= lastWeek),
              "value"
            ) /
              detail_WF) *
            100,

          lastWeek_act:
            (_.sumBy(
              totalDataArr
                .filter((com2) => com2.type === "act")
                .filter((com2) => com2.disc === com)
                .filter((com2) => com2.date <= lastWeek),
              "value"
            ) /
              detail_WF) *
            100,
        };
      });

      setColumnWidthList(columnListArr);
      setChartList(createDiscColumn);
    };

    fetchData();
  }, [project_code, lastWeek, today]);

  const hidden = {
    visible: false,
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>
        <div>계획: {points[0].value.target.toFixed(2)}%</div>
        <div>실적: {points[0].value.current.toFixed(2)}%</div>
        <div>
          차이: {(points[0].value.current - points[0].value.target).toFixed(2)}%
        </div>
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  return (
    <div className="eMSProgressDetail">
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "0%",
          },
          {
            height: "0%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
          {
            height: "15%",
          },
          {
            height: "35%",
          },
        ]}
        cols={columnWidthList}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "20px",
            zIndex: "100",
          }}
        >
          <Button
            title="테이블로 보기"
            iconClass="k-icon k-i-grid"
            fillMode="flat"
            onClick={toggleDialog}
          ></Button>
        </div>
        {chartList.map((v, index) => (
          <GridLayoutItem
            key={index}
            col={
              index + 1 > columnWidthList.length * 0.5
                ? index * 2 + 1 - columnWidthList.length
                : index * 2 + 1
            }
            row={index + 1 > columnWidthList.length * 0.5 ? 5 : 3}
            colSpan={2}
            rowSpan={2}
            style={{ textAlign: "center" }}
          >
            <Chart
              style={{
                height: BoxHeight * 0.52,
              }}
            >
              <ChartTooltip shared={true} render={sharedTooltipRender} />

              <ChartTitle
                text={`${v.disc} \n ${(v.today_act - v.today_plan).toFixed(
                  2
                )}% \n (계획:${v.today_plan.toFixed(
                  2
                )}% 실적:${v.today_act.toFixed(2)}%)`}
                font="0.7rem pretendard-B"
              />
              <ChartSeries>
                <ChartSeriesItem
                  type="bullet"
                  color={v.today_act - v.today_plan < 0 ? "#CD212A" : "#00A170"}
                  data={[v.today_act, v.today_plan]}
                  overlay={{ gradient: "glass" }}
                />
              </ChartSeries>
              <ChartValueAxis>
                <ChartValueAxisItem
                  majorGridLines={hidden}
                  minorTicks={hidden}
                  min={0}
                  max={100}
                  labels={{ font: "0.7rem pretendard-R" }}
                />
              </ChartValueAxis>
            </Chart>
            {!visibleDialog && (
              <Dialog
                title={"공정별 공정률 상세"}
                onClose={toggleDialog}
                width={"60%"}
              >
                <EMSOverallTable
                  chartList={chartList}
                  today={today}
                  lastWeek={lastWeek}
                />
              </Dialog>
            )}
          </GridLayoutItem>
        ))}
      </GridLayout>
    </div>
  );
};

export default EMSProgressDetail;

const diffDateCounter = (startDate, endDate) => {
  const startDateMoment = new Date(endDate);
  const endDateMoment = new Date(startDate);
  return moment(startDateMoment).diff(endDateMoment, "days") + 1;
};
