import moment from "moment";
import styled from "styled-components";

export const TotalText = (props) => {
  return (
    <td colSpan={props.colSpan} style={props.style}>
      합계
    </td>
  );
};

export const DateCell = (props) => {
  const { field, dataItem } = props;

  return (
    <td colSpan={props.colSpan} style={props.style}>
      {moment(new Date(dataItem[field].slice(0, 10))).format("YYYY-MM-DD")}
    </td>
  );
};

export const HyperLinkCell = (props) => {
  const { field, dataItem } = props;

  const goToLink = (link) => {
    if (!link.includes("null") && !link.includes("undefined"))
      window.open(link, "_blank");
  };

  return (
    <HyperLinkTag
      colSpan={props.colSpan}
      style={props.style}
      onClick={() => goToLink(`http://${dataItem["uuu_bp_record_url"]}`)}
    >
      {dataItem[field]}
    </HyperLinkTag>
  );
};

const HyperLinkTag = styled.td`
  cursor: pointer;

  &:hover {
    color: blue;
    font-weight: bolder;
    text-decoration: underline !important;
    transition: 0.2s ease-in-out all;
  }
`;
