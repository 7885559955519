import React, { useEffect, useState } from "react";
import { GridLayout } from "@progress/kendo-react-layout";
import axios from "axios";
import styled from "styled-components";
import { bplist } from "../../../Public/BPList";

import StockCost from "./StockCost";
import RunningOutStock from "./RunningOutStock";
import LongTermStock from "./LongTermStock";

const { REACT_APP_API_LGCHEMICAL_URI } = process.env;
const { MaterialInventoryManager } = bplist;

const StockManagement = ({ project_code, height }) => {
  const [materialInventoryManagerData, setMaterialInventoryManagerData] =
    useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const request_MaterialInventoryManager = await axios
        .post(
          `${REACT_APP_API_LGCHEMICAL_URI}/lgchemical/getbprecord?path=${project_code}`,
          MaterialInventoryManager
        )
        .then((com) => com.data.data.filter((com2) => com2.record_no !== null));

      setMaterialInventoryManagerData(request_MaterialInventoryManager);
    };

    fetchData();
  }, [project_code]);

  return (
    <StockManagementDiv>
      <GridLayout
        gap={{
          rows: 0,
          cols: 0,
        }}
        rows={[
          {
            height: height * 0.15,
          },
          {
            height: height * 0.35,
          },
          {
            height: height * 0.15,
          },
          {
            height: height * 0.35,
          },
        ]}
        cols={[
          {
            width: "50%",
          },
          {
            width: "50%",
          },
        ]}
      >
        <StockCost
          height={height}
          materialInventoryManagerData={materialInventoryManagerData}
        />
        <RunningOutStock
          height={height}
          materialInventoryManagerData={materialInventoryManagerData}
        />
        <LongTermStock
          height={height}
          materialInventoryManagerData={materialInventoryManagerData}
        />
      </GridLayout>
    </StockManagementDiv>
  );
};

export default StockManagement;

const StockManagementDiv = styled.div``;
