import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import _ from "lodash";
import styled from "styled-components";
import {
  HyperLinkCell,
  TotalText,
  DateCell,
} from "../../PublicCell/PubilcCell";

const DetailDialog = ({ projectCostPlan }) => {
  const [dataState, setDataState] = useState({
    sort: [],
  });
  const [dataResult, setDataResult] = useState(
    process(projectCostPlan, dataState)
  );

  const dataStateChange = (event) => {
    setDataResult(process(projectCostPlan, event.dataState));
    setDataState(event.dataState);
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setDataResult({
      ...dataResult,
      data: [...dataResult.data],
    });
  };

  return (
    <Grid
      data={dataResult}
      {...dataState}
      sortable={true}
      reorderable={true}
      onDataStateChange={dataStateChange}
      detail={DetailComponent}
      expandField="expanded"
      onExpandChange={expandChange}
    >
      <GridColumn field="key" title="관련부서" footerCell={TotalText} />
      <GridColumn
        field="cost"
        title="예산"
        cell={NumberlocalCaseString}
        footerCell={(e) => CostPlanTotalCell(e, dataResult, "plan")}
      />
      <GridColumn
        field="act_cost"
        title="집행"
        cell={NumberlocalCaseString}
        footerCell={(e) => CostPlanTotalCell(e, dataResult, "act")}
      />
    </Grid>
  );
};

export default DetailDialog;

const NumberlocalCaseString = (props) => {
  const { field } = props;
  const { cost, upaCurrPaymentDueCA, EstimatedBudget, act_cost } =
    props.dataItem;

  return (
    <td
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
    >
      {field === "cost"
        ? Number(cost).toLocaleString()
        : field === "upaCurrPaymentDueCA"
        ? Number(upaCurrPaymentDueCA).toLocaleString()
        : field === "EstimatedBudget"
        ? Number(EstimatedBudget).toLocaleString()
        : Number(act_cost).toLocaleString()}
    </td>
  );
};

const CostPlanTotalCell = (props, dataItem, type) => {
  const { data } = dataItem;

  if (type === "plan") {
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {Number(_.sumBy(data, "cost")).toLocaleString()}
      </td>
    );
  } else {
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {Number(_.sumBy(data, "act_cost")).toLocaleString()}
      </td>
    );
  }
};

const DetailCostSumcell = (props, dataItem, type) => {
  if (type === "payment") {
    const total_sum = _.sumBy(dataItem, "upaCurrPaymentDueCA");

    return (
      <td colSpan={props.colSpan} style={props.style}>
        {Number(total_sum).toLocaleString()}
      </td>
    );
  } else {
    const total_sum = _.sumBy(dataItem, "EstimatedBudget");

    return (
      <td colSpan={props.colSpan} style={props.style}>
        {Number(total_sum).toLocaleString()}
      </td>
    );
  }
};

const DetailComponent = (props) => {
  const { dataItem } = props;

  return (
    <div>
      <DetailComponentTitle>집행 상세 내역</DetailComponentTitle>
      <DetailComponentDiv>
        <DetailComponentDivBox>
          <DetailComponentTitle>지급 내역 상세</DetailComponentTitle>
          <Grid style={{ width: "100%" }} data={dataItem.payment_application}>
            <GridColumn
              field="upaInvoiceDateDOP"
              title="일자"
              width={150}
              cell={DateCell}
              footerCell={TotalText}
            />
            <GridColumn field="title" title="항목" cell={HyperLinkCell} />
            <GridColumn
              field="upaCurrPaymentDueCA"
              title="집행"
              cell={NumberlocalCaseString}
              footerCell={(e) =>
                DetailCostSumcell(e, dataItem.payment_application, "payment")
              }
            />
          </Grid>
        </DetailComponentDivBox>
        <DetailComponentDivBox>
          <DetailComponentTitle>설계 변경 내역 상세</DetailComponentTitle>
          <Grid
            style={{ width: "100%" }}
            data={dataItem.document_change_cost_total.map((com) => ({
              ...com,
              status_type: com.status === "Approved" ? "승인됨" : "진행중",
            }))}
          >
            <GridColumn
              field="type"
              title="구분"
              footerCell={TotalText}
              width={100}
            />
            <GridColumn field="status_type" title="진행사항" width={100} />
            <GridColumn field="title" title="항목" cell={HyperLinkCell} />
            <GridColumn
              field="EstimatedBudget"
              title="집행"
              cell={NumberlocalCaseString}
              footerCell={(e) =>
                DetailCostSumcell(
                  e,
                  dataItem.document_change_cost_approved,
                  "document"
                )
              }
            />
          </Grid>
        </DetailComponentDivBox>
      </DetailComponentDiv>
    </div>
  );
};

const DetailComponentDiv = styled.div`
  overflow: auto;
  max-height: 300px;
  display: flex;
  justify-content: space-between;
`;

const DetailComponentDivBox = styled.div`
  width: 50%;
  margin: 0 10px;
`;

const DetailComponentTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;
