import React, { useEffect, useState } from "react";
import { TileLayout } from "@progress/kendo-react-layout";
import _ from "lodash";
import moment from "moment";
import SectorMonthIncrease from "./SectorMonthIncrease/SectorMonthIncrease";
import TotalMonthPerformance from "./TotalMonthPerformance/TotalMonthPerformance";
import TotalPerformance from "./TotalPerformance/TotalPerformance";

const OverallDateRagePage = ({
  width,
  height,
  dateRange,
  projectListData,
  projectActTotalData,
}) => {
  const [tiles, setTiles] = useState([]);
  const [dateRangeData, setDateRangeData] = useState([]);
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM"));

  useEffect(() => {
    setEndDate(moment(dateRange.endDate).format("YYYY-MM"));

    const uniq_sector = _.uniqBy(projectListData, "uxesg_SectorName").map(
      (com) => com.uxesg_SectorName
    );

    const uniq_sector_arr_obj = uniq_sector.map((com) => ({ name: com }));

    uniq_sector_arr_obj.unshift({ name: "전사" });

    const datefilterData = projectActTotalData.filter(
      (com) =>
        new Date(com.uxesg_CarryDate.slice(0, 10)) >= dateRange.startDate &&
        new Date(com.uxesg_CarryDate.slice(0, 10)) <= dateRange.endDate
    );

    const monthRange = createDateRange(dateRange.startDate, dateRange.endDate);

    const sector_arr_data_sum = uniq_sector_arr_obj
      .map((com) => {
        if (com.name === "전사") {
          return { ...com, data: datefilterData };
        } else {
          return {
            ...com,
            data: datefilterData.filter(
              (com2) => com2.uxesg_SectorName === com.name
            ),
          };
        }
      })
      .map((com) => ({
        ...com,
        data: _.sortBy(
          com.data.map((com2) => ({
            ...com2,
            carryDate: new Date(com2.uxesg_CarryDate.slice(0, 10)),
            carryMonth: moment(
              new Date(com2.uxesg_CarryDate.slice(0, 10))
            ).format("YYYY-MM"),
            carryMonth2: moment(
              new Date(com2.uxesg_CarryDate.slice(0, 10))
            ).format("YY-MM"),
          })),
          "carryDate"
        ),
      }))
      .map((com) => ({
        ...com,
        dateRange: monthRange.map((com2) => ({
          ...com2,
          data: com.data.filter((com3) => com3.carryMonth === com2.months),
          value: _.sumBy(
            com.data.filter((com3) => com3.carryMonth === com2.months),
            "uxesg_CarryQty2"
          ),
          cost: Number(
            (
              _.sumBy(
                com.data.filter((com3) => com3.carryMonth === com2.months),
                "uxesg_CostTotal"
              ) / 1000000
            ).toFixed(0)
          ),
        })),
      }));

    setDateRangeData(sector_arr_data_sum);
  }, [dateRange, projectListData, projectActTotalData]);

  useEffect(() => {
    const tiles = [
      {
        header: <div>누적 배출현황</div>,
        body: (
          <TotalPerformance
            width={width}
            height={(height / 10) * 4}
            endDate={endDate}
            dateRangeData={dateRangeData}
            base_code={base_code}
          />
        ),
        reorderable: false,
      },
      {
        header: <div>월간 배출량</div>,
        body: (
          <TotalMonthPerformance
            height={(height / 10) * 6}
            endDate={endDate}
            dateRangeData={dateRangeData}
          />
        ),
        reorderable: false,
      },
      {
        header: <div>본부별 배출량</div>,
        body: (
          <SectorMonthIncrease
            height={height}
            endDate={endDate}
            dateRangeData={dateRangeData}
            base_code={base_code}
          />
        ),
        reorderable: false,
      },
    ];

    setTiles(tiles);
  }, [dateRange, dateRangeData, endDate, height, width]);

  return (
    <div>
      <TileLayout
        columns={2}
        rowHeight={(height - 90) / 10}
        positions={data}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tiles}
        style={{ padding: "0px" }}
      />
    </div>
  );
};

export default OverallDateRagePage;

const data = [
  {
    col: 1,
    colSpan: 1,
    rowSpan: 4,
  },
  {
    col: 1,
    colSpan: 1,
    rowSpan: 6,
  },
  {
    col: 2,
    colSpan: 1,
    rowSpan: 10,
  },
];

const base_code = [
  { code: "40-01-01", name: "폐콘크리트" },
  { code: "40-02-07", name: "폐합성수지" },
  { code: "40-02-06", name: "폐목재" },
  { code: "40-04-14", name: "혼합건설폐기물" },
  { code: "others", name: "기타" },
];

const createDateRange = (startDate, endDate) => {
  const monthDiff = moment(endDate).diff(startDate, "months");

  const monthRange = [];

  for (let i = 0; i <= monthDiff; i++) {
    monthRange.push({
      months: moment(startDate).add(i, "months").format("YYYY-MM"),
    });
  }

  return monthRange;
};
