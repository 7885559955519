import React from "react";
import {
  Chart,
  ChartTitle,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";

const VendorChart = ({ title, chartData, height }) => {
  const seriesLabels = {
    visible: true,
    padding: 0,
    font: "0.7rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
  };

  const chartLabelContents = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : Number(e.value.toFixed(0)).toLocaleString() + "백만원";
  };

  const chartValueAxisContents = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : Number(e.value.toFixed(0)).toLocaleString();
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    return (
      <div>
        <div>업체명: {categoryText}</div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              <div>
                비용: {Number(point.value.toFixed(0)).toLocaleString()}원
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Chart
        style={{
          height: height,
        }}
      >
        <ChartTitle
          text={title}
          position="top"
          align="center"
          font="0.9rem Pretendard-B"
        />
        <ChartArea background="none" />
        {/* <ChartLegend position="bottom" orientation="horizontal" /> */}
        <ChartTooltip shared={true} render={sharedTooltipRender} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            maxDivisions={10}
            startAngle={45}
            labels={seriesLabels}
            majorGridLines={{ step: 100 }}
            minorGridLines={{ step: 100 }}
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem
            labels={{
              font: "0.7rem pretendard-R",
              content: chartValueAxisContents,
            }}
            majorTicks={{ step: 100 }}
            minorTicks={{ step: 100 }}
            majorGridLines={{ step: 10 }}
            minorGridLines={{ step: 10 }}
          />
        </ChartValueAxis>
        <ChartSeries>
          <ChartSeriesItem
            type={"column"}
            data={chartData.slice(0, 20)}
            field={"cost_sum"}
            autoFit={true}
            categoryField="name"
            color={"rgb(79, 129, 189)"}
            labels={{
              visible: false,
              content: chartLabelContents,
              background: "none",
              color: "black",
            }}
          />
        </ChartSeries>
      </Chart>
    </div>
  );
};

export default VendorChart;
