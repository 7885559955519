import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import OverrallMabPerformanceChart from "../../../Components/OverrallMabPerformanceChart";

const OverallManbPerformanceDetail = ({ height, com }) => {
  return (
    <div>
      <GridLayout
        style={{ height: height }}
        cols={[
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
          {
            width: "25%",
          },
        ]}
        rows={[
          {
            height: "100%",
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        {com.base_code.map((com, idx) => {
          return (
            <GridLayoutItem col={idx + 1} row={1} key={idx}>
              <OverrallMabPerformanceChart height={height} com={com} />
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default OverallManbPerformanceDetail;
