import React, { useEffect } from "react";
import { FormElement, Field } from "@progress/kendo-react-form";
import { Label, Error } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import SlotDialog from "../SlotDialog/SlotDialog";
import moment from "moment";

const CustomContents = (props, dataItem, slotList) => {
  useEffect(() => {
    const buttonGroup = document.querySelector(".k-dialog-buttongroup");

    const saveButton = buttonGroup.firstChild;
    const cancleButton = buttonGroup.lastChild;

    cancleButton.firstChild.innerText = "닫기";

    if (dataItem.Title === undefined) {
      saveButton.firstChild.innerText = "신청";
    } else {
      saveButton.remove();
      buttonGroup.append(cancleButton);
    }
  }, [dataItem]);

  if (dataItem.Title === undefined) {
    return (
      <FormElement horizontal={false}>
        <div className="k-form-field">
          <Label>업체명</Label>
          <div className="k-form-field-wrap">
            <Field name={"Title"} component={Input} />
            {props.errors.Title && <Error>{props.errors.Title}</Error>}
          </div>
        </div>
        <div className="k-form-field">
          <Label>시작일</Label>
          <div className="k-form-field-wrap">
            <Field
              name={"Start"}
              component={DateTimePicker}
              as={DateTimePicker}
              rows={1}
            />
          </div>

          <div className="k-form-field">
            <Label>종료일</Label>
            <div className="k-form-field-wrap">
              <Field
                name={"End"}
                component={DateTimePicker}
                as={DateTimePicker}
                rows={1}
              />
            </div>
          </div>
        </div>
        <div className="k-form-field">
          <Label>상세 신청 이력</Label>
          <div className="k-form-field-wrap">
            <SlotDialog
              slotList={slotList.filter((com) =>
                com.Range.includes(moment(dataItem.Start).format("YYYY-MM-DD"))
              )}
            />
          </div>
        </div>
      </FormElement>
    );
  } else {
    return (
      <FormElement horizontal={false}>
        <div className="k-form-field">
          <Label>업체명</Label>
          <div className="k-form-field-wrap">
            <div>{dataItem.Title}</div>
          </div>
        </div>

        <div className="k-form-field">
          <Label>시작일</Label>
          <div className="k-form-field-wrap">
            <div>
              {moment(dataItem.Start).format("YYYY년 MM월 DD일 HH시 mm분")}
            </div>
          </div>
        </div>

        <div className="k-form-field">
          <Label>종료일</Label>
          <div className="k-form-field-wrap">
            <div>
              {moment(dataItem.End).format("YYYY년 MM월 DD일 HH시 mm분")}
            </div>
          </div>
        </div>

        <div className="k-form-field">
          <Label>승인여부</Label>
          <div className="k-form-field-wrap">
            <div>
              {dataItem.ApprovedIDS === 1 ? "승인 대기중" : "승인 완료"}
            </div>
          </div>
        </div>
        <div className="k-form-field">
          <Label>상세 신청 이력</Label>
          <div className="k-form-field-wrap">
            <SlotDialog
              slotList={slotList.filter((com) =>
                com.Range.includes(moment(dataItem.Start).format("YYYY-MM-DD"))
              )}
            />
          </div>
        </div>
      </FormElement>
    );
  }
};

export default CustomContents;
