import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import _ from "lodash";

const VendorTable = ({ title, tableData, height }) => {
  const normalcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field];

    if (field === "cost_sum") {
      return (
        <td style={{ fontSize: "0.8rem", textAlign: "left" }}>
          {Number((cell / 1000000).toFixed(0)).toLocaleString() + "백만원"}
        </td>
      );
    } else {
      return <td style={{ fontSize: "0.8rem", textAlign: "left" }}>{cell}</td>;
    }
  };

  const footerCell = (props) => {
    const field = props.field || "";

    return (
      <td style={{ fontSize: "0.8rem", textAlign: "left" }}>
        {Number(
          (_.sumBy(tableData, field) / 1000000).toFixed(0)
        ).toLocaleString() + "백만원"}
      </td>
    );
  };
  return (
    <div>
      <Grid
        data={tableData}
        style={{
          height: height,
        }}
      >
        <GridColumn
          field="id"
          title="순번"
          cell={normalcell}
          width={"50px"}
          // headerCell={HeaderCell}
        />
        <GridColumn
          field="name"
          title={title}
          cell={normalcell}
          width={"145px"}
          // headerCell={HeaderCell}
        />
        <GridColumn
          field="cost_sum"
          title={"비용"}
          cell={normalcell}
          footerCell={footerCell}
        />
      </Grid>
    </div>
  );
};

export default VendorTable;
