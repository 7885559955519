import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const TotalTable = ({ height, tableDatas }) => {
  const [tableDataWeight, setTableDataWeight] = useState({
    total: 0,
    conc: 0,
    plastic: 0,
    wood: 0,
    mix: 0,
    others: 0,
  });

  const [tableDataCost, setTableDataCost] = useState({
    total: 0,
    conc: 0,
    plastic: 0,
    wood: 0,
    mix: 0,
    others: 0,
  });

  useEffect(() => {
    const data_set = tableDatas.find((com) => com.name === "전사")?.[
      "base_code_data"
    ];

    setTableDataWeight((prev) => ({
      ...prev,
      total: _.sumBy(data_set, "totalWeight"),
      conc:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "40-01-01")?.["totalWeight"],
      plastic:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "40-02-07")?.["totalWeight"],
      wood:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "40-02-06")?.["totalWeight"],
      mix:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "40-04-14")?.["totalWeight"],
      others:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "others")?.["totalWeight"],
    }));

    setTableDataCost((prev) => ({
      ...prev,
      total: _.sumBy(data_set, "totalCost") / 1000000,
      conc:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "40-01-01")?.["totalCost"] /
            1000000,
      plastic:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "40-02-07")?.["totalCost"] /
            1000000,
      wood:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "40-02-06")?.["totalCost"] /
            1000000,
      mix:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "40-04-14")?.["totalCost"] /
            1000000,
      others:
        data_set === undefined
          ? 0
          : data_set.find((com) => com.code === "others")?.["totalCost"] /
            1000000,
    }));
  }, [tableDatas]);

  return (
    <div>
      <GridLayout
        style={{ height: height - 20, border: "1px solid #E8E7E6" }}
        cols={[
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
          {
            width: `${100 / 7}%`,
          },
        ]}
        rows={[
          {
            height: "25%",
          },
          {
            height: "25%",
          },
          {
            height: "25%",
          },
          {
            height: "25%",
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem
          col={1}
          row={1}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          구분
        </GridLayoutItem>
        <GridLayoutItem
          col={2}
          row={1}
          rowSpan={2}
          className="OverallTable tablegray"
        >
          배출
          <br />
          총량
        </GridLayoutItem>
        <GridLayoutItem
          col={3}
          colSpan={5}
          row={1}
          className="OverallTable tablegray"
        >
          성상구분
        </GridLayoutItem>
        <GridLayoutItem col={3} row={2} className="OverallTable tablegray">
          폐콘크리트
        </GridLayoutItem>
        <GridLayoutItem col={4} row={2} className="OverallTable tablegray">
          폐합성수지
        </GridLayoutItem>
        <GridLayoutItem col={5} row={2} className="OverallTable tablegray">
          폐목재
        </GridLayoutItem>
        <GridLayoutItem col={6} row={2} className="OverallTable tablegray">
          혼합폐기물
        </GridLayoutItem>
        <GridLayoutItem col={7} row={2} className="OverallTable tablegray">
          기타
        </GridLayoutItem>
        <GridLayoutItem col={1} row={3} className="OverallTable tablegray">
          반출량
        </GridLayoutItem>
        <GridLayoutItem col={1} row={4} className="OverallTable tablegray">
          비용
        </GridLayoutItem>

        {Object.keys(tableDataWeight).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={3}
              className="OverallTable"
              key={idx}
            >
              {Number(tableDataWeight[com].toFixed(0)).toLocaleString()}
            </GridLayoutItem>
          );
        })}

        {Object.keys(tableDataCost).map((com, idx) => {
          return (
            <GridLayoutItem
              col={idx + 2}
              row={4}
              className="OverallTable"
              key={idx}
            >
              {Number(tableDataCost[com].toFixed(0)).toLocaleString()}
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default TotalTable;
