import React, { useEffect, useState, useRef } from "react";
import {
  GridLayout,
  GridLayoutItem,
  Card,
  CardTitle,
  CardBody,
  CardSubtitle,
} from "@progress/kendo-react-layout";
import {
  Chart,
  ChartCategoryAxisItem,
  ChartCategoryAxis,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  exportVisual,
  ChartTitle,
} from "@progress/kendo-react-charts";
import moment from "moment";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import "hammerjs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { Tooltip } from "@progress/kendo-react-tooltip";
import _ from "lodash";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import Url from "../../url/fetchURL";
// const selectField = "selected";
// const expandField = "expanded";
// const dataItemKey = "text";
// const textField = "text";
// const subItemsField = "items";
// const fields = {
//   selectField,
//   expandField,
//   dataItemKey,
//   subItemsField,
// };

const EMSSCurveChart = ({ positiondata, blockH, order, project_code }) => {
  const chart = useRef(null);

  const [BoxHeight, setBoxHeight] = useState(250);

  useEffect(() => {
    const boxHeight = positiondata[order];
    setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
  }, [positiondata, order, blockH]);

  const [cardData, setCardData] = useState({
    actualpercent: 0,
    planpercent: 0,
  });

  const [discData, setDiscData] = useState([]);

  const [discDataValue, setDiscDataValue] = useState(null);

  const [chartRange, setChartRange] = useState({ min: "", max: "" });
  const [chartData, setChartData] = useState([]);
  const [selected, setSelected] = useState(false);

  const [zoomableyn, setzoomableyn] = useState(false);

  const today = moment(new Date()).format("YYYY-MM-DD");

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "Document Register",
        lineitem: "no",
        filter_condition: "status=Active",
      };

      const res = await axios.post(
        `${Url}/getbprecord?path=${project_code}`,
        body
      );

      //ComboBox Data
      const discColum = _.sortBy(
        _.uniqBy(res.data.data.data, "SKMHDisc_spd").map(
          (com) => com.SKMHDisc_spd
        )
      );

      setDiscData(discColum);

      //Disc Total WF
      const discTotalWF = discColum.map((com) => ({
        disc: com,
        total_wf: Number(
          _.sumBy(
            res.data.data.data.filter((com2) => com2.SKMHDisc_spd === com),
            "emsDeliverableWF"
          ).toFixed(1)
        ),
      }));

      // Chart Data
      const totalDateArr = [];

      res.data.data.data.forEach((com) => {
        const planStart = new Date(com.emsPlanStartDO.slice(0, 10));
        const planFinish = new Date(com.emsPlanFinishDO.slice(0, 10));

        totalDateArr.push(planStart);
        totalDateArr.push(planFinish);

        if (com.emsActualStartDO !== null) {
          const actStart = new Date(com.emsActualStartDO.slice(0, 10));
          totalDateArr.push(actStart);
        }
        if (com.emsActualFinishDO !== null) {
          const actFinish = new Date(com.emsActualFinishDO.slice(0, 10));
          totalDateArr.push(actFinish);
        }
      });

      const minDate = new Date(Math.min(...totalDateArr));
      const maxDate = new Date(Math.max(...totalDateArr));

      setChartRange({ min: minDate, max: maxDate });

      const baseDataArr = [];

      for (let j = 0; j < diffDateCounter(minDate, maxDate); j++) {
        baseDataArr.push({
          date: moment(minDate).add(j, "days").format("YYYY-MM-DD"),
        });
      }

      const totalDataArr = [];

      res.data.data.data.forEach((com) => {
        const planStart = new Date(com.emsPlanStartDO.slice(0, 10));
        const planFinish = new Date(com.emsPlanFinishDO.slice(0, 10));
        const planDiffDate = diffDateCounter(planStart, planFinish);

        for (let i = 0; i < planDiffDate; i++) {
          totalDataArr.push({
            type: "plan",
            disc: com.SKMHDisc_spd,
            date: moment(planStart).add(i, "days").format("YYYY-MM-DD"),
            value: Number(com.emsDeliverableWF) / planDiffDate,
          });
        }

        if (com.emsActualFinishDO !== null) {
          const actStart = new Date(com.emsActualStartDO.slice(0, 10));
          const actFinish = new Date(com.emsActualFinishDO.slice(0, 10));
          const actDiffDate = diffDateCounter(actStart, actFinish);

          for (let i = 0; i < actDiffDate; i++) {
            totalDataArr.push({
              type: "act",
              disc: com.SKMHDisc_spd,
              date: moment(actStart).add(i, "days").format("YYYY-MM-DD"),
              value: Number(com.emsDeliverableWF) / actDiffDate,
            });
          }
        }
      });

      if (discDataValue === null) {
        const baseChartData = baseDataArr.map((com) => {
          if (com.date <= today) {
            return {
              ...com,
              date: new Date(com.date),
              plan: _.sumBy(
                totalDataArr.filter(
                  (com2) => com2.date === com.date && com2.type === "plan"
                ),
                "value"
              ),
              act: _.sumBy(
                totalDataArr.filter(
                  (com2) => com2.date === com.date && com2.type === "act"
                ),
                "value"
              ),
              dev:
                _.sumBy(
                  totalDataArr.filter(
                    (com2) => com2.date === com.date && com2.type === "act"
                  ),
                  "value"
                ) -
                _.sumBy(
                  totalDataArr.filter(
                    (com2) => com2.date === com.date && com2.type === "plan"
                  ),
                  "value"
                ),
            };
          } else {
            return {
              ...com,
              date: new Date(com.date),
              plan: _.sumBy(
                totalDataArr.filter(
                  (com2) => com2.date === com.date && com2.type === "plan"
                ),
                "value"
              ),
            };
          }
        });

        baseChartData.reduce((acc, cum) => {
          return (cum["plan_cum"] = acc + cum.plan);
        }, 0);

        baseChartData.reduce((acc, cum) => {
          if (cum.act !== undefined) {
            return (cum["act_cum"] = acc + cum.act);
          }
        }, 0);

        setChartData(baseChartData);

        setCardData({
          actualpercent: _.sumBy(
            baseChartData.filter(
              (com) => moment(com.date).format("YYYY-MM-DD") <= today
            ),
            "act"
          ),
          planpercent: _.sumBy(
            baseChartData.filter(
              (com) => moment(com.date).format("YYYY-MM-DD") <= today
            ),
            "plan"
          ),
        });
      } else {
        const detailWF = discTotalWF.find((com) => com.disc === discDataValue)[
          "total_wf"
        ];

        const filterTotalDataArr = totalDataArr.filter(
          (com) => com.disc === discDataValue
        );

        const baseChartData = baseDataArr.map((com) => {
          if (com.date <= today) {
            return {
              ...com,
              date: new Date(com.date),
              plan:
                (_.sumBy(
                  filterTotalDataArr.filter(
                    (com2) => com2.date === com.date && com2.type === "plan"
                  ),
                  "value"
                ) /
                  detailWF) *
                100,
              act:
                (_.sumBy(
                  filterTotalDataArr.filter(
                    (com2) => com2.date === com.date && com2.type === "act"
                  ),
                  "value"
                ) /
                  detailWF) *
                100,
              dev:
                (_.sumBy(
                  filterTotalDataArr.filter(
                    (com2) => com2.date === com.date && com2.type === "act"
                  ),
                  "value"
                ) /
                  detailWF -
                  _.sumBy(
                    filterTotalDataArr.filter(
                      (com2) => com2.date === com.date && com2.type === "plan"
                    ),
                    "value"
                  ) /
                    detailWF) *
                100,
            };
          } else {
            return {
              ...com,
              date: new Date(com.date),
              plan:
                (_.sumBy(
                  filterTotalDataArr.filter(
                    (com2) => com2.date === com.date && com2.type === "plan"
                  ),
                  "value"
                ) /
                  detailWF) *
                100,
            };
          }
        });

        baseChartData.reduce((acc, cum) => {
          return (cum["plan_cum"] = acc + cum.plan);
        }, 0);

        baseChartData.reduce((acc, cum) => {
          if (cum.act !== undefined) {
            return (cum["act_cum"] = acc + cum.act);
          }
        }, 0);

        setChartData(baseChartData);

        setCardData({
          actualpercent: _.sumBy(
            baseChartData.filter(
              (com) => moment(com.date).format("YYYY-MM-DD") <= today
            ),
            "act"
          ),
          planpercent: _.sumBy(
            baseChartData.filter(
              (com) => moment(com.date).format("YYYY-MM-DD") <= today
            ),
            "plan"
          ),
        });
      }
    };

    fetchData();
  }, [project_code, discDataValue, today]);

  const handleDiscComboBox = (e) => {
    setDiscDataValue(e.value);
  };

  const toggleZoom = () => {
    setzoomableyn(!zoomableyn);
  };

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>
        {selected ? (
          <div>{moment(points[0].category).format("YY년 MM월")}</div>
        ) : (
          <div>
            {moment(points[0].category).format("YY년 MM월") +
              " " +
              Math.ceil(points[0].category.getDate() / 7) +
              "주"}
          </div>
        )}
        {points.map((point, index) => {
          return (
            <div key={index}>
              {point.series.name} : {point.value.toFixed(2)}%
            </div>
          );
        })}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const labelContentAxis1 = (e) => (e.value > 100 ? "" : e.value + "%");

  const labelContent_0 = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : selected
      ? moment(new Date(e.value)).format("YY년 MM월")
      : moment(new Date(e.value)).format("YY년 MM월") +
        " " +
        Math.ceil(new Date(e.value).getDate() / 7) +
        "주";
  };

  const seriesLabels_0 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Arial, sans-serif",
    position: "top",
    background: "none",
    rotation: { angle: "auto" },
    content: labelContent_0,
  };

  const onImageExportClick = () => {
    const chartVisual = exportVisual(chart.current);

    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) =>
        saveAs(dataURI, "EMSSCurvechart.png")
      );
    }
  };

  return (
    <div className="eMSSCurveChart">
      <GridLayout
        style={{ height: BoxHeight }}
        rows={[
          {
            height: "70%",
          },

          {
            height: "15%",
          },
          {
            height: "15%",
          },
        ]}
        cols={[
          {
            width: "18%",
          },
          {
            width: "82%",
          },
        ]}
        gap={{
          rows: 0,
          cols: 0,
        }}
      >
        <GridLayoutItem col={1} row={1}>
          <Card style={{ width: "100%", height: BoxHeight * 0.65 }}>
            <CardBody style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-50%, -40%)",
                }}
              >
                <CardTitle style={{ textAlign: "center", color: "#363945" }}>
                  Dev.
                </CardTitle>

                <CardTitle
                  style={{
                    textAlign: "center",
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                >
                  {cardData.actualpercent - cardData.planpercent > 0 ? (
                    <span style={{ color: "#00539C" }}>
                      {(cardData.actualpercent - cardData.planpercent).toFixed(
                        2
                      )}
                      %
                    </span>
                  ) : (
                    <span style={{ color: "#9E1030" }}>
                      {(cardData.actualpercent - cardData.planpercent).toFixed(
                        2
                      )}
                      %
                    </span>
                  )}
                </CardTitle>
              </div>
              <Tooltip anchorElement="target" position="top">
                <div
                  style={{ position: "absolute", left: "5px", bottom: "5px" }}
                  title="현재일까지 일할 계산된 계획값입니다."
                >
                  <CardSubtitle
                    style={{
                      color: "#939597",
                      fontSize: "1rem",
                      textAlign: "center",
                    }}
                  >
                    Plan
                  </CardSubtitle>

                  <CardSubtitle
                    style={{
                      fontSize: "2rem",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    {cardData.planpercent && cardData.planpercent.toFixed(2)}%
                  </CardSubtitle>
                </div>
              </Tooltip>

              <div
                style={{ position: "absolute", right: "5px", bottom: "5px" }}
              >
                <CardSubtitle
                  style={{
                    textAlign: "center",
                    color: "#939597",
                    fontSize: "1rem",
                  }}
                >
                  Actual
                </CardSubtitle>
                <CardSubtitle
                  style={{
                    textAlign: "center",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  {cardData.actualpercent && cardData.actualpercent.toFixed(2)}%
                </CardSubtitle>
              </div>
            </CardBody>
          </Card>
        </GridLayoutItem>

        <GridLayoutItem col={1} row={2}>
          <div>
            <div style={{ marginLeft: "5px" }}>공종</div>
            <ComboBox
              style={{
                width: "100%",
                marginRight: "5px",
              }}
              data={discData}
              value={discDataValue}
              onChange={handleDiscComboBox}
              className="disc"
            />
          </div>
        </GridLayoutItem>

        <GridLayoutItem col={2} row={1} rowSpan={3}>
          <div style={{ position: "absolute", top: "10px", right: "40px" }}>
            <ButtonGroup>
              <Button
                title="확대/이동 켜기"
                iconClass="k-icon k-i-zoom-in"
                themeColor={zoomableyn ? "primary" : null}
                fillMode="flat"
                onClick={toggleZoom}
              ></Button>

              <Button
                title="이미지로 저장"
                iconClass="k-icon k-i-image-export"
                fillMode="flat"
                onClick={onImageExportClick}
              ></Button>
            </ButtonGroup>
          </div>

          <div
            style={{
              position: "absolute",
              right: "85px",
              top: "70px",
              zIndex: 100003,
            }}
          >
            {selected ? <span>Monthly</span> : <span>Weekly</span>}
          </div>

          <div
            style={{
              position: "absolute",
              right: "5px",
              top: "60px",
              zIndex: 100003,
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={selected}
                    onChange={() => setSelected(!selected)}
                  />
                }
              />
            </FormGroup>
          </div>
          <Chart ref={chart} pannable={zoomableyn} zoomable={zoomableyn}>
            <ChartTitle
              text={
                discDataValue === null
                  ? "EMS S-Curve (All)"
                  : "EMS S-Curve (" + discDataValue + ")"
              }
              font="1.2rem pretendard-R"
            />
            <ChartArea height={BoxHeight * 1.1} margin={20} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                min={chartRange.min}
                max={chartRange.max}
                labels={seriesLabels_0}
                baseUnit={!selected ? "weeks" : "months"}
                axisCrossingValue={[0, chartData.length]}
                majorGridLines={{ step: 100 }}
                minorGridLines={{ step: 100 }}
              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem
                name="월간"
                visible={false}
                majorTicks={{ step: 100 }}
                minorTicks={{ step: 100 }}
                majorGridLines={{ step: 10 }}
                minorGridLines={{ step: 10 }}
              />
              <ChartValueAxisItem
                name="누적(%)"
                min={0}
                labels={{
                  content: labelContentAxis1,
                  font: "0.7rem Arial, pretendard-R",
                }}
                majorGridLines={{ step: 10 }}
                minorGridLines={{ step: 10 }}
                visible={true}
              />
            </ChartValueAxis>
            <ChartTooltip shared={true} render={sharedTooltipRender} />

            <ChartSeries>
              <ChartSeriesItem
                color="#DBF3FF"
                type="column"
                data={chartData}
                field="plan"
                categoryField="date"
                name="월간 계획"
                autoFit={true}
                axis="월간"
                aggregate="sum"
              />
              <ChartSeriesItem
                color="#42B7F4"
                type="column"
                data={chartData}
                field="act"
                categoryField="date"
                name="월간 실적"
                autoFit={true}
                axis="월간"
                aggregate="sum"
              />

              <ChartSeriesItem
                color="#B1CCE4"
                type="line"
                data={chartData}
                field="plan_cum"
                categoryField="date"
                name="누적 계획"
                autoFit={true}
                axis="누적(%)"
              />

              <ChartSeriesItem
                color="#5E8EFF"
                type="line"
                data={chartData}
                field="act_cum"
                categoryField="date"
                name="누적 실적"
                autoFit={true}
                axis="누적(%)"
              />
            </ChartSeries>
          </Chart>
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

export default EMSSCurveChart;

const diffDateCounter = (startDate, endDate) => {
  const startDateMoment = new Date(endDate);
  const endDateMoment = new Date(startDate);
  return moment(startDateMoment).diff(endDateMoment, "days") + 1;
};
