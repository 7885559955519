import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
    Card,
    CardTitle,
    CardBody,
    CardSubtitle,
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { Chart, ChartCategoryAxisItem, ChartCategoryAxis, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, exportVisual, ChartTitle } from "@progress/kendo-react-charts";
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import 'hammerjs';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { processTreeData, expandedState } from './tree-data-operations.js';
import axios from "axios";


const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "text";
const textField = "text";
const subItemsField = "items";
const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
};

const Progress1 = () => {

    const { project_code } = useParams();
    const [data2, setdata2] = useState([]);
    const [selectdata, setselectdata] = useState([]);
    const [selectdata2, setselectdata2] = useState([]);
    const [selectdata3, setselectdata3] = useState([]);

    const [value2, setValue2] = useState({ text: '' });
    const [value, setValue] = useState(null);
    const [value3, setValue3] = useState(null);

    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Activity List WBS",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Deleted",
                            "condition_type": "neq"
                        },
                    ]
                }
            }
            const res = await axios.post(`https://api.dtsolution.kr/api/hallagetbprecord?path=${project_code}`, body);

            const datafrom = res.data.data.data

            setdata2(

                (value2 === null|| value2.text === '' )&& value === null && value3 === null?
                    _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 }) :

                        value2 === null && value !== null ?
                            _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.WorkPkgforPK === value; }) :
                            value !== null && value2.text === '' ?
                                _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.WorkPkgforPK === value; }) :
                                value2.text !== '' && value2 !== null && value !== null && typeof value2.items === 'undefined' && value3 === null?
                                    _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.FacilityL3CDNM === value2.text && o.WorkPkgforPK === value; }) :
                                    value2.text !== '' && value2 !== null && value === null && typeof value2.items === 'undefined' && value3 !== null?
                                    _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.FacilityL3CDNM === value2.text  && o.UnitTypeBPK === value3; }) :
                                    value2.text !== '' && value2 !== null && value !== null && typeof value2.items === 'undefined' && value3 !== null?
                                    _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.FacilityL3CDNM === value2.text && o.WorkPkgforPK === value && o.UnitTypeBPK === value3; }) :
                                    value2.text !== '' && value2 !== null && value !== null && typeof value2.items !== 'undefined' ?
                                        _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.FacilityL2CDNM === value2.text && o.WorkPkgforPK === value }) :
                                        value2.text !== '' && value2 !== null && value === null && typeof value2.items === 'undefined' ?
                                            _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.FacilityL3CDNM === value2.text }) :
                                            _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.FacilityL2CDNM === value2.text })
                // value2 === null || value === null || value2.text === '' ?
                //     _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 }) :
                //     value2.text !== '' && typeof value2.items === 'undefined' ?
                //         _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.Lv_3_Name === value2.text  && o.Lv_4_Name === value}) :
                //         _.filter(datafrom, function (o) { return o.PlannedAmount !== 0 && o.Lv_2_Name === value2.text  && o.Lv_4_Name === value})
            )
            setselectdata(_.sortBy(_.uniqBy(datafrom.map((v) => { return { Area: v.FacilityL2CDNM, Subarea: v.FacilityL3CDNM } }), 'Subarea'), 'Area').filter(v => v.Area !== null));
            setselectdata2(_.sortBy(_.uniqBy(datafrom, 'WorkPkgforPK'), 'WorkPkgforPK').filter(v => v.WorkPkgforPK !== null).map(v => v.WorkPkgforPK));
            setselectdata3(_.sortBy(_.uniqBy(datafrom, 'UnitTypeBPK'), 'UnitTypeBPK').filter(v => v.UnitTypeBPK !== null).map(v => v.UnitTypeBPK));
        };
        fetchData();

    }, [project_code, value2, value,value3]);


    function dateRange(startDate, endDate) {
        var start = startDate.split('-');
        var end = endDate.split('-');
        var startYear = parseInt(start[2]);
        var endYear = parseInt(end[2]);
        var enddate = parseInt(end[1]);
        var startdate = parseInt(start[1]);
        var endmonth = parseInt(end[0]);
        var startmonth = parseInt(start[0]);
        var dates = [];

        var dateinstart = new Date(startYear, startmonth, 0).getDate() - startdate + 1


        for (var i = startYear; i <= endYear; i++) {
            var endMonth = i !== endYear ? 11 : parseInt(end[0]) - 1;
            var startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
            for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                var month = j + 1;
                var displayYear = i + "년"
                var displayMonth = month < 10 ? '0' + month + '월' : month + '월';
                dates.push({ month: [displayYear.slice(2, 5), displayMonth].join(' '), daysratio: (startmonth === endmonth ? enddate - startdate + 1 : i + month === startYear + startmonth ? dateinstart : i + month === endYear + endmonth ? enddate : new Date(i, month, 0).getDate()) / (moment(endDate, "MM-DD-YYYY").diff(moment(startDate, "MM-DD-YYYY"), 'days') + 1) });
            }
        }
        return dates;
    }

    function dateRange2(startDate, endDate) {
        if (startDate !== null) {

            var endDate2 = endDate === null ? moment().format("MM-DD-YYYY") : endDate

            var start = startDate.split('-');
            var end = endDate2.split('-');
            var startYear = parseInt(start[2]);
            var endYear = parseInt(end[2]);
            var enddate = parseInt(end[1]);
            var startdate = parseInt(start[1]);
            var endmonth = parseInt(end[0]);
            var startmonth = parseInt(start[0]);
            var dates = [];

            var dateinstart = new Date(startYear, startmonth, 0).getDate() - startdate + 1



            for (var i = startYear; i <= endYear; i++) {
                var endMonth = i !== endYear ? 11 : parseInt(end[0]) - 1;
                var startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
                for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                    var month = j + 1;
                    var displayYear = i + "년"
                    var displayMonth = month < 10 ? '0' + month + '월' : month + '월';
                    dates.push({
                        month: [displayYear.slice(2, 5), displayMonth].join(' '),
                        daysratio: (startmonth === endmonth ? enddate - startdate + 1 :
                            i + month === startYear + startmonth ? dateinstart :
                                i + month === endYear + endmonth ? enddate :
                                    new Date(i, month, 0).getDate()) / (moment(endDate2, "MM-DD-YYYY").diff(moment(startDate, "MM-DD-YYYY"), 'days') + 1)
                    });
                }
            }
            return dates;
        }
    }

    const dayweight = data2.map((v) => {
        return {
            disc: v.WorkPkgforPK,
            Area: v.FacilityL2CDNM,
            Subarea: v.FacilityL3CDNM,
            d_evm_plan_start: v.PlanStartDP,
            d_evm_plan_finish: v.PlanFinishDP,
            PlannedAmount: v.PlannedAmount,
            d_evm_actual_start: v.ActualStartDOP,
            d_evm_actual_finish: v.ActualFinishDOP,
            childplan: dateRange(v.PlanStartDP, v.PlanFinishDP),
            childactual: dateRange2(v.ActualStartDOP, v.ActualFinishDOP),
            rate: Number(v.uuu_P6PercentComplete)

        }
    })


    var dataarea = selectdata, keys = ['Area'], gresult = [], temp = { _: gresult };
    dataarea.forEach(function (a) {
        keys.reduce(function (r, k) {
            if (!r[a[k]]) {
                r[a[k]] = { _: [] };
                r._.push({ text: a['Area'], items: r[a[k]]._ });
            }
            return r[a[k]];
        }, temp)._.push({ text: a.Subarea });
    });
    const [expanded, setExpanded] = useState(['']);

    const [filter, setFilter] = React.useState({
        value: "",
        operator: "contains",
    });


    const onChange = (event) => setValue2(event.value);

    const onExpandChange = React.useCallback(
        (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
        [expanded]
    );

    const treeData = React.useMemo(
        () =>
            processTreeData(
                gresult,
                {
                    expanded,
                    value2,
                    filter
                },
                fields
            ),
        [expanded, value2, gresult, filter]
    );


    const onFilterChange = (event) => setFilter(event.filter);



    const plan = dayweight.reduce((c, v) => c.concat(v.childplan.map(o => Object.assign(o, { group: v.group, "planvalue": o.daysratio * v.PlannedAmount }))), []);
    const actual = _.filter(dayweight, function (o) { return typeof o.childactual !== 'undefined'; }).reduce((c, v) => c.concat(v.childactual.map(o => Object.assign(o, { group: v.group, "earnedvalue": o.daysratio * (v.PlannedAmount * v.rate / 100) }))), []);

    const total_pv = _.sumBy(plan, 'planvalue')

    var pv =
        _(plan)
            .groupBy('month')
            .map((objs, key) => ({
                'month': key,
                'PV': _.sumBy(objs, 'planvalue'),
                'C_PV': (_.sumBy(objs, 'planvalue') / total_pv) * 100,
            }))
            .value();
    const pv_sort = _.sortBy(pv, 'month')

    let cumpv = 0;
    let cumpv2 = 0;
    const cumpv_with = pv_sort.map(({ month, PV, C_PV }) => ({ month, PV, CC_PV: Number((cumpv += C_PV)), C_PV2: cumpv2 += PV }));


    var ev =
        _(actual)
            .groupBy('month')
            .map((objs, key) => ({
                'month': key,
                'EV': _.sumBy(objs, 'earnedvalue'),
                'C_EV': (_.sumBy(objs, 'earnedvalue') / total_pv) * 100
            }))
            .value();

    const ev_sort = _.sortBy(ev, 'month')


    let cumev = 0;
    const cumev_with = ev_sort.map(({ month, EV, C_EV }) => ({ month, EV, CC_EV: Number((cumev += C_EV)) }));

    const [montharray, setmontharray] = useState([]);

    useEffect(() => {

        if (data2.length > 0) {

            var startDate = moment(_.sortBy(_.filter(data2, function (o) { return typeof o.PlanStartDP !== 'undefined'; }).map((v) => ({ month: v.PlanStartDP.slice(6, 10) + '-' + v.PlanStartDP.slice(0, 5) })), 'month')[0]['month'])
            var endDate = moment(_.sortBy(_.filter(data2, function (o) { return typeof o.PlanFinishDP !== 'undefined'; }).map((v) => ({ month: v.PlanFinishDP.slice(6, 10) + '-' + v.PlanFinishDP.slice(0, 5) })), 'month').reverse()[0]['month'])

            var mresult = [];

            if (endDate.isBefore(startDate)) {
                throw "End date must be greated than start date."
            }

            while (startDate.isBefore(endDate)) {
                mresult.push(startDate.format("YYYY-MM-01"));
                startDate.add(1, 'month');
            }
            setmontharray(mresult)
        }

    }, [data2])

    const arrayformonth = montharray.map((v) => ({ month: v.slice(2, 4) + '년 ' + v.slice(5, 7) + '월' }))

    const COLORS = ['#DBF3FF', '#42B7F4', '#33B8B4', '#5E8EFF']

    const result = cumpv_with.length > cumev_with.length ?
        arrayformonth.map(v => ({ ...v, ...cumpv_with.find(sp => sp.month === v.month) }))
            .map(f => ({ ...f, ...cumev_with.find(sf => sf.month === f.month) }))
        // .map((v, index)=> ({...v, color:COLORS[index]}))
        :

        arrayformonth.map(v => ({ ...v, ...cumev_with.find(sp => sp.month === v.month) }))
            .map(f => ({ ...f, ...cumpv_with.find(sf => sf.month === f.month) }))



    const SharedTooltip = (props) => {
        const { points } = props;
        return (
            <div>
                <div>{points[0].category}</div>
                {points.map((point, index) => (
                    (point.series.name === '월간 실적' || point.series.name === '월간 계획') && point.series.name !== ''?
                        <div key={index}>
                            {point.value === 0 ?
                                <span>{point.series.name} :0 </span>
                                :
                                <span>{point.series.name} :{Number.parseFloat(point.value / 1000).toFixed(0) + '천'}</span>
                            }
                        </div> :
                    (point.series.name === '누적 실적' || point.series.name === '누적 계획') && point.series.name !== ''?

                        <div key={index}>
                            {point.value === 0 ?
                                <span>{point.series.name} :0% </span> :
                                <span>{point.series.name} : {Number.parseFloat(point.value).toFixed(2) + '%'}</span>}
                        </div>
                    :''

                ))}
            </div>
        );
    };

    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const labelContentAxis = (e) => e.value > 1000000 ? (e.value / 1000000).toFixed(0) + '백만' : e.value;
    const labelContentAxis1 = (e) => e.value > 100 ? '' : e.value + '%';

    const [zoomableyn, setzoomableyn] = useState(false);

    const toggleZoom = () => {
        setzoomableyn(!zoomableyn);
    };

    const chart = useRef(null);

    const onImageExportClick = () => {
        const chartVisual = exportVisual(chart.current);

        if (chartVisual) {
            exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "SCurvechart.png"));
        }
    };


    const axisCrossingValue = [0, result.length];


    const charttitle =
        (value2 === null || value2.text === '') && value === null ?
            'S-Curve (ALL)' :
            value2 !== null && value === null ?
                `S-Curve (Area: ${value2.text})` :
                value2 === null && value !== null ?
                    `S-Curve (공종: ${value})` :
                    `S-Curve (Area: ${value2.text} / 공종: ${value})`


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    
    const handleChange2 = (event) => {
        setValue3(event.target.value);
    };



    const last_month = moment().format("YY년 MM월")

    const planline1 = result.filter(v => Number(v.month.slice(0, 2) + v.month.slice(4, 6)) <= Number(last_month.slice(0, 2) + last_month.slice(4, 6)))
    const planline2 = result.filter(v => Number(v.month.slice(0, 2) + v.month.slice(4, 6)) >= Number(last_month.slice(0, 2) + last_month.slice(4, 6)))

    const planpercent =
        typeof _.get(_.filter(result, { 'month': last_month })[0], 'CC_PV') !== 'undefined' ? _.get(_.filter(result, { 'month': last_month })[0], 'CC_PV') : 100
    const actualpercent =
        typeof _.get(_.filter(result, { 'month': last_month })[0], 'CC_EV') !== 'undefined' ? _.get(_.filter(result, { 'month': last_month })[0], 'CC_EV') : _.get(_.maxBy(result, 'CC_EV'), 'CC_EV')



    return (
        <>
            <GridLayout style={{ height: '600px' }}
                rows={[
                    {
                        height: '70%',
                    },

                    {
                        height: '10%',
                    },
                    {
                        height: '10%',
                    },
                    {
                        height: '10%',
                    },
                ]}

                cols={[
                    {
                        width: '1%',
                    },
                    {
                        width: '17%',
                    }, {
                        width: '82%',
                    },


                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>

                <GridLayoutItem col={2} row={1}>
                    <Card style={{ width: '100%', height: '300px' }}>
                        <CardBody style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -40%)' }}>
                                <CardTitle style={{ textAlign: 'center', color: '#363945' }}>Dev.</CardTitle>
                                <CardTitle style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>

                                    {planpercent && actualpercent - planpercent > 0 ?
                                        <span style={{ color: '#00539C' }}>{(actualpercent - planpercent).toFixed(2)}</span> :
                                        <span style={{ color: '#9E1030' }}>{(actualpercent - planpercent).toFixed(2)}</span>}%</CardTitle>
                            </div>
                            <div style={{ position: 'absolute', left: '5px', bottom: '5px' }}>
                                <CardSubtitle style={{ color: '#939597', fontSize: '1rem', textAlign: 'center' }}>Plan</CardSubtitle>
                                <CardSubtitle style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}>  {planpercent && planpercent.toFixed(2)}%</CardSubtitle>
                            </div>

                            <div style={{ position: 'absolute', right: '5px', bottom: '5px' }}>
                                <CardSubtitle style={{ textAlign: 'center', color: '#939597', fontSize: '1rem' }}>Actual</CardSubtitle>
                                <CardSubtitle style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold', color: 'black' }}>{actualpercent && actualpercent.toFixed(2)}%</CardSubtitle>
                            </div>
                        </CardBody>
                    </Card>


                </GridLayoutItem>

                <GridLayoutItem col={2} row={2}>
                    <div>
                        <div style={{ marginLeft: '5px' }}>Area</div>
                        <DropDownTree
                            style={{
                                width: "100%", marginRight: '5px'
                            }}
                            data={treeData}
                            value={value2}
                            onChange={onChange}
                            placeholder="Select ..."
                            textField={textField}
                            dataItemKey={dataItemKey}
                            selectField={selectField}
                            expandField={expandField}
                            onExpandChange={onExpandChange}
                            filterable={true}
                            onFilterChange={onFilterChange}
                            filter={filter.value}
                        />
                    </div>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={4}>
                    <div>
                        <div style={{ marginLeft: '5px' }}>공종</div>
                        <ComboBox
                            style={{
                                width: "100%", marginRight: '5px'
                            }}
                            data={selectdata2}
                            value={value}
                            onChange={handleChange}
                            className='disc'
                        />
                    </div>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={3}>
                    <div style={{display: value2 !== null && value2.text.includes('세대') ? 'block':'none'}}>
                        <div style={{ marginLeft: '5px' }}>세대</div>
                        <ComboBox
                            style={{
                                width: "100%", marginRight: '5px'
                            }}
                            data={selectdata3}
                            value={value3}
                            onChange={handleChange2}
                            className='disc'
                        />
                    </div>
                </GridLayoutItem>


                <GridLayoutItem col={3} row={1} rowSpan={4}>

                    <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                        <span></span>
                        <ButtonGroup>
                            <Button
                                title="확대/이동 켜기"
                                iconClass='k-icon k-i-zoom-in'
                                themeColor={zoomableyn ? "primary" : null} fillMode="flat"
                                onClick={toggleZoom}
                            >
                            </Button>

                            <Button
                                title="이미지로 저장"
                                iconClass='k-icon k-i-image-export'
                                fillMode="flat"
                                onClick={onImageExportClick}
                            >
                            </Button>


                        </ButtonGroup>
                    </div>


                    <Chart ref={chart} pannable={zoomableyn} zoomable={zoomableyn}>
                        <ChartTitle text={charttitle} font="1.2rem pretendard-R" />
                        <ChartArea height={600} margin={20} />
                        <ChartLegend position="bottom" orientation="horizontal" />
                        <ChartValueAxis>
                            <ChartValueAxisItem name='월간' labels={{ content: labelContentAxis, font: "0.7rem Arial, pretendard-R" }} visible={false} majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} />
                            <ChartValueAxisItem name='누적(%)' min={0} labels={{ content: labelContentAxis1, font: "0.7rem Arial, pretendard-R" }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} visible={true} />
                        </ChartValueAxis>
                        <ChartTooltip shared={true} render={sharedTooltipRender} />

                        <ChartSeries>

                            <ChartSeriesItem
                                color='#DBF3FF'
                                type="column"
                                data={result}
                                field="PV"
                                categoryField="month"
                                name="월간 계획"
                                autoFit={true}
                                axis='월간'
                            />


                            <ChartSeriesItem
                                color='#42B7F4'
                                type="column"
                                data={result}
                                field="EV"
                                categoryField="month"
                                name="월간 실적"
                                autoFit={true}
                                axis='월간'


                            />

                            <ChartSeriesItem
                                color='#B1CCE4'
                                type="line"
                                data={planline1}
                                field="CC_PV"
                                categoryField="month"
                                name="누적 계획"
                                autoFit={true}
                                axis='누적(%)'

                            />
                            <ChartSeriesItem
                                color='#B1CCE4'
                                type="line"
                                data={planline2}
                                field="CC_PV"
                                categoryField="month"
                                name=""
                                autoFit={true}
                                axis='누적(%)'
                                dashType='dash'

                            />


                            <ChartSeriesItem
                                color='#5E8EFF'
                                type="line"
                                data={result}
                                field="CC_EV"
                                categoryField="month"
                                name="누적 실적"
                                autoFit={true}
                                axis='누적(%)'

                            />

                        </ChartSeries>
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem labels={{ font: "0.8rem pretendard-R", rotation: { angle: 'auto' } }} axisCrossingValue={axisCrossingValue} majorGridLines={{ step: 100 }} minorGridLines={{ step: 100 }} >
                            </ChartCategoryAxisItem>
                        </ChartCategoryAxis>

                    </Chart>
                </GridLayoutItem>


            </GridLayout>

        </>
    );




};

export default Progress1
