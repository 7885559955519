import React, { useEffect, useState } from "react";
import {
  Chart,
  ChartLegend,
  ChartArea,
  ChartTooltip,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import _ from "lodash";
import "hammerjs";

const TotalPieChart = ({ height, chartData }) => {
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (chartData.length > 0) {
      const total_count = _.sumBy(chartData[1].data, "value");
      setTotalCount(total_count);
    }
  }, [chartData]);

  const labelContent = (e) => {
    return `${e.category}: \n ${Number(e.value.toFixed(0)).toLocaleString()}t`;
  };

  const labelContent2 = (e) => {
    return `${e.category}`;
  };

  const renderTooltip = (context) => {
    const { category, series, value } = context.point || context;
    return (
      <div>
        {category} : {Number(value.toFixed(0)).toLocaleString()}t
      </div>
    );
  };

  const mapSeries = (series, index, array) => {
    return (
      <ChartSeriesItem
        key={index}
        type="donut"
        startAngle={index === array.length - 1 ? 100 : 87}
        name={series.name}
        data={series.data}
        field="value"
        categoryField="category"
        size={height / 10}
        colorField="color"
      >
        {index === array.length - 1 ? (
          <ChartSeriesLabels
            position="outsideEnd"
            background="none"
            font="0.8rem pretendard-R"
            content={labelContent}
          />
        ) : (
          <ChartSeriesLabels
            position="outsideEnd"
            background="none"
            font="0.8rem pretendard-R"
            color="white"
            content={labelContent2}
          />
        )}
      </ChartSeriesItem>
    );
  };

  const donutCenterRenderer = () => {
    return (
      <span style={{ fontWeight: "900", fontSize: "0.7rem" }}>
        <h3>총배출량</h3> {Number(totalCount.toFixed(0)).toLocaleString()}t
      </span>
    );
  };
  return (
    <Chart donutCenterRender={donutCenterRenderer} style={{ height: height }}>
      <ChartArea background="none" />
      <ChartTooltip render={renderTooltip} />
      <ChartLegend position="right" visible={true} />
      <ChartArea background="none" />
      <ChartSeries>{chartData.map(mapSeries)}</ChartSeries>
    </Chart>
  );
};

export default TotalPieChart;
