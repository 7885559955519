import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import _ from "lodash";
import { colorCodes } from "../../../Colors/colors";
import TotalPieChart from "../../../Components/TotalPieChart";
import TotalBarChart from "../../../Components/TotalBarChart";

const TotalPerformance = ({ height, dateRangeData }) => {
  const gridHeight = height - 32;
  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);

  useEffect(() => {
    const uniq_waste_cate = _.uniqBy(dateRangeData, "uxesg_Waste_Cate2").map(
      (com) => com.uxesg_Waste_Cate2
    );

    const waste_cate_obj = {
      name: "가연성 구분",
      data: uniq_waste_cate.map((com) => ({
        category: com,
        value: _.sumBy(
          dateRangeData.filter((com2) => com2.uxesg_Waste_Cate2 === com),
          "uxesg_CarryQty2"
        ),
        color: handleColor(com),
      })),
    };

    const uniq_waste_code = _.uniqBy(dateRangeData, "uxesg_WasteCode").map(
      (com) => com.uxesg_WasteCode
    );

    const waste_code_obj = {
      name: "code",
      data: uniq_waste_code.map((com) => ({
        category: dateRangeData.find((com2) => com2.uxesg_WasteCode === com)?.[
          "uxesg_WastePropertie"
        ],
        value: _.sumBy(
          dateRangeData.filter((com2) => com2.uxesg_WasteCode === com),
          "uxesg_CarryQty2"
        ),
        color: handleColor(com),
      })),
    };

    setChartData([waste_cate_obj, waste_code_obj]);

    const chart_data_2 = _.sortBy(
      uniq_waste_code.map((com) => ({
        code: com,
        category: dateRangeData.find((com2) => com2.uxesg_WasteCode === com)?.[
          "uxesg_Waste_Cate2"
        ],
        name: dateRangeData.find((com2) => com2.uxesg_WasteCode === com)?.[
          "uxesg_WastePropertie"
        ],
        value: _.sumBy(
          dateRangeData.filter((com2) => com2.uxesg_WasteCode === com),
          "uxesg_CarryQty2"
        ),
      })),
      "category"
    );

    setChartData2(
      chart_data_2.map((com) => {
        return [com];
      })
    );
  }, [dateRangeData]);

  return (
    <div>
      <GridLayout
        style={{ height: gridHeight }}
        cols={[
          {
            width: "100%",
          },
        ]}
        rows={[
          {
            height: "55%",
          },
          {
            height: "45%",
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem col={1} row={1}>
          <TotalPieChart height={gridHeight * 0.55} chartData={chartData} />
        </GridLayoutItem>
        <GridLayoutItem col={1} row={2}>
          <TotalBarChart height={gridHeight * 0.45} chartData={chartData2} />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

export default TotalPerformance;

// const handleColor = (idx) => {
//   const colorsLength = colorCodes.length;

//   const num = parseInt(idx / colorsLength);

//   if (idx >= colorCodes.length) {
//     return colorCodes[idx - num * colorsLength];
//   } else {
//     return colorCodes[idx];
//   }
// };

const handleColor = (target) => {
  const find_node = hwenc_color_code.find((com) => com.code === target);

  return find_node === undefined ? "#FFDAB9" : find_node["color"];
};

const hwenc_color_code = [
  {
    code: "40-02-06",
    color: "#FF0000",
  },
  {
    code: "40-02-07",
    color: "#DC143C",
  },
  {
    code: "40-02-08",
    color: "#B22222",
  },
  {
    code: "40-02-09",
    color: "#8B4513",
  },
  {
    code: "40-01-01",
    color: "#DCDCDC ",
  },
  {
    code: "40-01-02",
    color: "#2F4F4F",
  },
  {
    code: "40-01-03",
    color: "#D3D3D3",
  },
  {
    code: "40-01-04",
    color: "#A9A9A9",
  },
  {
    code: "40-01-05",
    color: "#778899",
  },
  {
    code: "40-04-13",
    color: "#CD853F",
  },
  {
    code: "40-03-10",
    color: "#D2691E",
  },
  {
    code: "40-03-11",
    color: "#696969",
  },
  {
    code: "40-03-12",
    color: "#E0FFFF",
  },
  {
    code: "40-04-10",
    color: "#F0F8FF",
  },
  {
    code: "40-04-11",
    color: "#F5F5DC",
  },
  {
    code: "40-04-12",
    color: "#FAF0E6",
  },
  {
    code: "40-04-14",
    color: "#D2B48C",
  },
  {
    code: "가연성",
    color: "#FF4500",
  },
  {
    code: "불연성",
    color: "#4682B4",
  },
  {
    code: "혼합",
    color: "#B8860B",
  },
  {
    code: "기타",
    color: "#000000",
  },
];
