import React, { useEffect, useState } from "react";
import {
  Chart,
  ChartArea,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import "hammerjs";
import moment from "moment";

const MonthPerformanceChart = ({
  height,
  chartData,
  endDate,
  MonthPerformanceToggle,
}) => {
  const [costData, setCostData] = useState({
    last: 0,
    lastCost: 0,
    twoLast: 0,
    twoLastCost: 0,
  });

  useEffect(() => {
    const findIndex = chartData.findIndex((com) => com.months === endDate);
    const findIndex2 = chartData.findIndex(
      (com) =>
        com.months ===
        moment(new Date(endDate)).subtract(1, "months").format("YYYY-MM")
    );

    setCostData((prev) => ({
      ...prev,
      last:
        chartData[findIndex]?.["value"] === undefined
          ? 0
          : chartData[findIndex]["value"],
      lastCost:
        chartData[findIndex]?.["cost"] === undefined
          ? 0
          : chartData[findIndex]["cost"],
      twoLast:
        chartData[findIndex2]?.["value"] === undefined
          ? 0
          : chartData[findIndex2]["value"],
      twoLastCost:
        chartData[findIndex2]?.["cost"] === undefined
          ? 0
          : chartData[findIndex2]["cost"],
    }));
  }, [chartData, endDate]);

  const handleColor = (props) => {
    if (props.category === moment(new Date(endDate)).format("YYYY-MM")) {
      return "#F0696E";
    } else {
      return "#4F81BD";
    }
  };

  const chartLabelContents = (e) => {
    const find_index = chartData.find((com) => com.months === e.category);

    if (MonthPerformanceToggle) {
      return e.value === 0 || e.value === null || typeof e.value === "undefined"
        ? ""
        : Number(e.value.toFixed(0)).toLocaleString() +
            "t\n" +
            Number(find_index["cost"].toFixed(0)).toLocaleString() +
            "백만원";
    } else {
      return e.value === 0 || e.value === null || typeof e.value === "undefined"
        ? ""
        : Number(e.value.toFixed(0)).toLocaleString() + "백만원";
    }
  };

  const chartValueAxisContents = (e) => {
    return e.value === 0 || e.value === null || typeof e.value === "undefined"
      ? ""
      : Number(e.value.toFixed(0)).toLocaleString();
  };

  const labelContent = (e) =>
    e.value === null || typeof e.value === "undefined"
      ? ""
      : moment(new Date(e.value)).format("YY-MM");

  const seriesLabels = {
    visible: true,
    padding: 0,
    font: "0.6rem pretendard-R",
    background: "none",
    rotation: { angle: "auto" },
    content: labelContent,
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const SharedTooltip = (props) => {
    const { points, categoryText } = props;

    return (
      <div>
        <div>{categoryText}</div>
        {points.map((point, idx) => {
          return (
            <div key={idx}>
              <div>
                처리량: {Number(point.value.toFixed(0)).toLocaleString()}t
              </div>
              <div>
                처리비용:{" "}
                {Number(
                  chartData
                    .find((com) => com.months === point.category)
                    ?.["cost"].toFixed(0)
                ).toLocaleString()}
                백만원
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          height: "25px",
          fontSize: "0.8rem",
          fontFamily: "Pretendard-B",
          textAlign: "center",
        }}
      >{`전월대비 처리량 ${Number(
        (costData.last - costData.twoLast).toFixed(0)
      ).toLocaleString()}t / 처리비용 ${Number(
        (costData.lastCost - costData.twoLastCost).toFixed(0)
      ).toLocaleString()}백만원`}</div>
      <Chart
        style={{
          height: height - 25,
        }}
      >
        <ChartArea background="none" margin={{ top: 30 }} />
        <ChartLegend position="bottom" orientation="horizontal" />
        <ChartTooltip shared={true} render={sharedTooltipRender} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            maxDivisions={10}
            startAngle={45}
            labels={seriesLabels}
            majorGridLines={{ step: 100 }}
            minorGridLines={{ step: 100 }}
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem
            labels={{
              font: "0.6rem pretendard-R",
              content: chartValueAxisContents,
            }}
            majorTicks={{ step: 100 }}
            minorTicks={{ step: 100 }}
            majorGridLines={{ step: 10 }}
            minorGridLines={{ step: 10 }}
          />
        </ChartValueAxis>
        <ChartSeries>
          <ChartSeriesItem
            type={"column"}
            data={chartData}
            field={MonthPerformanceToggle ? "value" : "cost"}
            autoFit={true}
            categoryField="months"
            color={handleColor}
            labels={{
              visible: true,
              content: chartLabelContents,
              background: "none",
              // position: "top",
              color: "black",
              font: "0.6rem pretendard-R",
            }}
          />
        </ChartSeries>
      </Chart>
    </>
  );
};

export default MonthPerformanceChart;
