import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import _ from "lodash";
import TotalTable from "./TotalTable/TotalTable";
import SectorTable from "./SectorTable/SectorTable";

const TotalPerformance = ({ width, height, dateRangeData, base_code }) => {
  const gridWidth = width - 32 - 16;
  const gridHeight = height - 32 - 45;
  const [tableDatas, setTableDatas] = useState([]);

  useEffect(() => {
    const data_set = dateRangeData.map((com) => ({
      ...com,
      base_code_data: base_code
        .map((com2) => {
          if (com2.code === "others") {
            return {
              ...com2,
              data: com.data.filter(
                (com3) =>
                  com3.uxesg_WasteCode !== "40-01-01" &&
                  com3.uxesg_WasteCode !== "40-02-07" &&
                  com3.uxesg_WasteCode !== "40-02-06" &&
                  com3.uxesg_WasteCode !== "40-04-14"
              ),
            };
          } else {
            return {
              ...com2,
              data: com.data.filter(
                (com3) => com3.uxesg_WasteCode === com2.code
              ),
            };
          }
        })
        .map((com2) => ({
          ...com2,
          totalWeight: _.sumBy(com2.data, "uxesg_CarryQty2"),
          totalCost: _.sumBy(com2.data, "uxesg_CostTotal"),
        })),
    }));

    setTableDatas(data_set);
  }, [dateRangeData, base_code]);

  return (
    <div>
      <GridLayout
        style={{ width: gridWidth, height: gridHeight, alignItems: "" }}
        cols={[
          {
            width: gridWidth * 0.5 - 5,
          },
          {
            width: gridWidth * 0.5 - 5,
          },
        ]}
        rows={[
          {
            height: "100%",
          },
        ]}
        gap={{
          cols: 10,
          rows: 0,
        }}
      >
        <GridLayoutItem col={1} row={1}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: "0.8rem", fontFamily: "Pretendard-B" }}>
              [전사 총괄]
            </div>
            <div style={{ fontSize: "0.8rem", fontFamily: "Pretendard-B" }}>
              [반출량 : t / 비용 : 백만원]
            </div>
          </div>
          <TotalTable height={gridHeight} tableDatas={tableDatas} />
        </GridLayoutItem>
        <GridLayoutItem col={2} row={1}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ fontSize: "0.8rem", fontFamily: "Pretendard-B" }}>
              [사업본부별]
            </div>
            <div style={{ fontSize: "0.8rem", fontFamily: "Pretendard-B" }}>
              [반출량 : t / 비용 : 백만원]
            </div>
          </div>
          <SectorTable height={gridHeight} tableDatas={tableDatas} />
        </GridLayoutItem>
      </GridLayout>
    </div>
  );
};

export default TotalPerformance;
