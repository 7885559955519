import React, { useMemo } from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

import {
  SubTitleDiv,
  headerClass,
  LGChemicalTable,
} from "../../../Public/StyledComponents";
import { normalCell, listNumberCell } from "../../../Public/TableCell";

const ExpectedMaterials = ({
  height,
  jobPlansData,
  pmBooksData,
  materialInventoryManagerData,
}) => {
  const expectedMaterialsTableData = useMemo(() => {
    return pmBooksData
      .map((com) => {
        return {
          ...com,
          material_list: jobPlansData
            .filter((com2) => com2.ujpJobPlanName === com.ujpJobPlanName)
            .map((com2) => {
              const find_Inventory = materialInventoryManagerData.find(
                (com3) => com3.uuu_cm5_name === com2.uuu_cm5_name
              );
              return {
                ...com2,
                uskuQuantityInHandDA:
                  find_Inventory?.["uskuQuantityInHandDA"] === undefined
                    ? 0
                    : find_Inventory?.["uskuQuantityInHandDA"],
              };
            }),
        };
      })
      .reduce(
        (acc, cur) =>
          acc.concat(
            cur.material_list.map((com) => {
              return Object.assign(com, {
                upmEqpName: cur.upmEqpName,
                due_date: cur.due_date,
              });
            })
          ),
        []
      )
      .map((com, idx) => {
        const { upwoEstiQtyDA, uskuQuantityInHandDA } = com;

        return {
          ...com,
          No: idx + 1,
          waring: upwoEstiQtyDA > uskuQuantityInHandDA,
        };
      });
  }, [jobPlansData, pmBooksData, materialInventoryManagerData]);

  return (
    <>
      <GridLayoutItem row={1} col={3}>
        <SubTitleDiv text_position={"flex-start"}>예상 소요 자재</SubTitleDiv>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={3} rowSpan={3}>
        <LGChemicalTable>
          <Grid
            className={headerClass}
            style={{ width: "100%", maxHeight: height * 0.9 }}
            data={expectedMaterialsTableData}
          >
            <GridColumn
              title="No."
              field="No"
              width={45}
              cell={listNumberCell}
            />
            <GridColumn
              title="Work Order Name"
              field="ujpJobPlanName"
              cell={normalCell}
            />
            <GridColumn title="자재명" field="uuu_cm5_name" cell={normalCell} />
            <GridColumn
              title="단위"
              field="uuu_resc_assign_uom"
              cell={normalCell}
              width={60}
            />
            <GridColumn
              title="필요수량"
              field="upwoEstiQtyDA"
              cell={normalCell}
              width={80}
            />
            <GridColumn
              title="재고량"
              field="uskuQuantityInHandDA"
              cell={normalCell}
              width={60}
            />
            <GridColumn
              title="Warning"
              field="waring"
              cell={waringCell}
              width={80}
            />
          </Grid>
        </LGChemicalTable>
      </GridLayoutItem>
    </>
  );
};

export default ExpectedMaterials;

const waringCell = (props) => {
  const { field, dataItem } = props;

  return (
    <td
      colSpan={props.colSpan}
      style={{
        ...props.style,
        fontSize: "0.7rem",
      }}
    >
      {dataItem[field] && (
        <div
          style={{
            width: "100%",
            height: "1rem",
            borderRadius: "0.4rem",
            backgroundColor: "rgba(255, 0, 0, 0.5)",
          }}
        ></div>
      )}
    </td>
  );
};
