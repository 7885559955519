import React, { useEffect, useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import styled from "styled-components";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import DetailDialog from "./DetailDialog";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH363108325");

const CostActPage = ({
  height,
  projectCostPlan,
  sliceChartName,
  setSliceChartName,
  sliceChartIndex,
  setSliceChartIndex,
  projectCostToggle,
  setProjectCostToggle,
}) => {
  const viewPort = useWindowDimensions();
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    if (projectCostPlan.length > 0) {
      const find_index = projectCostPlan.find(
        (com) => com.key === sliceChartName
      );

      const { payment_application, document_change_cost_approved } = find_index;

      if (
        payment_application.length > 0 ||
        document_change_cost_approved.length > 0
      ) {
        const drawing = document_change_cost_approved.filter(
          (com) => com.type === "Drawing"
        );

        const design = document_change_cost_approved.filter(
          (com) => com.type === "Design"
        );

        const pieDataSet = [
          {
            type: "기성 지급",
            cost: _.sumBy(payment_application, "upaCurrPaymentDueCA"),
            color: colorList[0],
            subs: payment_application.map((com) => ({
              type: com.title,
              cost: com.upaCurrPaymentDueCA,
            })),
          },
          {
            type: "설계변경(설계)",
            cost: _.sumBy(drawing, "EstimatedBudget"),
            color: colorList[1],
            subs: drawing.map((com) => ({
              type: com.title,
              cost: com.EstimatedBudget,
            })),
          },
          {
            type: "설계변경(공사)",
            cost: _.sumBy(design, "EstimatedBudget"),
            color: colorList[2],
            subs: design.map((com) => ({
              type: com.title,
              cost: com.EstimatedBudget,
            })),
          },
        ];

        setPieData(pieDataSet);
      } else {
        setPieData([]);
      }
    }
  }, [projectCostPlan, sliceChartName]);

  useEffect(() => {
    if (pieData.length > 0) {
      let chart = am4core.create("qchart2", am4charts.PieChart);

      chart.colors.list = colorList.map(function (color) {
        return new am4core.color(color);
      });

      chart.numberFormatter.bigNumberPrefixes = [
        { number: 1e3, suffix: "천" },
        { number: 1e6, suffix: "백만" },
        { number: 1e8, suffix: "억" },
      ];

      // Set data
      let selected;

      // Add data
      chart.data = generateChartData();

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "cost";
      pieSeries.dataFields.category = "type";
      pieSeries.slices.template.propertyFields.fill = "color";
      pieSeries.slices.template.propertyFields.isActive = "pulled";
      pieSeries.slices.template.strokeWidth = 0;

      pieSeries.slices.template.tooltipText =
        "{category}: {value.formatNumber('#,###.0a')}원";

      function generateChartData() {
        let chartData = [];

        for (var i = 0; i < pieData.length; i++) {
          if (i === selected) {
            for (var x = 0; x < pieData[i].subs.length; x++) {
              console.log(pieData[i].subs[x].type);
              chartData.push({
                type: pieData[i].subs[x].type,
                cost: pieData[i].subs[x].cost,
                color: pieData[i].color,
                pulled: true,
              });
            }
          } else {
            chartData.push({
              type: pieData[i].type,
              cost: pieData[i].cost,
              color: pieData[i].color,
              id: i,
            });
          }
        }
        return chartData;
      }

      pieSeries.slices.template.events.on("hit", function (event) {
        if (event.target.dataItem.dataContext.id !== undefined) {
          selected = event.target.dataItem.dataContext.id;
        } else {
          selected = undefined;
        }
        chart.data = generateChartData();
      });
      return () => {
        chart && chart.dispose();
      };
    }
  }, [pieData]);

  return (
    <div>
      <AmChartArea id="qchart2" height={height * 0.9} />
      {projectCostToggle && (
        <Dialog
          title={"부서별 예산 집행 상세 현황"}
          onClose={() => setProjectCostToggle((prev) => !prev)}
          width={viewPort.width * 0.9}
          height={viewPort.height * 0.9 - 210}
        >
          <DetailDialog projectCostPlan={projectCostPlan} />
        </Dialog>
      )}
    </div>
  );
};

export default CostActPage;

const AmChartArea = styled.div`
  width: 100%;
  ${(props) => {
    return { height: props.height };
  }};
  z-index: 999;
  margin: auto;
`;

const colorList = [
  "#0072B5",
  "#00A170",
  "#D2386C",
  "#CD212A",
  "#FFA500",
  "#00758F",
  "#9C4722",
];

// const bgColor = (color, i) => {
//   if (i >= color.length) {
//     return color[i % color.length];
//   } else {
//     return color[i];
//   }
// };

// const pieDataFormat = [
//   {
//     type: "기성 지급",
//     cost: 0,
//     subs: [],
//   },
//   {
//     type: "설계변경(설계)",
//     cost: 0,
//     subs: [],
//   },
//   {
//     type: "설계변경(공사)",
//     cost: 0,
//     subs: [],
//   },
// ];

// let types = [
//   {
//     type: "Fossil Energy",
//     cost: 70,
//     subs: [
//       {
//         type: "Oil",
//         cost: 15,
//       },
//       {
//         type: "Coal",
//         cost: 35,
//       },
//       {
//         type: "Nuclear",
//         cost: 20,
//       },
//     ],
//   },
//   {
//     type: "Green Energy",
//     cost: 30,
//     subs: [
//       {
//         type: "Hydro",
//         cost: 15,
//       },
//       {
//         type: "Wind",
//         cost: 10,
//       },
//       {
//         type: "Other",
//         cost: 5,
//       },
//     ],
//   },
// ].map((com, idx) => ({
//   ...com,
//   color: am4core.color(bgColor(colorList, idx)),
// }));
