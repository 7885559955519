import React, { useEffect, useState } from "react";
import _ from "lodash";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import OverallPerformanceChart from "../../../Components/OverallPerformanceChart";

const OverallPerformance = ({ height, sectorData, setTargetPerformance }) => {
  const gridHeight = height - 32 - 45;
  const [chartDatas, setChartDatas] = useState([]);

  useEffect(() => {
    if (sectorData.length > 1) {
      const chartDatas_base_set = sectorData
        .map((com) => ({
          ...com,
          plan: Number(
            _.sumBy(com.data, "project_total_target_weight").toFixed(0)
          ),
          actual: Number(
            _.sumBy(com.data, "project_total_act_weight").toFixed(0)
          ),
        }))
        .map((com) => ({
          ...com,
          dev: com.plan - com.actual,
          percent:
            Number(((com.actual / com.plan) * 100).toFixed(2)) === Infinity ||
            isNaN(Number(((com.actual / com.plan) * 100).toFixed(2)))
              ? 0
              : Number(((com.actual / com.plan) * 100).toFixed(2)),
        }));

      setChartDatas(chartDatas_base_set);
      setTargetPerformance(chartDatas_base_set);
    }
  }, [sectorData]);

  return (
    <div>
      <GridLayout
        style={{ height: gridHeight }}
        cols={[
          {
            width: "28%",
          },
          {
            width: "24%",
          },
          {
            width: "24%",
          },
          {
            width: "24%",
          },
        ]}
        rows={[
          {
            height: "100%",
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        {chartDatas.map((com, idx) => {
          return (
            <GridLayoutItem col={idx + 1} row={1} key={idx}>
              <OverallPerformanceChart height={gridHeight} com={com} />
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default OverallPerformance;
