import React, { useEffect, useState, useLayoutEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Spirited from "@amcharts/amcharts5/themes/Spirited";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import * as am5percent from "@amcharts/amcharts5/percent";
const CHART_ID = 'prdchart';

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#004098',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.6rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        // borderRadius: '8px 0px 0px 8px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important',
        width: 100

    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0)
        }
    }
}));


const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});

const PRDChart = ({ height }) => {
    const [selectedValue, setSelectedValue] = useState("1");
    const defaultvalue = new Date()
    const [datevalue, setDateValue] = React.useState([
        dayjs(dayjs(defaultvalue).format("YYYY-MM-DD")),
        dayjs(dayjs(defaultvalue).format("YYYY-MM-DD")),
    ]);

    const handleChange = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
        }
    };

    useLayoutEffect(() => {
        let root = am5.Root.new(CHART_ID);

        // Create custom theme
        // https://www.amcharts.com/docs/v5/concepts/themes/#Quick_custom_theme
        var myTheme = am5.Theme.new(root);
        myTheme.rule("Label").set("fontSize", "0.8em");

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root),
            myTheme
        ]);

        // Create wrapper container
        var container = root.container.children.push(am5.Container.new(root, {
            width: am5.p100,
            height: am5.p100,
            layout: root.horizontalLayout
        }));

        // Create first chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var chart0 = container.children.push(am5percent.PieChart.new(root, {
            innerRadius: am5.p50,
            tooltip: am5.Tooltip.new(root, {})
        }));

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var series0 = chart0.series.push(am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category",
            alignLabels: false
        }));

        series0.labels.template.setAll({
            textType: "circular",
            templateField: "dummyLabelSettings"
        });

        series0.ticks.template.set("forceHidden", true);

        series0.slices.template.set("tooltipText", "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value})");


        series0.get("colors").set("colors", [
            am5.color(0x095256),
            am5.color(0x087f8c),
            am5.color(0x5aaa95),
            am5.color(0x86a873),
            am5.color(0xbb9f06)
          ]);
        var sliceTemplate0 = series0.slices.template;
        sliceTemplate0.setAll({
            draggable: true,
            templateField: "settings",
            cornerRadius: 5
        });

        // Separator line
        container.children.push(am5.Line.new(root, {
            layer: 1,
            height: am5.percent(60),
            y: am5.p50,
            centerY: am5.p50,
            strokeDasharray: [4, 4],
            stroke: root.interfaceColors.get("alternativeBackground"),
            strokeOpacity: 0.5
        }));

        // Label
        container.children.push(am5.Label.new(root, {
            layer: 1,
            text: "선 너머로 조각을 끌어보세요",
            y: am5.p50,
            textAlign: "center",
            rotation: -90,
            isMeasured: false
        }));

        // Create second chart
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
        var chart1 = container.children.push(am5percent.PieChart.new(root, {
            innerRadius: am5.p50,
            tooltip: am5.Tooltip.new(root, {})
        }));

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var series1 = chart1.series.push(am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category",
            alignLabels: false
        }));

        series1.labels.template.setAll({
            textType: "circular",
            radius: 20,
            templateField: "dummyLabelSettings"
        });

        series1.ticks.template.set("forceHidden", true);

        series1.slices.template.set("tooltipText", "{category}: [bold]{valuePercentTotal.formatNumber('0.00')}%[/] ({value})");

        series1.get("colors").set("colors", [
            am5.color(0x095256),
            am5.color(0x087f8c),
            am5.color(0x5aaa95),
            am5.color(0x86a873),
            am5.color(0xbb9f06)
          ]);

        var sliceTemplate1 = series1.slices.template;
        sliceTemplate1.setAll({
            draggable: true,
            templateField: "settings",
            cornerRadius: 5
        });

        var previousDownSlice;

        // change layers when down
        sliceTemplate0.events.on("pointerdown", function (e) {
            if (previousDownSlice) {
                //  previousDownSlice.set("layer", 0);
            }
            e.target.set("layer", 1);
            previousDownSlice = e.target;
        });

        sliceTemplate1.events.on("pointerdown", function (e) {
            if (previousDownSlice) {
                // previousDownSlice.set("layer", 0);
            }
            e.target.set("layer", 1);
            previousDownSlice = e.target;
        });

        // when released, do all the magic
        sliceTemplate0.events.on("pointerup", function (e) {
            series0.hideTooltip();
            series1.hideTooltip();

            var slice = e.target;
            if (slice.x() > container.width() / 4) {
                var index = series0.slices.indexOf(slice);
                slice.dataItem.hide();

                var series1DataItem = series1.dataItems[index];
                series1DataItem.show();
                series1DataItem.get("slice").setAll({ x: 0, y: 0 });

                handleDummy(series0);
                handleDummy(series1);
            } else {
                slice.animate({
                    key: "x",
                    to: 0,
                    duration: 500,
                    easing: am5.ease.out(am5.ease.cubic)
                });
                slice.animate({
                    key: "y",
                    to: 0,
                    duration: 500,
                    easing: am5.ease.out(am5.ease.cubic)
                });
            }
        });

        sliceTemplate1.events.on("pointerup", function (e) {
            var slice = e.target;

            series0.hideTooltip();
            series1.hideTooltip();

            if (slice.x() < container.width() / 4) {
                var index = series1.slices.indexOf(slice);
                slice.dataItem.hide();

                var series0DataItem = series0.dataItems[index];
                series0DataItem.show();
                series0DataItem.get("slice").setAll({ x: 0, y: 0 });

                handleDummy(series0);
                handleDummy(series1);
            } else {
                slice.animate({
                    key: "x",
                    to: 0,
                    duration: 500,
                    easing: am5.ease.out(am5.ease.cubic)
                });
                slice.animate({
                    key: "y",
                    to: 0,
                    duration: 500,
                    easing: am5.ease.out(am5.ease.cubic)
                });
            }
        });

        // data
        var data = [
            {
                category: "Dummy",
                value: 1000,
                settings: {
                    fill: am5.color(0xdadada),
                    stroke: am5.color(0xdadada),
                    fillOpacity: 0.3,
                    strokeDasharray: [4, 4],
                    tooltipText: null,
                    draggable: false
                },
                dummyLabelSettings: {
                    forceHidden: true
                }
            },
            {
                category: "A 제품",
                value: 501.9
            },
            {
                category: "B 제품",
                value: 301.9
            },
            {
                category: "C 제품",
                value: 201.1
            },
            {
                category: "D 제품",
                value: 165.8
            },
            {
                category: "E 제품",
                value: 139.9
            },
            {
                category: "F 제품",
                value: 128.3
            }
        ];

        // show/hide dummy slice depending if there are other visible slices
        function handleDummy(series) {
            // count visible data items
            var visibleCount = 0;
            am5.array.each(series.dataItems, function (dataItem) {
                if (!dataItem.isHidden()) {
                    visibleCount++;
                }
            });
            // if all hidden, show dummy
            if (visibleCount == 0) {
                series.dataItems[0].show();
            } else {
                series.dataItems[0].hide();
            }
        }
        // set data
        series0.data.setAll(data);
        series1.data.setAll(data);

        // hide all except dummy
        am5.array.each(series1.dataItems, function (dataItem) {
            if (dataItem.get("category") != "Dummy") {
                dataItem.hide(0);
            }
        });

        // hide dummy
        series0.dataItems[0].hide(0);

        // reveal container
        container.appear(1000, 100);

        return () => {
            root && root.dispose();
        };

    }, []);

    return (
        <>
            <div style={{ display: "flex", fontSize: '0.8rem', zIndex: 9999, justifyContent: "flex-end", position: "absolute", top: 0, right: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['SingleInputDateRangeField']} sx={{ marginRight: 1 }}>
                        <DateRangePicker
                            slots={{ field: SingleInputDateRangeField }}
                            slotProps={{ textField: { size: 'small' } }}
                            name="allowedRange"
                            format='YYYY-MM-DD'
                            value={datevalue}
                            onChange={(newValue) => setDateValue(newValue)}
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <ThemeProvider theme={theme}>
                    <ToggleButtonGroup size="small" onChange={handleChange} value={selectedValue} exclusive aria-label="baseunit">
                        <ToggleButton value="1" aria-label='공헌이익률1' >
                            취급액 기준
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="공헌이익률2">
                            공헌이익금 기준
                        </ToggleButton>
                    </ToggleButtonGroup>
                </ThemeProvider>
            </div>
            <div id={CHART_ID} style={{ width: "100%", height: (height / 5) * 2, zIndex: '999', margin: '0 auto', marginTop: 30}}></div>
        </>
    );
};

export default PRDChart
