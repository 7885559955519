import React, { useEffect, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import { Calendar } from "@progress/kendo-react-dateinputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl";
import moment from "moment";
import _ from "lodash";
import "@progress/kendo-theme-default/dist/all.css";
import krMessages from "../../kr.json";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import VendorDialog from "./VendorDialog/VendorDialog";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);
// loadMessages(krMessages, "ko-KR");

const OverallInfoPage = ({
  height,
  selectedValue,
  setSelectedValue,
  dateRange,
  setDateRange,
  gotoReportPage,
  projectActTotalData,
}) => {
  const [calendarToggle, setCalendarToggle] = useState(false);
  const [vendorToggle, setVendorToggle] = useState(false);
  const [tranVendorData, setTranVendorData] = useState([]);
  const [dispVendorData, setDispVendorData] = useState([]);

  useEffect(() => {
    const data_base_set = projectActTotalData.filter(
      (com) =>
        new Date(com.uxesg_CarryDate.slice(0, 10)) >= dateRange.startDate &&
        new Date(com.uxesg_CarryDate.slice(0, 10)) <= dateRange.endDate
    );

    const tran_data_base_set = _.sortBy(
      _.uniqBy(data_base_set, "uxesg_TranVendor")
        .map((com) => ({
          name: com.uxesg_TranVendor,
          data: data_base_set.filter(
            (com2) => com2.uxesg_TranVendor === com.uxesg_TranVendor
          ),
        }))
        .map((com) => ({
          ...com,
          cost_sum: _.sumBy(com.data, "uxesg_TranCostTotal"),
        })),
      "cost_sum"
    )
      .reverse()
      .map((com, idx) => ({ ...com, id: idx + 1 }));

    const disp_data_base_set = _.sortBy(
      _.uniqBy(data_base_set, "uxesg_DispVendor")
        .map((com) => ({
          name: com.uxesg_DispVendor,
          data: data_base_set.filter(
            (com2) => com2.uxesg_DispVendor === com.uxesg_DispVendor
          ),
        }))
        .map((com) => ({
          ...com,
          cost_sum: _.sumBy(com.data, "uxesg_DispCostTotal"),
        })),
      "cost_sum"
    )
      .reverse()
      .map((com, idx) => ({ ...com, id: idx + 1 }));

    setTranVendorData(arr_chunk(tran_data_base_set));
    setDispVendorData(arr_chunk(disp_data_base_set));
  }, [projectActTotalData, dateRange]);

  const handleCalendar = (e, name) => {
    if (name === "endDate") {
      if (dateRange.startDate > e.value) {
        return alert("종료일을 재 설정하여 주시기 바랍니다.");
      }
    }

    if (name === "startDate") {
      if (dateRange.endDate < e.value) {
        return alert("시작일을 재 설정하여 주시기 바랍니다.");
      }
    }

    setDateRange((prev) => ({ ...prev, [name]: e.value }));
  };

  const toggleDialog = () => {
    setCalendarToggle((prev) => !prev);
  };

  const vendorToggleDialog = () => {
    setVendorToggle((prev) => !prev);
  };

  return (
    <div className="projectInfoPage">
      <div className="projectInfoLeft">
        <div className="projectInfoLeftContents">
          {!selectedValue && (
            <div
              className="infoTextBox"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setCalendarToggle((prev) => !prev);
              }}
            >
              <div className="infoTextTitle">기간</div>
              <div className="infoTextContent2">
                {moment(dateRange.startDate).format("YYYY. MM. DD")}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>~</div>
              <div className="infoTextContent2">
                {moment(dateRange.endDate).format("YYYY. MM. DD")}
              </div>
            </div>
          )}
        </div>

        <button className="projectInfoButton" onClick={gotoReportPage}>
          월간 Report 출력
        </button>
      </div>
      <div>
        <ToggleButton
          value="check"
          selected={vendorToggle}
          onChange={() => {
            vendorToggleDialog();
          }}
          sx={{ height: height / 2, marginRight: "10px" }}
        >
          <span
            role="presentation"
            className="k-button-icon k-icon k-i-grid"
          ></span>
        </ToggleButton>
        <ToggleButton
          value="check"
          selected={selectedValue}
          onChange={() => {
            setSelectedValue(!selectedValue);
          }}
          sx={{ height: height / 2 }}
        >
          <img
            src="/images/right-left-solid.svg"
            alt="transfer"
            style={{ width: "16px" }}
          />
        </ToggleButton>
      </div>
      {calendarToggle && (
        <Dialog title={"기간 선택"} onClose={toggleDialog}>
          <div style={{ display: "flex" }}>
            <div>
              <div
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Pretendard-B",
                  marginBottom: "5px",
                }}
              >
                시작일
              </div>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko-KR">
                  <Calendar
                    value={dateRange.startDate}
                    onChange={(e) => handleCalendar(e, "startDate")}
                  />
                </IntlProvider>
              </LocalizationProvider>
            </div>
            <div>
              <div
                style={{
                  fontSize: "0.9rem",
                  fontFamily: "Pretendard-B",
                  marginBottom: "5px",
                }}
              >
                종료일
              </div>
              <LocalizationProvider language="ko-KR">
                <IntlProvider locale="ko-KR">
                  <Calendar
                    value={dateRange.endDate}
                    onChange={(e) => handleCalendar(e, "endDate")}
                  />
                </IntlProvider>
              </LocalizationProvider>
            </div>
          </div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={toggleDialog}
            >
              닫기
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
      {vendorToggle && (
        <Dialog
          title={`운반/처리업체 비용 처리 현황 (${moment(
            dateRange.startDate
          ).format("YY. MM. DD")} ~ 
          ${moment(dateRange.endDate).format("YY. MM. DD")})`}
          onClose={vendorToggleDialog}
          height={"700px"}
          width={"80%"}
        >
          <VendorDialog
            tranVendorData={tranVendorData}
            dispVendorData={dispVendorData}
          />
        </Dialog>
      )}
    </div>
  );
};

export default OverallInfoPage;

const arr_chunk = (arr) => {
  if (arr.length > 20) {
    const arr_to_20 = arr.slice(0, 20);
    const arr_from_20 = arr.slice(20);

    const others_obj = {
      id: 21,
      name: "기타",
      cost_sum: _.sumBy(arr_from_20, "cost_sum"),
    };

    return [...arr_to_20, others_obj];
  } else {
    return arr;
  }
};
