import React from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import OverrallMabPerformanceChart from "../Chart/OverrallMabPerformanceChart";

const OverallManbPerformanceDetail = ({ height, com }) => {
  return (
    <div>
      <GridLayout
        style={{ height: height }}
        cols={[
          {
            width: "12%",
          },
          {
            width: "21%",
          },
          {
            width: "21%",
          },
          {
            width: "21%",
          },
          {
            width: "21%",
          },
        ]}
        rows={[
          {
            height: "100%",
          },
        ]}
        gap={{
          cols: 0,
          rows: 0,
        }}
      >
        <GridLayoutItem
          col={1}
          row={1}
          style={{
            padding: "0",
          }}
        >
          <div className="overallMonthIncreaseBox">
            <div
              className="overallMonthIncrease"
              style={{ fontSize: "0.7rem" }}
            >
              {com.name.replace("사업본부", "")}
            </div>
          </div>
        </GridLayoutItem>
        {com.base_code.map((com, idx) => {
          return (
            <GridLayoutItem col={idx + 2} row={1} key={idx}>
              <OverrallMabPerformanceChart height={height} com={com} />
            </GridLayoutItem>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default OverallManbPerformanceDetail;
