import React, { useEffect, useState } from "react";
import _ from "lodash";
import ManbDetailChart from "../../../Components/ManbDetailChart";

const ManbWaste = ({
  height,
  base_code,
  projectActTotalData,
  targetProject,
  setReportChartData,
}) => {
  const gridHeight = height - 32 - 45;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const chart_data_base_set = base_code
      .map((com) => ({
        ...com,
        planWeight:
          targetProject.data_arr.length > 0
            ? targetProject.data_arr.find((com2) => com2.code === com.code)[
                "plan_weight"
              ]
            : 0,
        actualWeight: _.sumBy(
          projectActTotalData.filter(
            (com2) => com2.uxesg_WasteCode === com.code
          ),
          "uxesg_CarryQty2"
        ),
        plan_cost:
          targetProject.data_arr.length > 0
            ? targetProject.data_arr.find((com2) => com2.code === com.code)[
                "plan_cost"
              ]
            : 0,
        actualCost: _.sumBy(
          projectActTotalData.filter(
            (com2) => com2.uxesg_WasteCode === com.code
          ),
          "uxesg_CostTotal"
        ),
      }))
      .map((com) => ({
        ...com,
        devWeight: com.planWeight - com.actualWeight,
        devCost: com.plan_cost - com.actualCost,
      }));

    setReportChartData((prev) => ({ ...prev, manb: chart_data_base_set }));
    setChartData(chart_data_base_set);
  }, [base_code, projectActTotalData, targetProject]);

  return (
    <div>
      <ManbDetailChart height={gridHeight} chartData={chartData} />
    </div>
  );
};

export default ManbWaste;
