import React, { useMemo } from "react";
import { GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartLegend,
  ChartArea,
  ChartTooltip,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import "hammerjs";
import _ from "lodash";
import {
  CostDivBox,
  CostDivTitle,
  CostDivCost,
} from "../../../Public/StyledComponents";

const StockCost = ({ height, materialInventoryManagerData }) => {
  const inventory_cost_sum = useMemo(() => {
    return Number(
      (
        _.sumBy(materialInventoryManagerData, "umatTotalPriceSNL") / 100000000
      ).toFixed(1)
    );
  }, [materialInventoryManagerData]);

  const chartData = useMemo(() => {
    const category_group = _(materialInventoryManagerData)
      .groupBy("umatCategoryPD")
      .map((objs, key, idx) => {
        return {
          category: key,
          value: _.sumBy(objs, "umatTotalPriceSNL") / 100000000,
          objs,
        };
      })
      .value()
      .map((com, idx) => ({ ...com, color: handleColor(idx) }));
    return category_group;
  }, [materialInventoryManagerData]);

  const labelContent = (e) => {
    return `${e.category}: \n ${((e.value / inventory_cost_sum) * 100).toFixed(
      1
    )}%`;
  };

  const renderTooltip = (context) => {
    const { category, value } = context.point || context;
    return (
      <div>
        {category}: {((value / inventory_cost_sum) * 100).toFixed(1)}%
      </div>
    );
  };

  return (
    <>
      <GridLayoutItem row={1} col={1}>
        <CostDivBox>
          <CostDivTitle>총 재고 자산</CostDivTitle>
          <CostDivCost>{inventory_cost_sum} 억원</CostDivCost>
        </CostDivBox>
      </GridLayoutItem>
      <GridLayoutItem row={2} col={1} rowSpan={3}>
        <Chart style={{ height: height * 0.85, margin: 10 }}>
          <ChartTooltip render={renderTooltip} />
          <ChartLegend visible={false} />
          <ChartArea background="none" />
          <ChartSeries>
            <ChartSeriesItem
              type="donut"
              holeSize={height * 0.85 * 0.25}
              data={chartData}
              categoryField="category"
              field="value"
              color={colorList}
            >
              <ChartSeriesLabels
                position="outsideEnd"
                background="none"
                content={labelContent}
              />
            </ChartSeriesItem>
          </ChartSeries>
          {/* <ChartSeries>{internetGrowthData.map(mapSeries)}</ChartSeries> */}
        </Chart>
      </GridLayoutItem>
    </>
  );
};

export default StockCost;

const handleColor = (idx) => {
  const colorsLength = colorList.length;

  const num = parseInt(idx / colorsLength);

  if (idx >= colorsLength) {
    return colorList[idx - num * colorsLength];
  } else {
    return colorList[idx];
  }
};

const colorList = [
  "rgb(103, 183, 220)",
  "rgb(103, 148, 220)",
  "rgb(103, 113, 220)",
  "rgb(128, 103, 220)",
  "rgb(163, 103, 220)",
  "rgb(199, 103, 220)",
  "rgb(220, 103, 206)",
  "rgb(220, 103, 171)",
  "rgb(220, 103, 136)",
];
