// const record_project = "P-0054";
const sector_arr = [
  { sectors: ["개발사업본부", "건축사업본부"], sector: "건축사업본부" },
  {
    sectors: ["인프라개발사업실", "토목환경사업본부", "풍력사업실"],
    sector: "토목사업본부",
  },
  { sectors: ["플랜트사업본부"], sector: "플랜트사업본부" },
];

export default sector_arr;
