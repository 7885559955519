import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import EConstruction from "./Construction/EConstruction";
import EProcurement from "./Procurement/EProcurement";
import EDesign from "./Design/EDesign";
import EService from "./Service/EService";

import "./VendorEvaluation.scss";

const VendorEvaluation = () => {
  const location = useLocation();
  const { type } = useParams();
  const [pageRouter, setPageRouter] = useState("");

  const vendorName = location.state.vendorName;

  useEffect(() => {
    setPageRouter(type);
  }, [type]);

  switch (pageRouter) {
    case "construction":
      return (
        <div className="vendorEvaluation">
          <EConstruction vendorName={vendorName} />
        </div>
      );
    case "procurement":
      return (
        <div className="vendorEvaluation">
          <EProcurement vendorName={vendorName} />
        </div>
      );
    case "design":
      return (
        <div className="vendorEvaluation">
          <EDesign vendorName={vendorName} />
        </div>
      );
    case "service":
      return (
        <div className="vendorEvaluation">
          <EService vendorName={vendorName} />
        </div>
      );
    default:
      return;
  }
};

export default VendorEvaluation;
