import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import {
    GridLayout,
    GridLayoutItem,
    Card,
    CardTitle,
    CardBody,
    CardSubtitle,
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { Chart, ChartCategoryAxisItem, ChartCategoryAxis, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, exportVisual, ChartTitle } from "@progress/kendo-react-charts";
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import 'hammerjs';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { DropDownTree } from '@progress/kendo-react-dropdowns';
import { processTreeData, expandedState } from './tree-data-operations';
import axios from "axios";
import { Tooltip } from "@progress/kendo-react-tooltip";
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { RangeSlider, SliderLabel } from "@progress/kendo-react-inputs";


const REACT_APP_URI2 = 'https://api.dtsolution.kr'
const REACT_APP_PATH2 = 'home/centos/download'

const ToggleButton = styled(MuiToggleButton)(() => ({
    '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: '#4B5335',
    },
    '&.MuiToggleButton-root': {
        fontSize: '0.7rem',
        fontFamily: 'Pretendard-R',
        padding: 0,
        width: 75,
        borderRadius: '5px 5px 5px 5px!important',
        border: '1px solid rgba(0, 0, 0, 0.12)!important'

    }
}));

const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({
    '&.MuiToggleButtonGroup-root': {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
    }
}));

const theme = createTheme({
    palette: {
        text: {
            primary: '#00ff00',
        },
    },
});


const selectField = "selected";
const expandField = "expanded";
const dataItemKey = "text";
const textField = "text";
const subItemsField = "items";
const fields = {
    selectField,
    expandField,
    dataItemKey,
    subItemsField,
};

const Progress1Test = () => {

    const { project_code } = useParams();
    const [selectdata, setselectdata] = useState([]);
    const [selectdata2, setselectdata2] = useState([]);
    const [result, setresult] = useState([]);

    console.log(result)
    
    const progressSource = '작업일보 기반'

    const [value2, setValue2] = useState({ text: '' });
    const [value, setValue] = useState(null);
    const today = moment(new Date()).format("YYYY-MM-DD");

    const [selectedValue, setSelectedValue] = React.useState("monthly");
    // const handleChange = React.useCallback(
    //     (e) => {
    //         setSelectedValue(e.value);
    //     },
    //     [setSelectedValue]
    // );
    const handleChangeChart = (event, selectedValue) => {
        if (selectedValue !== null) {
            setSelectedValue(selectedValue);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(
                `${REACT_APP_URI2}/api/getJSON?path=/${REACT_APP_PATH2}/file/activityprogress/${project_code}/${today}_act.json`
            );

            const datafrom = res.data

            // progressSource === '작업일보 기반' ? res.data : res.data.map((v) => ({
            //     ...v,
            //     d_evm_actual_start: typeof v.ManualActualStart !== 'undefined' ? v.ManualActualStart : v.d_evm_actual_start,
            //     d_evm_actual_finish: typeof v.ManualActualFinish !== 'undefined' ? v.ManualActualFinish : v.d_evm_actual_finish,
            //     d_actual_progress: typeof v.ManualActualProgress !== 'undefined' ? v.ManualActualProgress : v.d_actual_progress,
            // }))

            const resPVM = await axios.get(
                `${REACT_APP_URI2}/api/getJSON?path=/${REACT_APP_PATH2}/file/activitypv/${project_code}_M.json`
            );

            const resPVW = await axios.get(
                `${REACT_APP_URI2}/api/getJSON?path=/${REACT_APP_PATH2}/file/activitypv/${project_code}_W.json`
            );
            const datafrom2 = (selectedValue === 'monthly' ? resPVM.data : resPVW.data).map(f => ({ ...f, Lv_1_Name: res.data.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId)['Lv_1_Name'], Lv_2_Name: res.data.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId)['Lv_2_Name'], Lv_3_Name: res.data.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId)['Lv_3_Name'], Lv_4_Name: res.data.find(sf => sf.uuu_P6ActivityId === f.uuu_P6ActivityId)['Lv_4_Name'] }))

            const data2 = 

            value2 === null && value === null ?
                _.filter(datafrom, function (o) { return o.d_weight_value !== 0 }) :

                value === null && value2.text === '' ?
                    _.filter(datafrom, function (o) { return o.d_weight_value !== 0 }) :
                    value2 === null && value !== null ?
                        _.filter(datafrom, function (o) { return o.d_weight_value !== 0 && o.Lv_4_Name === value; }) :
                        value !== null && value2.text === '' ?
                            _.filter(datafrom, function (o) { return o.d_weight_value !== 0 && o.Lv_4_Name === value; }) :
                            value2.text !== '' && value2 !== null && value !== null && typeof value2.items === 'undefined' ?
                                _.filter(datafrom, function (o) { return o.d_weight_value !== 0 && o.Lv_3_Name === value2.text && o.Lv_4_Name === value; }) :
                                value2.text !== '' && value2 !== null && value !== null && typeof value2.items !== 'undefined' ?
                                    _.filter(datafrom, function (o) { return o.d_weight_value !== 0 && o.Lv_2_Name === value2.text && o.Lv_4_Name === value }) :

                                    value2.text !== '' && value2 !== null && value === null && typeof value2.items === 'undefined' ?
                                        _.filter(datafrom, function (o) { return o.d_weight_value !== 0 && o.Lv_3_Name === value2.text }) :
                                        _.filter(datafrom, function (o) { return o.d_weight_value !== 0 && o.Lv_2_Name === value2.text })
            // value2 === null || value === null || value2.text === '' ?
            //     _.filter(datafrom, function (o) { return o.d_weight_value !== 0 }) :
            //     value2.text !== '' && typeof value2.items === 'undefined' ?
            //         _.filter(datafrom, function (o) { return o.d_weight_value !== 0 && o.Lv_3_Name === value2.text  && o.Lv_4_Name === value}) :
            //         _.filter(datafrom, function (o) { return o.d_weight_value !== 0 && o.Lv_2_Name === value2.text  && o.Lv_4_Name === value})
        

    const plandata = 
            value2 === null && value === null ?
                _.filter(datafrom2, function (o) { return o.d_weight_value !== 0 }) :

                value === null && value2.text === '' ?
                    _.filter(datafrom2, function (o) { return o.d_weight_value !== 0 }) :
                    value2 === null && value !== null ?
                        _.filter(datafrom2, function (o) { return o.d_weight_value !== 0 && o.Lv_4_Name === value; }) :
                        value !== null && value2.text === '' ?
                            _.filter(datafrom2, function (o) { return o.d_weight_value !== 0 && o.Lv_4_Name === value; }) :
                            value2.text !== '' && value2 !== null && value !== null && typeof value2.items === 'undefined' ?
                                _.filter(datafrom2, function (o) { return o.d_weight_value !== 0 && o.Lv_3_Name === value2.text && o.Lv_4_Name === value; }) :
                                value2.text !== '' && value2 !== null && value !== null && typeof value2.items !== 'undefined' ?
                                    _.filter(datafrom2, function (o) { return o.d_weight_value !== 0 && o.Lv_2_Name === value2.text && o.Lv_4_Name === value }) :

                                    value2.text !== '' && value2 !== null && value === null && typeof value2.items === 'undefined' ?
                                        _.filter(datafrom2, function (o) { return o.d_weight_value !== 0 && o.Lv_3_Name === value2.text }) :
                                        _.filter(datafrom2, function (o) { return o.d_weight_value !== 0 && o.Lv_2_Name === value2.text })




            const dayweight = data2.filter(v => v.d_evm_actual_start !== null)
                .map((v) => {
                    return {
                        uuu_P6ActivityId: v.uuu_P6ActivityId,
                        disc: v.Lv_4_Name,
                        Area: v.Lv_2_Name,
                        Subarea: v.Lv_3_Name,
                        d_evm_plan_start: v.d_evm_plan_start,
                        d_evm_plan_finish: v.d_evm_plan_finish,
                        d_weight_value: v.d_weight_value,
                        // onedayplanvalue: v.d_weight_value / moment(v.d_evm_plan_finish, "MM-DD-YYYY").diff(moment(v.d_evm_plan_start, "MM-DD-YYYY"), 'days'),
                        d_evm_actual_start: v.d_evm_actual_start,
                        d_evm_actual_finish: v.d_evm_actual_finish,
                        // onedayactualvalue:
                        //     v.d_evm_actual_start === null ? 0 : v.d_evm_actual_start !== null && v.d_evm_actual_finish === null ?
                        //         (v.d_weight_value * v.d_actual_progress / 100) / moment().diff(moment(v.d_evm_actual_start, "MM-DD-YYYY"), 'days')
                        //         :
                        //         (v.d_weight_value * v.d_actual_progress / 100) / moment(v.d_evm_actual_finish, "MM-DD-YYYY").diff(moment(v.d_evm_actual_start, "MM-DD-YYYY"), 'days'),

                        // childplan: dateRange(v.d_evm_plan_start, v.d_evm_plan_finish),
                        childactual: getDateRangeWithRatio(new Date(moment(v.d_evm_actual_start).format('YYYY-MM-DD')), v.d_evm_actual_finish !== null ? new Date(moment(v.d_evm_actual_finish).format('YYYY-MM-DD')) : new Date()),
                        // selectedValue ==='monthly' ? dateRange2(v.d_evm_actual_start, v.d_evm_actual_finish) :  weekdateRange(v.d_evm_actual_start, v.d_evm_actual_finish),
                        rate: v.d_actual_progress,
                        d_actual_wv: v.d_actual_wv

                    }
                })

            const actual = _.filter(dayweight, function (o) { return typeof o.childactual !== 'undefined'; }).reduce((c, v) => c.concat(v.childactual.map(o => Object.assign(o, { "earnedvalue": o.dayratio * (v.rate * v.d_weight_value) / 100 }))), []);



            const total_pv = _.sumBy(datafrom2, 'PV')

            var ev =
                _(actual)
                    .groupBy('date')
                    .map((objs, key) => ({
                        'date': new Date(key),
                        'EV': isNaN(_.sumBy(objs, 'earnedvalue')) ? 0 : _.sumBy(objs, 'earnedvalue'),
                        'C_EV': isNaN((_.sumBy(objs, 'earnedvalue') / total_pv) * 100) ? 0 : (_.sumBy(objs, 'earnedvalue') / total_pv) * 100
                    }))
                    .value();

            const ev_sort = _.sortBy(ev, 'date')

            let cumev = 0;
            const cumev_with = ev_sort.map(({ date, EV, C_EV }) => ({ date, EV, CC_EV: Number((cumev += C_EV)) }));



            var pv =
                _(plandata)
                    .groupBy('date')
                    .map((objs, key) => ({
                        'date': new Date(key),
                        'PV': isNaN(_.sumBy(objs, 'PV')) ? 0 : _.sumBy(objs, 'PV'),
                        'C_PV': isNaN((_.sumBy(objs, 'PV') / total_pv) * 100) ? 0 : (_.sumBy(objs, 'PV') / total_pv) * 100,
                    }))
                    .value();
            const pv_sort = _.sortBy(pv, 'date')

            let cumpv = 0;
            let cumpv2 = 0;
            const cumpv_with = pv_sort.map(({ date, PV, C_PV }) => ({ date, PV, CC_PV: Number((cumpv += C_PV)), C_PV2: cumpv2 += PV }));

            let dateList = getRangeBetweenDates(cumev_with, cumpv_with).map((v)=>({...v, kdate:moment(v.date).format('YYYY-MM-DD')}))

            console.log(cumpv_with)

            const result = cumpv_with.length > cumev_with.length ?
                dateList.map(v => ({ ...v, ...cumpv_with.map((v)=>({...v, kdate:moment(v.date).format('YYYY-MM-DD')})).find(sp => sp.kdate === v.kdate) }))
                    .map(f => ({ ...f, ...cumev_with.map((v)=>({...v, kdate:moment(v.date).format('YYYY-MM-DD')})).find(sf => sf.kdate === f.kdate), month_date: moment(f.date).format("YYYY-MM") }))

                // .map((v, index)=> ({...v, color:COLORS[index]}))
                :

                dateList.map(v => ({ ...v, ...cumev_with.map((v)=>({...v, kdate:moment(v.date).format('YYYY-MM-DD')})).find(sp => sp.kdate === v.kdate) }))
                    .map(f => ({ ...f, ...cumpv_with.map((v)=>({...v, kdate:moment(v.date).format('YYYY-MM-DD')})).find(sf => sf.kdate === f.kdate), month_date: moment(f.date).format("YYYY-MM") }))



            setresult(result)

            setselectdata(_.sortBy(_.uniqBy(datafrom.map((v) => { return { Area: v.Lv_2_Name, Subarea: v.Lv_3_Name } }), 'Subarea'), 'Area').filter(v => v.Area !== null));
            setselectdata2(_.sortBy(_.uniqBy(datafrom, 'Lv_4_Name'), 'Area').filter(v => v.Lv_4_Name !== null).map(v => v.Lv_4_Name));
        };

        fetchData();

    }, [project_code, value2, value, today, progressSource, selectedValue]);

    const BoxHeight = 500

    // const [BoxHeight, setBoxHeight] = useState(250);

    // useEffect(() => {

    //     const boxHeight = positiondata[order];
    //     setBoxHeight((blockH && blockH) * (boxHeight && boxHeight.rowSpan) - 80);
    // }, [positiondata, order, blockH]);

    function getDateRangeWithRatio(start_date, end_date) {
        // 결과 배열 초기화
        let dateArray = [];

        // start_date와 end_date가 정상적인 날짜 값인지 확인
        if (!start_date || !moment(start_date).isValid() || !end_date || !moment(end_date).isValid()) {
            return dateArray;
        }

        // start_date와 end_date를 moment 객체로 변환
        let startDateMoment = moment(start_date);
        let endDateMoment = moment(end_date).add(1, 'day');

        // start_date와 end_date 사이의 총 날짜 수 계산
        let totalDays = endDateMoment.diff(startDateMoment, 'days');

        // currentDate가 end_date보다 크거나 같으면 루프 중지
        while (startDateMoment < endDateMoment) {
            // 배열에 현재 날짜와 비율 정보 추가
            dateArray.push({
                date: startDateMoment.toDate(),
                dayratio: 1 / totalDays
            });
            // startDateMoment에 1일 추가
            startDateMoment.add(1, 'day');
        }

        return dateArray;
    }





    var dataarea = selectdata, keys = ['Area'], gresult = [], temp = { _: gresult };
    dataarea.forEach(function (a) {
        keys.reduce(function (r, k) {
            if (!r[a[k]]) {
                r[a[k]] = { _: [] };
                r._.push({ text: a['Area'], items: r[a[k]]._ });
            }
            return r[a[k]];
        }, temp)._.push({ text: a.Subarea });
    });
    const [expanded, setExpanded] = useState(['']);

    const [filter, setFilter] = React.useState({
        value: "",
        operator: "contains",
    });


    const onChange = (event) => setValue2(event.value);

    const onExpandChange = React.useCallback(
        (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
        [expanded]
    );

    const treeData = React.useMemo(
        () =>
            processTreeData(
                gresult,
                {
                    expanded,
                    value2,
                    filter
                },
                fields
            ),
        [expanded, value2, gresult, filter]
    );


    const onFilterChange = (event) => setFilter(event.filter);


    // console.log(plandata)
    // // const plan = dayweight.reduce((c, v) => c.concat(v.childplan.map(o => Object.assign(o, { uuu_P6ActivityId: v.uuu_P6ActivityId, "planvalue": o.daysratio * v.d_weight_value }))), []);


    function getRangeBetweenDates(dateList1, dateList2) {
        // dateList1과 dateList2의 모든 날짜를 하나의 배열에 추가
        let allDates = [...dateList1, ...dateList2];
        // allDates 배열이 빈 배열인 경우 null을 반환
        if (allDates.length === 0) {
            return null;
        }
        // allDates 배열에서 객체의 date 속성이 정의되어 있지 않은 객체가 포함된 경우 null을 반환
        if (allDates.some(d => d.date === undefined)) {
            return null;
        }
        // 모든 날짜 중에서 가장 빠른 날짜와 가장 느린 날짜를 찾음
        let minDate = allDates.reduce((min, d) => (d.date < min.date ? d : min), allDates[0]).date;
        let maxDate = allDates.reduce((max, d) => (d.date > max.date ? d : max), allDates[0]).date;

        // 결과 배열 초기화
        let dateArray = [];
        // minDate 복사
        let currentDate = new Date(minDate);
        // maxDate 다음 날 생성
        let endDate = new Date(maxDate);
        endDate.setDate(endDate.getDate() + 1);

        // currentDate가 maxDate보다 크거나 같으면 루프 중지
        while (currentDate < endDate) {
            // 배열에 현재 날짜 추가
            dateArray.push({ date: new Date(currentDate) });
            // currentDate에 1일 추가
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    }



    //   console.log(cumev_with.map((v)=>({...v, date2:typeof v.date})))



    // console.log(result)

    // const [result,setresult] =useState([]);

    // useEffect(() => {
    //     let dateList = getRangeBetweenDates(cumev_with, cumpv_with)

    //    const res =  cumpv_with.length > cumev_with.length ?
    //     dateList.map(v => ({ ...v, ...cumpv_with.find(sp => sp.date === v.date) }))
    //         .map(f => ({ ...f, ...cumev_with.find(sf => sf.date === f.date),  month_date: moment(f.date).format("YYYY-MM") }))

    //     // .map((v, index)=> ({...v, color:COLORS[index]}))
    //     :

    //     dateList.map(v => ({ ...v, ...cumev_with.find(sp => sp.date === v.date) }))
    //         .map(f => ({ ...f, ...cumpv_with.find(sf => sf.date === f.date),  month_date: moment(f.date).format("YYYY-MM") }))

    //         setresult(res)

    //     }, [cumev_with, cumpv_with]);


    // const COLORS = ['#DBF3FF', '#42B7F4', '#33B8B4', '#5E8EFF']



    const SharedTooltip = (props) => {
        const { points } = props;
        return (
          <div>
            {selectedValue === 'monthly' ? (
              <div>{moment(points[0].category).format("YY년 MM월")}</div>
            ) : (
              <div>
                {moment(points[0].category).format("YY년 MM월") +
                  " " +
                  Math.ceil(points[0].category.getDate() / 7) +
                  "주"}
              </div>
            )}
                {points.map((point, index) => (
                    point.series.name === '월간 실적' || point.series.name === '월간 계획' || point.series.name === '주간 계획' || point.series.name === '주간 실적' ?
                        <div key={index}>
                            {point.value === 0 ?
                                <span>{point.series.name} :0 </span>
                                :
                                <span>{point.series.name} :{Number.parseFloat(point.value / 1000).toFixed(0) + '천'}</span>
                            }
                        </div> :

                        <div key={index}>
                            {point.value === 0 ?
                                <span>{point.series.name} :0% </span> :
                                <span>{point.series.name === '' ? '누적 계획' : point.series.name} : {Number.parseFloat(point.value).toFixed(2) + '%'}</span>}
                        </div>

                ))}
          </div>
        );
      };

    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const labelContentAxis = (e) => e.value > 1000000 ? (e.value / 1000000).toFixed(0) + '백만' : e.value;
    const labelContentAxis1 = (e) => e.value > 100 ? '' : e.value + '%';

    const [zoomableyn, setzoomableyn] = useState(false);

    const toggleZoom = () => {
        setzoomableyn(!zoomableyn);
    };

    const chart = useRef(null);

    const onImageExportClick = () => {
        const chartVisual = exportVisual(chart.current);

        if (chartVisual) {
            exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "SCurvechart.png"));
        }
    };




    const charttitle =
        (value2 === null || value2.text === '') && value === null ?
            '시공 S-Curve (ALL)' :
            value2 !== null && value === null ?
                `시공 S-Curve (Area: ${value2.text})` :
                value2 === null && value !== null ?
                    `시공 S-Curve (공종: ${value})` :
                    `시공 S-Curve (Area: ${value2.text} / 공종: ${value})`


    const handleChange = (event) => {
        setValue(event.target.value);
    };



    const planline1 = result.filter(v=> Number(moment(v.date).format('YYYYMMDD')) <= Number(moment().add(1, selectedValue === 'monthly'?'month':'week').format('YYYYMMDD')))
    const planline2 =result.filter(v=> Number(moment(v.date).format('YYYYMMDD')) >= Number(moment().format('YYYYMMDD')))

    const planpercent =_.sortBy(result.filter(v=> Number(moment(v.date).format('YYYYMMDD')) <= Number(moment().format('YYYYMMDD')) && typeof v.CC_PV !== 'undefined'), 'date').reverse()[0] 
    && _.sortBy(result.filter(v=> Number(moment(v.date).format('YYYYMMDD')) <= Number(moment().format('YYYYMMDD')) && typeof v.CC_PV !== 'undefined'), 'date').reverse()[0] ['CC_PV']


    const actualpercent = _.maxBy(result.filter(v=> typeof v.CC_EV !== 'undefined'), 'CC_EV') &&
    _.maxBy(result.filter(v=> typeof v.CC_EV !== 'undefined'), 'CC_EV')['CC_EV']



    const [chartRange, setChartRange] = useState({ min: "", max: "" });
    const [dateRangeSlider, setDateRangeSlider] = useState({ min: "", max: "" });

    useEffect(() => {
        const date_arr = result.map((com) => com.date);

        const minDate = new Date(Math.min(...date_arr));
        const maxDate = new Date(Math.max(...date_arr));

        setChartRange({ min: minDate, max: maxDate });

        setDateRangeSlider({
            min: new Date(moment(minDate).add(-1, "days").format("YYYY-MM-DD")),
            max: new Date(moment(maxDate).add(1, "days").format("YYYY-MM-DD")),
        });
    }, [result]);

    const labelContent_0 = (e) => {
        const idx = e.index;

        return e.value === 0 || e.value === null || typeof e.value === "undefined"
            ? ""
            : selectedValue === 'monthly'
                ? moment(new Date(e.value)).format("YY년 MM월")
                : result.length <= 50
                    ? moment(new Date(e.value)).format("YY년 MM월") +
                    " " +
                    Math.ceil(new Date(e.value).getDate() / 7) +
                    "주"
                    : idx % 4 === 0
                        ? moment(new Date(e.value)).format("YY년 MM월") +
                        " " +
                        Math.ceil(new Date(e.value).getDate() / 7) +
                        "주"
                        : "";
    };

    const seriesLabels_0 = {
        visible: true,
        // Note that visible defaults to false
        padding: 0,
        font: "0.75rem Arial, sans-serif",
        position: "top",
        background: "none",
        rotation: { angle: "auto" },
        content: labelContent_0,
    };


    const handledateRange = (e) => {
        const { start, end } = e.value;

        const customStart = new Date(moment(new Date(start)).format("YYYY-MM-DD"));
        const customEnd = new Date(moment(new Date(end)).format("YYYY-MM-DD"));

        setChartRange({ min: customStart, max: customEnd });
      };
    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '70%',
                    },

                    {
                        height: '15%',
                    },
                    {
                        height: '15%',
                    },
                ]}

                cols={[
                    {
                        width: '18%',
                    }, {
                        width: '82%',
                    },


                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>

                <GridLayoutItem col={1} row={1}>
                    <Card style={{ width: '100%', height: BoxHeight * 0.65 }}>
                        <CardBody style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -40%)' }}>
                                <CardTitle style={{ textAlign: 'center', color: '#363945' }}>Dev.</CardTitle>

                                <CardTitle style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold' }}>

                                    {planpercent && actualpercent && actualpercent - planpercent > 0 ?
                                        <span style={{ color: '#00539C' }}>{(actualpercent - planpercent).toFixed(2)}</span> :
                                        <span style={{ color: '#9E1030' }}>{(actualpercent - planpercent).toFixed(2)}</span>}%</CardTitle>
                            </div>
                            <Tooltip anchorElement="target" position="top">
                                <div style={{ position: 'absolute', left: '5px', bottom: '5px' }} title="현재일까지 일할 계산된 계획값입니다.">

                                    <CardSubtitle style={{ color: '#939597', fontSize: '1rem', textAlign: 'center' }}>Plan</CardSubtitle>

                                    <CardSubtitle style={{ fontSize: '2rem', fontWeight: 'bold', textAlign: 'center' }}>  {planpercent && planpercent.toFixed(2)}%</CardSubtitle>
                                </div>
                            </Tooltip>

                            <div style={{ position: 'absolute', right: '5px', bottom: '5px' }}>
                                <CardSubtitle style={{ textAlign: 'center', color: '#939597', fontSize: '1rem' }}>Actual</CardSubtitle>
                                <CardSubtitle style={{ textAlign: 'center', fontSize: '2rem', fontWeight: 'bold', color: 'black' }}>{actualpercent && actualpercent.toFixed(2)}%</CardSubtitle>
                            </div>
                        </CardBody>
                    </Card>



                </GridLayoutItem>

                <GridLayoutItem col={1} row={2}>
                    <div>
                        <div style={{ marginLeft: '5px' }}>Area</div>
                        <DropDownTree
                            style={{
                                width: "100%", marginRight: '5px'
                            }}
                            data={treeData}
                            value={value2}
                            onChange={onChange}
                            placeholder="Select ..."
                            textField={textField}
                            dataItemKey={dataItemKey}
                            selectField={selectField}
                            expandField={expandField}
                            onExpandChange={onExpandChange}
                            filterable={true}
                            onFilterChange={onFilterChange}
                            filter={filter.value}
                        />
                    </div>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={3}>
                    <div>
                        <div style={{ marginLeft: '5px' }}>공종</div>
                        <ComboBox
                            style={{
                                width: "100%", marginRight: '5px'
                            }}
                            data={selectdata2}
                            value={value}
                            onChange={handleChange}
                            className='disc'
                        />
                    </div>
                </GridLayoutItem>


                <GridLayoutItem col={2} row={1} rowSpan={2}>

                    <div style={{ position: 'absolute', top: '10px', right: '80px' }}>
                        <span></span>
                        <ButtonGroup>
                            <Button
                                title="확대/이동 켜기"
                                iconClass='k-icon k-i-zoom-in'
                                themeColor={zoomableyn ? "primary" : null} fillMode="flat"
                                onClick={toggleZoom}
                            >
                            </Button>

                            {/* <Button
                                title="이미지로 저장"
                                iconClass='k-icon k-i-image-export'
                                fillMode="flat"
                                onClick={onImageExportClick}
                            >
                            </Button> */}


                        </ButtonGroup>
                    </div>
                    <div style={{ position: 'absolute', right: '12px', zIndex: 9999999 }} className='manRB'>
                        <ThemeProvider theme={theme}>
                            <ToggleButtonGroup size="small" onChange={handleChangeChart} value={selectedValue} exclusive aria-label="baseunit">

                                <ToggleButton value="weekly" aria-label="weekly">
                                    weekly
                                </ToggleButton>
                                <ToggleButton value="monthly" aria-label="monthly">
                                    monthly
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </ThemeProvider>

                    </div>

                    <Chart ref={chart} pannable={zoomableyn} zoomable={zoomableyn}>
                        <ChartTitle text={charttitle} font="1.2rem pretendard-R" />
                        <ChartArea height={BoxHeight * 0.86} margin={20} />
                        <ChartLegend position="bottom" orientation="horizontal" />
                        <ChartValueAxis>
                            <ChartValueAxisItem name='월간' labels={{ content: labelContentAxis, font: "0.7rem Arial, pretendard-R" }} visible={false} majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} />
                            <ChartValueAxisItem name='누적(%)' min={0} labels={{ content: labelContentAxis1, font: "0.7rem Arial, pretendard-R" }} majorGridLines={{ step: 10 }} minorGridLines={{ step: 10 }} visible={true} />
                        </ChartValueAxis>
                        <ChartTooltip shared={true} render={sharedTooltipRender} />

                        <ChartSeries>

                            <ChartSeriesItem
                                color='#DBF3FF'
                                type="column"
                                data={result}
                                field="PV"
                                categoryField="date"
                                name={selectedValue === 'monthly' ? "월간 계획" : '주간 계획'}
                                autoFit={true}
                                axis='월간'
                            />


                            <ChartSeriesItem
                                color='#42B7F4'
                                type="column"
                                data={result}
                                field="EV"
                                categoryField="date"
                                name={selectedValue === 'monthly' ? "월간 실적" : '주간 실적'}
                                autoFit={true}
                                axis='월간'


                            />

                            <ChartSeriesItem
                                color='#B1CCE4'
                                type="line"
                                data={planline1}
                                field="CC_PV"
                                categoryField="date"
                                name="누적 계획"
                                autoFit={true}
                                axis='누적(%)'
                                markers={{
                                    visible: selectedValue ==='monthly'?true: false,
                                  }}

                            />
                            <ChartSeriesItem
                                color='#B1CCE4'
                                type="line"
                                data={planline2}
                                field="CC_PV"
                                categoryField="date"
                                name=""
                                autoFit={true}
                                axis='누적(%)'
                                dashType='dash'
                                markers={{
                                    visible: selectedValue ==='monthly'?true: false,
                                  }}


                            />


                            <ChartSeriesItem
                                color='#5E8EFF'
                                type="line"
                                data={result}
                                field="CC_EV"
                                categoryField="date"
                                name="누적 실적"
                                autoFit={true}
                                axis='누적(%)'
                                markers={{
                                    visible: selectedValue ==='monthly'?true: false,
                                  }}


                            />

                        </ChartSeries>


                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                                min={chartRange.min}
                                max={chartRange.max}
                                labels={seriesLabels_0}
                                baseUnit={selectedValue !== 'monthly' ? "weeks" : "months"}
                                axisCrossingValue={[0, result.length]}
                                majorGridLines={{ step: 100 }}
                                minorGridLines={{ step: 100 }}
                            />
                        </ChartCategoryAxis>

                    </Chart>
                </GridLayoutItem>

                <GridLayoutItem col={2} row={3} rowSpan={1}>
                    {chartRange.min !== "" && chartRange.max !== "" && (
                        <RangeSlider
                            min={dateRangeSlider.min.getTime()}
                            max={dateRangeSlider.max.getTime()}
                            value={{
                                start: chartRange.min.getTime(),
                                end: chartRange.max.getTime(),
                            }}
                            onChange={(e) => handledateRange(e)}
                            style={{
                                width: "100%",
                                fontSize: "10px",
                            }}
                            labels={{
                                position: "top",
                            }}
                        >
                            {_.uniqBy(result, "month_date").map((com, idx, arr) => {
                                if (idx !== 0 && idx !== arr.length - 1) {
                                    return (
                                        <SliderLabel
                                            key={idx}
                                            position={new Date(`${com.month_date}-01`).getTime()}
                                        >
                                            {moment(com.date).format("YY/MM")}
                                        </SliderLabel>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </RangeSlider>
                    )}
                </GridLayoutItem>
            </GridLayout>

        </>
        // <div></div>
    );




};

export default Progress1Test
