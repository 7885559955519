import React, { useEffect, useState } from "react";
import { TileLayout } from "@progress/kendo-react-layout";
import _ from "lodash";
import OverallTarget from "./OverallTarget/OverallTarget";
import OverallPerformance from "./OverallPerformance/OverallPerformance";
import OverallManbPerformance from "./OverallManbPerformance/OverallManbPerformance";
import OverallTargetPercent from "./OverallTargetPercent/OverallTargetPercent";

const OverallTotalPage = ({
  width,
  height,
  projectListData,
  projectActTotalData,
  setOverallTable,
  setSectorTable,
  setTargetPerformance,
  setTargetManbPerformance,
}) => {
  const [tiles, setTiles] = useState([]);
  const [sectorData, setSectorData] = useState([
    {
      name: "전사",
      data: [],
    },
    {
      name: "건축사업본부",
      data: [],
    },
    {
      name: "토목사업본부",
      data: [],
    },
    {
      name: "플랜트사업본부",
      data: [],
    },
  ]);

  useEffect(() => {
    const uniq_sector_arr_obj = _.uniqBy(
      projectListData,
      "uxesg_SectorName"
    ).map((com) => ({ name: com.uxesg_SectorName }));

    uniq_sector_arr_obj.unshift({ name: "전사" });

    const sector_arr_data_sum = uniq_sector_arr_obj.map((com) => {
      if (com.name === "전사") {
        return { ...com, data: projectListData };
      } else {
        return {
          ...com,
          data: projectListData.filter(
            (com2) => com2.uxesg_SectorName === com.name
          ),
        };
      }
    });

    

    setSectorData(sector_arr_data_sum);
  }, [projectActTotalData, projectListData]);

  useEffect(() => {
    const tiles = [
      {
        header: <div>전사 목표 달성 실적</div>,
        body: (
          <OverallTarget
            height={((height - 30) / 10) * 2}
            sectorData={sectorData}
          />
        ),
        reorderable: false,
      },
      {
        header: <div>현재 목표 / 실적(공정률 대비 달성률)</div>,
        body: (
          <OverallTargetPercent
            width={width}
            height={(height / 10) * 4}
            sectorData={sectorData}
            setOverallTable={setOverallTable}
            setSectorTable={setSectorTable}
          />
        ),
        reorderable: false,
      },
      {
        header: <div>목표 달성 실적</div>,
        body: (
          <OverallPerformance
            height={(height / 10) * 4}
            sectorData={sectorData}
            setTargetPerformance={setTargetPerformance}
          />
        ),
        reorderable: false,
      },
      {
        header: <div>성상별 목표 달성 실적</div>,
        body: (
          <OverallManbPerformance
            height={height}
            sectorData={sectorData}
            base_code={base_code}
            setTargetManbPerformance={setTargetManbPerformance}
          />
        ),
        reorderable: false,
      },
    ];

    setTiles(tiles);
  }, [width, height, sectorData]);

  return (
    <div>
      <TileLayout
        columns={2}
        rowHeight={(height - 90) / 10}
        positions={data}
        gap={{
          rows: 10,
          columns: 10,
        }}
        items={tiles}
        style={{ padding: "0px" }}
      />
    </div>
  );
};

export default OverallTotalPage;

const data = [
  {
    col: 1,
    colSpan: 1,
    rowSpan: 2,
  },
  {
    col: 1,
    colSpan: 1,
    rowSpan: 4,
  },
  {
    col: 1,
    colSpan: 1,
    rowSpan: 4,
  },
  {
    col: 2,
    colSpan: 1,
    rowSpan: 10,
  },
];

const base_code = [
  { code: "40-01-01", name: "폐콘크리트" },
  { code: "40-02-07", name: "폐합성수지" },
  { code: "40-02-06", name: "폐목재" },
  { code: "40-04-14", name: "혼합건설폐기물" },
];
