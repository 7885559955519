import React, { useEffect, useState } from "react";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import _ from "lodash";
import moment from "moment";
import OverallMonthIncreaseChart from "../../../Components/OverallMonthIncreaseChart";

const SectorMonthIncrease = ({ height, endDate, dateRangeData, base_code }) => {
  const gridHeight = height - 32 - 45;
  const [chartDatas, setChartDatas] = useState([]);

  useEffect(() => {
    const deleteTotal = dateRangeData.filter((com) => com.name !== "전사");

    const base_code_insert = deleteTotal.map((com) => ({
      ...com,
      base_code_data: base_code
        .map((com2) => {
          if (com2.code === "others") {
            return {
              ...com2,
              data: com.data.filter(
                (com3) =>
                  com3.uxesg_WasteCode !== "40-01-01" &&
                  com3.uxesg_WasteCode !== "40-02-07" &&
                  com3.uxesg_WasteCode !== "40-02-06" &&
                  com3.uxesg_WasteCode !== "40-04-14"
              ),
            };
          } else {
            return {
              ...com2,
              data: com.data.filter(
                (com3) => com3.uxesg_WasteCode === com2.code
              ),
            };
          }
        })
        .map((com2) => ({
          ...com2,
          data:
            com2.data.length > 0
              ? _.sortBy(
                  com2.data.map((com3) => ({
                    ...com3,
                    carryDate: new Date(com3.uxesg_CarryDate.slice(0, 10)),
                    carryMonth: moment(
                      new Date(com3.uxesg_CarryDate.slice(0, 10))
                    ).format("YYYY-MM"),
                    carryMonth2: moment(
                      new Date(com3.uxesg_CarryDate.slice(0, 10))
                    ).format("YY-MM"),
                  })),
                  "carryDate"
                )
              : [],
        }))
        .map((com2) => ({
          ...com2,
          data2: _.uniqBy(com2.data, "carryMonth").map((com3) => ({
            date: com3.carryDate,
            month: com3.carryMonth,
            month2: com3.carryMonth2,
            value: _.sumBy(
              com2.data.filter((com4) => com4.carryMonth === com3.carryMonth),
              "uxesg_CarryQty2"
            ),
            cost: _.sumBy(
              com2.data.filter((com4) => com4.carryMonth === com3.carryMonth),
              "uxesg_CostTotal"
            ),
          })),
        }))
        .map((com2) => {
          const findIndex = com2.data2.findIndex(
            (com3) => com3.month === endDate
          );
          const findIndex2 = com2.data2.findIndex(
            (com3) =>
              com3.month ===
              moment(new Date(endDate)).subtract(1, "months").format("YYYY-MM")
          );

          return {
            ...com2,
            data3: [
              {
                month: moment(new Date(endDate))
                  .subtract(1, "months")
                  .format("YYYY-MM"),
                value:
                  com2.data2[findIndex2]?.["value"] === undefined
                    ? 0
                    : com2.data2[findIndex2]?.["value"],
                cost:
                  com2.data2[findIndex2]?.["cost"] === undefined
                    ? 0
                    : com2.data2[findIndex2]?.["cost"],
              },
              {
                month: endDate,
                value:
                  com2.data2[findIndex]?.["value"] === undefined
                    ? 0
                    : com2.data2[findIndex]?.["value"],
                cost:
                  com2.data2[findIndex]?.["cost"] === undefined
                    ? 0
                    : com2.data2[findIndex]?.["cost"],
              },
            ],
          };
        }),
    }));

    setChartDatas(base_code_insert);
  }, [dateRangeData, base_code, endDate]);

  return (
    <div>
      <GridLayout
        style={{ gridHeight, alignItems: "" }}
        cols={[
          {
            width: "10%",
          },
          {
            width: "90%",
          },
        ]}
        rows={[
          {
            height: gridHeight / 3,
          },
          {
            height: gridHeight / 3,
          },
          {
            height: gridHeight / 3,
          },
        ]}
        gap={{
          cols: 0,
          rows: 5,
        }}
      >
        {chartDatas.map((com, idx) => {
          return (
            <React.Fragment key={idx}>
              <GridLayoutItem
                col={1}
                row={idx + 1}
                style={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                  padding: "10px 0",
                }}
              >
                <div className="overallMonthIncreaseBox">
                  <div className="overallMonthIncrease">
                    {com.name.replace("사업본부", "")}
                  </div>
                </div>
              </GridLayoutItem>
              <GridLayoutItem
                col={2}
                row={idx + 1}
                style={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
                  padding: "10px 0",
                }}
              >
                <OverallMonthIncreaseChart
                  height={gridHeight / 3 - 10 - 15}
                  chartData={com.base_code_data}
                  endDate={endDate}
                />
              </GridLayoutItem>
            </React.Fragment>
          );
        })}
      </GridLayout>
    </div>
  );
};

export default SectorMonthIncrease;
