import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import OrganizationChart from "@dabeng/react-orgchart";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import axios from "axios";
import _ from "lodash";
import Node from "./Node";
import Url from "../url/fetchURL";
import "./SkonEMSOrgChart.scss";

const SkonEMSOrgChart = () => {
  const { project_code } = useParams();
  const [orgChartData, setOrgChartData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        bpname: "조직도",
        lineitem: "yes",
        filter_condition: "status=Active",
      };

      const unifierData = await axios.post(
        `${Url}/getbprecord?path=${project_code}`,
        body
      );

      const dataf = unifierData.data.data.data.map((v) => ({
        id: v.record_no,
        position: v.orgPartSPD,
        biopic: v.OrgChartPhotoRT,
        name: v.OrgName,
        pid: v.orgParentBPK === null ? 0 : v.orgParentBPK,
        part: v.orgPartSPD,
      }));

      var nodes = function (data, root) {
        var r = [],
          o = {};
        data.forEach(function (a) {
          if (o[a.id] && o[a.id].children) {
            a.children = o[a.id] && o[a.id].children;
          }
          o[a.id] = a;
          if (a.pid === root) {
            r.push(a);
          } else {
            o[a.pid] = o[a.pid] || {};
            o[a.pid].children = o[a.pid].children || [];
            o[a.pid].children.push(a);
          }
        });

        return r;
      };

      const treeData = nodes(dataf, 0);
  
      setOrgChartData(...treeData);

      // const tableData = dataf.filter((com) => com.pid !== 0);
    };

    fetchData();
  }, []);

  return (
    <div className="skonEMSOrgChart">
      {orgChartData !== undefined && (
        <OrganizationChart
          datasource={orgChartData}
          chartClass="sekure-org-chart"
          NodeTemplate={Node}
          pan={true}
          zoom={true}
        />
      )}
    </div>
  );
};

export default SkonEMSOrgChart;
