import React, { useState } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import _ from "lodash";
import "./EDesign.scss";

const EDesign = ({ vendorName }) => {
  const [agree, setAgree] = useState({
    antiCorruption: null,
    creditSanctioningCompany: null,
    engineeringActivities: null,
  });

  const [evaluationResult, setEvaluationResult] = useState({
    step1: null,
    step2: null,
    step3: null,
    step4: null,
    step5: null,
    step6: null,
    step7: null,
    step8: null,
    step9: null,
  });

  const handleAgree = (e) => {
    const { target, value } = e;
    agree[target.props.name] = value;
    setAgree(agree);
  };

  const handleResult = (e) => {
    const { target, value } = e;
    switch (target.props.name) {
      case "step1":
        handleStep1(evaluationResult, target.props.name, value);
        return setEvaluationResult(evaluationResult);
      case "step2":
        handleStep2(evaluationResult, target.props.name, value);
        return setEvaluationResult(evaluationResult);
      case "step3":
        handleStep3(evaluationResult, target.props.name, value);
        return setEvaluationResult(evaluationResult);
      case "step4":
        handleStep4(evaluationResult, target.props.name, value);
        return setEvaluationResult(evaluationResult);
      case "step5":
        handleStep5(evaluationResult, target.props.name, value);
        return setEvaluationResult(evaluationResult);
      case "step6":
        handleStep6(evaluationResult, target.props.name, value);
        return setEvaluationResult(evaluationResult);
      case "step7":
        handleStep7(evaluationResult, target.props.name, value);
        return setEvaluationResult(evaluationResult);
      case "step8":
        handleStep8(evaluationResult, target.props.name, value);
        return setEvaluationResult(evaluationResult);
      case "step9":
        handleStep9(evaluationResult, target.props.name, value);
        return setEvaluationResult(evaluationResult);

      default:
        return setEvaluationResult(evaluationResult);
    }
  };

  const handleSubmit = () => {
    const agreeKeyArr = Object.keys(agree);
    const objKeyArr = Object.keys(evaluationResult);

    const agreeFilter = agreeKeyArr
      .map((com) => agree[com])
      .filter((com) => com === null);

    const resultFilter = objKeyArr
      .map((com) => evaluationResult[com])
      .filter((com) => com === null);
    console.log(resultFilter);
    console.log(agreeFilter);
    if (resultFilter.length === 0 && agreeFilter.length === 0) {
      const pointSum = _.sumBy(objKeyArr.map((com) => evaluationResult[com]));
      console.log(pointSum);
      console.log(agree);
    } else {
      console.log(evaluationResult);
      console.log(agree);
      alert("평가표를 모두 작성하여 주시기 바랍니다.");
    }
  };

  return (
    <div className="evaluation">
      <section className="evaluationHeader">
        <h3 className="evaluationHeaderTitle">신규 업체 평가서(설계)</h3>
      </section>
      <br />
      <section className="evaluationName">
        <h6 className="evaluationHeaderTitle">업체이름: {vendorName}</h6>
      </section>
      <br />
      <section className="evaluationBody">
        <section className="contentsBox">
          <h5 className="contentsTitle">여신제재업체 유무</h5>
          <div className="contentsBody">
            <ComboBox
              placeholder="Yes"
              suggest={true}
              style={{ width: "100%" }}
              data={["Yes", "No"]}
              onChange={handleAgree}
              name="creditSanctioningCompany"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">엔지니어링활동 주체 신고</h5>
          <div className="contentsBody">
            <ComboBox
              placeholder="Yes"
              suggest={true}
              style={{ width: "100%" }}
              data={["Yes", "No"]}
              onChange={handleAgree}
              name="engineeringActivities"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">준법 및 반부패준수 서약 동의</h5>
          <div className="contentsBody">
            <ComboBox
              placeholder="Yes"
              suggest={true}
              style={{ width: "100%" }}
              data={["Yes", "No"]}
              onChange={handleAgree}
              name="antiCorruption"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">1. 신용등급</h5>
          <div className="contentsBody">
            <ComboBox
              placeholder="A등급"
              suggest={true}
              style={{ width: "100%" }}
              data={["A등급", "BBB등급", "BB등급", "B등급", "C등급"]}
              onChange={handleResult}
              name="step1"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">2. 현금흐름등급</h5>
          <div className="contentsBody">
            <ComboBox
              placeholder="A등급"
              suggest={true}
              style={{ width: "100%" }}
              data={["A등급", "B등급", "C+등급", "C-등급", "D등급 이하"]}
              onChange={handleResult}
              name="step2"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">3. 영업이익률(%)</h5>
          <div className="contentsBody">
            <NumericTextBox
              style={{ width: "100%" }}
              placeholder="0"
              onChange={handleResult}
              name="step3"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">4. 유동비율(%)</h5>
          <div className="contentsBody">
            <ComboBox
              placeholder="180% 이상"
              suggest={true}
              style={{ width: "100%" }}
              data={[
                "180% 이상",
                "150% 이상",
                "120% 이상",
                "90% 이상",
                "60% 미만",
              ]}
              onChange={handleResult}
              name="step4"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">5. 회사운영기간(년)</h5>
          <div className="contentsBody">
            <NumericTextBox
              style={{ width: "100%" }}
              placeholder="0"
              onChange={handleResult}
              name="step5"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">6. 대표이사 등재기간(년)</h5>
          <div className="contentsBody">
            <NumericTextBox
              style={{ width: "100%" }}
              placeholder="0"
              onChange={handleResult}
              name="step6"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">7. 대표이사 경영능력</h5>
          <div className="contentsBody">
            <ComboBox
              placeholder="3년 흑자"
              suggest={true}
              style={{ width: "100%" }}
              data={["3년 흑자", "2년 흑자", "1년 흑자"]}
              onChange={handleResult}
              name="step7"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">8. 기술자 현황(인원)</h5>
          <div className="contentsBody">
            <ComboBox
              placeholder="300점 이상"
              suggest={true}
              style={{ width: "100%" }}
              data={[
                "300점 이상",
                "250점 이상",
                "200점 이상",
                "150점 이상",
                "150점 미만",
              ]}
              onChange={handleResult}
              name="step8"
            />
          </div>
        </section>
        <br />

        <section className="contentsBox">
          <h5 className="contentsTitle">9. 사업수행경험</h5>
          <div className="contentsBody">
            <ComboBox
              placeholder="300MV이상"
              suggest={true}
              style={{ width: "100%" }}
              data={[
                "300MV이상",
                "200MV이상",
                "100MV이상",
                "50MV이상",
                "50MV미만",
              ]}
              onChange={handleResult}
              name="step9"
            />
          </div>
        </section>
      </section>
      <br />
      <section className="submitButtonBox">
        <Button className="submitButton" onClick={handleSubmit}>
          제 출
        </Button>
      </section>
    </div>
  );
};

export default EDesign;

const handleStep1 = (obj, name, value) => {
  switch (value) {
    case "A등급":
      return (obj[name] = 15);

    case "BBB등급":
      return (obj[name] = 12);

    case "BB등급":
      return (obj[name] = 8);

    case "B등급":
      return (obj[name] = 5);

    case "C등급":
      return (obj[name] = 2);

    default:
      return (obj[name] = value);
  }
};

const handleStep2 = (obj, name, value) => {
  switch (value) {
    case "A등급":
      return (obj[name] = 15);

    case "B등급":
      return (obj[name] = 12);

    case "C+등급":
      return (obj[name] = 8);

    case "C-등급":
      return (obj[name] = 5);

    case "D등급 이하":
      return (obj[name] = 0);

    default:
      return (obj[name] = value);
  }
};

const handleStep3 = (obj, name, value) => {
  if (value === null) {
    return (obj[name] = value);
  } else {
    if (value > 4) {
      return (obj[name] = 5);
    } else if (value > 3 && value <= 4) {
      return (obj[name] = 4);
    } else if (value > 2 && value <= 3) {
      return (obj[name] = 3);
    } else if (value >= 1 && value <= 2) {
      return (obj[name] = 2);
    } else if (value < 1) {
      return (obj[name] = 1);
    }
  }
};

const handleStep4 = (obj, name, value) => {
  switch (value) {
    case "180% 이상":
      return (obj[name] = 5);

    case "150% 이상":
      return (obj[name] = 4);

    case "120% 이상":
      return (obj[name] = 3);

    case "90% 이상":
      return (obj[name] = 2);

    case "60% 미만":
      return (obj[name] = 1);

    default:
      return (obj[name] = value);
  }
};

const handleStep5 = (obj, name, value) => {
  if (value === null) {
    return (obj[name] = value);
  } else {
    if (value > 12) {
      return (obj[name] = 5);
    } else if (value > 9 && value <= 12) {
      return (obj[name] = 4);
    } else if (value > 6 && value <= 9) {
      return (obj[name] = 3);
    } else if (value >= 3 && value <= 6) {
      return (obj[name] = 2);
    } else if (value < 3) {
      return (obj[name] = 1);
    }
  }
};

const handleStep6 = (obj, name, value) => {
  if (value === null) {
    return (obj[name] = value);
  } else {
    if (value > 10) {
      return (obj[name] = 2.5);
    } else if (value >= 7 && value <= 10) {
      return (obj[name] = 2);
    } else if (value >= 5 && value <= 6) {
      return (obj[name] = 1.5);
    } else if (value >= 3 && value <= 4) {
      return (obj[name] = 1);
    } else if (value < 3) {
      return (obj[name] = 0.5);
    }
  }
};

const handleStep7 = (obj, name, value) => {
  switch (value) {
    case "3년 흑자":
      return (obj[name] = 2.5);

    case "2년 흑자":
      return (obj[name] = 2);

    case "1년 흑자":
      return (obj[name] = 1);

    default:
      return (obj[name] = value);
  }
};

const handleStep8 = (obj, name, value) => {
  switch (value) {
    case "300점 이상":
      return (obj[name] = 25);

    case "250점 이상":
      return (obj[name] = 17);

    case "200점 이상":
      return (obj[name] = 10);

    case "150점 이상":
      return (obj[name] = 7);

    case "150점 미만":
      return (obj[name] = 3);

    default:
      return (obj[name] = value);
  }
};

const handleStep9 = (obj, name, value) => {
  switch (value) {
    case "300MV이상":
      return (obj[name] = 25);

    case "200MV이상":
      return (obj[name] = 17);

    case "100MV이상":
      return (obj[name] = 10);

    case "50MV이상":
      return (obj[name] = 7);

    case "50MV미만":
      return (obj[name] = 3);

    default:
      return (obj[name] = value);
  }
};
